import http from '@/http'
import i18n from '@/locales'
import router from '@/router'
import columns from './columns'

import {
  BOM_RECEIVED,
  BOM_ILLUSTRATION_RECEIVED,
  BOM_ITEMS_SELECT,
  BOM_LOADING,
  BOM_UPDATE_LAYOUT,
  CLEAR_BOM,
  THREE_D_MODAL_OPEN,
  THREE_D_MODAL_CLOSE
} from '../contentTypes'
import { entityTypePluralize } from '../contentHelpers'

const state = getDefaultState()

const actions = {
  close3dModal ({ commit }) {
    commit(THREE_D_MODAL_CLOSE)
  },
  open3dModal ({ commit }, bomItem) {
    commit(THREE_D_MODAL_OPEN, bomItem)
  },
  async getBom ({ commit, dispatch }, { id, type }) {
    try {
      await dispatch('getBomColumns')
      if (type === 'page') {
        if (!state.illustration || id !== state.illustration.id) {
          commit(BOM_LOADING)

          const entityTypePlural = entityTypePluralize(type)
          const { data } = await http.get(`${entityTypePlural}/${id}/parts`)
          await dispatch('mapBomItems', { bom: data })
        }
      }
    } catch (err) {
      console.log('err :>> ', err)
      const response = err.response
      router.replace({ path: '/error', query: { status: response.status } }).catch(() => {})
    }
  },
  // A copy of above, to respect the different callers and their respective privileges
  async getAdminBom ({ commit, dispatch, state }, { id, type }) {
    try {
      await dispatch('getBomColumns')
      if (type === 'page') {
        /*
         * Content must be committed to the store prior to invocation
         * Otherwise, the if-check below will fail
         */
        if (!state.illustration || id !== state.illustration.id) {
          commit(BOM_LOADING)

          const entityTypePlural = entityTypePluralize(type)
          const { data } = await http.get(`admin/${entityTypePlural}/${id}/parts`)
          await dispatch('mapBomItems', { bom: data })
        }
      }
    } catch (err) {
      console.log('err :>> ', err)
      const response = err.response
      router.replace({ path: '/error', query: { status: response.status } }).catch(() => {})
    }
  },
  selectBomItems ({ commit }, { item }) {
    let found = false
    if (this.selected && this.selected.length) {
      found = this.selected.find((selected) => selected === item)
    }

    if (!found) {
      commit(BOM_ITEMS_SELECT, { item })
    } else {
      commit(BOM_ITEMS_SELECT, { item: '' })
    }
  },
  selectBomItemsFromCallout ({ commit }, item) {
    const items = state.itemsMap[item]

    if (items && items.length) {
      commit(BOM_ITEMS_SELECT, { item: items[0] })
    } else {
      commit(BOM_ITEMS_SELECT, { item: '' })
    }
  },
  updateBomLayout ({ commit }, bomLayout) {
    const layout = bomLayout || (state.layout === 'horizontal' ? 'vertical' : 'horizontal')
    commit(BOM_UPDATE_LAYOUT, { layout })
  },
  async bomSearchWithReturn ({ state, rootState }, { query }) {
    try {
      if (query) {
        const length = Object.keys(state.itemsMap).length

        // Offset not supported by the endpoint
        const params = {
          limit: length || 1000,
          fk_page_id: rootState.content.id,
          q: query
        }

        const { data } = await http.get('bom-search', { params })
        return data.items
      }
    } catch (err) {
      // na
    }
    return []
  },
  async mapBomItems ({ commit }, { bom }) {
    let map = {}
    for (const bomItem of bom) {
      const index = bom.indexOf(bomItem);
      if (!map[bomItem.itemText]) {
        map[bomItem.itemText] = []
      }
      bomItem.popoverPositionBottom = (bom.length < 14) ? true : index < 14
      bomItem.showHotpointLinkPopover = false
      bomItem.showThumbnailPopover = false
      // calculating position based on existence of other popover items.
      // ordering is important here as thumbnail is always first in line of icons on UX
      // for the other thumbnails, the same prescription can be applied using the ordering sequence below
      let count = 0
      if(bomItem.commentCount > 0) count += 1
      if(bomItem.relatedCount > 0) count += 1
      if(bomItem.tagCount > 0) count += 1
      bomItem.thumbnailPopoverPosition = count += 1
      bomItem.index = index
      map[bomItem.itemText].push(bomItem)
    }
    commit(BOM_RECEIVED, { map: map, bom: bom })
  }
}

const mutations = {
  [BOM_RECEIVED] (state, { map, bom }) {
    state.items = bom
    state.selected = []
    state.itemsMap = map
    state.isLoaded = true
  },
  [BOM_ITEMS_SELECT] (state, { item }) {
    if (item) {
      if (item.itemText === i18n.global.t('itemNotShown') || !item.itemText) {
        state.selected = [item]
      } else {
        state.selected = state.itemsMap[item.itemText]
      }

      // Scroll to Element
      const element = document.getElementById(item.pagePartId)
      if (element) {
        element.scrollIntoView({
          block: 'center',
          inline: 'start'
        })
      }
    } else {
      state.selected = []
    }
  },
  [BOM_ILLUSTRATION_RECEIVED] (state, { illustration }) {
    state.illustration = illustration
      ? {
          id: illustration.id || '',
          name: illustration.name || '',
          type: illustration.type || '',
          contentType: illustration.contentType || ''
        }
      : null
  },
  [BOM_UPDATE_LAYOUT] (state, { layout }) {
    state.layout = layout
  },
  [BOM_LOADING] (state) {
    state.isLoaded = false
    state.selected = []
  },
  [CLEAR_BOM] (state) {
    Object.assign(state, getDefaultState())
  },
  [THREE_D_MODAL_CLOSE] (state) {
    state.selectedBomItemFor3DModal = null
  },
  [THREE_D_MODAL_OPEN] (state, bomItem) {
    state.selectedBomItemFor3DModal = bomItem
  }
}

export default {
  state,
  actions,
  mutations,
  modules: {
    columns
  }
}

export function getDefaultState() {
  return {
    illustration: undefined,
    isLoaded: false,
    items: [],
    itemsMap: {},
    layout: 'horizontal',
    selected: undefined,
    selectedBomItemFor3DModal: null
  }
}
