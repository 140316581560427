import * as Communicator from '@/plugins/hoops/types/web-viewer'
import {
  Projection,
  ViewOrientation
} from '@/plugins/hoops/types/web-viewer'
import { readonly } from 'vue'

// Perspective is Projection or ViewOrientaiton
export type HoopsPerspective = {
  iconType: string // defined and ported from HOOPS style class
  labelKey: string
  type: HoopsPerspectiveType
  value: Projection|ViewOrientation;
}

export function toProjectionStringValue(value: Projection) {
  return `Projection-${Communicator.Projection[value]}`
}

export function toViewOrientationStringValue(value: ViewOrientation) {
  return `ViewOrientation-${Communicator.ViewOrientation[value]}`
}

export function getRawValue(perspective: HoopsPerspective | null) {
  switch (perspective?.type) {
    case HoopsPerspectiveType.Projection:
      return toProjectionStringValue(<Projection>perspective.value)
    case HoopsPerspectiveType.ViewOrientation:
      return toViewOrientationStringValue(<ViewOrientation>perspective.value)
    default:
      return ''
  }
}

export enum HoopsPerspectiveType {
  Projection,
  ViewOrientation
}

export const projectionDefault: HoopsPerspective = {
  iconType: 'ortho',
  labelKey: 'threeD.orientationAndProjection.orthographic',
  type: HoopsPerspectiveType.Projection,
  value: Communicator.Projection.Orthographic
}

/**
 * Structured for desktop use
 */
export const HoopsOrientationAndProjectionOptions = readonly<Map<string, HoopsPerspective[][]>>(
  new Map<string, HoopsPerspective[][]>([
    ['orientation', [
      [
        {
          iconType: 'iso',
          labelKey: 'threeD.orientationAndProjection.isometric',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Iso
        },
        {
          iconType: 'left',
          labelKey: 'threeD.orientationAndProjection.left',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Left
        },
        {
          iconType: 'right',
          labelKey: 'threeD.orientationAndProjection.right',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Right
        },
      ],
      [
        {
          iconType: 'bottom',
          labelKey: 'threeD.orientationAndProjection.bottom',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Bottom
        },
        {
          iconType: 'front',
          labelKey: 'threeD.orientationAndProjection.front',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Front
        },
        {
          iconType: 'back',
          labelKey: 'threeD.orientationAndProjection.back',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Back
        },
      ],
      [
        {
          iconType: 'top',
          labelKey: 'threeD.orientationAndProjection.top',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Top
        }
      ],
    ]],
    ['projection', [
        [
          {
            iconType: 'ortho',
            labelKey: 'threeD.orientationAndProjection.orthographic',
            type: HoopsPerspectiveType.Projection,
            value: Communicator.Projection.Orthographic
          },
          {
            iconType: 'persp',
            labelKey: 'threeD.orientationAndProjection.perspective',
            type: HoopsPerspectiveType.Projection,
            value: Communicator.Projection.Perspective
          },
        ]
    ]],
  ])
)

/**
 * Structured for mobile use
 */
export const HoopsOrientationAndProjectionOptionsForMobile = readonly<Map<string, HoopsPerspective[]>>(
  new Map<string, HoopsPerspective[]>([
    ['orientation',
      [
        {
          iconType: 'iso',
          labelKey: 'threeD.orientationAndProjection.isometric',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Iso
        },
        {
          iconType: 'left',
          labelKey: 'threeD.orientationAndProjection.left',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Left
        },
        {
          iconType: 'right',
          labelKey: 'threeD.orientationAndProjection.right',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Right
        },
        {
          iconType: 'bottom',
          labelKey: 'threeD.orientationAndProjection.bottom',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Bottom
        },
        {
          iconType: 'front',
          labelKey: 'threeD.orientationAndProjection.front',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Front
        },
        {
          iconType: 'back',
          labelKey: 'threeD.orientationAndProjection.back',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Back
        },
        {
          iconType: 'top',
          labelKey: 'threeD.orientationAndProjection.top',
          type: HoopsPerspectiveType.ViewOrientation,
          value: Communicator.ViewOrientation.Top
        }
      ],
    ],
    ['projection', [
      {
        iconType: 'ortho',
        labelKey: 'threeD.orientationAndProjection.orthographic',
        type: HoopsPerspectiveType.Projection,
        value: Communicator.Projection.Orthographic
      },
      {
        iconType: 'persp',
        labelKey: 'threeD.orientationAndProjection.perspective',
        type: HoopsPerspectiveType.Projection,
        value: Communicator.Projection.Perspective
      },
    ]],
  ])
)
