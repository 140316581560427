import { readonly } from 'vue'
import { v4 as uuidv4 } from 'uuid'

/*
 * Our system calls these options 'Slice'. HOOPS Communicator call these 'Cutting Planes'.
 */
export type HoopsSlice = {
  elementId: string
  iconType: string
  labelKey: string
  type: CuttingPlaneType
}

export enum CuttingPlaneType {
  PLANE_FACE,
  PLANE_SECTION,
  PLANE_VISIBILITY,
  PLANE_X,
  PLANE_Y,
  PLANE_Z,
  RESET
}

/**
 * Structured for desktop use
 */
export const HoopsSliceOptions = readonly<HoopsSlice[][]>([
  [
    {
      elementId: `hoops-option-${uuidv4()}`,
      iconType: 'cuttingplane-x',
      labelKey: 'threeD.slice.planeX',
      type: CuttingPlaneType.PLANE_X,
    },
    {
      elementId: `hoops-option-${uuidv4()}`,
      iconType: 'cuttingplane-toggle',
      labelKey: 'threeD.slice.visibility',
      type: CuttingPlaneType.PLANE_VISIBILITY,
    }
  ],
  [
    {
      elementId: `hoops-option-${uuidv4()}`,
      iconType: 'cuttingplane-y',
      labelKey: 'threeD.slice.planeY',
      type: CuttingPlaneType.PLANE_Y,
    },
    {
      elementId: `hoops-option-${uuidv4()}`,
      iconType: 'cuttingplane-section',
      labelKey: 'threeD.slice.section',
      type: CuttingPlaneType.PLANE_SECTION
    }
  ],
  [
    {
      elementId: `hoops-option-${uuidv4()}`,
      iconType: 'cuttingplane-z',
      labelKey: 'threeD.slice.planeZ',
      type: CuttingPlaneType.PLANE_Z
    },
    {
      elementId: `hoops-option-${uuidv4()}`,
      iconType: 'cuttingplane-reset',
      labelKey: 'threeD.slice.reset',
      type: CuttingPlaneType.RESET
    }
  ]
])

/**
 * Structured for mobile use (resolutions less than 1024px)
 */
export const HoopsSliceOptionsForMobile = readonly<HoopsSlice[]>([
  {
    elementId: `hoops-option-${uuidv4()}`,
    iconType: 'cuttingplane-x',
    labelKey: 'threeD.slice.planeX',
    type: CuttingPlaneType.PLANE_X,
  },
  {
    elementId: `hoops-option-${uuidv4()}`,
    iconType: 'cuttingplane-y',
    labelKey: 'threeD.slice.planeY',
    type: CuttingPlaneType.PLANE_Y,
  },
  {
    elementId: `hoops-option-${uuidv4()}`,
    iconType: 'cuttingplane-z',
    labelKey: 'threeD.slice.planeZ',
    type: CuttingPlaneType.PLANE_Z
  },
  {
    elementId: `hoops-option-${uuidv4()}`,
    iconType: 'cuttingplane-section',
    labelKey: 'threeD.slice.section',
    type: CuttingPlaneType.PLANE_SECTION
  },
  {
    elementId: `hoops-option-${uuidv4()}`,
    iconType: 'cuttingplane-toggle',
    labelKey: 'threeD.slice.visibility',
    type: CuttingPlaneType.PLANE_VISIBILITY,
  },
  {
    elementId: `hoops-option-${uuidv4()}`,
    iconType: 'cuttingplane-reset',
    labelKey: 'threeD.slice.reset',
    type: CuttingPlaneType.RESET
  }
])

