import { store } from '@/store'

export default function registerFetchInterceptor() {
  const { fetch: originalFetch } = window
  window.fetch = async (resource, config = {}) => {
    let response
    if (resource.includes('/document-engine/')) {
      /*
       * Document Engine is a separate machine using authentication,
       * signed by Turbo, and require custom headers.
       */
      response = await originalFetch(resource, config)
    } else {
      try {
        const { accessToken, hostedDomain } = store.state.auth
        config.headers = {
          // Any custom headers are removed
          ['Authorization']: `Bearer ${accessToken}`,
          ['Hosted-Domain']: hostedDomain
        }

        response = await originalFetch(resource, config)
      } catch(e) {
        console.error(e)
      }
    }
    return response
  }
}
