<template>
  <div class="content-filter-bar">
    <div class="field is-horizontal">
      <div class="field-body">
        <div v-if="filters.hasSearchTerm"
             class="field has-addons"
             :class="{'justify-end' : !isMobile}"
        >
          <div class="control has-icons-left">
            <input type="text"
                   class="input"
                   data-int="grid-filter"
                   data-unit="grid-filter"
                   v-model="searchString"
                   :placeholder="$tc('filter', 1)">
            <span class="icon is-left fas fa-search" />
          </div>
        </div>
        <div v-if="hasFilters"
             class="buttons mr-3">
          <button class="button"
                  data-int="grid-advanced-filter"
                  :class="{'has-text-primary': showModal}"
                  @click="expandModal()">
            <i class="fas fa-filter" />
          </button>
        </div>
        <div v-if="!!filters.dropdownOptions">
          <b-dropdown :value="filters.selectedDropdown"
                      :class="['mr-2', 'mb-2']"
                      append-to-body
                      trap-focus
                      aria-role="list"
                      data-int="user-viewer-dropdown"
                      data-unit="view-selector"
                      position="is-bottom-left"
                      @change="$emit('update:selectedDD', $event)">
            <template #trigger>
              <b-button icon-right="angle-down">
                {{ filters.selectedDropdown }}
              </b-button>
            </template>
            <b-dropdown-item v-for="(opt, i) in filters.dropdownOptions"
                        :key="i"
                        :value="opt"
                        aria-role="listitem">
              {{ opt }}
            </b-dropdown-item>
            <!-- <b-dropdown-item :value="false"
                             aria-role="listitem">
              {{ orgNameOnly }}
            </b-dropdown-item>
            <b-dropdown-item :value="true"
                             aria-role="listitem">
              {{ orgParentNames }}
            </b-dropdown-item> -->
          </b-dropdown>
        </div>
        <div v-if="(hasFilters || filters.hasSearchTerm) && !isMobile"
             class="">
          <button class="button mr-2"
                  :disabled="isFiltersEmpty"
                  @click="clearFilters"
                  data-int="grid-clear-filter"
                  data-unit="grid-clear-filter">
            <span>
              {{ $t('clearFilters') }}
            </span>
          </button>
        </div>
        <div v-if="filters.hasDeleteBtn">
          <b-button v-if="!hasSelections"
                    data-unit="multi-delete-trigger"
                    icon-left="fa fa-trash"
                    type="is-danger is-light"
                    class="button mr-2"
                    disabled>
            <span>{{ $t('delete') }}</span>
          </b-button>
          <b-button v-else
                    icon-left="fa fa-trash"
                    data-unit="multi-delete-trigger"
                    @click.exact="$emit('deleteSelected')"
                    @keydown.enter="$emit('deleteSelected')"
                    class="button mr-2"
                    type="is-danger is-light">
            {{ $t('delete') }}
          </b-button>
        </div>
        <div v-if="filters.hasAddBtn"
             class="field">
          <button class="button is-light is-success"
                  :disabled="filters.disableAdd"
                  @click="$router.push(filters.newMediaRedirect)">
            <span class="icon fas fa-plus" />
            <span data-unit="add-btn">{{ $t('add') }}</span>
          </button>
        </div>
        <div v-if="filters.hasAddMediaBtn"
             class="field">
          <button class="button is-light is-success"
                  @click="$emit('addSelected')">
            <span class="icon fas fa-plus" />
            <span data-unit="add-btn">{{ $t('add') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div v-show="showModal"
         class="filter-options mb-4">
      <div v-if="filters.hasUpdated"
           class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('updated') }}</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <span class="button is-static">
                {{ $t('after') }}
              </span>
            </div>
            <b-datepicker v-model="updatedFrom"
                          :useHtml5Validation="false"
                          locale="en-US"/>
            <div class="control">
              <span class="button is-static">
                {{ $t('before') }}
              </span>
            </div>
            <b-datepicker v-model="updatedTo"
                          :useHtml5Validation="false"
                          locale="en-US"/>
            <div class="control">
              <button class="button"
                      @click="updatedFrom = null; updatedTo = null">
                <i class="icon fas fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="filters.hasCreated"
           class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('createdOn') }}</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <span class="button is-static">
                {{ $t('after') }}
              </span>
            </div>
            <b-datepicker v-model="createdFrom"
                          :useHtml5Validation="false"
                          locale="en-US"/>
            <div class="control">
              <span class="button is-static">
                {{ $t('before') }}
              </span>
            </div>
            <b-datepicker v-model="createdTo"
                          :useHtml5Validation="false"
                          locale="en-US"/>
            <div class="control">
              <button class="button"
                      @click="createdFrom = null; createdTo = null">
                <i class="icon fas fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="filters.tagsMatching"
           class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $tc('tag', 1) }}</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <div class="select">
                <select class="input"
                        :class="{disabled: tagName === null}"
                        v-model="tagName"
                        :placeholder="$tc('tag', 1)"
                        @change="onTagNameChange"
                        :disabled="searchableTags.length === 0">
                  <option :value="null"
                          disabled>
                    <template v-if="searchableTags.length > 0">
                      {{ $tc('tag', 1) }}
                    </template>
                    <template v-else>
                      {{ $t('noSearchableTags') }}
                    </template>
                  </option>
                  <option v-for="tag in searchableTags"
                          :key="`tag-${tag.id}`"
                          :value="tag.name">
                    {{ tag.translatedName || tag.name || "Tag #" + tag.id }}
                  </option>
                </select>
              </div>
            </div>
            <div class="control">
              <span class="button is-static">
                &equals;
              </span>
            </div>
            <div class="control"
                 v-if="inputType === 'TEXTFIELD'">
              <input type="text"
                     class="input"
                     v-model="tagValue"
                     :placeholder="$t('value')"
                     :disabled="searchableTags.length < 1">
            </div>
            <div class="control"
                 v-else-if="inputType === 'LISTBOX'">
              <div class="select is-fullwidth">
                <select v-model="tagValue"
                        class="input"
                        :class="{disabled: tagValue === null}">
                  <option :disabled="true"
                          :value="null">
                    {{ $t('value') }}
                  </option>
                  <option v-for="(option, index) in tagNamePayload.selectOptions"
                          :key="`section-option-${index}`"
                          :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
            <template v-else-if="inputType === 'RANGE'">
              <div class="control">
                <div class="select"
                     v-if="tagNamePayload.prefixes && tagNamePayload.prefixes.length">
                  <select v-model="prefix"
                          class="input"
                          :class="{disabled: prefix === null}">
                    <option :disabled="true"
                            :value="null">
                      {{ $tc('prefix', 2) }}
                    </option>
                    <option v-for="(prefix, index) in tagNamePayload.prefixes"
                            :key="index"
                            :value="prefix">
                      {{ prefix }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="control">
                <input type="text"
                       class="input"
                       v-model="tagValue"
                       :placeholder="$t('value')">
              </div>
              <div class="control">
                <div class="select"
                     v-if="tagNamePayload.suffixes && tagNamePayload.suffixes.length">
                  <select v-model="suffix"
                          class="input"
                          :class="{disabled: suffix === null}">
                    <option :disabled="true"
                            :value="null">
                      {{ $tc('suffix', 2) }}
                    </option>
                    <option v-for="(suffix, index) in tagNamePayload.suffixes"
                            :key="index"
                            :value="suffix">
                      {{ suffix }}
                    </option>
                  </select>
                </div>
              </div>
            </template>
            <div class="control"
                 v-else>
              <span class="button is-static">
                {{ $tc('value', 1) }}
              </span>
            </div>
            <div class="control">
              <button class="button"
                      :disabled="searchableTags.length < 1"
                      @click="tagName = null; tagValue = null">
                <span class="icon fas fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="filters.hasPartCode"
           class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $tc('partCode', 1) }}</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <div class="select">
                <select v-model="partCodeId"
                        class="input"
                        :class="{disabled: partCodeId === null}">
                  <option data-unit="part-code-text"
                          :value="null"
                          disabled>
                    {{ $tc('partCode', 1) }}
                  </option>
                  <option v-for="partCode in partCodes"
                          :key="`partcode-${partCode.id}`">
                    {{ partCode.partcode }}
                  </option>
                </select>
              </div>
            </div>
            <div class="control">
              <button class="button"
                      @click="partCodeId = null">
                <i class="icon fas fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="filters.hasSupplier"
           class="field is-horizontal">
        <div class="field-label is-normal">
          <label  class="label"
                  data-unit="supplier-select-label">
            {{ $tc('supplier', 1) }}
          </label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="supplierKey"
                        data-unit="supplier-dropdown"
                        :class="{disabled: supplierKey === null}">
                  <option :value="null"
                          disabled>
                    {{ $tc('supplier', 1) }}
                  </option>
                  <option v-for="supplier in supplierKeys"
                          :key="`supplier-${supplier.supplierId}`"
                          :value="supplier.supplierKey">
                    {{ supplier.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="control">
              <button class="button"
                      @click="supplierKey = null">
                <i class="icon fas fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="filters.hasMediaType"
           class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $tc('type', 1) }}</label>
        </div>
        <div class="field-body">
          <div class="field has-addons">
            <div class="control">
              <div class="select">
                <select v-model="mediaType"
                        class="input"
                        :class="{disabled: mediaType === null}">
                  <option :value="null"
                          disabled>
                    {{ $tc('type', 1) }}
                  </option>
                  <option value="book">
                    {{ $tc('book', 1) }}
                  </option>
                  <option value="document">
                    {{ $tc('document', 1) }}
                  </option>
                  <option value="external">
                    {{ $tc('external', 1) }}
                  </option>
                  <option value="image">
                    {{ $tc('image', 1) }}
                  </option>
                  <option value="microsite">
                    {{ $tc('microsite', 1) }}
                  </option>
                  <option value="video">
                    {{ $tc('video', 1) }}
                  </option>
                </select>
              </div>
            </div>
            <div class="control">
              <button class="button"
                      @click="mediaType = null">
                <i class="icon fas fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
 The purpose of this component is to replace the ContentFilterBar component whenever the associated grid is converted to a Virtual Scroll list type.
 Any customizations needed to header filters should be constructed within the confines of this component.
 Not all future possibilities are known, nor have they been accounted for in the initial implementation.
 Currently, this acts as a one to one replacement of the ContentFilterBar component.
 Hard coding should be avoided in future customizations in favor of dynamic and configurable inputs.
 */
import { mapActions } from 'vuex'
import debounce from '@/plugins/debounce'
const fields = [
  'partCodeId',
  'tagName',
  'tagValue',
  'updatedFrom',
  'updatedTo',
  'supplierKey',
  'mediaType',
  'prefix',
  'suffix',
  'createdFrom',
  'createdTo'
]
// Query variable names for persisting state in this component
const namedQueries = [
  'media_type',
  'part_number_text_exact',
  'search',
  'supplier_key',
  'tag_name',
  'tag_value',
  'tag_range_prefix',
  'tag_range_suffix',
  'created',
  'updated'
]
export default {
  name: 'VirtualFilterBar',
  props: {
    gridName: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    hasSelections: {
      type: Boolean,
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  mixins: [debounce],
  data () {
    return {
      showModal: false,
      searchableTags: [],
      searchString: '',
      partCodes: [],
      supplierKeys: [],
      ...fields.reduce((acc, f) => ({ ...acc, [f]: null }), {}),

    }
  },
  debounceMethods: {
    debounceSetFilterProp: 500,
    debounceSearchString: 500
  },
  watch: {
    /*
    Push all filters within this component to query to persist state
    TagName and tagValue are an exception using different names for readability
     */
    effectiveFilter (filter, oldFilter) {
      const q = { ...this.queryParams }
      for (const o in oldFilter) {
        if (Object.prototype.hasOwnProperty.call(oldFilter, o)) {
          // Tags name and value need their hand held... gently
          if (o.split(':')[0] === 'tag') {
            delete q[o]
            delete q.tag_name
            delete q.tag_value
          } else {
            delete q[o]
          }
        }
      }
      for (const f in filter) {
        if (Object.prototype.hasOwnProperty.call(filter, f) && filter[f]) {
          // Tags name and value need their hand held... gently
          if (f.split(':')[0] === 'tag') {
            q.tag_name = f.split(':')[1]
            q.tag_value = filter[f]
          }
          q[f] = filter[f]
        }
      }
      this.debounceSetFilterProp({ grid: this.gridName, values: q })
    },
    searchString (value) {
      this.debounceSearchString({grid: this.gridName, value: value})
    },
    /*
    Por que watcher for tagName? Good question. TagName will not update
    via EffectiveFilter watch without a complete pair of name and value.
    But! We do need the name added or moved for filter state persistence,
    especially when prefix or suffix are applied.
     */
    tagName (name) {
      const q = { ...this.filters.queryParams }
      if (name && !this.tagValue) {
        q.tag_name = name
      } else if (!name && !this.tagValue) {
        delete q.tag_name
      }
    }
  },
  computed: {
    hasFilters () {
      return this.filters.hasUpdated ||
        this.filters.hasCreated ||
        this.filters.tagsMatching ||
        this.filters.hasPartCode ||
        this.filters.hasSupplier ||
        this.filters.hasMediaType
    },
    queryParams () {
      return this.$store.getters['grids/searchFilters'](this.gridName)
    },
    searchTerm () {
      return this.$store.getters['grids/filterTerm'](this.gridName)
    },
    isFiltersEmpty () {
      return this.filterCountApplied === 0 && !this.searchTerm
    },
    filterCountApplied () {
      return Object.keys(this.effectiveFilter).length
    },
    effectiveFilter () {
      const filter = {}
      if (this.partCodeId) {
        filter.part_number_text_exact = this.partCodeId
          .replace(/([-+\\])/g, '\\$1')
      }
      if (this.tagName && this.tagValue) {
        filter[`tag:${this.tagName}`] = this.tagValue
      }
      if (this.updatedFrom || this.updatedTo) {
        const start = this.updatedFrom ? new Date(this.updatedFrom).toISOString() : '*'
        const end = this.updatedTo ? new Date(this.updatedTo).toISOString() : 'NOW'
        filter.updated = `[${start} TO ${end}]`
      }
      if (this.supplierKey) {
        filter.supplier_key = this.supplierKey
      }
      if (this.mediaType) {
        filter.media_type = this.mediaType
      }
      if (this.prefix) {
        filter.tag_range_prefix = this.prefix
      }
      if (this.suffix) {
        filter.tag_range_suffix = this.suffix
      }
      if (this.createdFrom || this.createdTo) {
        const start = this.createdFrom ? new Date(this.createdFrom).toISOString() : '*'
        const end = this.createdTo ? new Date(this.createdTo).toISOString() : 'NOW'
        filter.created = `[${start} TO ${end}]`
      }
      return filter
    },
    inputType () {
      return this.tagNamePayload &&
        this.tagNamePayload.inputType
    },
    tagNamePayload () {
      return this.searchableTags.find((tn) => tn.name === this.tagName)
    },
    searchTermEmpty () {
      return (!this.searchTerm || !this.searchTerm.length)
    }
  },
  methods: {
    ...mapActions({
      setFilterProp: 'grids/setFilterProp',
      setSearchString: 'grids/setGridFilter',
      clearSearchFilters: 'grids/clearFilterProps'
    }),
    debounceSearchString(val) {
      this.setSearchString(val)
    },
    debounceSetFilterProp(val) {
      this.setFilterProp(val)
    },
    clearFilters () {
      fields.forEach((f) => {
        this[f] = null
      })
      this.searchString = ''
      this.setSearchString({grid: this.gridName, value: ''})
      this.clearSearchFilters(this.gridName)
    },
    expandModal () {
      this.showModal = !this.showModal
      this.$emit('expandedFilterBar', this.showModal)
    },
    async loadPartCodes () {
      try {
        const { data } = await this.$rest.get('/part-codes')
        this.partCodes = data
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async loadSuppliers () {
      try {
        const { data } = await this.$rest.get('/suppliers')
        this.supplierKeys = data
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async loadTagNames () {
      try {
        const { data } = await this.$rest.get('/tag-names')
        const matchValues = (match, value) => {
          if (!value) return false
          if (match instanceof Array) {
            return match.some((mv) => value.includes(mv))
          } else if (match instanceof Function) {
            return match(value)
          } else {
            return value.includes(match)
          }
        }
        const matchByKey = (match, value, key) => {
          return matchValues(match[key], value[key])
        }
        this.searchableTags = data.filter((tag) =>
          Object.keys(this.filters.tagsMatching)
            .every((tagKey) => matchByKey(this.filters.tagsMatching, tag, tagKey))
        )
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    onTagNameChange () {
      this.prefix = null
      this.suffix = null
      this.tagValue = null
    },
    resetTagFields () {
      this.tagName = null
      this.onTagNameChange()
    },
    checkForPersistedState () {
      if (Object.keys(this.queryParams).length > 0) {
        const query = { ...this.queryParams }
        // Filters defined within this component
        for (const property of namedQueries) {
          // Null is typically the value for placeholder select option items
          const value = query[property] ? query[property] : null
          const formattedValue = value?.replaceAll(/[[\]]/g, '').split(' TO ')
          switch (property) {
            case 'media_type':
              this.mediaType = value
              break
            case 'part_number_text_exact':
              this.partCodeId = value ? value.replaceAll(/[\\]/g, '') : null
              break
            case 'supplier_key':
              this.supplierKey = value
              break
            case 'search':
              this.searchString = this.searchTerm
              break
            case 'tag_name':
              this.tagName = value
              break
            case 'tag_value':
              this.tagValue = value
              break
            case 'tag_range_prefix':
              this.prefix = value
              break
            case 'tag_range_suffix':
              this.suffix = value
              break
            case 'updated':
              if (!value) {
                this.updatedFrom = null
                this.updatedTo = null
                break
              }
              this.updatedFrom = formattedValue[0] === '*' ? null : new Date(formattedValue[0])
              this.updatedTo = formattedValue[1] === 'NOW' ? null : new Date(formattedValue[1])
              break
            case 'created':
              if (!value) {
                this.createdFrom = null
                this.createdTo = null
                break
              }
              this.createdFrom = formattedValue[0] === '*' ? null : new Date(formattedValue[0])
              this.createdTo = formattedValue[1] === 'NOW' ? null : new Date(formattedValue[1])
              break
            default:
          }
        }
      }
    }
  },
  async mounted () {
    if(this.filters.hasPartCode) await this.loadPartCodes()
    if(this.filters.hasSupplier) await this.loadSuppliers()
    if(this.filters.tagsMatching && Object.keys(this.filters.tagsMatching).length > 0) {
      await this.loadTagNames()
    }
    this.checkForPersistedState()
    if(Object.keys(this.queryParams).length > 0) this.expandModal()
  }
}
</script>
<style scoped lang="scss">
.justify-end {
  justify-content: flex-end;
}
.content-filter-bar {
  position: relative;
  margin-right: 0.5rem;
}
.is-field-body-text {
  padding-top: 0.5rem;
  margin-right: 0.75rem;
}
select.disabled {
  color: grey;
  font-style: italic;
}
.has-spacer {
  margin-left: 0.75rem;
}
.content-filter-bar {
  position: relative;
}
</style>
