import http from '@/http'
import { SearchLogDto, SearchBarSelectionLogDto } from '@/interfaces/library'

export function setClickedThrough(dto: SearchLogDto) {
   http.put('search/record-clicked-through', dto)
}

export function logSearchBarSelection(dto: SearchBarSelectionLogDto) {
  http.put('search/search-bar-select', dto)
}
