<template>
  <article class="media"
           @dblclick.stop="open">
    <figure class="media-left is-hidden-mobile"
            @click.stop="open">
      <thumbnail :thumbnail-url="result.thumbnailUrl"
                 :thumbnail-type="entityType"
                 data-int="search-result-thumbnail"
                 class="is-48x48" />
    </figure>
    <div class="media-content">
      <div class="content">
        <p>
          <i v-if="result.favorited"
             class="fas fa-star" />
            <router-link :to="{ path: uri, query: query }"
                       :target="getTargetAttributeForMedia()"
                       @click="$store.dispatch('search/keepPagination')">
            <strong data-testid="result-name">{{ result.name }}</strong>
          </router-link>
          <br v-if="result.description">
          <span v-if="result.description"
                class="search-description"
                data-testid="description">
            {{ result.description }}
          </span>
          <br v-if="identifier">
          <span v-if="identifier"
                class="identifier-text"
                data-testid="identifier">
            {{ identifier }}
          </span>
        </p>
      </div>
    </div>
    <div class="media-right">
      <a class="icon is-medium"
         @click.stop.prevent="info">
        <i class="fa fa-2x fa-info-circle" />
      </a>
    </div>
  </article>
</template>

<script>
import Thumbnail from '@/components/common/Thumbnail'

export default ({
  name: 'SearchResult',
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  components: {
    Thumbnail
  },
  computed: {
    hideMediaIdentifier () {
      return this.$store.state.user.hideIdentifier
    },
    uri () {
      return `/${this.entityType}/${this.result.entityId}`
    },
    entityType () {
      return this.result.mediaType || this.result.entityType
    },
    query () {
      if ((this.entityType?.toLowerCase() === 'document') || ((this.entityType?.toLowerCase() === 'page') && (this.result?.identifier?.toLowerCase().endsWith('.pdf')))) {
        const { query: { q } } = this.$route
        const qVal = q?.replace('*','')
        return { q: qVal }
      } else return null
    },
    identifier () {
      switch (this.entityType) {
        case 'part':
          return `${this.$t('partNumber')}: ${this.result.identifier}`
        case 'page':
          return `${this.$t('identifier')}: ${this.result.identifier}`
        default:
          return this.hideMediaIdentifier ? '' : `${this.$t('identifier')}: ${this.result.identifier}`
      }
    }
  },
  methods: {
    getTargetAttributeForMedia () {
      if (this.result.mediaType === 'microsite' && this.result.openNewWindow) {
        return '_blank'
      } else {
        return ''
      }
    },
    info () {
      this.$emit('info', this.result)
    },
    open () {
      this.$emit('open', this.result)
    }
  }
})
</script>

<style scoped>
.image {
  cursor: pointer;
}
.media {
  margin: 0 !important;
  padding: 0.5rem;
}
.media:nth-child(even) {
  background: #fafafa;
}
.media:hover {
  background: whitesmoke;
}
.search-description {
  margin-top: .5rem;
}
.identifier-text {
  font-style: oblique;
}
</style>
