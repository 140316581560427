<template>
  <section  class="is-flex m-2 is-justify-content-space-between">
    <div class="is-flex">
      <table-of-contents  v-show="showTocDropdown"/>
      <widget-breadcrumbs   v-show="!isLoading"
                            :breadcrumbs="breadcrumbs"
                            @openAsset="handleRedirect"/>
    </div>
    <div class="buttons">
      <button v-if="hasDownloadableContent"
              class="button"
              @click="customDownload(contentUrl)">
        <span class="icon fas fa-download" />
        <span>{{ $t('download') }}</span>
      </button>
      <widget-toc-steppers v-show="showTocDropdown && !hasInfoPageOpen"/>
    </div>
  </section>
  <widget-loading-icon v-if="isLoading"/>
  <widget-content-header />
  <slot />
</template>

<script setup>
import WidgetBreadcrumbs from '../components/WidgetBreadcrumbs'
import WidgetLoadingIcon from '@/components/widgets/components/WidgetLoadingIcon'
import TableOfContents from '@/components/library/content/header/TableOfContents'
import WidgetTocSteppers from '@/components/widgets/components/WidgetTocSteppers'
import WidgetContentHeader from '@/components/widgets/components/WidgetContentHeader'
import { customDownload } from '@/helpers/customDownload'
import { toRaw, computed } from 'vue'
import { useBrowseFlows } from '@/components/widgets/composables/useBrowseFlows'
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import { useRoute } from 'vue-router'
import { useSearch } from '@/components/widgets/composables/useSearch'
import { useStore } from 'vuex'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import { WidgetRootBreadcrumbs } from '@/interfaces/global'

const {
  breadcrumbs,
  goHome,
  isBook,
  navigateToContent
} = useWidgetNavigator()
const { hasInfoPageOpen } = useInfoLandingPage()
const { isLoading } = useFetch()
const { navigateToSearch } = useSearch()
const { goToLastBrowse } = useBrowseFlows()
const route = useRoute()
const store = useStore()

const contentUrl = computed(() => store.state.content.contentUrl)
const filterQuery = computed(() => store.state.search.filters.query)
const hasDownloadableContent = computed(() => {
  const isBookPrintingDisabled = (store.state.content?.disableExport ?? false) ||
    (store.state.content.toc?.book?.disableExport ?? false)
  const hasDocumentEngine = store.getters['user/hasDocumentEngine']

  if (hasDocumentEngine && ['document', 'image', 'video'].includes(store.state.content.contentType)) {
    return !isBookPrintingDisabled
  }

  return false
})
const showToc = computed(() => {
  return store.getters['widgets/showToc']
})
const showTocDropdown = computed(() => {
  return showToc.value && isBook.value && !isLoading.value
})

function handleRedirect(dto) {
  switch(dto.type) {
    case WidgetRootBreadcrumbs.browse:
      goToLastBrowse()
      break
    case WidgetRootBreadcrumbs.search:
      navigateToSearch(toRaw((filterQuery.value || route.query.q) ?? ''), {
        clearAllContent: true })
      break
    case WidgetRootBreadcrumbs.home:
      goHome()
      break
    default:
      navigateToContent(toRaw(dto))
  }
}
</script>
