<template>
  <div class="field has-addons"
       v-d-click-outside="close"
  >
    <div class="control is-expanded has-icons-right search-control">
      <input v-model="input"
             :placeholder="placeholder"
             autocomplete="off"
             class="input is-primary is-flex-grow-1"
             data-int="search-input"
             type="text"
             @click="open"
             @input="loadSuggestions"
             @keydown.up="up"
             @keydown.down="down"
             @keyup.enter="enter">
        <span v-if="showClearButton" class="icon is-small is-right fa fa-times clear-button" @click="clear"/>
        <span class="icon is-small is-right fa fa-search search-icon-container" @click="enter"/>
      </input>
      <div v-if="searchBoxActive">
        <search-suggestions   v-if="hasSuggestions"
                              class="search-suggestions-box"
                              :suggestions="suggestions"
                              :suggestedBookSearchResults="suggestedBookSearchResults"
                              :suggestedPageSearchResults="suggestedPageSearchResults"
                              :suggestedPartSearchResults="suggestedPartSearchResults"
                              :suggestedExternalSearchResults="suggestedExternalSearchResults"
                              :suggestedDocumentSearchResults="suggestedDocumentSearchResults"
                              :suggestedImageSearchResults="suggestedImageSearchResults"
                              :suggestedVideoSearchResults="suggestedVideoSearchResults"
                              :suggestedMicrositeSearchResults="suggestedMicrositeSearchResults"
                              :suggestedSearchResultTypes="suggestedSearchResultTypes"
                              :loadingSuggestions="loadingSuggestions"
                              :loadingSuggestionResults="loadingSuggestionResults"
                              :query="input"
                              :exact-match="exactMatch"
                              @selectSuggestion="updateSearchSuggestions"
                              @close="close"
                              @searchContent="searchContent"
                              @searchAll="enter"
                              @toggleExactMatch="toggleExactMatch"
        />
        <div v-else>
          <div v-if="loadingSuggestions"
               class="search-info-box"
          >
            <utility-block class="spinner-space"/>
          </div>
          <div v-else>
            <div v-if="!hasSearchHistory"
                class="search-info-box"
            >
              <div class="search-header">
                <div>
                  <h3 class="title-three">
                    {{ $t('searchAllContent') }}
                  </h3>
                  <P class="search-helper-text">
                    {{ $t('beginTypingToFindAnything') }}
                  </P>
                </div>
              </div>
              <div v-if="!hideSearchButton" class="search-button">
                <button class="button" @click="enter">
                  <span class="search-button-text">{{ $t('viewAll') }}</span>
                  <span class="search-button-arrow fas fa-angle-right"/>
                </button>
              </div>
            </div>
            <div v-else
               class="search-history-box"
            >
              <search-history-display
                @selectSuggestion="initializeSuggestions"
                @close="close"
                @enter="enter"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router';
  import debounce from 'lodash.debounce'
  import { ref, computed, watch, onMounted } from "vue"
  import SearchSuggestions from './SearchSuggestions'
  import SearchHistoryDisplay from './SearchHistory'
  import UtilityBlock from '@/components/common/UtilityBlock'
  import { SearchCallTypes } from '@/helpers/SearchCallType'
  import { useWidgetNavigator } from "@/components/widgets/composables/useWidgetNavigator";
  import { useBreakpoints } from "@/helpers";
  const emit = defineEmits( ['enter','searchContent','updateInputWithoutSearch','toggleExactMatch'])
  const props = defineProps({
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    exactMatch: {
      type: Boolean,
      required: true
    },
    propertyField: {
      type: String,
      default: 'q'
    },
    content: {
      type: Object,
      default: () => {
      }
    },
    suggestionTypes: {
      type: Array,
      default: null
    },
    limit: {
      type: Number,
      default: 5
    },
    numberOfResults: {
      type: Number,
      default: 0
    }
  })
  const store = useStore()
  const route = useRoute()
  const searchBoxActive = ref(false)
  const loadingSuggestions = ref(false)
  const loadingSuggestionResults = ref(false)
  const suggestions = ref([])
  const suggestedSearchResultTypes = ref([])
  const suggestedBookSearchResults = ref([])
  const suggestedPageSearchResults = ref([])
  const suggestedPartSearchResults = ref([])
  const suggestedExternalSearchResults = ref([])
  const suggestedDocumentSearchResults = ref([])
  const suggestedImageSearchResults = ref([])
  const suggestedVideoSearchResults = ref([])
  const suggestedMicrositeSearchResults = ref([])
  const input = ref('')
  const hasSuggestions = computed(() => (!loadingSuggestions.value) && (suggestions.value?.length > 0 || suggestedSearchResultTypes.value?.length > 0))
  const searchHistory = computed(() => store.getters['search/getSearchHistory'])
  const hasSearchHistory = computed(() => searchHistory.value?.length > 0)
  const hideSearchButton = computed(() => store.state.user.searchHidden)
  const isWidget = computed(() => store.state.auth.isWidget)
  const { currentWidgetView } = useWidgetNavigator()
  const homeRoute = computed(() => { return (route.name === 'Home' || (isWidget.value && currentWidgetView.value === 'home')) })
  const { width } = useBreakpoints()
  const isDesktopView = computed(() => width.value >= 1024)
  const showClearButton = computed(() => searchBoxActive.value && input.value?.length > 0)

  const getSuggestions = async () => {
    const suggestionsList = await store.dispatch('search/searchSuggestionsWithReturn', {
        field: props.propertyField,
        q: input.value,
        content: props.content,
        searchableType: props.suggestionTypes
      })
      suggestions.value = suggestionsList ? suggestionsList.slice(0, props.limit) : []
  }
  const getSuggestedSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, callType: SearchCallTypes.CONTEXTUALSEARCH })
    suggestedSearchResultTypes.value = suggestedResults ? suggestedResults?.facets?.searchable_type : []
  }
  const getSuggestedBookSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, searchableType: 'book', limit: 3 })
    suggestedBookSearchResults.value = suggestedResults ? suggestedResults?.items : []
  }
  const getSuggestedPageSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, searchableType: 'Page', limit: 3 })
    suggestedPageSearchResults.value = suggestedResults ? suggestedResults?.items : []
  }
  const getSuggestedPartSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, searchableType: 'Part', limit: 3 })
    suggestedPartSearchResults.value = suggestedResults ? suggestedResults?.items : []
  }
  const getSuggestedExternalSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, searchableType: 'external', limit: 3 })
    suggestedExternalSearchResults.value = suggestedResults ? suggestedResults?.items : []
  }
  const getSuggestedDocumentSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, searchableType: 'document', limit: 3 })
    suggestedDocumentSearchResults.value = suggestedResults ? suggestedResults?.items : []
  }
  const getSuggestedImageSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, searchableType: 'image', limit: 3 })
    suggestedImageSearchResults.value = suggestedResults ? suggestedResults?.items : []
  }
  const getSuggestedVideoSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, searchableType: 'video', limit: 3 })
    suggestedVideoSearchResults.value = suggestedResults ? suggestedResults?.items : []
  }
  const getSuggestedMicrositeSearchResults = async (value) => {
    const suggestedResults = await store.dispatch('search/searchWithFacets', { q: value ?? input.value, exact: props.exactMatch, searchableType: 'microsite', limit: 3 })
    suggestedMicrositeSearchResults.value = suggestedResults ? suggestedResults?.items : []
  }
  const select = (value) => {
    input.value = value
    emit('enter', value)
    //ensure searchbox closes and isn't reopened by in-process search results
    setTimeout(() => {
      searchBoxActive.value = false
    }, 100)
  }
  const clear = () => {
    input.value = ''
    clearSuggestions()
    if (isDesktopView.value) {
      open()
    }
  }
  const clearSuggestions = () => {
    suggestions.value = []
    suggestedSearchResultTypes.value = []
    suggestedBookSearchResults.value = []
    suggestedPartSearchResults.value = []
    suggestedPageSearchResults.value = []
    suggestedVideoSearchResults.value = []
    suggestedMicrositeSearchResults.value = []
    suggestedImageSearchResults.value = []
    suggestedDocumentSearchResults.value = []
    suggestedExternalSearchResults.value = []
  }
  const searchContent = (value) => {
    searchBoxActive.value = false
    emit('searchContent', value, input.value)
  }
  const enter = () => {
    select(input.value)
  }
  const close = () => {
    searchBoxActive.value = false
  }
  const open = () => {
    searchBoxActive.value = true
  }
  const loadSuggestions = debounce(async function (e) {
    if (e.target?.value?.length >= 2) {
        searchBoxActive.value = true
        loadingSuggestions.value = true
        loadingSuggestionResults.value = true
        await Promise.all([
          getSuggestions(),
          getSuggestedBookSearchResults(),
          getSuggestedPageSearchResults(),
          getSuggestedPartSearchResults(),
          getSuggestedDocumentSearchResults(),
          getSuggestedExternalSearchResults(),
          getSuggestedImageSearchResults(),
          getSuggestedVideoSearchResults(),
          getSuggestedMicrositeSearchResults(),
          getSuggestedSearchResults()
        ]).finally(() => {
          loadingSuggestions.value = false
          loadingSuggestionResults.value = false
          emit('updateInputWithoutSearch', e.target?.value)
        })
    } else {
      searchBoxActive.value = true
      clearSuggestions()
    }
  }, 400)

  const updateSearchSuggestions = debounce(async (value) => {
    loadingSuggestionResults.value = true
    input.value = value
    await Promise.all([
      getSuggestedBookSearchResults(value),
      getSuggestedPageSearchResults(value),
      getSuggestedPartSearchResults(value),
      getSuggestedDocumentSearchResults(value),
      getSuggestedExternalSearchResults(value),
      getSuggestedImageSearchResults(value),
      getSuggestedVideoSearchResults(value),
      getSuggestedMicrositeSearchResults(value),
      getSuggestedSearchResults(value)]
    ).finally(() => {
      loadingSuggestionResults.value = false
    })
  }, 400)

  const initializeSuggestions = async (value) => {
    input.value = value
    await getSuggestions()
    await updateSearchSuggestions(value)
  }
  const toggleExactMatch = async () => {
    emit('toggleExactMatch')
    await updateSearchSuggestions(input.value)
  }

  watch(() => props.value, (currentValue, oldValue) => {
    if (currentValue !== oldValue) {
     input.value = currentValue || ''
     if (input.value.length === 0) {
       clearSuggestions()
     }
    }
  })

  watch(homeRoute, (newVal,oldVal) => {
    if (newVal && !oldVal) {
      input.value = ''
      clearSuggestions()
    }
  })

  onMounted(async () => {
    if (input.value && input.value !== '') {
      await initializeSuggestions(input.value)
    }
  })

</script>

<style scoped>
.is-expanded {
  /* needs to show above other content when opened */
  z-index: 10;
}

.menu-list {
  position: absolute;
  display: block;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  border: 1px solid lightgrey;
  z-index: auto;
}

input {
  box-shadow: none;
}

.search-icon-container {
  color: black !important;
  cursor: pointer;
  height: 2.0em !important;
  width: 2.0em !important;
  top: .25em !important;
  margin-right: .25em;
}

.clear-button {
  color: black !important;
  cursor: pointer;
  margin-right: 2.0em;
  height: 2.0em !important;
  width: 2.0em !important;
  top: .25em !important;
}

.search-control .icon {
  pointer-events: visiblepainted;
}

.search-info-box {
  text-align: center;
  position: absolute;
  display: block;
  background-color: #fff;
  width: 100%;
  height: 15em;
  overflow: auto;
  border: 1px solid lightgrey;
  z-index: auto;
  border-bottom-right-radius:15px;
  border-bottom-left-radius:15px;
  -moz-border-bottom-radius: 15px;
}

.search-history-box {
  position: absolute;
  width: 100%;
  height: 30em;
  overflow: hidden;
  border: 1px solid lightgrey;
  z-index: auto;
  border-bottom-right-radius:15px;
  border-bottom-left-radius:15px;
  -moz-border-bottom-radius: 15px;
}

.search-suggestions-box {
  position: absolute;
  width: 100%;
  height: 40em;
  overflow: auto;
  border: 1px solid lightgrey;
  z-index: auto;
  border-bottom-right-radius:15px;
  border-bottom-left-radius:15px;
  -moz-border-bottom-radius: 15px;
}

.search-header {
  text-align: center;
  border-radius: 25px;
  padding: 1em;
  width: 80%;
  background-color: lightgrey;
  position: absolute;
  top: 35%;
  left: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.search-helper-text {
  margin: 1em;
  font-weight: bold;
}

.search-button {
  text-align: center;
  position: absolute;
  bottom: 1em;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.search-button-text {
  font-weight: bold;
}

.search-button-arrow {
  margin-left: .5em;
}

.spinner-space {
  position: absolute;
  top: 30%;
  left: 50%;
}
</style>
