/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a}/{b}',
  about: 'Tietoa',
  aboutThisItem: 'Tästä nimikkeestä',
  accessApiKeyManager: 'Käytä API-avaimen hallinnointia tälle vuokralaiselle.',
  accessControls: 'Käyttöoikeudet',
  accessControlsCopiedText: 'Käyttöoikeudet päivitetään välittömästi. Median suojausoikeudet päivitetään muutaman minuutin kuluessa.',
  accessControlsCopiedTitle: 'Käyttöoikeuksien kopiointi onnistui',
  accessControlsCopyConfirmation: 'Tämä poistaa kaikki olemassa olevat käyttöoikeudet kohteesta {to} ja korvaa ne kohteen {from} käyttöoikeuksilla. Tätä toimintoa ei voi kumota.',
  accessControlsDescription: 'Käyttöoikeudet määrittävät Organisaatioiden ja Mediakategorioiden perusteella, kenellä on oikeus nähdä tämä media.',
  accessControlsIndeterminance: 'Huom.: Yläorganisaatioilla on aina pääsy sisältöön, joka on kohdistettu niiden alaorganisaatioihin.',
  account: 'Tili',
  accountErrorMessage: 'Tilin virheviesti',
  accountErrorMessageWarning: 'Tietokannan tilinVirheviesti asetetaan vuokralaiselle arvoon ”Ei lupaa”.',
  action: 'Toimintoa | Toimintoja',
  actionDelete: 'poista',
  actionLoad: 'lataa',
  actionPaste: 'Kopioi',
  actionSave: 'Tallenna',
  actionRun: 'suorita',
  actionUndoWarning: 'Tätä toimintoa ei voi kumota.',
  actionUpload: 'siirrä',
  actualSize: 'Todellinen Koko',
  add: 'Lisää',
  addAPIKey: 'Lisää API-avain',
  addItem: 'Lisää {item}',
  addABookText: 'Lisää kirja',
  addASharedCart: 'Lisää jaettu ostoskoriy',
  addATemplate: 'Lisää malli',
  addATag: 'Lisää merkki',
  addABookToStart: '{addABookText}, niin pääset alkuun.',
  addAForm: 'Aloita lisäämällä Lomake',
  addAFormOnly: 'Lisää lomake',
  addAMedia: 'Lisää media',
  addAnOrderQuoteEmailFormat: 'Lisää lomakkeen/tarjouksen sähköpostimuoto',
  addAPrintSettingText: 'Lisää Tulostusasetus',
  addAPrintSettingToStart: '{addAPrintSettingText}, niin pääset alkuun.',
  addBooks: 'Lisää Kirjoja',
  addChildBrowseFlow: 'Lisää Alatason Selausjärjestys',
  addChapter: 'Lisää luku',
  addComment: 'Lisää kommentti',
  addExistingPart: 'Lisää Olemassa Oleva Osa',
  addGroupsToUsers: 'Lisää ryhmiä {groups} käyttäjiin {users}',
  additionalInformation: 'Lisätiedot',
  additionalNotification: 'Lisäilmoitus',
  additionalPartNumberDescription: 'Käytä osanumeron lisäkenttänä',
  addBrowseFlow: 'Lisää selausjärjestys',
  addMedia: 'Lisää Media',
  addPages: 'Lisää Sivuja',
  addPart: 'Lisää Osa',
  addReports: 'Lisää raportteja',
  addRootBrowseFlow: 'Lisää Juuriselausjärjestys',
  addRow: 'Lisää rivi',
  addTemplates: 'Lisää mallit',
  addToCartMessage: '{partNumber} lisättiin onnistuneesti',
  addToUserGroup: 'Lisää Ryhmiin',
  address: 'Osoite | Osoitteet',
  addressInUse: 'Osoite on tällä hetkellä asetettuna oletusosoitteeksi, jota ei voi poistaa',
  addressInUseSubtitle: 'Jos haluat poistaa tämän osoitteen, sinun on ensin poistettava sen oletusarvoisuus.',
  addressLine: 'Osoiterivi {number}',
  addWidgetKey: 'Lisää widget-avain',
  admin: 'Hallinta',
  adminCenter: 'Hallintakeskus',
adminCenterBanner: {
    bodyLabel: 'Bannerin runko',
    enableLabel: 'Aktivoi hallintabanneri',
    headerGreenOption: 'Vihreä',
    headerLabel: 'Bannerin otsikko',
    headerRedOption: 'Punainen',
    headerYellowOption: 'Keltainen',
    title: 'Hallintakeskuksen banneri',
    typeLabel: 'Bannerin tyyppi'
  },
  adminCenterMessage: 'Sisällön, käyttäjien, kirjastoasetusten, raportoinnin ja viennin hallintakeskus',
  adminMessage: 'Ominaisuuksia lisätään niiden tullessa saataville',
  administration: 'Hallinta',
  affectedBookMedia: '{count} Kirjamedia päivitetään.',
  affectedTemplateMedia: '{count} Kirjamallit päivitetään.',
  after: 'Jälkeen',
  aggregatedFromChildren: 'Koostettu Alatasoilta',
  aiAlgorithm: 'Tekoälyskanneri',
  all: 'Kaikki',
  alphanumeric: 'Aakkosnumeerinen',
  algorithm: 'Algoritmi',
  alignHorizontal: 'Kohdista Vaakasuora',
  alignVertical: 'Kohdista Pystysuora',
  allUsersDisabled: 'Kaikki vuokralaiselle kuuluvat käyttäjät poistetaan käytöstä.',
  analyticsJSON: 'Analytiikka JSON',
  andCountMore: 'ja {count} lisää...',
  any: 'Mikä tahansa',
  apiKey: 'API-avain | API-avaimet',
  apiKeyAdmin: 'API Key Admin',
  apiAuthHeader: 'API-valtuutusotsikko',
  apiKeyCopied: 'API-valtuutusotsikon arvo kopioitu leikepöydälle',
  apiKeyDeleted: 'API-avain poistettu',
  apiKeyDeletedError: 'Virhe API-avaimen poistossa',
  apiKeyDeleteMsg: 'Tämän API-avaimen poistaminen estää sen käytön integroinneilla',
  apiKeyDeleteTitle: 'Oletko varma, että haluat poistaa tämän API-avaimen?',
  apiKeyInstructions: 'Avain luodaan Tallennettaessa',
  apiKeyManager: 'API-avaimen Haltija',
  apiKeyNameEmpty: 'Nimi ei voi olla tyhjä',
  apiKeyRegenError: 'Virhe API-avaimen regeneroinnissa',
  apiKeyRegenMsg: 'Tämän API-avaimen palauttaminen voi poistaa käytöstä olemassa olevat integraatiot',
  apiKeyRegenTitle: 'Oletko varma, että haluat palauttaa tämän API-avaimen?',
  apiKeySaved: 'API-avain Tallennettu',
  apiKeyType: 'API-avaintyyppi',
  appliesTo: 'Koskee Seuraavia',
  appliesToEmpty: 'Edellyttää ainakin yhtä valittua kokonaisuustyyppiä',
  appliesToPopulated: 'Aseta, mihin kokonaisuuksiin tagia käytetään',
  applyItem: 'Käytä {item}',
  applyToChildItems: 'Sovella alatasoon {item}',
  applyToPartOnly: 'Käytä vain tähän osaan',
  april: 'Huhtikuu',
  area: 'Alue',
  areYouSure: 'Oletko varma, että haluat poistaa kohteen {item}?',
  areYouSureCopy: 'Oletko varma, että haluat kopioida kohteen {item}?',
  areYouSureYouWantToDisableTheTenant: 'Haluatko varmasti poistaa vuokralaisen käytöstä?',
  assembly: 'Kokoonpano | Kokoonpanot',
  assemblyCreatorSuccessTitle: 'Kokoonpanon luontityö lähetetty',
  assemblyCreatorSuccessSubtitle: 'Hotpoint-linkit ja muutokset kirjan sisällysluetteloon tehdään välittömästi. Hakutulokset näkyvät, kun indeksointi on valmis.',
  assemblyConfirmationTitle: 'Haluatko päättää kokoonpanon luonnin?',
  assemblyCreatorHelpText1: 'Tämän toiminnon avulla voit lisätä valitun sivun kirjoihin kaikkialla, missä tämä osa {partName} on, ja yhdistää osan ja valitun sivun automaattisesti kohdepistelinkin välityksellä. Voit halutessasi rajoittaa lisäämisen vain valittuihin kirjoihin.',
  assemblyCreatorHelpText2: 'Valittu sivu lisätään kerran per kirja sen jälkeen, kun {partName} on esiintynyt ensimmäisen kerran.',
  assemblyStepOne: 'Vaihe 1: Valitse lisättävä sivu',
  assemblyStepTwo: 'Vaihe 2: Valitse kirjat, joihin haluat lisätä sivun',
  asset: 'Omaisuus | Omaisuudet',
  assetsNotFound: 'Kohdetta {type} Ei Löytynyt',
  assignBrowseFlow: 'Kohdista Selausjärjestys',
  assignedOrganizations: 'Kohdistetut Organisaatiot',
  atLeastFiveError: 'Valitse arvo, joka on vähintään 5.',
  attachment: 'Liite | Liitteet',
  attachmentEditorDescription: 'Liitä kuvia, videoita ja asiakirjoja suoraan tähän kokonaisuuteen. Huomaa, että ne löytyvät "Liittyvä"-välilehdeltä.',
  attachmentView: 'Näytä Liitteet',
  attachRelatedPDFs: 'Liitä liittyvien osasivujen PDF:t',
  attributions: 'Määritteet',
  august: 'Elokuu',
  automaticZoom: 'Automaattinen Zoomi',
  autoHotpoint: 'Auto Hotpoint',
  autoPopulate: 'Täytä Automaattisesti',
  availability: 'Saatavuus',
  back: 'Takaisin',
  backCover: 'Takakansi',
  backStitch: 'Takanidonta',
  backToLibrary: 'Takaisin Kirjastoon',
  backToSearch: 'Palaa Hakuun',
  badCredentials: 'Virheelliset Tunnukset',
  bahasaDescription: 'Bahasa Indonesia Kuvaus',
  bahasaName: 'Bahasa Indonesia Nimi',
  banner: 'Banneri',
  before: 'Ennen',
  beginTypingToFindAnything: 'Aloita kirjoittaminen etsiäksesi mitä tahansa',
  betaAccessKeys: 'Beta Pääsyavaimet',
  betaResources: 'Beta-Resurssit',
  betaReleaseVideo: 'Beta-Julkaisuvideo',
  blobFinder: 'BLOB-haku',
  body: 'Teksti',
  bomDefaultSupplier: 'BOM-oletustoimittaja',
  bomItem: 'BOM Item',
  bom: 'BOM',
  bomExportSuccess: 'BOM-vienti onnistui',
  bomLayout: 'Materiaaliluettelon Asettelu',
  bomListOptions: 'BOM Luettelovaihtoehdot',
  bomListSettings: 'BOM Luetteloasetukset',
  both: 'Molemmat',
  book: 'Kirja | Kirjat',
  bookInserts: 'Kirjalisäykset',
  bookLabel: 'Kirja | Kirjat',
  bookPrintingDisable: 'Poista Kirjan Tulostus Käytöstä',
  booksAndPagesDisplay: 'Kirjojen ja Sivujen Näyttö',
  boost: 'Haun Tehostaminen',
  boostDescription: 'Lisäämällä suuremman arvon kuin 1,5 voit tehostaa tämän tagin haun osuvuutta osanumeroissa, mediatunnisteissa ja nimissä',
  boostRangeInvalid: 'Boostin on oltava välillä 0,5 ja 100,0',
  border: 'Reunaviiva',
  bottom: 'Alas',
  branding: 'Brändäys',
  browse: 'Selaa',
  browseComputer: 'Selaa Tietokonetta',
  browseFlow: 'Selausjärjestys | Selausjärjestykset',
  browseFlowAssigned: 'Selausjärjestys Kohdistettu',
  browseFlowCopyMessage: 'Anna uudelle Selausjärjestykselle yksilöllinen nimi.',
  browseFlowDeleted: 'Selausvirta poistettu',
  browseFlowDeleteMessage: 'Selausjärjestyksen poistaminen ei poista vain selausjärjestystä vaan myös kaikki sen alatason selausjärjestykset.',
  browseFlowEmptyState: 'Selausjärjestyksiä ei ole määritetty. {New}, niin pääset alkuun.',
  browseFlowHeirarchy: 'Selausjärjestyksen Hierarkia',
  browseFlowNoExistingCriteria: 'Tälle selausjärjestykselle ei ole asetettu hakukriteerejä.  Valitse hakukriteerit yllä olevien asetusten avulla.',
  browseFlowMoveToRoot: 'Tee ensimmäinen alataso pudottamalla solmun tähän',
  browseFlowNoAssignedOrgs: 'Kohdistettuja organisaatioita ei ole',
  browseFlowSaveFailed: 'Selausjärjestyksen päivitys ei onnistunut',
  browseFlowSetDefaultSuccess: 'Oletusselausjärjestys asetettu',
  browseFlowThumbnailDeleteFailTitle: 'Esikatselukuvan Poisto Epäonnistui',
  browseFlowThumbnailDeleteSuccessTitle: 'Esikatselukuvan Poisto Onnistui',
  browseFlowThumbnailUploadFailTitle: 'Esikatselukuvan Lataus Epäonnistui',
  browseFlowThumbnailUploadSuccessTitle: 'Esikatselukuvan Lataus Onnistui',
  browseFlowTooManyOrgs: 'Sinulla on liian monta organisaatiota nykyisen selausjärjestyksen editorin käyttämiseksi. Ota yhteyttä Documoton tukeen osoitteessa {email}, niin saat apua Documoton selausjärjestysten muokkaamiseen.',
  browserPartiallySupport: 'Voit nähdä tässä selaimessa ulkoisia eroja ja muita muutoksia.',
  browseFlowSaved: 'Selausjärjestys tallennettu',
  browserDeprecation: {
    title: 'Ilmoitus Selaimen Vanhentumisesta',
    text: 'Johtuen Microsoftin tuen päättymisestä Internet Explorerille (kaikki versiot) ja siihen liittyvistä tietoturvahuolista emme enää 30. syyskuuta 2021 alkaen tue käyttäjien pääsyä tälle sivustolle Internet Exploreria (kaikki versiot) käyttämällä.'
  },
  buildHotpointLinks: 'Muodosta Kohdepistelinkkejä',
  buildHotpointLinksInfo: 'Tämä toiminto poistaa ensin kaikki olemassa olevat kohdepistelinkit kirjan sivuilta. Luo sitten uudet kohdepistelinkit osien ja vastaavien kokoonpanosivujen välille.',
  buildHotpointLinksTitle: 'Haluatko muodostaa kohdepistelinkkejä tähän kirjaan?',
  bulkIndexingLane: 'Joukon indeksointikaista',
  bulkInsert: 'Joukkolisäys',
  bulkExportConfirm: 'Haluatko viedä {n} {unit}?',
  bulkExportLimit: 'Raja on {limit} kirjaa per vienti',
  bulkPageInsertJob: 'Sivun Joukkolisäys',
  business: 'Business',
  cancel: 'Peruuta',
  canceled: 'Peruutettu',
  cancelAssemblyCreatorTitle: 'Hylätäänkö kokoonpanon luonti?',
  cancelAssemblyCreatorSubtitle: 'Kokoonpanon luontia ei käsitellä.',
  cannotExceed100: 'Ei voi ylittää 100 %',
  cart: 'Ostoskori | Ostoskorit',
  cartBehaviorEnabled: 'Ota Käyttöön Lisää Ostoskoriin Määrän Kehote',
  cartBehaviorDisabled: 'Poista Käytöstä Lisää Ostoskoriin Määrän Kehote',
  cartBehaviorLabel: 'Lisää Ostoskoriin Määrän Toiminta',
  cartColumn: 'Ostoskorisarake | Ostoskorisarakkeet',
  cartColumn1: '1. Ostoskorin Hintasarake',
  cartColumn2: '2. Ostoskorin Hintasarake',
  cartColumn3: '3. Ostoskorin Hintasarake',
  cartColumnPartLocationContent1: 'Tämän ominaisuuden käyttöön ottamisella on seuraavat vaikutukset:  ',
  cartColumnPartLocationContent2: 'Osan rivin yksilöllisyys ostoskorissa perustuu myös sen sijaintiin sisällössä, jos sovellettavissa.',
  cartColumnPartLocationContent3: 'Tätä asetusta ei voi poistaa käytöstä organisaation tasolla ja se koskee kaikkia käyttäjiä.',
  cartColumnPartLocationContent4: 'Tätä asetusta ei tule ottaa käyttöön yhdessä Lähetä ostoskori -integroinnin kanssa ottamatta ensin yhteyttä Documoton Customer Success Manageriin.',
  cartColumnPartLocationOption: 'Ota Osan Sijaintitiedot Käyttöön Ostoskorissa',
  cartColumnPartLocationTitle: 'Oletko varma, että haluat ottaa Osan Sijaintietiedot Käyttöön Ostoskorissa?',
  cartDisable: 'Poista Ostoskori Käytöstä',
  cartEmailFailed: 'Ostoskorin sähköpostiviesti epäonnistui',
  cartEmailSent: 'Ostoskorin sähköpostiviesti lähetetty',
  cartEnable: 'Ota Ostoskori Käyttöön',
  cartSaveEnable: 'Ota Ostoskorin Tallennus Käyttöön',
  cartErpMessage: 'Lähetys Erp:iin Onnistui',
  cartImportError: 'Virhe ostoskorin tuonnissa',
  cartLoadedWithItemsRemoved: '{count} kohdetta poistettu, koska sinulla ei ole oikeutta tilata niitä tällä hetkellä',
  cartSharedAddEdit: 'Lisää / Muokkaa Jaettuja Ostoskoreja',
  cartUpdated: 'Ostoskärry päivitetty',
  cartUpdatedAndRemovedCount: 'Ostoskori Päivitetty; {count} ei-tilattavissa olevaa osaa poistettu',
  category: 'Kategoria | Kategoriat',
  categoryCreated: 'Kategoria Luotu',
  categoryUpdated: 'Kategoria Päivitetty',
  centimeter: 'Senttimetri | Senttimetrit',
  changeNotification: 'Muuta Ilmoitusta',
  changeScope: '{n} {type} on kohteena',
  changeUserOrganization: 'Vaihda Organisaatio',
  chapter: 'Kappale | Kappaleet',
  chapterHeader: 'Kappaleen Ylätunniste',
  chaptersOnly: 'Vain kappaleet',
  chineseDescription: 'Kiina Kuvaus',
  chineseName: 'Kiina Nimi',
  circle: 'Ympyrä',
  city: 'Kaupunki',
  classicAdminMessage: 'Tämä ohjaa sinut takaisin Flexiin käyttämään hallintamoduuleja.',
  classicAdmins: 'Perinteinen Hallinta',
  classicExporter: 'Perinteinen Vientitoiminto',
  classicExporterMessage: 'Vanha Flash-pohjainen sisällön vientimoduuli',
  classicOrganizationAdmin: 'Perinteinen Organisaation Hallintatoiminto',
  classicOrganizationAdminMessage: 'Vanha Flash-pohjainen käyttäjien hallintamoduuli organisaatioiden ja käyttäjien hallintaan',
  classicPublisher: 'Perinteinen Julkaisutoiminto',
  classicPublisherMessage: 'Vanha Flash-pohjainen julkaisumoduuli sisällön luontia ja tulostusasetuksia varten',
  classicReporting: 'Perinteinen Raportointitoiminto',
  classicReportingMessage: 'Vanha Flash-pohjainen raportointimoduuli',
  classicTenantAdmin: 'Perinteinen VTenant-Hallintatoiminto',
  classicTenantAdminMessage: 'Vanha Flash-pohjainen hallintamoduuli tenant-asetuksia varten',
  className: 'Luokan nimi',
  clear: 'Tyhjennä',
  clearItemNumber: 'Tyhjennä tuotenumero',
  clearItemNumberTitle: 'Haluatko tyhjentää tuotenumeron?',
  clearItemNumberMessage: 'Tuotenumeron poistaminen poistaa myös seuraavat tiedot:',
  clearFilters: 'Tyhjennä Suodattimet',
  clearHotpoints: 'Tyhjennä hotpointit',
  clearSearch: 'Tyhjennä haku',
  clickHereToUpload: 'Pudota tiedosto tai napsauta ladataksesi korvaava.',
  clickMagnifyingGlass: 'Napsauta suurennuslasia mennäksesi suoraan Edistyneeseen hakuun.',
  clickOkToContinue: 'Jatka napsauttamalla ”OK”. ',
  clickToDownload: 'Napsauta Ladataksesi',
  clickToEnlarge: 'Napsauta suurentaaksesi',
  clickToOpen: 'Napsauta Avataksesi',
  clipboard: 'Leikepöytä',
  clipboardAccessError: 'Muokkaa selainasetuksesi sallimaan Leikepöydän käyttö',
  close: 'Sulje',
  closed: 'Suljettu',
  collapseAll: 'Tiivistä Kaikki',
  color: 'Väri | Värit',
  column: 'Sarake | Sarakkeita',
  columnName: 'Sarakkeen Nimi',
  columnItemType: {
    customText: 'Mukauta Teksti',
    documentTitle: 'Asiakirjan Otsikko',
    horizontalLine: 'Vaakaviiva',
    pageName: 'Sivun Nimi',
    pageNumber: 'Sivun Numero',
    printDate: 'Tulostuspäivämäärä',
    tenantName: 'Tenant-Nimi',
    userName: 'Käyttäjän Nimi'
  },
  combinedPage: 'Yhdistetty Sivu',
  combinedPageMessage: 'Kaavio ylhäällä, osaluettelo alhaalla',
  comingSoon: 'Tulossa Pian',
  commaDelimited: 'Pilkulla erotettu',
  comment: 'Kommentti | Kommentit',
  commentAdded: 'Kommentti lisätty',
  commentDeleted: 'Kommentti poistettu',
  commentEdit: 'Lisää / Muokkaa kommentteja',
  commentEditAll: 'Muokkaa Kaikkia Kommentteja',
  commentUpdated: 'Kommentti päivitetty',
  commentView: 'Näytä Kommentit',
  commerceSystem: 'Kaupankäyntijärjestelmä',
  completed: 'Valmis',
  confirm: 'Vahvista',
  confirmBrowseFlowApplyToChildren: 'Haluatko käyttää tätä selausjärjestystä alaorganisaatioissa?',
  confirmBrowseFlowOverrideToChildren: 'Tämä poistaa kaikki alaorganisaatioissa asetetut selausjärjestysten ohitukset.',
  confirmPrintSettingsApplyToChildren: 'Haluatko käyttää tätä tulostusasetusta alaorganisaatioissa?',
  confirmPrintSettingsOverrideToChildren: 'Tämä poistaa kaikki alaorganisaatioissa asetetut tulostusasetusten ohitukset.',
  contains: 'Sisältää',
  containsHeaderRow: 'Sisältää Ylätunnisterivin',
  content: 'Sisältö | Sisällöt',
  contentAccessControls: 'Kohteen {content} Käyttöoikeudet',
  contentEntity: 'Sisältökokonaisuus | Sisältökokonaisuudet',
  contentNotFound: 'Sisältöä ei löydy',
  contentUpdatedQueueJob: 'Sisältö Päivitetty Jono',
  contourShape: 'Ääriviivan muoto',
  convertEntity: 'Muunna {entity}',
  convertToEntity: 'Muunna kohteeksi {entity}',
  copyAccessControls: 'Kopioi Käyttöoikeudet',
  copyAccessControlsWarning: 'Tämä kopioi kaikki kohdistetut käyttöoikeudet valitusta Organisaatiosta tähän Organisaatioon',
  copy: 'Kopioi',
  copyAndRetain: 'Kopioi ja Säilytä Integrointia varten',
  copyAndRetainWidget: 'Kopioi ja säilytä, tämä on ainoa kerta, kun widgetin valtuutusarvo näkyy sovelluksessa',
  copyAtoB: 'Kopioi {a} kohteeseen {b}',
  copyContent: 'Kopioi {type}? {name}',
  copyingItem: 'Kopioidaan {item}',
  copyItem: 'Kopioi {item}',
  copyItemQuestion: 'Kopioidaanko {item}?',
  copyTo: 'Kopioi kohteeseen {target}',
  copyMeMessage: 'Kopioi minut tähän viestiin',
  copyrightMessage: '© 2010-{year} Documoto Inc. Kaikki oikeudet pidätetään.',
  count: 'Määrä',
  country: 'Maa | Maat',
  coverPages: 'Kansisivut',
  coverAmpStitchPages: 'Kansi- ja Nidontasivut',
  createAssembly: 'Luo Kokoonpano',
  created: 'Luotu',
  createdBy: 'Luonnin suoritti {name}',
  createdDate: 'Luontipäivä',
  createdOn: 'Luotu',
  currentPassword: 'Nykyinen Salasana',
  custom: 'Mukautettu',
  czechDescription: 'Tsekki Kuvaus',
  czechName: 'Tsekki Nimi',
  dashboard: 'Koontinäyttö | Koontinäytöt',
  dashboardLibrary: 'Ohjauspaneelin kirjasto',
  dataInsights: 'Tietojen oivallus',
  dataInsightsInfoMessage: 'Sisällön, käyttäjän ja kirjaston tietojen oivalluksen raportointi- ja ohjauspaneelikeskus.',
  dataMapping: 'Tietokartoitus/Näytedata (enintään 10 riviä)',
  dataOptions: 'Tietoasetukset',
  date: 'Päivämäärä',
  dateModified: 'Muokkauspäivä',
  debugPageBuilderPLZ: 'Debug Sivunrakentaja PLZ',
  december: 'Joulukuu',
  decreaseHotpoint: 'Vähennä Hotpoint',
  default: 'Oletus',
  defaultAddress: 'Oletusosoite',
  defaultBrowseFlow: 'Oletusselausjärjestys',
  defaultCurrencyCode: 'Oletusvaluutan Koodi',
  defaultCurrencySymbolOn: 'Oletusvaluuttasymboli päällä',
  defaultEmailRecipient: 'Sähköpostin Oletusvastaanottaja | Sähköpostin Oletusvastaanottajat',
  defaultImage: 'Oletuskuva | Oletuskuvat',
  defaultImagesManager: 'Oletuskuvien Hallintatoiminto',
  defaultItemImage: 'Kohteen {item} Oletuskuva',
  defaultPartsListLayout: 'Osaluettelon Oletusasettelu',
  defaultPartsListLayoutHelp: 'Osien oletusasettelu kuvituksen vasemmalle puolelle tai alle',
  defaultSearchMode: 'Oletushakutila',
  defaultSearchModeHelp: 'Yleinen oletushakumenetelmä kohtaan \'sisältää\' tai \'tarkka\'',
  defaultSortMode: 'Oletushakutila',
  defaultSupplier: 'Oletustoimittaja',
  defaultTocStateOpen: 'Sisällysluettelo Oletusarvoisesti Auki',
  defaultTocStateOpenHelp: 'Määrittää, avautuuko Sisällysluettelo automaattisesti kirjaa katseltaessa',
  defaultUom: 'Oletus UOM',
  defaultValue: 'Oletusarvo',
  defaults: 'Oletusarvot',
  defaultsAndCapabilities: 'Oletusarvot ja Ominaisuudet',
  delete: 'Poista',
  deleteAll: 'Peri Ylätasolta',
  deleteAnyPartnerApiKeys: 'Poista mikä tahansa kumppanin API-avain, joka annettiin SmartEquipille.',
  deleteContent: 'Oletko varma, että haluat poistaa kohteen {type}? {name}',
deletePart: {
    infoOne: 'Osien poistot tulevat voimaan välittömästi kirjaston ja sisällön hallinnassa. Poistettu osa pysyy hakukelpoisena, kunnes indeksointi on tehty uudelleen muutaman minuutin kuluessa.',
    deletingPartNumber: '{partNumber} poistetaan.',
    affectedCarts: 'Kaikki ostoskorit, jotka sisältävät tämän osan, poistetaan pysyvästi.',
    infoTwo: 'Tätä toimenpidettä ei voi perua ilman Documoto-tuen apua. Poistettu tietue ja kaikki yhteydet poistetaan pysyvästi 90 päivän kuluttua.'
  },
deletePartInUse: {
    title: 'Tämä osa kuuluu sivulle (sivuille) eikä sitä voi poistaa.',
    body: 'Documoto tukee tällä hetkellä vain sellaisten osien poistamista, jotka eivät kuulu sivuille. Poista tämä osa kaikista julkaistuista tai luonnossivuista, jotta voit jatkaa poistamista.',
    submitLabel: 'Siirry sivulle, jossa käytetään'
  },
  deleteRelated: 'Haluatko varmasti poistaa {count} {type}?',
  deletedItem: '{item} poistettu',
  deleteFilesMessage: 'Oletko varma, että haluat poistaa {count} tiedoston(tiedostot)?',
  deleteHotpointLinksTitle: ' Haluatko varmasti poistaa pikalinkin/-linkit?',
  deleteMessage: 'Haluatko varmasti poistaa?',
  deleteSelectedCriteria: 'Poista Valitut Kriteerit',
  deleteTagsTitle: 'Haluatko varmasti poistaa tunnisteen (tunnisteet)?',
  deleteTaxonMessage: 'Tämä poistaa pysyvästi tämän luokan ja kaikki sen alatasot. Haluatko varmasti jatkaa?',
  deleteTaxonomyMessage: 'Tämä poistaa pysyvästi kaikki tämän Organisaation tai Tenant-luokat. Haluatko varmasti jatkaa?',
  deleteTenantSupplier: 'Haluatko varmasti poistaa tämän toimittajan vuokraajalle?',
  demo: 'Demo',
  description: 'Kuvaus',
  deselectAll: 'Poista Kaikkien Valinta',
  deselectCriteria: 'Poista Kriteerien Valinta',
  detail: 'Yksityiskohta | Yksityiskohdat',
  diagonal: 'Diagonaalinen',
  diameter: 'Halkaisija',
  disable: 'Poista Käytöstä',
  disableAll: 'Poista kaikki käytöstä',
  disableDocumotoTenant: 'Poista Documoto-vuokralainen käytöstä',
  disableFwdReindex: 'Poista käytöstä edelleen toimitettu uudelleen indeksoitu kohdemedia DSW:lle',
  disableLibraryBannerImage: 'Poista kirjaston bannerikuva käytöstä',
  disableSmartEquipModalTitle: 'Haluatko varmasti poistaa tämän lisenssin käytöstä?',
  disableTEK: 'Poista suora URL-kirjautuminen käytöstä',
  disabled: 'Poistettu Käytöstä',
  discard: 'Hylkää',
  discardChanges: 'Hylkää Muutokset',
  discardQ: 'Hylkää Muutokset?',
  discounted: 'Alennettu',
  discountedPrice: 'Alennettu Hinta',
  discountedTotal: 'Alennettu Yhteensä',
  discountPrice: 'Alennushinta',
  displayInOrdersList: 'Näytä Tilausluettelossa',
  displayOrder: 'Näyttöjärjestys',
  displayOrderDescription: 'Asettaa Suodattimien pudotusvalikon näyttöjärjestyksen sisältötyyppien tageille',
  document: 'Asiakirja | Asiakirjat',
  documentLabel: 'Asiakirja | Asiakirjat',
  documentSettings: 'Asiakirjan asetukset',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Documoto-lisenssipaketti',
  domain: 'Domain',
  domainEmptyMessage: 'Sallittu domain ei voi olla tyhjä',
  domainInvalidMessage: 'Voimassaoleva domain on pakollinen',
  download: 'Lataa',
  downloadBrowser: 'Lataa yksi näistä erinomaisista selaimista.',
  downloadFailed: 'Virhe ladattaessa {name}',
  downloadStarted: 'Lataus on käynnissä {name}',
  draft: 'Luonnos | Luonnokset',
  draftBomDeleteTitle: 'Haluatko varmasti poistaa tämän osan sivulta?',
  draftBomMultipleDeleteTitle: 'Haluatko varmasti poistaa nämä osat sivulta?',
  draftBomDropError: 'Osia ei voi sijoittaa sisäkkäin yli 5 tasoa syvemmälle',
  draftBomEmptyField: 'Kaikki osaluettelon kentät on täytettävä',
  draftBomQuantityError: 'Määrän on oltava numero',
  draftBomError: 'Virhe materiaaliluonnoksen käsittelyssä',
  draftPage: 'Luonnossivu',
  draftPageDelete: 'Poista luonnos',
  draftPageDeleteMessage: 'Luonnossivu poistettu onnistuneesti.',
  draftPageDeleteWarning: 'Sivun luonnoksen poistaminen poistaa meneillään olevat päivitykset.',
  draftPageLastUpdated: 'Luonnos - viimeksi päivitetty {date} käyttäjältä {username}',
  draftPageLoadError: 'Luonnossivun lataaminen epäonnistui.',
  draftPageProcessingPlz: 'Sivua ladataan PLZ-tiedostosta',
  draftPagePublishInProgress: 'Sivun julkaisutyö käynnissä',
  draftPageSaveSuccess: 'Luonnossivu tallennettu onnistuneesti.',
  draftPageSaveUnsuccessful: 'Luonnossivun tallennus epäonnistui.',
  draftSheetImageHotpointsFailure: 'Algoritmin {algorithm} hotpointeja ei voi muodostaa luonnoskuvalle.',
  draftValidationMessage: {
    item: 'VAROITUS: BOM-osanumerolle {partNumber} ei ole määritelty Tuotenumeroa',
    empty: 'Ei Validointivirheitä/Varoituksia',
    hotpointOrHotpointLink: 'BOM-kohteelle {item} ei ole määritetty hotpointia(-eja)/hotpoint-linkkiä(-ejä)',
    quantity: 'BOM-kohteelle {item} ei ole määritetty määrää',
    tag: 'BOM-kohteen {item} merkistä {tagName} puuttuu merkkiarvo',
    translationDescription: 'BOM-kohteesta {item} puuttuu {lang} käännöskuvaus',
    uom: 'BOM-kohteelle {item} ei ole määritetty mittayksikköä',
    supersede: 'Osa {partNumber} korvasi osan {supersededPartNumber} ylityksen vuoksi.'
  },
  drawAutohotpointRegion: 'Piirrä {shape} koko',
  drawMinimumArea: 'Piirrä minimialue',
  dropFileMessage: 'Pudota tiedosto tähän tai napsauta ladataksesi.',
  duplicate: 'Kopioi',
  duplicateSaveError: 'Merkintä on päällekkäinen. Ei voida tallentaa.',
  dutchDescription: 'Hollanti Kuvaus',
  dutchName: 'Hollanti Nimi',
  dynamic: 'Dynaaminen',
  dynamicNaming: 'Dynaaminen Nimeäminen',
  ecommerce: 'Sähköinen kaupankäynti',
  ecommerceEmailFormatCreated: 'Verkkokaupan sähköpostimuoto luotu',
  ecommerceEmailFormatSaved: 'Verkkokaupan sähköpostimuoto tallennettu',
  ecommerceLogo: 'Verkkokaupan logo',
  ecommerceUseExtOrder: 'Verkkokaupan käytön ulkoinen tilausnumero',
  edit: 'Muokkaa',
  editItem: 'Muokkaa kohdetta {item}',
  email: 'Sähköposti',
  emailFormat: 'Sähköpostimuoto',
  emailFormatTemplate: 'Sähköpostimuodon malli | Sähköpostimuotojen mallit',
  emailList: 'Sähköpostiluettelo',
  emailMessage: 'Sähköposti on pakollinen',
  emailRecipient: 'Sähköpostin Vastaanottaja | Sähköpostin Vastaanottajat',
  emailRecipientMessage: 'Nämä sähköpostiosoitteet saavat sähköpostia, kun tilauksen tila muuttuu tilaksi',
  emptyMessage: {
    orderSuggestions: 'Näyttää siltä, että tälle osalle ei ole vielä Tilausehdotuksia.'
  },
  emptyBomState: 'Sivulle ei ole määritelty BOM-kohteita. Klikkaa +{add} aloittaaksesi',
  enable: 'Ota Käyttöön',
  enable3d: 'Ota 3D käyttöön',
  enableAll: 'Ota kaikki käyttöön',
  enableAPIKeyAdmin: 'Ota käyttöön API-avaimen ylläpito',
  enabled: 'Otettu käyttöön',
  enableCommerce: 'Ota Kaupankäynti Käyttöön',
  enableEcommerceOrderManagement: 'Ota käyttöön tilaushallinta',
  enableExporter: 'Ota käyttöön vientitoiminto',
  enableExportToLinkOne: 'Ota käyttöön vienti LinkOneen',
  enableExportToSmartEquip: 'Ota käyttöön vienti SmartEquipiin',
  enablePDFDownloadWarning: 'Ota käyttöön suuren PDF:n latausvaroitus',
  largePDFDownloadWarning: 'PDF-tiedoston suuren koon vuoksi asiakirjan lataaminen ja näyttäminen sovelluksessa voi kestää tavallista pidempään. Odota kärsivällisesti, että tiedosto latautuu katselua varten.',
  enableIframeSupport: 'Ota käyttöön iFrame-tuki',
  enableLibraryBetaFeatures: 'Ota kirjaston beta-ominaisuudet käyttöön',
  enablePerformanceLogging: 'Ota käyttöön suorituskyvyn kirjaus',
  enablePLZToDraft: 'Ota käyttöön PLZ-käsittely luonnokseksi',
  enableQuickAddToCart: 'Ota käyttöön pikalisäys ostokoriiin',
  enableQuickAddToCartHelpContext: 'Asetus arvoon \’Ota käyttöön\’ ottaa käyttöön pikalisäyksen ostoskoriin -ominaisuuden sisällönkatseluohjelman yläpuolelle tälle sisällölle.',
  enableRocketUi: 'Ota Rocket-Käyttäjäkokemus Käyttöön',
  enableSmartEquipApiIntegration: 'Ota käyttöön SmartEquip API -integraatio',
  enableWidgetLogo: 'Ota käyttöön Documoto Widget -logo',
  enableWidgets: 'Ota widgetit käyttöön',
  encryptedKey: 'Salattu Avain',
  endDate: 'Päättymispäivä',
  endDateBad: 'Aloituspäivämäärän on oltava sama tai aikaisempi kuin päättymispäivämäärä.',
  enforceNumericSize: 'Pakota Numeerinen Koko',
  englishDescription: 'Englanti Kuvaus',
  englishName: 'Englanti Nimi',
  enterprise: 'Yritys',
  enterReportParameters: 'Raportin parametrien lisääminen',
  enterUniqueNameForNewItem: 'Anna tälle uudelle kohteelle {item} yksilöllinen nimi',
  enterValidNumber: 'Anna kelvollinen numero',
  enterValidEmail: 'Anna kelvollinen sähköposti',
  entityType: 'Kokonaisuustyyppi | Kokonaisuustyypit',
  erp: 'ERP',
  erpEndpointConfig: 'ERP-päätepisteen konfigurointi',
  erpPartInfo: 'ERP-osan tiedot välimuistin kesto minuuteissa',
  erpSystem: 'ERP-Järjestelmä',
  erpSystems: 'ERP-järjestelmät',
  erpSystemSaved: 'ERP-järjestelmä tallennettu',
  error: 'Virhe',
  errorDeletePageMessage: 'Sivua poistettaessa tapahtui virhe.',
  errorInputParsing: 'Ongelma jäsennettäessä syötetiedostoa.',
  errorPageMessage: 'Tapahtui virhe.',
  errorPageTitle: 'Jotakin meni vikaan...',
  errorType: {
    error: 'Virhe',
    warning: 'Varoitus'
  },
  essential: 'Essential | Essentials',
  eta: 'ETA',
  etlJob: 'ETL',
  exact: 'Tarkka',
  excelFile: 'Excel-tiedosto',
  expandAll: 'Laajenna Kaikki',
  export: 'Vie',
  exportSearch: 'Vientihaku',
  exportAsCsv: 'Vie CSV-muodossa (Pakollinen Muoto Tuonnissa)',
  exportAsCSVDropdown: 'Vie CSV-muodossa',
  exportAsHtml: 'Vie HTML-muodossa',
  exportAsPdf: 'Vie PDF-muodossa',
  exportAsPlz: 'Vie PLZ-muodossa',
  exportAsXlsx: 'Vie XLSX-muodossa',
  exportBasicPartInfo: 'Vie Osan Perustiedot',
  exportCartToXlsxDisabled: 'Poista Käytöstä Ostoskorin Vienti XLSX:ään',
  exportCartToXlsxEnabled: 'Ota Käyttöön Ostoskorin Vienti XLSX:ään',
  exportDisplayedCartColumns: 'Vie Näytetyt Ostoskorisarakkeet',
  exportJob: 'Vientityö',
  exportLane: 'Vie kaista',
  exportMessage: 'Vientityön lähetys onnistui.',
  exporter: 'Vientitoiminto',
  exporterMessage: 'Sallii sinun viedä sisältöä',
  exportParts: 'Vie Osat',
  external: 'Ulkoinen | Ulkoiset',
  externalUrl: 'Ulkoinen URL',
  faceted: 'Kuvioitu',
  facetedTag: 'Suodatinarvojen tulee olla kohdistettuja',
  facetedTagTooMany: 'Tagi ylittää 1000 yksilöllisen tagin rajan',
  failedAction: 'Kohdetta {content} ei voitu {action}.',
  failedProcessContentSql: 'Sisällön käsittely epäonnistui luvattoman tekstin vuoksi. Lataa tuettu sisältö uudelleen.',
  failedToCopy: 'Kohteen {content} kopiointi epäonnistui.',
  failedToDelete: 'Kohdetta {content} ei voitu poistaa.',
  failedToLoad: 'Kohdetta {content} ei voitu ladata.',
  failedToSave: 'Kohdetta {content} ei voitu tallentaa.',
  failedToSubmitItem: '{item} lähettäminen epäonnistui',
  failedToUpdate: 'Kohteen {content} päivitys epäonnistui.',
  false: 'Väärin',
  faq: 'Usein Kysytyt Kysymykset',
  favorites: 'Suosikkikirjat',
  favoritesLists: 'Suosikkikirjaluettelo',
  february: 'Helmikuu',
  feedbackForm: 'Lähetä Palautetta',
  feedbackFormMessage: 'Kerro, mitä haluat nähdä',
  fieldDefinition: 'Kentän Määrittely',
  field: 'Kenttä | Kentät',
  fieldName: 'Kentän nimi',
  file: 'Tiedosto | Tiedostot',
  filename: 'Tiedostonimi',
  filesSubmitted: 'Tiedosto(t) lähetetty käsiteltäväksi',
  fileUpload: 'Tiedoston lataus',
  fill: 'Täytä',
  filter: 'Suodatin | Suodattimet',
  filterOrgNameOnly: 'Suodatin: Vain Alkup. Nimi',
  filterOrgParentNames: 'Suodatin: Alkup. ja Vanhempien Nimet',
  filterable: 'Suodatettava',
  filterableTag: 'Suodatettavissa Oleva Tagi',
  filterBy: 'Suodatustapa',
  filterBoundsError: 'Suodattimessa on oltava vähintään 3 merkkiä',
  finnishDescription: 'Suomi Kuvaus',
  finnishName: 'Suomi Nimi',
  firstName: 'Etunimi',
  firstNameEmpty: 'Etunimi ei voi olla tyhjä',
  fixedHotpointSize: 'Kiinteä Kohdepisteen Koko',
  font: 'Fontti | Fontit',
  footer: 'Alatunniste',
  forgotPassword: 'Unohditko Salasanasi',
  forgotPasswordMessage: 'Lähetämme antamallesi käyttäjätunnukselle sähköpostia, joka sisältää ohjeet salasanan palauttamiseksi.',
  forgotPasswordSuccess: 'Jos antamasi käyttäjätunnus on kelvollinen, lähetämme tiedostossa olevana sähköpostiin ohjeet salasanan palauttamiseksi.',
  form: 'Lomake | Lomakkeet',
  format: 'Muoto',
  formattingOptions: 'Muotoiluasetukset',
  formCount: 'Lomakkeiden Määrä',
  formName: 'Lomakkeen nimi',
  formType: 'Lomakkeen tyyppi',
  freezeImage: 'Lukitse kuva',
  frenchDescription: 'Ranska Kuvaus',
  frenchName: 'Ranska Nimi',
  fri: 'Pe',
  from: 'Lähettäjä',
  fromDate: 'Alkamispäivä',
  frontCover: 'Etukansi',
  frontStitch: 'Etunidonta',
  fullScreen: 'Koko näyttö',
  gdprFaqs: 'Yleistä Tietosuoja-asetusta (GDPR) Koskevat Usein Kysytyt Kysymykset',
  gdprMessage: 'Kirjautumalla tähän sivustomme osioon ymmärrät, että käytämme välttämättömiä evästeitä. Nämä evästeet ovat välttämättömiä palveluissamme navigoimiseen. Emme voi tarjota pyytämiäsi palveluita ilman kyseisiä evästeitä. Lisätietoja näet kohdassa {policy}.',
  general: 'Yleistä',
  generalSearch: 'Yleinen Haku',
  generic: 'Yleinen',
  germanDescription: 'Saksa Kuvaus',
  germanName: 'Saksa Nimi',
  gotIt: 'Selvä',
  goTo: 'Mene kohtaan',
  goToHome: 'Mene Etusivulle',
  goToJobManager: 'Mene Job Manageriin',
  goToSearch: 'Mene Hakuun',
  hashKey: 'Ristikkonäppäin',
  headerAmpFooter: 'Ylä- ja Alatunniste',
  header: 'Ylätunniste',
  help: 'Ohjeet',
  helpCenter: 'Ohjekeskus',
  highlightDescription: 'Korosta osia osaluettelossa tällä tagilla',
  history: 'Historia',
  home: 'Etusivu',
  horizontal: 'Horisontaalinen',
  host: 'Isäntä',
  hotpoint: 'Kohdepiste | Kohdepisteet',
  hotpointCalloutBehavior: 'Kiintopisteen hälytystoiminto',
  hotpointLinksWillBeCreated: 'Kohdepistelinkit luodaan: ({count} {content})',
  hotpointLink: 'Pikalinkki | Pikalinkit',
  hotpointsGenerated: 'Kuvasta löytyi {count} toimintopistettä.',
  hotpointShift: 'Hotpoint (Shift H)',
  hourlyDWSRequestLimit: 'Tuntikohtainen DWS-pyyntörajoitus',
  hourlyRESTRequestLimit: 'Tuntikohtainen REST-pyyntörajoitus',
  htmlUiUserSwitchEnabled: 'HTML UI -käyttäjäkytkin otettu käyttöön',
  hungarianDescription: 'Unkari Kuvaus',
  hungarianName: 'Unkari Nimi',
  icon: 'Kuvake',
  id: 'TUNNISTE',
  identifier: 'Tunniste',
  ignore: 'OHITA',
  ignoreAccessControls: 'Ohita Käyttöoikeudet',
  ignoreMatchingTranslations: 'Jätä huomiotta käännökset, joilla on yhteensopivat tapahtumapaikat tuonnissa',
  illustration: 'Kuvitus | Kuvitukset',
  image: 'Kuva | Kuvat',
  imageDeleteMessage: 'Haluatko varmasti poistaa tämän sivun kuvan?',
  imageUploadFailure: 'Kuvan Lataus Epäonnistui',
  imageSaveFail: 'Kuvan Tallennus Epäonnistui',
  imageOptimizer: 'Kuvan Optimoija',
  imageSizeExceededTitle: 'Kuvan enimmäiskoko ylitetty',
  imageSizeExceededMessage: 'Suurin Dokumotossa sallittu kuvatiedoston koko on {size} megatavua. Kuva, jota yrität ladata, on liian suuri eikä sitä voi ladata. Muuta kuvan kokoa lisätäksesi sen tälle sivulle.',
  imageDimensionsExceededMessage: 'Documoton suurin kuvakoko on 65,5 megapikseliä (8,191 X 8,191 pikseliä).  Kuva, jota yrität ladata, on ylittänyt enimmäismäärän, eikä sitä voi ladata. Napsauta "Muuta kokoa" antaaksesi Documoton muuttaa kokoa ja lisätä kuvan, tai "Peruuta" muokata kuvaa ja lisätä manuaalisesti',
  import: 'Tuo',
  importBOM: 'Tuo BOM',
  importBOMError: 'Näytedatan käsittely epäonnistui, tarkasta syötelähde',
  importParts: 'Tuo Osat',
  inCart: 'Ostoskorissa',
  inCartDescription: 'Näytä tagit ostoskorissa',
  includePagePartDetails: 'Sisällytä sivun osien tason tiedot',
  includePartDetails: 'Sisällytä osien tason tiedot',
  indexQueueJob: 'Indeksijono',
  inPartsList: 'Osaluettelossa',
  inPartsListDescription: 'Näytä tagit osaluettelossa',
  inputSource: 'Syötelähde',
  inch: 'Tuuma | Tuumat',
  includeComments: 'Sisällytä Kommentit',
  includeCommentsAuthorAndDate: 'Sisällytä Kommentin Antaja ja Päivämäärä',
  includeHeaderRow: 'Sisällytä otsikkorivi',
  includeImages: 'Sisällytä Kuvat',
  includePagesIndex: 'Sisällytä Sivuhakemisto',
  includePartsIndex: 'Sisällytä Osahakemisto',
  includePartsList: 'Sisällytä Osaluettelo',
  includeSubChapters: 'Sisällytä Alikappaleet',
  indexLane: 'Indeksikaista',
  useSequentialPageNumbering: 'Käytä Peräkkäistä Sivunumerointia',
  hideBookInSearchAndRecent: 'Piilota Kirjat Hakutuloksissa ja Viimeksi Lisätyissä',
  hideDuplicatePages: 'Piilota Sivujen Kaksoissivut',
  hidePageInSearchAndRecent: 'Piilota Sivut Hakutuloksissa ja Viimeksi Lisätyissä',
  hidePartInSearchAndRecent: 'Piilota Osat Hakutuloksissa ja Viimeksi Lisätyissä',
  homePageSettings: 'Etusivun asetukset',
  hotpointZoomBehavior: 'Kiintopisteen zoomaustoiminto',
  includeNewParts: 'Sisällytä vain "uudet" osat (ei Documotossa)',
  includeWatermark: 'Lisää vesileima?',
  increaseHotpoint: 'Suurenna Hotpoint',
  indexed: 'Indeksoitu',
  indexingConfiguration: 'Indeksoinnin konfigurointi',
  indexIncludeTimestamp: 'Indeksiin sisällytettävä aikaleima',
  info: 'Info',
  information: 'Tiedot',
  inherit: 'Periä',
  inheritedParent: 'Peritty Ylätasolta.',
  inputSpecification: 'Syötteen Määritys',
  inputType: 'Syötetyyppi',
  inputTypeListboxWarning: 'Lajittelumääritykset näkyvät tallennettaessa',
  integration: 'Integrointi | Integroinnit',
  integrationsAddPartToErpCart: '{action} Lisää Osa ERP-ostoskoriin',
  integrationsAddToShoppingCartPricingFromErp: '{action} Lisää Ostoskorin Hinnoittelu ERP:stä',
  integrationsDisableErp: 'Poista ERP Käytöstä',
  integrationsDoErpForQuotes: 'Tee ERP Tarjouksille',
  integrationsEnableErp: 'Ota ERP Käyttöön',
  integrationsEnableFetchPartInfoFromErp: 'Ota Käyttöön Hae Osatieto ERP:stä',
  integrationsEnableScopeErp: 'Ota {scope} ERP Käyttöön',
  integrationsEnableSendShoppingCartToErp: 'Ota Käyttöön Lähetä Ostoskori ERP:iin',
  integrationsEnableSyncPoAddressWithErp: 'Ota käyttöön Synkronoi Ostotilauksen Osoite ERP:n kanssa',
  integrationsErpAvailabilityMappedFrom: 'ERP-Saatavuus Yhdistetty Kohteesta',
  integrationsErpCurrencyCodeMappedFrom: 'ERP-Valuuttakoodi Yhdistetty Kohteesta',
  integrationsErpDiscountedPriceMappedFrom: 'Alennettu ERP-Hinta Yhdistetty Kohteesta',
  integrationsErpEtaMappedFrom: 'ERP ETA Yhdistetty Kohteesta',
  integrationsErpMappings: 'ERP-Yhdistämiset',
  integrationsErpRetailPriceMappedFrom: 'ERP-Jälleenmyyntihinta Yhdistetty Kohteesta',
  integrationsErpWholesalePriceMappedFrom: 'ERP-Tukkuhinta Yhdistetty Kohteesta',
  integrationsInvokeGetPartInfoFromClient: 'Pyydä GetPartInfo Asiakkaalta',
  integrationsParseAddressForType: 'Jäsennä Osoite Tyypille',
  integrationsShoppingCartAndPartsTitle: 'Ostoskori ja Osat',
  integrationsShoppingCartAvailability: '{action} Ostoskorin Saatavuus',
  integrationsShoppingCartEtaFromErp: '{action} Ostoskorin ETA ERP:stä',
  internalApiKey: 'Sisäinen API-avain',
  internalDescription: 'Sisäinen Kuvaus',
  internalName: 'Sisäinen Nimi',
  internalNameOptional: 'Tunnisteesi tagille',
  internalNameRequired: 'Nimi on pakollinen',
  invalidDecimals: 'Vain kaksi desimaalia on sallittu',
  invalidField: '{field} on virheellinen',
  invalidFileType: 'Tiedostotyyppiä ei tueta. Saat Documoton Tukikeskuksesta kattavan listan tuetuista tiedostotyypeistä.',
  isSearchable: 'On Haettavissa',
  insertDataField: 'Lisää tietokenttä',
  isSystem: 'On Järjestelmä',
  italianDescription: 'Italia Kuvaus',
  italianName: 'Italia Nimi',
  item: '#',
  itemAlt: 'Kohde',
  itemCopied: '{item} kopioitu',
  itemDeleted: '{item} poistettu',
  itemInProgress: '{item} on käynnissä',
  itemInformation: 'Kohteen {item} tiedot',
  itemMustBeLteItem: 'Kohteen {itemA} on oltava sama tai aikaisempi kuin kohteen {itemB}.',
  itemMustBeUnique: 'Kohteen {item} on oltava yksilöllinen',
  itemMustBeUniqueAndNotEmpty: 'Kohteen {item} on oltava yksilöllinen ja se on pakollinen',
  itemName: 'Kohteen {item} Nimi',
  itemNotShown: '',
  itemSaved: '{item} tallennettu',
  itemSpecification: 'Kohteen Määritys',
  showChapterTOCForHiddenPages: 'Näytä Piilotetut Sivut Kappaleen Sisällysluettelossa',
  january: 'Tammikuu',
  japaneseDescription: 'Japani Kuvaus',
  japaneseName: 'Japani Nimi',
  job: 'Työ | Työt',
  jobManager: 'Job Manager',
  jobManagerMessage: 'Vie, julkaise ja tulosta/lataa töitä',
  july: 'Heinäkuu',
  june: 'Kesäkuu',
  koreanDescription: 'Korea Kuvaus',
  koreanName: 'Korea Nimi',
  label: 'Nimitys | Nimitykset',
  labelKey: 'Nimityksen Selite',
  landscape: 'Vaaka',
  laneConfiguration: 'Kaistan konfigurointi',
  language: 'Kieli',
  lastModifiedDate: 'Viimeinen muokkauspäivä',
  lastGenerated: 'Viimeksi Luotu',
  lastName: 'Sukunimi',
  lastNameEmpty: 'Sukunimi ei voi olla tyhjä',
  lastUpdatedOn: 'Viimeksi päivitetty',
  layout: 'Asettelu',
  layoutOptions: 'Asetteluvaihtoehdot',
  ledger: 'Pääkirja',
  left: 'Vasen',
  legal: 'Legal',
  letter: 'Letter',
  library: 'Kirjasto',
  libraryAdmin: 'Kirjaston Ylläpitäjä',
  libraryBannerLogo: 'Kirjaston bannerilogo',
  librarySettings: 'Kirjaston Asetukset',
  librarySettingsDescription: 'Näillä asetuksilla hallitaan Kirjaston eri elementtejä ja vaihtoehtoja. Tee muutokset huolella. Ota yhteyttä Documoton Palveluihin lisätietojen saamiseksi.',
  licenseConfiguration: 'Lisenssin konfigurointi',
  line: 'Rivi',
  lineTool: 'Viivatyökalu',
  link: 'Linkki | Linkit',
  listBox: 'Luetteloruutu',
  listBoxValue: 'Luettele Ruutuarvo | Luettele Ruutuarvot',
  listValue: 'Luettele Arvo | Luettele Arvot',
  load: 'Lataa',
  loadMore: 'Lataa lisää',
  loading: 'Ladataan',
  locale: 'Kieliasetus',
  login: 'Kirjaudu sisään',
  loginBackground: 'Kirjautumisen Tausta',
  loginGDPRMessageBeginning: 'Tämä sivusto käyttää evästeitä. Jos jatkat tämän sivuston käyttöä, annat suostumuksesi evästeiden käyttöön. Katso lisätietoja kohdasta ',
  loginGDPRMessageEnd: 'ja kohdasta',
  loginSSO: 'Kirjaudu (SSO)',
  logiReportName: 'Logi-raportin nimi',
  logiReportToggle: 'Logi-raportti?',
  logo: 'Logo | Logot',
  logout: 'Kirjaudu ulos',
  lookupLoadParts: 'Etsi/Lataa Osia Dokumotosta Tuonnin yhteydessä',
  lowerBound: 'Alaraja',
  makePagesDivisibleBy: 'Tee Sivuista Jaollisia Seuraavasti:  ',
  mainInfo: 'Päätiedot',
  mainLogo: 'Päälogo',
  manageItem: 'Hallitse kohdetta {item}',
  management: 'Hallinta',
  march: 'Maaliskuu',
  margin: 'Marginaali | Marginaalit',
  markdown: 'Hinnanalennus',
  may: 'Toukokuu',
  media: 'Media | Mediat',
  mediaCategory: 'Mediakategoria | Mediakategoriat',
  mediaCategoryEditor: 'Mediakategorian Editori',
  mediaCategoryName: 'Mediakategorian Nimi',
  mediaCategoryNameMissing: 'Kaikki mediakategoriat vaativat nimen',
  mediaBookDeleteMessage: 'Tämän median poistaminen poistaa pääsyn tähän sisältöön sekä Kirjastossa että Sisällön Hallinnassa ja se ei ole enää käyttäjien tai Sisällönvalvojien käytettävissä. Mitään tämän kirjan osia tai sivuja, joita ei löydy muista kirjoista, ei myöskään ole enää saatavissa Kirjastossa.',
  mediaDeleteMessage: 'Tämän mallineen poistaminen poistaa sen Sisällön Hallinnasta ja se ei enää ole saatavissa kopioitavaksi uuteen Mediaan.',
  mediaDeleteSuccessText: 'Se poistetaan luetteloista, kun indeksointi on valmis.',
  mediaDescription: 'median kuvaus',
  mediaIdentifier: 'median tunniste',
  mediaName: 'Median nimi',
  mediaPrintSettingsSaveSuccess: 'Median Tulostusasetukset tallennettu',
  mediaWhereUsed: 'Käytetty Mediassa',
  menu: 'Valikko',
  merge: 'Yhdistä',
  mergeOrOverrideMessage: 'Haluatko korvata tai yhdistää nämä kohteet?',
  mergeWarning: 'Datan tuonti päivittää nykyiset osat ja lisää uusia syötteitä',
  message: 'Viesti',
  messageLevel: 'Viestin Taso',
  microsite: 'Mikroalue | Mikroalueet',
  micrositeAdmin: {
    cardSubtitle: 'Pakatun sisältötiedoston ja HTML-syötetiedot',
    cardTitle: 'Sivun sisältö',
    errorRequiredFile: 'Sivun sisältö-ZIP-tiedosto tarvitaan',
    fieldFile: 'Sivun sisältö-ZIP-tiedosto',
    fieldPoint: 'HTML-aloituspiste',
  },
  minArea: 'Pienin Alue',
  minAreaMessage: 'Vähimmäisarvo on pakollinen. Arvo ei saa ylittää väliä 1–1500.  On oltava kokonaislukuarvo.',
  moreInfo: 'Lisätiedot',
  multipleAlternatingPages: 'Useita Vuorottelevia Sivuja',
  multipleAlternatingPagesMessage: 'Kaavio sivuilla 1 ja 3, osaluettelo sivuilla 2 ja 4.',
  mToM: 'M2M',
  multiplePages: 'Useita Sivuja',
  multiplePagesMessage: 'Kaavio omalla sivullaan, minkä jälkeen osaluettelon sivut',
  requiredField: 'Pakollinen Kenttä',
  messageMessage: 'Viesti on pakollinen',
  metadata: 'Metadata',
  mine: 'Oma',
  miscellaneous: 'Sekalaista',
  mon: 'Ma',
  myCart: 'Oma Ostoskori | Omat Ostoskorit',
  navigation: 'Navigointi',
  nItemsCreated: '{n} {items} Luotu',
  nItemsDeleted: '{n} {items} Poistettu',
  nOfMItems: '{n}/{m} {items}',
  name: 'Nimi',
  nameDescriptionHelpText: 'Nimi ja valinnainen kuvaus',
  new: 'Uusi',
  newAPIKey: 'Uusi API-avain',
  newERPSystem: 'Uusi ERP-järjestelmä',
  newItem: 'Uusi {item}',
  newKeyGenerated: 'Uusi Integrointiavain Luotu, Kopioitu ja Säilytetty tulevaa käyttöä varten',
  newMediaCategory: 'Uusi Mediakategoria',
  newPassword: 'Uusi Salasana',
  newsItem: 'Uutinen| Uutiset',
  newTenant: 'Uusi vuokraaja',
  newWidgetKey: 'Uusi widget-avain',
  newWidgetKeyGenerated: 'Uusi widget-avain luotu, kopioi ja säilytä tulevaa käyttöä varten',
  noTenantSuppliers: 'Vuokraajalle ei ole nimetty toimittajia.',
  noAPIKeys: 'Ei määriteltyjä API-avaimia.',
  node: 'Solmu',
  noData: 'Ei Tietoja',
  noFilesSelected: 'Ei valittuja Tiedostoja',
  noIllustrationFound: 'Kuvia ei Löytynyt',
  noImageFound: 'Kuvaa ei Löytynyt',
  noItemAvailable: 'Kohdetta {item} ei saatavilla',
  noItemFound: 'Tuotetta {item} ei löydy.',
  noItemSelected: 'Kohdetta {item} ei valittuna',
  noItemAddedToTarget: 'Mitään {item} ei lisätty {target}. ',
  noItemsFound: 'Tuotteita Ei Löytynyt',
  noMoreResults: 'Ei enempää tuloksia',
  noFormsMessage: 'Lomakkeita ei ole määritetty.',
  noMediaDefined: 'Ei määriteltyä mediaa',
  nonSearchableFieldsSuccessfullyUpdated: 'Ei haettavissa olevat kentät päivitetty onnistuneesti.',
  noPartsSelected: 'Ei valittuja osia.',
  noPrice: 'Pyydä Hinta',
  noResults: 'Ei tuloksia',
  norwegianDescription: 'Norja Kuvaus',
  norwegianName: 'Norja Nimi',
  noSearchableTags: 'Ei haettavia tageja',
  noSuggestions: 'Ei ehdotuksia hakusanalle',
  notOrderable: 'Ei Tilattavissa',
  notSelected: 'Ei valittu',
  noTagsFound: 'Ei löytynyt tunnisteita',
  noUnsavedChanges: 'Ei Tallentamattomia Muutoksia',
  none: 'Ei mitään',
  notImplemented: 'Ei Otettu Käyttöön',
  notification: 'Ilmoitus | Ilmoitukset',
  notificationsMessage: 'Tärkeät tiedotukset ja uutiset',
  notMapped: 'Ei Kartoitettu',
  november: 'Marraskuu',
  noWidgetKeys: 'Ei määritelty widget-avaimia.',
  numeric: 'Numeerinen',
  numberOf: '# /{item}',
  october: 'Lokakuu',
  of: '/',
  off: 'Pois päältä',
  offlineContent: 'Offline-Sisältö',
  ok: 'Ok',
  oldPassword: 'Vanha salasana',
  on: 'Päällä',
  opacity: 'Läpinäkyvyys',
  open: 'Avoin',
  openItem: 'Avaa {item}',
  optimizeIndexJob: 'Optimoi Indeksi',
  options: 'Vaihtoehdot',
  openTab: 'Avaa linkki uudessa välilehdessä',
  order: 'Tilaus | Tilaukset',
  orderable: 'Tilattavissa',
  orderableDescription: 'Osan voi lisätä ostoskoriin ja ostaa',
  orderableLocal: 'Tilattavissa tällä sivulla',
  orderableLocalBlocked: 'Osa ei ole Yleisesti Tilattavissa',
  orderDisable: 'Poista Tilaushistoria Käytöstä',
  orderEmailRecipient: 'Tilaussähköpostin Vastaanottaja | Tilaussähköpostin Vastaanottajat',
  orderEntity: 'Tilauskokonaisuus | Tilauskokonaisuudet',
  orderFieldUneditableRequired: 'Tilaus-/tarjouskentän asettaminen Pakolliseksi eikä Käyttäjän Muokattavaksi voi tehdä Tilaus-/tarjouslomakkeen täyttämisen mahdottomaksi. Varmista, että Tilaus-/tarjouslomakkeesi voidaan täyttää.',
  orderFieldsEmptyMessage: 'Tilaus-/tarjouskenttiä ei ole määritelty.',
  orderFieldsEmptyPrompt: 'Lisää Tilaus-/tarjouskenttä',
  orderField: {
    nameMessage: 'Jos vaihdat Tilaus-/tarjouskentän nimeä, muista päivittää kaikki Tilaus-/tarjouslomakkeet tai Sähköpostiformaatit, jotka käyttävät tätä kenttää.',
  },
  orderFieldCopyMessage: 'Anna uudelle Tilaus-/tarjouskentälle yksiselitteinen nimi',
  orderForm: {
    uniqueNameInputHint: 'Anna tälle uudelle Lomakkeelle yksilöllinen nimi.'
  },
  orderFormField: 'Tilaus-/tarjouskenttä | Tilaus-/tarjouskentät',
  orderFormLayout: 'Tilaus-/tarjouslomakkeen Asettelu | Tilaus-/tarjouslomakkeen Asettelut',
  orderFormSetDefault: 'Tilaus-/Tarjouslomakkeen oletussarja',
  orderFormType: 'Tilauslomake',
  orderLayout: 'Tilauspohja | Tilauspohjat',
  orderManagementApprover: 'Tilaushallinnan Hyväksyjä',
  orderManagementDisable: 'Poista Tilaushallinnan Ylläpito Käytöstä',
  orderManagementViewer: 'Tilaushallinnan Katsoja',
  orderNumber: 'Tilausnumero',
  orderQuoteEmail: 'Tilauksen/tarjouksen sähköposti | Tilausten/tarjousten sähköpostit',
  orderQuoteEmailFormat: 'Tilauksen/tarjouksen sähköpostimuoto | Tilausten/tarjousten sähköpostimuodot',
  orderQuoteEmailFormatCopyMessage: 'Valitse uuden tilauksen/tarjouksen sähköpostimuodon kieli.',
  orderQuoteFetchError: 'Virhe noudettaessa tilaus-/tarjouslomakkeen tietoja',
  orderQuoteForm: 'Tilaus-/Tarjouslomake | Tilaus-/Tarjouslomakkeet',
  orderQuoteSaveConfirmation: 'Tallennetaanko organisaation tilaus-/tarjouslomakkeiden muutokset?',
  orderQuoteSaveError: 'Virhe tallennettaessa tilaus-/tarjouslomakkeen tietoja',
  orderQuoteFormSaved: 'Tilaus-/tarjouslomake tallennettu',
  orderStatus: 'Tilauksen/tarjouksen tila | Tilausten/tarjousten tilat',
  orderStatusDeleteMessage: 'Tätä tilaa on käytetty tilauksissa, eikä sitä voi poistaa.',
  orderSubmittedMessage: 'Lähetys Onnistui',
  orderSuggestion: 'Tilausehdotus | Tilausehdotukset',
  orderSuggestionEnable: 'Ota Tilausehdotukset Käyttöön',
  orderSuggestionRemovalWarning: 'Osa ei enää näy tilausehdotuksena. | Osat eivät enää näy tilausehdotuksina.',
  orderSuggestionThreshold: 'Tilausehdotuksen Raja-arvo',
  ordersLogo: 'Tilausten Logo',
  ordersMessage: 'Tilauslähetyshistoria',
  orientation: 'Suunta',
  org: 'Alkup.',
  orgEmailRecipients: 'Organisaation Sähköpostin Vastaanottajat',
  organization: 'Organisaatio | Organisaatiot',
  organizationAdmin: 'Organisaation Hallinta',
  organizationAdminBrowseFlow: 'Organisaatio voi käyttää oletusselausjärjestystä tai sen voi ohittaa ja käyttää jotain muuta selausjärjestystä.',
  organizationAdminMessage: 'Hallitsee käyttäjänhallintaa ja organisaatioasetuksia',
  organizationEmpty: 'Organisaatio on valittava',
  organizationEmailRecipients: 'Organisaation Sähköpostin Vastaanottajat',
  organizationEnforceAccessControlsForQuickAdd: 'Pikalisäyksen pääsynvalvonnan käyttöönotto',
  organizationKey: 'Organisaation Selite',
  organizationLibrarySettingsDescription: 'Näillä asetuksilla hallitaan Organisaatiotasoisia Kirjaston elementtejä ja vaihtoehtoja. Tee muutokset huolella. Ota yhteyttä Documotoon lisätietojen saamiseksi.',
  organizationMoveToRoot: 'Pudota tähän siirtääksesi juureen',
  organizationName: 'Organisaation nimi',
  organizationRearrange: 'Järjestä Organisaatiot Uudelleen',
  organizationRearrangeDescription: 'Hierarkkinen Organisaatiorakenne määrittää monia Organisaation periytyviä ominaisuuksia kuten Tulostusasetuksia, Tyylejä jne. Vedä ja pudota Organisaatiot haluamaasi hierarkiaan.',
  organizationReportSettingsSuccessMessage: 'Raportointiasetukset tallennettu',
  organizationSelect: 'Valitse Organisaatio',
  organizationTags: 'Organisaation Tagit',
  organizationsEmpty: 'Et ole luonut vielä yhtään organisaatiota',
  orgNameOnly: 'Vain Alkup. Nimi',
  other: 'Muu',
  otherSettings: 'Muut Asetukset',
  outboundCustomerIdParameter: 'Uloslähtevä Asiakastunnuksen Parametrin Nimi',
  outboundSessionIdParameter: 'Uloslähtevä Tilaisuuden Tunnuksen Parametrin Nimi',
  outlineMode: 'Ääriviivatila (Shift O)',
  output: 'Tuloste',
  outputCatalog: 'Tulosteluettelo',
  outputDefinition: 'Tulosteen määritelmä | Tulosteen määritelmät',
  outputDefinitionDeleted: 'Tulostemääritelmä poistettu',
  outputDefinitionSaved: 'Tulostemääritelmä tallennettu',
  outputFileName: 'Tulostiedoston nimi',
  outputName: 'Tulosteen nimi',
  outputDestination: 'Tuloksen kohde',
  outputSpecification: 'Tuloksen tiedot',
  outputSubType: 'Tulosteen alityyppi',
  outputType: 'Tulosteen tyyppi',
  overridden: 'Ohitettu',
  overriddenByOrg: 'Organisaation Ohittama',
  override: 'Ohita',
  overrideBrowseFlow: 'Ohita Selausjärjestys',
  overrideFacetLimitValue: 'Ohita fasetin rajoitusarvo',
  overrideHotpointScale: 'Ohita Kohdepisteskaalaus',
  overridePdfNamingStandard: 'PDF-tiedostojen nimeämisstandardin ohittaminen',
  overridePrintSettings: 'Ohita Tulostusasetukset',
  overrideFontSettings: 'Ohita Fonttiasetukset',
  overrideOrderForm: 'Ohita tilauslomake',
  overrideQuoteForm: 'Ohita tarjouslomake',
  overrideSvgOpacity: 'Ohita SVG-Peittävyys',
  overrideWarning: 'Datan tuonti korvaa nykyisen BOM-luettelosi',
  owner: 'Omistaja | Omistajat',
  page: 'Sivu | Sivut',
  pageBulkInsertConfirmTitle: 'Haluatko suorittaa joukkolisäyksen?',
  pageBulkInsertConfirmInsertAfter: 'Tämä liittää {replacement}  {original} jälkeen kohdassa {whereUsedBookCount} {bookUnits} ja {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'Tämä liittää {replacement} ennen {original} kohdassa {whereUsedBookCount} {bookUnits} ja {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'Tämä liittää {replacement} ja poistaa {original} kohdassa {whereUsedBookCount} {bookUnits} ja {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'Tämä liittää {replacement}  {original} jälkeen kohdassa {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'Tämä liittää {replacement} ennen {original} kohdassa {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'Tämä liittää {replacement} ja poistaa {original} kohdassa {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Tämä liittää {replacement}  {original} jälkeen kohdassa {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Tämä liittää {replacement} ennen {original} kohdassa {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Tämä liittää {replacement} ja poistaa {original} kohdassa {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'Joukkolisäystä ei käsitellä.',
  pageBulkInsertHelpText: 'Tämä toiminto sallii sinun lisätä tämän lähdesivun {thisDisplayName} valittuihin varaosakirjoihin, joissa on jo toinen sivu {originalDisplayName}. Vaihtoehtoisesti voit myös poistaa {originalDisplayName} noista kirjoista.',
  pageBulkInsertInsertAfter: 'Lisää {replacement} kohteen {original} JÄLKEEN',
  pageBulkInsertInsertBefore: 'Lisää {replacement} ENNEN kohdetta {original}',
  pageBulkInsertInsertReplace: 'Lisää {replacement} ja POISTA {original}',
  pageBulkInsertScopeAll: 'All {type} jolla on kohdesivu {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Ei mikään {type} jolla on kohdesivu {originalDisplayName}',
  pageBulkInsertScopeSome: 'Osa {type} joilla on kohdesivu {originalDisplayName}',
  pageBulkInsertStep1: 'Vaihe 1: Etsi haluttu kohdesivu',
  pageBulkInsertStep2: 'Vaihe 2: Päätä, miten sivujen lisäys tai korvaus tapahtuu',
  pageBulkInsertStep3: 'Vaihe 3: Valitse kirjat, joissa haluat joukkolisäyksen käsiteltävän.',
  pageBulkInsertStep4: 'Vaihe 4: Valitse mallit, joissa haluat joukkolisäyksen käsiteltävän.',
  pageBulkInsertSuccessTitle: 'Joukkolisäystyö lähetetty',
  pageBulkInsertSuccessBody: 'Kirjan sisällysluettelon muutokset tehdään välittömästi. Ne heijastuvat hakutuloksissa, kun indeksointi on valmis.',
  pageBulkErrorTooManyBooks: 'Rajaa valittujen kirjojen määrää enintään arvoon 1000',
  pageBuilder: 'Sivunrakentaja',
  pageComposer: 'Page Composer',
  pageCreated: 'Sivu Luotu',
  pageDeleteMessage: 'Sivujen poistot tulevat voimaan välittömästi kirjaston ja sisällön hallinnassa. Poistettu sivu pysyy hakukelpoisena, kunnes indeksointi on suoritettu uudelleen muutaman minuutin kuluessa.',
  pageDeleteMessage2: 'Tätä toimenpidettä ei voi perua ilman Documoto-tuen apua. Poistettu tietue ja kaikki assosiaatiot poistetaan pysyvästi 90 päivän kuluttua.',
  pageDescription: 'Sivun kuvaus',
  pageFile: 'Sivutiedosto',
  pageFilename: 'Sivun tiedostonimi',
  pageFilenameDeleted: '{filename} poistetaan.',
  pageFileRequired: 'Sivutiedosto on pakollinen.',
  pageBomPartsDeleted: '{count} Sivun BOM-osat eivät enää ole käytettävissä {filename}kautta.',
  pageDraftsDeleted: 'Tälle sivulle liittyvät luonnossivut poistetaan pysyvästi.',
  pageFit: 'Sivun Sovitus',
  pageHeader: 'Sivun Ylätunniste',
  pageLabel: 'Sivu | Sivut',
  pageLayout: 'Sivupohja',
  pageName: 'Sivun nimi',
  pagePart: 'Sivun Osa | Sivun Osat',
  pagePartTag: 'Sivun Osamerkki | Sivun Osamerkit',
  pagePartData: 'Sivun osan tiedot',
  pageUsageDataCouldNotBeLoaded: 'Sivun käyttötietoja ei voitu ladata',
  pagePartLevelDetails: 'Sivun osa tilattavissa/nähtävissä, Sivun osan tunnisteet',
  pagePartNumberVisible: 'Sivun Osanumero näkyvillä',
  pagePartOrderable: 'Sivun Osa Tilattavissa',
  pagePartOrderableDescription: 'Osa Tilattavissa tältä sivulta',
  pagePartVisibleDescription: 'Osanumero näkyvillä tällä sivulla',
  pageUpdated: 'Sivu Päivitetty',
  pageViewer: 'Sivun katseluohjelma',
  pageWidth: 'Sivun Leveys',
  pagesWhereUsed: 'Käytetty Sivuilla',
  parentName: 'Ylätason Nimi',
  part: 'Osa | Osat',
  partCode: 'Osakoodi | Osakoodit',
  partCodeAddError: 'Sivukoodia(-koodeja) Ei Voi lisätä',
  partCodeErrorEmpty: 'Ei voitu tallentaa: Osakoodi ei voi olla tyhjä',
  partCodeErrorInUse: 'Tätä osakoodia käytetään Documoton osissa, eikä sitä voi poistaa. Ota yhteyttä Documoton Tukeen osoitteessa support{\'@\'}documoto.com lisätietojen saamiseksi.',
  partCodeErrorNotFound: 'Tätä osakoodia ei voitu poistaa, koska sitä ei löytynyt. Varmista, että kaikki muutokset on tallennettu.',
  partCodesManager: 'Osakoodien Hallintatoiminto',
  partCodesMessage: 'Osakoodeja voidaan käyttää osanumeroiden sijasta, ja niitä käytetään usein osoittamaan, että BOM-kohde on vanhentunut, se ei ole saatavilla tai se on vain referenssituote (esim. \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Osa Luotu',
  partData: 'Osatiedot',
  partUpdated: 'Osa päivitetty',
  partDebouncerEmailQueueJob: 'Sähköpostijono',
  partDescription: 'Osan kuvaus',
  partLabel: 'Osa | Osat',
  partLevelDetails: 'Osan hinnoittelu, Tilattavissa/nähtävissä, Lisäkäännökset, Osan tunnisteet',
  partName: 'Osan Nimi',
  partnerApiKey: 'Kumppanin API-avain',
  partnerRESTRequestLimit: 'Kumppanin REST-pyyntörajoitus',
  partNumber: 'Osanumero',
  partNumberDescription: 'Sisäinen osanumero, jota loppukäyttäjät voivat hakea',
  partNumberVisible: 'Osanumero näkyvillä',
  partNumberVisibleDescription: 'Osanumero näkyy loppukäyttäjille',
  partNumberVisibleLocal: 'Näkyvillä tällä sivulla',
  partNumberVisibleLocalBlocked: 'Osanumero ei ole Yleisesti Näkyvillä',
  partOrderable: 'Osa Tilattavissa',
  partRequired: 'Vähintään yksi osa vaaditaan',
  partTagProperty: 'Osan Tagin Ominaisuus | Osan Tagin Ominaisuudet',
  partTag: 'Osamerkki | Osamerkit',
  partThumbnailDescription: '',
  partialSupport: 'Osittainen Tuki',
  partsListColumn: 'Osaluettelon Sarake | Osaluettelon Sarakkeet',
  partsBook: 'Varaosakirja | Varaosakirjat',
  partsColumns: 'Osien Sarakkeet',
  partsNotSelected: 'Oletuskuva, jota käytetään osien esikatselukuvissa. Jos mukautettua esikatselukuvaa ei käytetä, Documoto näyttää standardin oletusesikatselukuvan.',
  partsPage: 'Osasivu | Osasivut',
  partsPages: 'Osasivut',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Jätä Osakoodit Pois Osaluetteloista',
    highlightAlternateRows: 'Korosta Vuorottelevia Rivejä',
    rotateImagesAutomatically: 'Kierrä Kuvia Automaattisesti',
    printBorderAroundImages: 'Tulosta Raja Kuvien Ympärille',
    keepImagesOnLeft: 'Pidä Kuvat Vasemmalla',
    keepBOMOnRight: 'Pidä BOM Oikealla',
    columnsUpdateErrorMsg: 'Osien Sarakkeiden päivittämistä yritettäessä tapahtui virhe',
    columnsUpdateSuccessMsg: 'Osan Sarakkeet päivitetty',
    columnsLoadErrorMsg: 'Osien sarakkeiden lataaminen epäonnistui',
    printHotpointsOnImages: 'Tulosta Hotpointit Kuviin',
  },
  partsList: 'Osaluettelo',
  partsListData: 'Osaluettelon Tiedot',
  partsListGridHeader: 'Osaluettelon Taulukon Otsikko',
  password: 'Salasana',
  passwordExpiredHeader: 'Salasana umpeutunut',
  passwordExpiredInfo: 'Salasanasi on umpeutunut. Uuden salasanasi on vastattava seuraavia kriteereitä:',
  passwordCriteriaInfo: 'Uuden salasanasi on vastattava seuraavia kriteereitä:',
  passwordBlankMessage: 'Salasana ei voi olla tyhjä',
  passwordCannotBeSame: 'Ei voi olla sama kuin nykyinen salasanasi',
  passwordCasingMassage: 'On sisällettävä vähintään yksi pieni kirjain ja yksi iso kirjain',
  passwordLowerCaseMessage: 'Salasanassa on oltava vähintään yksi pieni kirjain',
  passwordMinLengthMessage: 'Salasanoissa on oltava vähintään 8 merkkiä',
  passwordNotMatchingMessage: 'Salasanat eivät täsmää',
  passwordSpecialCharactersMessage: 'Salasanassa on oltava vähintään yksi erikoismerkki',
  passwordUpperCaseMessage: 'Salasanassa on oltava vähintään yksi iso kirjain',
  passwordWillExpire1Day: 'Kirjautumissalasana umpeutuu alle päivän sisällä',
  passwordWillExpire5Days: 'Kirjautumissalasana umpeutuu alle viiden päivän sisällä',
  passwordsMatch: 'Salasanojen vastaavuus',
  permission: 'Oikeus | Oikeudet',
  phoneNumber: 'Puhelinnumero',
  phoneNumberExt: 'Ulk.',
  phonePrimary: 'Ensisijainen Puhelinnumero',
  phoneSecondary: 'Toissijainen Puhelinnumero',
  pinFilter: 'Kiinteä suodatin tulossivujen hakua varten',
  pinned: 'Kiinnitetty',
  plusMoreOrgs: '+ {totalOrgCount} muuta Organisaatiota',
  polishDescription: 'Puola Kuvaus',
  polishName: 'Puola Nimi',
  populatePdfNameWith: 'Täydennä PDF-tiedoston tiedostonimi seuraavilla tiedoilla',
  populatePdfNameSelection: 'Täydennä PDF-tiedoston nimen valinta',
  port: 'Portti',
  portrait: 'Kuva',
  portugueseDescription: 'Portugali Kuvaus',
  portugueseName: 'Portugali Nimi',
  postComment: 'Lähetä Kommentti',
  postalCode: 'Postinumero',
  poweredBy: 'Voimanlähde:',
  preferences: 'Käyttäjäasetukset',
  preferencesDisable: 'Poista Käyttäjäasetukset Käytöstä',
  prefix: 'Etuliite | Etuliitteet',
  preview: 'Esikatselu',
  previewOfName: '{name} esikatselu',
  pricing: 'Hinnoittelu',
  pricingDiscountEnabled: 'Näytä Alennushinta',
  pricingDisplayInCart: 'Näytä Hinnat Ostoskorissa',
  pricingDisplayInPartsList: 'Näytä Hinnat Osaluettelossa',
  pricingGlobal: 'Yleinen Hinnoittelu',
  pricingGlobalDescription: 'Normaali hinta, kun Organisaation ohitusta ei ole',
  pricingHidePricingButton: 'Ota Käyttöön Piilota Hinnoittelu -Painike',
  pricingHidePricingButtonDisable: 'Poista Käytöstä Piilota Hinnoittelu -Painike',
  pricingOrganization: 'Organisaation Hinnoittelu',
  pricingOrganizationDeleteConfirm: 'Oletko varma, että haluat poistaa tämän Organisaation hinnoittelun?',
  pricingOrganizationDescription: 'Organisaation hinnoittelu ohittaa Organisaation ja sen Alaorganisaatioiden kaikkien käyttäjien yleisen hinnoittelun.',
  pricingOrganizationDuplicate: 'Tämä organisaatio on jo ohitettu',
  pricingOrganizationEmptyState: 'Organisaatioiden ohituksia ei ole määritetty. Aloita napsauttamalla {add}.',
  pricingRetailEnabled: 'Näytä Jälleenmyyntihinta',
  pricingWholesaleEnabled: 'Näytä Tukkuhinta',
  primary: 'Ensisijainen',
  print: 'Tulosta',
  printerLane: 'Tulostinkaista',
  printJob: 'Tulosta Työ',
  printLogo: 'Tulosta logo',
  printSetting: 'Tulostusasetus',
  printSettingDeleteFail: 'Tulostusasetuksen Poistaminen Epäonnistui',
  printSettingSaveFail: 'Tulostusasetuksen Tallentaminen Epäonnistui',
  printSettingEmptyMessage: 'Tulostusasetuksia ei ole määritetty. Aloita Lisäämällä Tulostusasetus.',
  printSettingSetDefaultSuccess: 'Oletusarvoinen Tulostusasetussarja',
  printSettingUpdateFail: 'Tulostusasetuksen Päivittäminen Epäonnistui',
  printSettingCopyMessage: 'Anna uudelle Tulostusasetukselle yksilöllinen nimi.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Tulostusasetus luotu',
  printSettings: 'Tulostusasetukset',
  printSettingsLogo: 'Tulostusasetusten Logo',
  printing: 'Tulostus',
  printingDisabled: 'Poista Kaikki Tulostus Käytöstä',
  privacyPolicy: 'Tietosuojakäytäntö',
  proceed: 'Jatka',
  process: 'Edistyminen',
  processContent: 'Prosessin Sisältö',
  processing: 'Käsittely',
  processToDraft: 'Käsitelty luonnokseksi',
  processedPlzToDraft: 'PLZ(:t) käsitelty luonnoksiksi',
  production: 'Tuotanto',
  professional: 'Ammattilainen',
  profile: 'Profiili',
  prohibitExportDownloadOfContent: 'Kiellä sisällön vienti/lataus',
  prohibitExportDownloadOfContentHelpText: 'Asetus arvoon \'Ota käyttöön\' estää käyttäjiä (joilla on etuoikeudet) viedä tai ladata tätä sisältöä.',
  property: 'Ominaisuus | Ominaisuudet',
  province: 'Lääni/Alue',
  public: 'Julkinen',
  publish: 'Julkaise',
  publisherLane: 'Julkaisijan kaista',
  publisherManualLane: 'Julkaisijan manuaalinen kaista',
  publishThumbnailJob: 'Julkaise Esikatselukuva',
  publishDocuments: 'Julkaise Asiakirjat',
  publishDraft: 'Julkaise Luonnos',
  publisher: 'Julkaisija | Julkaisijat',
  publishingErrors: 'Julkaisuvirheet',
  publishingErrorsWithName: 'Julkaisuvirheet {nimi}',
  publishingInProgress: 'Julkaisu on käynnissä, ei voi lähettää',
  publisherJob: 'Julkaisutyö',
  publisherMessage: 'Sisällön luonti, hallinta ja tulostusasetukset',
  purchaseOrder: 'Ostotilaus',
  purchaseOrderTemplateEnabled: 'Ota Tilausmallineet Käyttöön',
  purchaseOrderTemplateDisabled: 'Poista Tilausmallineett Käytöstä',
  qty: 'Määrä',
  quantity: 'Määrä',
  query: 'Kysely',
  quickAdd: 'Pikalisäys',
  quickAddHelpText: 'Aloita kirjoittaminen löytääksesi osat, jotka haluat lisätä ostoskoriin.',
  quickAddInCartEnabled: 'Ota Pikalisäys Käyttöön Ostoskorissa',
  quickLinks: 'Pikalinkit',
  quote: 'Tarjous | Tarjoukset',
  quoteFormType: 'Tarjouslomake',
  quoteTemplateDisabled: 'Poista Tarjousmallit Käytöstä',
  quoteTemplateEnabled: 'Ota Tarjousmallit Käyttöön',
  range: 'Alue',
  rearrange: 'Järjestä uudelleen',
  rebuildIndexJob: 'Kokoa Indeksi uudestaan',
  recentSearches: 'Uusimmat haut',
  recentlyAdded: 'Viimeksi Lisätty',
  recentlyEdited: 'Viimeksi Muokattu',
  recentlyViewed: 'Viimeksi Katsottu',
  rectangle: 'Suorakulmio',
  redirect: 'Ohjaa uudelleen',
  refresh: 'Päivitä',
  related: 'Liittyvä',
  relatedDescription: 'Kohteeseen {type} liittyvä kirjaston mediasisältö. Huomaa, että liittyvässä mediasisällössä tulee olla käyttöoikeuksien valvontaa, jotta käyttäjät voivat nähdä sen \'liittyvien\' välilehdessä.',
  relatedInfo: 'Liittyvät Tiedot',
  relatedView: 'Näytä Liittyvä Media',
  relationship: 'Suhde | Suhteet',
  releaseNotes: 'Julkaisutiedot',
  rememberMe: 'Muista minut',
  remainingWidth: 'Jäljellä Oleva Leveys',
  remove: 'Poista',
  removeAll: 'Poista Kaikki',
  removeItemConfirmation: 'Oletko varma, että haluat poistaa kohteen {item}?',
  removeItemCount: 'Haluatko varmasti poistaa {count} {type}?',
  replace: 'Vaihda',
  replaceExisting: 'Korvaa Olemassa Oleva',
  report: 'Raportti | Raportit',
  reportAnIssue: 'Ilmoita Ongelmasta',
  reportAnIssueMessage: 'Sähköpostin lähetys onnistui.',
  reportCatalogName: 'Raporttiluettelon nimi',
  reportCatalogNotSet: 'Raporttiluetteloa ei ole määritetty raportille.',
  reportDefinition: 'Raportin määritelmä | Raportin määritelmät',
  reportDefinitionDeleteError: 'Raporttiluetteloa ei ole määritetty raportille.',
  reportsEmptyState: 'Ei raportteja',
  reportExportTimeWarning: 'Tietueiden lukumäärästä ja valitusta formaatista riippuen vienti voi kestää useita minuutteja.',
  reporting: 'Raportointi',
  reportingMessage: 'Pääsy eri raportteihin ja mittareihin',
  reportSettings: 'Raporttiasetukset',
  reportsLibrary: 'Raporttikirjasto',
  requestForQuote: 'Hintapyyntö',
  requestFormType: 'Tarjouslomake',
  required: 'Pakollinen',
  requiredItem: '{item} on pakollinen.',
  requiredMessage: '{item} on pakollinen.',
  requiredMessageAndUnique: '{item} on pakollinen ja sen on oltava yksilöllinen.',
  requireAuthentication: 'Vaadi Todennus',
  requiredReading: 'Vaatii Lukemisen',
  requiredReadingMessage: 'Tarkista seuraava:  ',
  requiredUrlMessage: 'Oikein muodostettu URL on pakollinen.',
  reset: 'Palauta',
  resetPassword: 'Palauta Salasana',
  resetPasswordError: 'Salasanan palauttamisessa oli ongelmia, yritä myöhemmin uudelleen.',
  resetPasswordTokenError: 'Salasanan palautustunnus on virheellinen.',
  resetZoom: 'Palauta Zoomaus',
  resource: 'Resurssi | Resurssit',
  resize: 'Muuta kokoa',
  resizeThumbnails: 'Muuta pikkukuvien koko automaattisesti',
  result: 'Tulos | Tulokset',
  resultFor: 'Tulos kohteelle {item} | Tulokset kohteelle {item}',
  retail: 'Jälleenmyynti',
  retailPrice: 'Jälleenmyyntihinta',
  retailTotal: 'Jälleenmyynti Yhteensä',
  return: 'Palaa',
  returnToItem: 'Palaa kohteeseen {item}',
  returnToPartList: 'Palaa Osaluetteloon',
  returnToMedia: 'Palaa Mediaan',
  returnToTemplate: 'Palaa Malliin',
  returnToViewer: 'Palaa katseluohjelmaan',
  revertChanges: 'Palauta Muutokset',
  right: 'Oikea',
  romanianDescription: 'Romania Kuvaus',
  romanianName: 'Romania Nimi',
  rootLevelBrowseFlow: 'Juuritaso',
  row: 'Rivi',
  russianDescription: 'Venäjä Kuvaus',
  russianName: 'Venäjä Nimi',
  sampleData: 'Näytedata',
  samplePreview: 'Näytteen esikatselu',
  sandbox: 'Sandbox',
  sat: 'La',
  save: 'Tallenna',
  saveChanges: 'Tallennatko muutokset kohteeseen {item}? {name}',
  saveChangesToItem: 'Tallennatko muutokset tähän kohteeseen {item}?',
  saved: 'Tallennettu',
  saveNewAPIKey: 'Uutta Integrointiavainta ei Tallennettu, Palaa viimeistelemään asennus',
  saveNewWidgetKey: 'Uutta widget-avainta ei tallennettu. Palaa täydellisiin asetuksiin.',
  search: 'Hae',
  searchableFieldsSuccessfullyUpdated: 'Hakukelpoiset kentät päivitetty onnistuneesti.',
  searchAllContent: 'Hae kaikki sisältö',
  searchConfiguration: 'Haun määritys',
  searchCriteria: 'Hakukriteerit',
  searchDocumoto: 'Hae Documoto',
  searchFeatures: 'Hakuominaisuudet',
  searchFieldConfigDescription: 'Seuraavat järjestelmäkentän hakuasetukset koskevat vain Kirjaston yleistä hakua, hakutulossivua ja Pikahakua ostoskorissa. Tämä ei vaikuta muihin hakutoimintoihin (kuten Hallintakeskuksen haku ja Kirjasto missä käytetty).',
  searchInContent: 'Hae kohdassa {contentName}...',
  searchingInLanguage: 'Haetaan vain kohdassa {lang} ',
  searchingInTwoLanguages: 'Haetaan vain kohdassa {lang1} ja {lang2} ',
  searchPlaceholder: 'Hae kaikki sisältö...',
  searchPrompt: 'Hae kaikki sisältö...',
  searchProperty: 'Hakuominaisuus | Hakuominaisuudet',
  searchResultsConfiguration: 'Hakutulosten määritys',
  searchSettings: 'Hakuasetukset',
  searchSettingsDescription: 'Nämä asetukset säätelevät kirjaston eri hakukäyttäytymisiä, -ominaisuuksia ja -tuloksia.',
  searchTerm: 'Hakusana',
  searchTips: 'Hakuvinkit:',
  searchable: 'Haettavissa Oleva',
  searchableTag: 'Haettavissa Oleva Tagi',
  section: 'Osio',
  select: 'Valitse',
  selectAField: 'Valitse {field}',
  selectAll: 'Valitse Kaikki',
  selectAndClose: 'Valitse ja Sulje',
  selected: 'Valittu',
  selectedTargetPage: 'Valittu Kohdesivu',
  selectChapters: 'Valitse Luku(-vut)',
  selectChildren: 'Valitse Alatasot',
  selectFields: 'Valitse {fields}',
  selectItem: 'Valitse {item}',
  selectMedia: 'Valitse Media',
  selectPage: 'Valitse Sivu',
  selectPages: 'Valitse Sivu(t)',
  selectTargetPage: 'Valitse Kohdesivu',
  send: 'Lähetä',
  september: 'Syyskuu',
  serbianDescription: 'Serbia Kuvaus',
  serbianName: 'Serbia Nimi',
  serviceUri: 'Palvelun URI',
    sessionLog: 'Istuntoloki',
  set: 'Aseta',
  setAsDefault: 'Aseta Oletukseksi',
  settings: 'Asetukset',
  settingsMessage: 'Päivitä käyttäjäasetukset ja salasana',
  sequence: 'Järjestys',
  shape: 'Muoto',
  shapeTool: 'Muototyökalu',
  shared: 'Jaettu',
  sharedCart: 'Jaettu Ostoskori | Jaetut Ostoskorit',
  sharedCartDeleteContent: 'Tämä poistaa tämän Jaetun Ostoskorin kaikista Organisaatioista, joissa se on jaettuna.',
  sharedCartOrganizationContent: 'Valitse Organisaatiot, jotka voivat käyttää tätä Jaettua Ostoskoria.',
  sharedShoppingCartsEnabled: 'Ota Jaetut Ostoskorit Käyttöön',
  shoppingCartExportJob: 'Ostoskorin Vienti',
  shoppingCartLoadingLongTime: 'Ostoskoria ladataan. Korin kuvakkeet tulevat käyttöön, kun prosessi on suoritettu. Jos ongelma jatkuu, pyydä apua Documoto-ylläpitäjältäsi.',
  showExportFromSearch: 'Salli Vienti Hakutuloksista',
  showExportFromSearchHelp: 'Salli hakutuloksena saadun kirjaluettelon vienti',
  showHelp: 'Näytä Ohjeet',
  showIdentifierInSearch: 'Näytä Mediatunniste haussa',
  showItem: 'Näytä {item}',
  showLess: 'Näytä Vähemmän',
  showMediaDescriptionFilter: 'Näytä Median Kuvauksen Suodatin',
  showMediaIdentifierFilter: 'Näytä Median Tunnisteen Suodatin',
  showMediaNameFilter: 'Näytä Median Nimen Suodatin',
  showMore: 'Näytä Enemmän',
  showOrganizationFilter: 'Näytä Organisaation Suodatin',
  showPartNameFilter: 'Näytä Osan Nimen Suodatin',
  showPartNumberFilter: 'Näytä Osanumeron Suodatin',
  showRecentlyAdded: 'Näytä Viimeksi Lisätty',
  showRecentlyViewed: 'Näytä Viimeksi Katsottu',
  showSearchButton: 'Näytä Etusivun Hakupainike',
  showSearchButtonHelp: 'Näytä \'Siirry Hakuun\' -painike Kirjaston ylätunnisteessa',
  signature: 'Allekirjoitus',
  size: 'Koko',
  snapshot: 'Tilannekuva | Tilannekuvat',
  solrSlaveSvrOverride: 'SOLR-orjapalvelinten alustan URL:n päällekirjoitus',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Osuvin',
  sortType: 'Lajittelutyyppi',
  spanishDescription: 'Espanja Kuvaus',
  spanishName: 'Espanja Nimi',
  square: 'Neliö',
  ssoConfiguration: 'SSO-konfigurointi',
  ssoIdpMetadataUrl: 'SSO IDP Metadata URL',
  ssoLoginMaxAuthenticationAgeSeconds: 'SSO-kirjautumisen maksimitodennusikä sekunneissa',
  ssoSamlDnAttributeName: 'SSO SAML DN -määritenimi',
  ssoSamlEmailAttributeName: 'SSO SAML Email -määritenimi',
  ssoSamlMemberofAttributeName: 'SSO SAML MemberOf -määritenimi',
  ssoSniForSsoMetadataRetrieval: 'Käytä SNI SSO:ta Metadata -haulle',
  standard: 'Vakio',
  standardUserGroup: 'Vakiokäyttäjäryhmä',
  startDate: 'Aloituspäivä',
  startDateRequired: 'Aloituspäivämäärä vaaditaan.',
  state: 'Osavaltio',
  static: 'Staattinen',
  status: 'Tila',
  statusCode: 'Tilakoodi | Tilakoodit',
  stitchPages: 'Nidontasivut',
  storeFrontAdmin: 'Julkisivun Hallinta',
  storefront: 'Julkisivu',
  storefrontIntegration: 'Julkisivun Integrointi | Julkisivun Integroinnit',
  storefrontIntegrationSubtitle: 'Näillä asetuksilla hallitaan Julkisivun Integroinnin eri elementtejä ja vaihtoehtoja. Tee muutokset huolella. Ota yhteyttä Documoton Tukeen lisätietojen saamiseksi.',
  storefrontSettings: 'Julkisivuasetukset',
  storefrontSettingsDescription: 'Näillä asetuksilla hallitaan Julkisivun eri elementtejä ja vaihtoehtoja. Tee muutokset huolella. Ota yhteyttä Documoton Palveluihin lisätietojen saamiseksi.',
  storefrontSettingsOrgDescription: 'Näillä asetuksilla hallitaan Organisaatiotasoisia Julkisivun elementtejä ja vaihtoehtoja. Tee muutokset huolella. Ota yhteyttä Documoton Palveluihin lisätietojen saamiseksi.',
  storeFilterValues: 'Tallenna suodatinarvot',
  storeFilterValuesDescription: 'Tämän asetuksen ottaminen käyttöön tallentaa arvot, jotka käyttäjät ovat asettaneet hakusivun suodattimiin tälle tunnisteelle.',
  stroke: 'Viiva',
  style: 'Tyyli | Tyylit',
  styles: {
    colorHotpoint: 'Hotpoint-oletus',
    invalidHexcode: 'Virheellinen heksakoodi',
    inheritedFromOrg: 'Peritty organisaatiosta {org}',
    inheritedFromSystem: 'Peritty järjestelmän oletuksesta',
    inheritedFromTenant: 'Peritty vuokralaiselta',
    organization: 'Organisaatiotyyli | Organisaatiotyylit',
    organizationSaved: 'Tallennetut organisaatiotyylit',
    sampleNavigation: 'Navigointivälilehdet',
    samplePrimary: 'Navigointi/Painikkeet',
    sampleSelectedHotpoint: 'Valitut hotpointit',
    tenant: 'Vuokralaisen tyyli | Vuokralaisen tyylit',
    tenantSaved: 'Tallennetut vuokralaisen tyylit'
  },
  subChapter: 'Alakappale | Alakappaleet',
  subject: 'Otsikko',
  subjectMessage: 'Otsikko on pakollinen',
  submit: 'Lähetä',
  submitPurchaseOrder: 'Ostotilaus',
  submitRequestForQuote: 'Hintapyyntö',
  submitted: 'Lähetetty',
  submitter: 'Lähettäjä',
  submittedBy: 'Lähettäjä: ',
  submitterEmail: 'Lähettäjän sähköpostiosoite',
  submitterFirstName: 'Lähettäjän etunimi',
  submittedFilesMessage: 'Lähetettiin {fileCount} tiedosto(a).',
  submitterLastName: 'Lähettäjän sukunimi',
  submitterOrgName: 'Lähettäjän organisaation nimi',
  submitterUserName: 'Lähettäjän käyttäjänimi',
  success: 'Onnistui',
  imageSaveSuccess: 'Kuvien päivittäminen onnistui',
  successNotificationDuration: 'Lisää Ostoskärryyn - Onnistuneen Toast -ilmoituksen Kesto',
  suffix: 'Jälkiliite | Jälkiliitteet',
  suggestedKeywords: 'Ehdotetut hakusanat',
  suggestedResults: 'Ehdotetut tulokset',
  suggestion: 'Ehdotus | Ehdotukset',
  sun: 'Su',
  superadmin: 'Pääylläpitäjä',
  superAdminSettings: "Pääylläpitäjän asetukset",
  supersede: 'Korvaa',
  supersedeComplete: 'Haluatko suorittaa korvaamisen?',
  supersedeDiscardChanges: 'Hylkää korvaaminen?',
  supersedeMainText: 'Tämä korvaa olemassa olevan osan yhdellä tai useammalla osalla kaikilla Osien Sivuilla, joista vanha osa löytyy. Vanha osanumero poistetaan, mutta sitä voi edelleen hakea tagien avulla, joten sisältö löytyy käyttämällä vanhaa numeroa tai uutta numeroa (uusia numeroita).',
  supersedeMessage: 'Osat päivittyvät välittömästi. Haku vastaa päivityksiä muutaman minuutin kuluessa.',
  supersedeNotProcessed: 'Korvaamista ei käsitellä.',
  supersedeReplacePart: 'Hae yhtä tai useampaa osaa, joka korvaa osan',
  supersedeSuccessful: 'Korvaaminen Onnistui',
  supersedeWith: 'Korvaa Osa Seuraavalla...',
  supplier: 'Toimittaja | Toimittajat',
  supplierKey: 'Toimittajan avain',
  supplierDescription: 'Yritys, joka toimittaa tämän osan',
  supportedImageFormats: 'Tuetut kuvamuodot: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Ei-tuettu tiedosto; tuetut luonnostiedostot: MDZ, PLZ',
  surroundWithQuotes: 'Merkitse useampi hakusana lainausmerkeillä saadaksesi tarkat vastaavuudet.',
  svgFinder: 'SVG-haku',
  swedishDescription: 'Ruotsi Kuvaus',
  swedishName: 'Ruotsi Nimi',
  system: 'Järjestelmä',
  systemAdmin: 'Järjestelmän Hallinta',
  systemFields: 'Järjestelmäkentät',
  systemFieldSearchAndFiltering: 'Järjestelmäkenttien haku ja suodatus',
  systemStatus: 'Järjestelmän Tila',
  systemName: 'Järjestelmän nimi',
  systemVersion: 'Järjestelmäversio',
  tabDelimited: 'Välilehdellä erotettu',
  tableOfContents: 'Sisällysluettelo',
  tabloid: 'Tabloidi',
  tag: 'Tagi | Tagit',
  tagName: 'Tunnisteen nimi',
  tagEditor: {
    rangeBoundLengthInvalid: 'Rajassa voi olla korkeintaan {limit} numeroa.',
    tagEditorModal: 'Tagieditorin Muoto',
    filterPrompt: 'Näytetään ensimmäiset 100 arvoa. Käytä suodatinta nähdäksesi lisää.'
  },
  tagSearchAndFiltering: 'Tunnisteiden haku ja suodatus',
  tagSearchAndFilteringHelpText: 'Vihje: Jotkin haku- ja suodatinasetukset ovat määritettävissä myös Tag Managerin tunnisteiden muokkaajissa, jotka löytyvät täältä: ',
  tagSubmitAffected: 'Mikä tahansa tyypin {type} sisältö pudottaa automaattisesti tagin {tag} muutosten lisäksi.',
  tagDeleteAffectedCount: 'Tämä poistaa tagin ja tagin tiedot {count} kohteesta.',
  tagDeleteAffectedNone: 'Yksikään kohde ei tällä hetkellä käytä tätä tagia.',
  tagManagerDeleteWarning: 'Kaikki olemassa olevat mediat, joilla on {deleteCategoryName}, menettävät tämän kategorian poistamisen jälkeen.',
  tagsDisplayMore: 'Näytetään ensimmäiset 100 arvoa. Käytä suodatinta nähdäksesi lisää.',
  tagsManager: 'Tagien Hallintatoiminto',
  tagValue: 'Tagin Arvo | Tagien Arvot',
  tagValueMissing: 'Sinun on annettava tagin arvo',
  tagValueMultiple: 'Puolipiste erottaa eri syötteet',
  tagValueTooLong: 'Tagin arvo ylittää 25 merkin rajan | Tagien arvot ylittävät 25 merkin rajan',
  taxon: 'Luokka',
  taxonomy: 'Luokitus',
  template: 'Malli | Mallit',
  templateEmailRecipients: 'Sähköpostimallin Vastaanottajat',
  templateLayout: {
    addRow: 'Lisää rivi',
    addItem: 'Lisää kohde',
    deleteRow: 'Poista rivi',
    deleteColumn: 'Poista sarake',
    fullWidth: 'Täysleveä',
    oneColumn: 'Yksi sarake',
    threeColumns: 'Kolme saraketta',
    twoColumns: 'Kaksi saraketta'
  },
  tenant: 'Tenant | Tenants',
  tenantAdmin: 'Tenant-ylläpitäjä',
  tenantAdminMessage: 'Hallitsee tenant-laajuisia asetuksia',
  tenantCleanerJob: 'Tenantin Ylläpito',
  tenantConfiguration: 'Vuokraajan konfigurointi',
  tenantEmailRecipients: 'Tenant-sähköpostin Vastaanottajat',
  tenantEmailRecipientsOverrideMessage: 'Nämä ohitetaan, jos Organisaation Sähköpostin Vastaanottajat määritetään',
  tenantIndexQueueJob: 'Tenantin Indeksijono',
  tenantKey: 'Vuokraajan avain',
  tenantKeyError: 'Vuokraajan avain ei ole uniikki',
  tenantKeyMessage: 'Vuokraajan avaimen on oltava uniikki',
  tenantLabelDeleteMessage: 'Tämän nimityksen poistaminen palauttaa Documoton oletusnimen.',
  tenantReindexModulus: 'Vuokraajan uudelleenindeksoinnin moduuli',
  tenantSettings: {
    collapseTOCLabel: 'Tiivistä Sisällysluettelo Yhdistämissivulla',
    defaultLocaleLabel: 'Oletuskieliasetus',
    defaultLocaleMessage: 'Tenant-oletuskieliasetus.',
    deleteMissingTranslationsOnPublishLabel: 'Poistetaanko puuttuvat käännökset julkaisusta?',
    deleteMissingTranslationsOnPublishMessage: 'Ota käyttöön osan käännösten poistaminen julkaisusta',
    defaultSupplierLabel: 'Oletustoimittaja',
    defaultSupplierMessage: 'Tenant-oletustoimittaja.',
    enableChapterIndexingMessage: 'Tee kappalehakemistot',
    enableChapterIndexingLabel: 'Käynnistää Mediakappaleiden hakemistonluonnin',
    enableIndexingLabel: 'Julkaisujen indeksointi',
    enableIndexingMessage: 'Kytkee julkaisujen indeksoinnin päälle.',
    helpUrlLabel: 'Oletusohjeen URL',
    helpUrlMessage: 'Vaihtoehtoisen ohjejärjestelmän URL.',
    hotPointLinkPageInBookOnlyLabel: 'Sivun pikanäppäinlinkit vain kirjassa',
    hotPointLinkPageInBookOnlyMessage: 'Pikalinkit vain kirjan sivuilta',
    mediaTimestampLabel: 'Päivitä Median Aikaleima Käyttöoikeuksien Muutoksen Yhteydessä',
    mediaTimestampMessage: 'Ota käyttöön, jos käyttöoikeuksien muutoksen tulee päivittää median aikaleima.',
    publisherDefaultUom: 'Julkaisijan Oletusarvoinen UOM',
    publishingContactLabel: 'Yhteyssähköpostin Julkaiseminen',
    publishingContactMessage: 'Oletussähköpostiosoite, joka vastaanottaa sisältöongelmista raportoivia sähköposteja.',
    replaceHotpointLabel: 'Korvaa Aina Kohdepistelinkit Julkaistaessa',
    replaceHotpointMessage: 'Koskee vain Docustudio-julkaisua.',
    ssoLoginSystemLabel: 'SSO-kirjautumisjärjestelmä',
    ssoLoginSystemMessage: 'Tunnistetietojen toimittajan SSO-järjestelmä.',
    userPasswordExpirationLabel: 'Käyttäjän salasanan umpeutumispäivät',
    userPasswordExpirationMessage: 'Päivien määrä, jonka jälkeen salasanat on nollattava; jätä tyhjäksi, jos haluat että salasana ei umpeudu koskaan',
    lockPartTrOnPublishLabel: 'Lukitse osan käännökset julkaisussa',
    lockPartTrOnPublishMessage: 'Estää osan käännösten muokkaamisen julkaisun aikana',
    exportPgAsHashLabel: 'Vie sivutiedoston nimi ristikkonäppäimenä',
    exportPgAsHashMessage: 'Käytä sivun ristikkonäppäimiä sivun tiedoston niminä vientisisällössä'
  },
  tenantSettingsTitle: 'Vuokralaisen Asetukset',
  tenantSettingsSaved: 'Vuokralaisen Asetukset Tallennettu',
  tenantSettingsSubtitle: 'Nämä asetukset hallitsevat vuokralaisen laajuisia ominaisuuksia ja käyttäytymistä. Ole varovainen tehdessäsi muutoksia. Ota yhteys Documoto Palveluun lisätietoja varten.',
  tenantSuppliers: 'Vuokraajan toimittajat',
  tenantLabelsPageText: 'Nimityksiä käytetään Documoton Kirjastossa ja Hallintanäytöillä esiintyvien monien objektien nimien mukauttamiseen.',
  tenantPrintSettingsDescription: 'Valitse Tenant-laajuinen oletustulostusasetus. Tätä käytetään kaikessa kirjojen tulostuksessa, ellei ohitettu Organisaatiossa tai Mediassa.',
  tenantType: 'Vuokraajatyyppi',
  tenantWebserviceUser: 'Vuokraajan verkkopalvelukäyttäjä',
  text: 'Teksti',
  textArea: 'Tekstialue',
  textField: 'Tekstikenttä',
  textFile: 'Tekstitiedosto (CSV, TXT)',
  textTool: 'Tekstityökalu',
  threeD: {
    addFile: 'Lisää 3D-osatiedosto',
    beforeSaveModal: {
      title: 'CAD-tiedosto on jo olemassa Documotossa tällä tiedostonimellä.',
      message: 'Haluatko korvata tämän tiedoston kaikkialla, jossa se on olemassa, tai liittää sen vain tähän osaan? Jos haluat korvata tiedoston, tapahtuu seuraavaa:',
      messageBulletOne: '{count} osa(t) päivitetään tällä uudella CAD-tiedostolla',
      messageBulletTwo: 'Tämän tiedoston aiempi versio poistetaan Documoton tiedostojärjestelmästä',
      tip: 'Vinkki: Välttyäksesi tiedostojen ylikirjoittamiselta liitosprosessin aikana voit muuttaa liittämäsi tiedoston nimen ennen sen tuontia.'
    },
    deleteTitle: 'Haluatko varmasti poistaa tämän 3D-näkymän?',
    drawMode: {
      label: 'Piirrostila',
      hiddenLines: 'Piilotetut rivit',
      shaded: 'Varjostettu',
      wireframe: 'Wireframe',
      wireframeShaded: 'Wireframe varjostettu',
    },
    explode: 'Levitä',
    fileSizeModal: {
      message: 'Documoton sallittu tiedoston enimmäiskoko on 25 megatavua. Tiedosto, jota yrität ladata, on ylittänyt enimmäismäärän, eikä sitä voi ladata. Jatka napsauttamalla ”OK”.',
      title: 'Tiedoston enimmäiskoko ylitetty',
    },
    orientationAndProjection: {
      back: 'Takaisin',
      bottom: 'Alhaalla',
      front: 'Edessä',
      isometric: 'Isometrinen',
      left: 'Vasen',
      orthographic: 'Ortografinen',
      projection: 'Projisointi',
      perspective: 'Perspektiivi',
      right: 'Oikea',
      top: 'Ylhäällä'
    },
    resetCamera: 'Nollaa kamera',
    slice: {
      label: 'Osuus',
      planeX: 'Leikkaustaso X',
      planeY: 'Leikkaustaso Y',
      planeZ: 'Leikkaustaso Z',
      reset: 'Leikkaustason nollaus',
      section: 'Leikkaustason osion vaihto',
      visibility: 'Leikkaustason näkyvyyden vaihto',
    },
    supportedFilesMessage: 'Documoto tukee monia CAD-tiedostoformaatteja. Kysy lisää tukikeskuksesta, jos haluat tietää, onko CAD-tiedostoformaattisi tuettu.',
    toolsLabel: '3D-työkalut',
    topAssemblyFilename: 'Yläkokoonpanon tiedostonimi',
    topAssemblyFilenameRequired: 'Yläkokoonpanon tiedostonimi on pakollinen',
    viewerTitle: '3D-katseluohjelma',
  },
  thu: 'To',
  thumbnail: 'Esikatselukuva',
  thumbnailDeleted: 'Esikatselukuvan Poisto Onnistui',
  thumbnailDeletedError: 'Esikatselukuvan Poisto Epäonnistui',
  thumbnailUpdated: 'Esikatselukuvan Päivitys Onnistui',
  thumbnailUpdatedError: 'Esikatselukuvan Päivitys Epäonnistui',
  thumbnailReapplyWarning: 'Järjestelmä voi ottaa esikatselukuvan uudelleen käyttöön median sisällöstä riippuen. Tämä voi kestää muutaman minuutin.',
  thumbnailResizeTooltip: 'Muuttaa pikkukuvat enintään 500x500 pikseliin, jos valittu',
  title: 'Otsikko',
  to: 'Päättyen',
  toastNotifications: 'Toast -ilmoitukset',
  toDate: 'Päättymispäivä',
  toc: 'Sisällysluettelo',
  tocClipBoardSuccessTitle: 'Kopioitu Leikepöydälle',
  tocClipBoardWarningText: 'VAROITUS: Et voi kopioida tallentamattomia muutoksia leikepöydälle',
  tocClipBoardWarningTitle: 'Kopioitu Leikepöydälle',
  tocDisplayingFirstValues: 'Näytetään ensimmäiset 100 arvoa. Käytä suodatinta nähdäksesi lisää.',
  tocDuplicateWarningText: 'Kappaleesta löytyi sama sivu kahteen kertaan. Kaksoiskappale poistettiin.',
  tocEmptyMessage: 'Et ole vielä luonut Sisällysluetteloa',
  toGetStarted: 'ja aloita',
  tocMissingChapterName: 'Kappaleen Nimi Puuttuu',
  tocModalTableCaption: 'Tagieditorin Muoto',
  tocNothingSelected: 'Ei Mitään Valittuna',
  tocOpenEditor: 'Avaa Editori',
  tocSaveRequired: 'Tallennettava ennen kappaleen tietojen muokkaamista',
  tocState: 'Sisällysluettelon Alustava Tila',
  tocThumbnailDeleteFailTitle: 'Esikatselukuvan Poisto Epäonnistui',
  tocThumbnailDeleteSuccessTitle: 'Esikatselukuvan Poisto Onnistui',
  tocThumbnailUploadFailTitle: 'Esikatselukuvan Lataus Epäonnistui',
  tocThumbnailUploadSuccessTitle: 'Esikatselukuvan Lataus Onnistui',
  tocTranslationMessage: 'Tämän kappaleen nimi ja valinnainen kuvaus',
  tocUntitledPage: 'Otsikoimaton Sivu',
  tooManySelections: 'Luettelo ylittää valintarajan: {limit}',
  togglePage: 'Vaihtele Sivua',
  top: 'Ylös',
  total: 'Yhteensä',
  totalItems: 'Tuotteita Yhteensä',
  translation: 'Käännös | Käännökset',
  translationHelpTextItem: 'Tämän kohteen {item} nimi ja valinnainen kuvaus',
  true: 'Totta',
  tryAgain: 'Yritä uudelleen.',
  tue: 'Ti',
  turkishDescription: 'Turkki Kuvaus',
  turkishName: 'Turkki Nimi',
  twoSided: 'Kaksipuolinen',
  type: 'Tyyppi | Tyypit',
  typeAtLeastTwoChars: 'Kirjoita vähintään 2 merkkiä nähdäksesi luettelon vastaavuuksista.',
  typeToConfirm: 'Kirjoita {value} vahvistaaksesi',
  typography: 'Typografia',
  ukraineDescription: 'Ukraina Kuvaus',
  ukraineName: 'Ukraina Nimi',
  unableToRetrieveResults: 'Kyselyn/haun tuloksia ei voida hakea',
  unauthorized: 'Ei lupaa',
  unknownError: 'Tuntematon virhe, ota yhteyttä Documoton tukeen osoitteessa support{\'@\'}documoto.com lisätietojen saamiseksi.',
  unknownPart: 'Tuntematon Osa',
  unknownType: 'Tuntematon tyyppi',
  unsavedAPIKeyWarning: 'API-avaimesi katoaa, jos et Palaa',
  unsavedChanges: 'Sinulla on tallentamattomia muutoksia. Jatkatko?',
  unsavedChangesWarning: 'Muutoksesi häviävät, ellet tallenna niitä.',
  unsavedWidgetKeyWarning: 'Widget-avaimesi menetetään, jos et palaa.',
  unsupportedBrowserMessage: 'Suunnittelimme tämän sovelluksen viimeisimmällä teknologialla. Se tekee sovelluksesta entistä nopeamman ja helppokäyttöisemmän. Valitettavasti selaimesi ei tue näitä teknologioita.',
  uom: 'MY',
  update: 'Päivitä',
  updateFailed: 'Päivitys epäonnistui',
  updateIndex: 'Päivitä Hakemisto',
  updatePassword: 'Päivitä Salasana',
  updatePasswordMessage: 'Salasanan päivitys onnistui',
  updatePasswordPending: 'Salasanan vaihto odottaa tallennusta.',
  updated: 'Päivitetty',
  upgradeBrowser: 'Päivitä selaimesi.',
  upload: 'Lataa',
  uploadComplete: 'Lataus Valmis',
  uploadInProgress: 'Lataus Käynnissä',
  uponSavingDisabledTenant: 'Kun olet tallentanut tämän sivun tämä asetus käytössä, tapahtuu seuraavaa:',
  uponSavingThisLicense: 'Kun olet tallentanut tämän sivun tällä asetuksella, sinun ON TEHTÄVÄ SEURAAVAA:',
  upperBound: 'Yläraja',
  untitledOrg: 'Nimetön Organisaatio',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Käytä Ostoskorin Oletussarakkeita',
  useDefaultPartsListColumns: 'Käytä Osaluettelon Oletussarakkeita',
  user: 'Käyttäjä',
  userAddress: 'Käyttäjän Osoitteet | Käyttäjien Osoitteet',
  userCount: 'Käyttäjämäärä',
  userEditable: 'Käyttäjän Muokattavissa',
  userExpirationDate: 'Käyttäjän viimeinen käyttöpäivä',
  userExpirationDateInvalid: 'Käyttäjän voimassaolon päättymispäivä annettava',
  userGroup: 'Käyttäjäryhmä | Käyttäjäryhmät',
  userGroupEmpty: 'Käyttäjäryhmät eivät voi olla tyhjiä',
  userGroupType: 'Käyttäjäryhmän tyyppi',
  userGroupUniqueMessage: 'Tämän nimen on erotuttava kaikista muista tämän tenant Käyttäjäryhmien nimistä.',
  userGroupUsers: 'Käyttäjäryhmän Käyttäjät',
  username: 'Käyttäjätunnus',
  usernameEmpty: 'Käyttäjätunnus ei voi olla tyhjä',
  users: 'Käyttäjät',
  usersWillReceiveAnUnauthorizedError: 'Käyttäjät saavat ”Ei lupaa” -virheviestin, kun he yrittävät kirjautua vuokralaisen sivulle.',
  useSniForSsoMetadataRetrieval: 'Käytä SNI SSO:ta Metadata -haulle',
  value: 'Arvo',
  validate: 'Vahvista',
  validateTenantDirectories: {
    buttonLabel: 'Vahvista sisältöhakemistot',
    error: 'Hakemistojen vahvistus epäonnistui',
    success: 'Vahvistetut vuokraajahakemistot'
  },
  variantSKU: 'SKU-Variantti',
  validationMessage: 'Validointiviesti | Validointiviestit',
  valueGteError: 'Arvon on oltava pienempi tai sama kuin {max}',
  valueLteError: 'Arvon on oltava suurempi tai sama kuin {min}',
  vendorName: 'Toimittajan nimi',
  verifyPassword: 'Vahvista Salasana',
  vertical: 'Vertikaalinen',
  version: 'Versio',
  video: 'Video | Videot',
  view: 'Näytä',
  viewAll: 'Näytä kaikki',
  viewAllPagesAndBooks: 'Näkymä: Kaikki sivut ja kirjat',
  viewAllResults: 'Näytä kaikki tulokset',
  viewBooks: 'Näkymä: Kirjat',
  viewMore: 'Näytä enemmän',
  viewPages: 'Näkymä: Sivut',
  viewModeList: 'Listanäkymä',
  viewModeTable: 'Taulukkonäkymä',
  visibility: 'Näkyvyys',
  visibilityDescription: 'Kenen nähtävissä tämä {entity} on',
  visible: 'Näkyvillä',
  waitForUpload: 'Odota, kunnes lataus on valmis.',
  watermark: 'Vesileima',
  watermarkFont: 'Vesileiman fontti',
  watermarkOpacityPercentage: 'Vesimerkin läpinäkyvyys %',
  watermarkOrientation: 'Vesileiman suuntaus',
  watermarkSaved: 'Vesileima tallennettu',
  watermarkSaveFailed: 'Vesileiman tallentaminen epäonnistui.',
  watermarkSelectionRequired: 'Tunnuksen tai tekstin valinta vaaditaan.',
  watermarkText: 'Vesileiman teksti',
  wed: 'Ke',
  whereUsed: 'Missä Käytetty',
  whoCanViewComment: 'Kuka voi nähdä tämän kommentin?',
  wholesale: 'Tukku',
  wholesalePrice: 'Tukkuhinta',
  wholesaleTotal: 'Tukku Yhteensä',
  widget: 'Widgetti',
  widgetAuthHeader: 'Widgetin valtuutusarvo',
  widgetKey: 'Widget-avain | Widget-avaimet',
  widgetKeyAdmin: 'Widget-avaimen hallinta',
  widgetKeyCopied: 'Widgetin valtuutusarvo kopioitu leikepöydälle',
  widgetKeyDeleted: 'Widget-avain poistettu',
  widgetKeyDeletedError: 'Virhe poistettaessa widget-avainta',
  widgetKeyDeleteMsg: 'Widget-avaimen poistaminen estää sen käytön',
  widgetKeyManager: 'Widget-avainten hallintar',
  widgetKeyNameEmpty: 'Nimen on oltava yksilöllinen ja se vaaditaan. Tätä arvoa käytetään myös raportointi- ja lokitarkoituksiin widgetin toiminnan käyttäjänimenä',
  widgetKeyRegenError: 'Virhe Widget-avaimen uudelleenluomisessa',
  widgetKeyRegenMsg: 'Tämän widget-avaimen uudelleenluominen voi poistaa olemassa olevat integraatiot käytöstä',
  widgetKeyRegenTitle: 'Oletko varma, että haluat luoda tämän Widget-avaimen uudelleen?',
  widgetKeySaved: 'Widget-avain tallennettu',
  widgetPermissions: 'Widget-käyttäjillä on vain lukuoikeus, ja ominaisuuksien käyttöoikeuksia hallitaan Widget-tason määrityksillä. Tavalliset käyttäjäryhmän oikeudet eivät koske Widget-käyttäjiä Media Kategorian käyttöoikeudet eivät. Jatka medialuokan käyttöoikeuksien määrittelyä.',
  widgetRefreshErrorMsg: "Päivitä sivu nähdäksesi pyydetyn sisällön",
  widgetUserGroup: 'Widget-käyttäjäryhmä',
  widgetUserGroupDeleteMsg: 'Ei voi poistaa Widget-ryhmää käyttäjineen',
  willBeAffected: 'ovat kohteena.',
  willBeDeleted: '{content} poistetaan.',
  willBeInsertedIntoItem: '{name} lisätään kohteeseen {item}',
  willBeUpdated: 'päivitetään.',
  zipCode: 'Postinumero',
  zoomHotpointToCanvas: 'Zoomaa Kohdepiste Alustan Kokoon',
  zoomIn: 'Lähennä',
  zoomOut: 'Loitonna'
}
/* eslint-enable max-len */
