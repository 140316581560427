<template>
  <widget-header class="widget-header-height"
                 :input="input"
                 :isExactMatch="isExactMatch"
                 @updateInput="updateInput"
                 @updateExactMatch="updateExactMatch"
  />
  <widget-media-controls-header v-if="showMediaBreadcrumbs"/>
  <component  :is="componentInView"
              :style="componentStyle"
              :exact-match="isExactMatch"
              @clearSearchInput="clearSearchInput"
              @toggleExactMatch="updateExactMatch(!isExactMatch)"
  />
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { useOpenToContent } from '@/components/widgets/composables/useOpenToContent'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import WidgetHome from '@/components/widgets/components/library/WidgetHome'
import WidgetHeader from '@/components/widgets/components/library/WidgetHeader'
import WidgetSearch from '@/components/widgets/components/library/WidgetSearch'
import WidgetBrowse from '@/components/widgets/components/library/WidgetBrowse'
import WidgetMediaViewer from '@/components/widgets/components/WidgetMediaViewer'
import WidgetChapter from '@/components/widgets/components/WidgetChapter'
import WidgetPage from '@/components/widgets/components/WidgetPage'
import WidgetMediaControlsHeader from '@/components/widgets/components/WidgetMediaControlsHeader'
import WidgetInfoPage from '@/components/widgets/components/WidgetInfoPage'
import { LibraryWidgetPages } from '@/interfaces/global/widgets'
import { useSearch } from "@/components/widgets/composables/useSearch";

const { getComponentInViewFromMapping } = useWidgetNavigator()
const { hasInfoLandingPage } = useInfoLandingPage()
const { setLandingPage } = useOpenToContent()
const {
  input,
  isExactMatch,
  setIsExactMatch,
  setInput } = useSearch()

const ComponentViews = Object.freeze({
  [LibraryWidgetPages.home]: WidgetHome,
  [LibraryWidgetPages.search]: WidgetSearch,
  [LibraryWidgetPages.browse]: WidgetBrowse,
  [LibraryWidgetPages.media]: WidgetMediaViewer,
  [LibraryWidgetPages.chapter]: WidgetChapter,
  [LibraryWidgetPages.page]: WidgetPage,
  [LibraryWidgetPages.info]: WidgetInfoPage
})
const componentInView = computed(getComponentInViewFromMapping(ComponentViews))

const showMediaBreadcrumbs = computed(() => {
  const mediaComponents = new Set([
    WidgetMediaViewer, WidgetChapter,
    WidgetPage, WidgetInfoPage ])
  return mediaComponents.has(componentInView.value)
})

const componentStyle = computed(() => {
  const maxHeight = (showMediaBreadcrumbs.value
    && hasInfoLandingPage.value) ? '75%;' : '90%;'
  return `max-height: ${maxHeight} ` +
    'min-height: 50%;'
})

const clearSearchInput = () => {
  setInput('')
}

const updateInput = (value) => {
  if (value !== input.value) {
    setInput(value)
  }
}

const updateExactMatch = (value) => {
  setIsExactMatch(value)
}

onMounted(async () => {
  await setLandingPage()
})
</script>
<style lang="scss" scoped>
.widget-header-height {
  max-height: 5em;
}
</style>
