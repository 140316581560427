import http from '@/http'
import { getRoute } from "@/router/getRoute"

export async function search (params: Object) {
  return await http.get('search', { params })
}

export async function publisherSearch (params: Object) {
  return await http.get('publisher-search', { params })
}

export async function mediaTemplatesSearch (pageId: number, params: Object) {
  return await http.get(`medias/templates/${pageId}`, { params })
}

export function getExactMatchParameter() {
  let exactMatch = false
  const route = getRoute()
  if (route.query?.exact) {
    exactMatch = route.query.exact === 'true'
    localStorage.setItem("defaultSearchMode", exactMatch ? 'exact' : 'contains')
  } else {
    const defSearchMode = localStorage.getItem("defaultSearchMode")
    if (defSearchMode != null) {
      exactMatch = defSearchMode === 'exact'
    }
  }
  return exactMatch
}
