/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} / {b}',
  about: '詳述',
  aboutThisItem: 'この項目について',
  accessApiKeyManager: 'このテナントのAPI Keyマネージャーにアクセスします。',
  accessControls: ' アクセス制御',
  accessControlsCopiedText: 'アクセス制御が直ちに更新されます。メディアセキュリティ権限が数分かけて更新されます',
  accessControlsCopiedTitle: 'アクセス制御のコピー完了',
  accessControlsCopyConfirmation: '今までのアクセス制御すべてが{to}から除去され、{from}からのアクセス制御に交換されます。このアクションは取り消すことができません。',
  accessControlsDescription: 'アクセス制御は、選択した組織とメディアカテゴリに基づいて、メディアを閲覧できる人物を限定します。',
  accessControlsIndeterminance: '注記: 親組織は、子組織へ割り当てられたコンテンツにいつでもアクセスできます。',
  account: 'アカウント',
  accountErrorMessage: 'アカウントエラーメッセージ',
  accountErrorMessageWarning: 'データベースのアカウントエラーメッセージ欄は、テナント用に「無許可」と設定されます。',
  action: 'アクション | アクション（複数）',
  actionDelete: '削除',
  actionLoad: '読み込み',
  actionPaste: 'ペースト',
  actionSave: '保存',
  actionRun: '実行',
  actionUndoWarning: 'このアクションは取り消すことができません。',
  actionUpload: 'アップロード',
  actualSize: '実寸',
  add: '追加',
  addAPIKey: 'API Keyを追加',
  addItem: '{item}を追加',
  addABookText: '本を追加',
  addASharedCart: '共有カートを追加',
  addATemplate: 'テンプレートを1つ追加する',
  addATag: 'タグを追加',
  addABookToStart: '{addABookText}で開始します。',
  addAForm: 'フォームを追加して開始',
  addAFormOnly: 'フォームを追加',
  addAMedia: 'メディアを追加する',
  addAnOrderQuoteEmailFormat: '注文／見積もりEメールフォーマットを追加',
  addAPrintSettingText: '印刷設定を追加',
  addAPrintSettingToStart: '{addAPrintSettingText}で開始します。',
  addBooks: '本を追加',
  addChildBrowseFlow: '子ブラウザフローを追加',
  addChapter: '章の追加',
  addComment: 'コメントを追加',
  addExistingPart: '既存部品を追加',
  addGroupsToUsers: '{groups}グループを{users}ユーザーに追加',
  additionalInformation: '追加情報',
  additionalNotification: '追加の通知',
  additionalPartNumberDescription: '追加部品番号フィールドとして使用',
  addBrowseFlow: 'ブラウザフローを追加',
  addMedia: 'メディアを追加',
  addPages: 'ページを追加',
  addPart: '部品を追加',
  addReports: 'レポートの追加',
  addRootBrowseFlow: 'ルートブラウザフローを追加',
  addRow: '行を追加',
  addTemplates: '複数のテンプレートを追加する',
  addToCartMessage: '{partNumber}の追加完了',
  addToUserGroup: 'グループに追加',
  address: '住所 | 住所（複数）',
  addressInUse: '現在の住所はデフォルト設定されているので削除できません',
  addressInUseSubtitle: '住所を削除するには、このデフォルト住所を最初に除去してください。',
  addressLine: 'アドレスライン {number}',
  addWidgetKey: 'ウィジェット・キーを追加する',
  admin: '管理',
  adminCenter: '管理センター',
adminCenterBanner: {
    bodyLabel: 'バナーの本文',
    enableLabel: '管理者バナーを有効にする',
    headerGreenOption: 'グリーン',
    headerLabel: 'バナーヘッダー',
    headerRedOption: 'レッド',
    headerYellowOption: 'イエロー',
    title: '管理者センターバナー',
    typeLabel: 'バナーの種類'
  },
  adminCenterMessage: 'コンテンツ、ユーザー、ライブラリ設定、レポーティング、およびエクスポーティングの管理センター',
  adminMessage: '使用可能になれば機能が追加されます',
  administration: '管理',
  affectedBookMedia: '{count} 書籍メディアが更新されます。',
  affectedTemplateMedia: '{count} 書籍テンプレートが更新されます。',
  after: '後',
  aggregatedFromChildren: '子から総計',
  aiAlgorithm: 'AIスキャナー',
  all: 'すべて',
  alphanumeric: '英数字',
  algorithm: 'アルゴリズム',
  alignHorizontal: '水平に揃える',
  alignVertical: '垂直に揃える',
  allUsersDisabled: 'テナントに紐づくすべてのユーザーは無効になります。',
  analyticsJSON: '分析結果JSON',
  andCountMore: 'および{count}その他...',
  any: '任意',
  apiKey: 'API Key | API Key（複数）',
  apiKeyAdmin: 'API Key管理者',
  apiAuthHeader: 'API認証ヘッダー',
  apiKeyCopied: 'API認証ヘッダー値がクリップボードにコピーされました',
  apiKeyDeleted: 'API Keyが削除されました',
  apiKeyDeletedError: 'API Key削除の際エラーが発生しました',
  apiKeyDeleteMsg: 'このAPI Keyを削除すると統合によるその使用が無効化されます',
  apiKeyDeleteTitle: 'このAPI Keyを削除してよろしいですか?',
  apiKeyInstructions: '保存されるときKeyが生成されます',
  apiKeyManager: 'API Keyマネージャー',
  apiKeyNameEmpty: '名前を空欄にすることはできません',
  apiKeyRegenError: 'API Keyを再生成する際エラーが発生しました',
  apiKeyRegenMsg: 'このAPI Keyを再生成すると既存の統合が無効化される可能性があります',
  apiKeyRegenTitle: 'このAPI Keyを再生成してよろしいですか?',
  apiKeySaved: 'API Keyが保存されました',
  apiKeyType: 'API Keyの種類',
  appliesTo: '適用先',
  appliesToEmpty: '最低一つのエンティティタイプを選択すること',
  appliesToPopulated: 'タグに適用できるエンティティを設定',
  applyItem: '{item}を適用',
  applyToChildItems: '子に適用{item}',
  applyToPartOnly: 'この部品のみに適用',
  april: '4月',
  area: '領域',
  areYouSure: '{item}を削除しますか?',
  areYouSureCopy: '{item}をコピーしますか?',
  areYouSureYouWantToDisableTheTenant: 'テナントを無効にしてもよろしいですか？',
  assembly: 'アセンブリ | アセンブリ（複数）',
  assemblyCreatorSuccessTitle: '作成アセンブリジョブを送信しました',
  assemblyCreatorSuccessSubtitle: 'ホットポイントのリンクと本の目次変更が直ちに行われます。索引付けが完了したら、検索の結果が反映されます。',
  assemblyConfirmationTitle: 'アセンブリ作成を完了しますか?',
  assemblyCreatorHelpText1: 'この機能は、部品{partName}がある場合に選択したページを本に挿入し、ホットポイントリンクで自動的に部品と選択したページを接続します。選択した本のみへの挿入に制限することもできます。',
  assemblyCreatorHelpText2: '{partName}の初回出現後、選択したページが各本に一回挿入されます',
  assemblyStepOne: 'ステップ1: 挿入対象のページを選択',
  assemblyStepTwo: 'ステップ2: ページを挿入したい本を選択',
  asset: 'アセット | アセット（複数）',
  assetsNotFound: '{type}が見つかりません',
  assignBrowseFlow: 'ブラウズフローを割り当て',
  assignedOrganizations: '割り当てられた組織',
  atLeastFiveError: '5以上の値を選択してください。',
  attachment: 'アタッチメント | アタッチメント（複数）',
  attachmentEditorDescription: 'このエンティティに画像、ビデオ、ドキュメントを直接添付します。注記, これらは「関連」タブにあります。',
  attachmentView: 'アタッチメントを閲覧',
  attachRelatedPDFs: '関連部品ページのPDFを添付',
  attributions: '属性',
  august: '8月',
  automaticZoom: 'オートズーム',
  autoHotpoint: '自動ホットポイント',
  autoPopulate: 'オートポピュレート',
  availability: '可用性',
  back: '戻る',
  backCover: '裏表紙',
  backStitch: 'バックステッチ',
  backToLibrary: 'ライブラリに戻る',
  backToSearch: '検索に戻る',
  badCredentials: '不良認証',
  bahasaDescription: 'インドネシア語の説明',
  bahasaName: 'インドネシア語の名前',
  banner: 'バナー',
  before: '前',
  beginTypingToFindAnything: 'タイピングを開始して何かを見つける',
  betaAccessKeys: 'ベータ版アクセスキー',
  betaResources: 'ベータ版リソース',
  betaReleaseVideo: 'ベータ版発売ビデオ',
  blobFinder: 'BLOBファインダー',
  body: '本文',
  bomDefaultSupplier: 'BOMデフォルトサプライヤー',
  bomItem: 'BOMアイテム',
  bom: 'BOM',
  bomExportSuccess: 'BOMエクスポート成功',
  bomLayout: '部品リストのレイアウト',
  bomListOptions: 'BOMリストオプション',
  bomListSettings: 'BOMリスト設定',
  both: '両方',
  book: '本 | 本（複数）',
  bookInserts: '本挿入',
  bookLabel: '本 | 本（複数）',
  bookPrintingDisable: '本印刷を停止',
  booksAndPagesDisplay: '本とページのディスプレイ',
  boost: '検索ブースト',
  boostDescription: '1.5超の値を追加し、部品番号、メディア識別子、および名称とこのタグの検索関連性を拡張します',
  boostRangeInvalid: 'ブーストは0.5～100.0の間で行わなくてはなりません',
  border: '罫線',
  bottom: '底部',
  branding: 'ブランディング',
  browse: 'ブラウズ',
  browseComputer: 'コンピュータをブラウズ',
  browseFlow: 'ブラウズフロー | ブラウズフロー(複数)',
  browseFlowAssigned: 'ブラウズフローが割り当てられました',
  browseFlowCopyMessage: '新しいブラウズフローの固有名を入力します。',
  browseFlowDeleted: '閲覧フローが削除されました',
  browseFlowDeleteMessage: 'ブラウズフローの削除は、そのブラウズフローだけではなく子ブラウズフローも削除します。',
  browseFlowEmptyState: 'ブラウズフローが未設定です。{new}で開始します。',
  browseFlowHeirarchy: 'ブラウズフロー階層',
  browseFlowNoExistingCriteria: 'このブラウズフローは検索基準が未設定です。 上掲のコントロールを使用して検索基準を選択してください。',
  browseFlowMoveToRoot: 'ノードをここにドロップして第一レベルの子を作成',
  browseFlowNoAssignedOrgs: '割り当てられた組織なし',
  browseFlowSaveFailed: 'ブラウズフローを更新できませんでした',
  browseFlowSetDefaultSuccess: 'ブラウズフローのデフォルト設定',
  browseFlowThumbnailDeleteFailTitle: 'サムネイルの削除失敗',
  browseFlowThumbnailDeleteSuccessTitle: 'サムネイルの削除完了',
  browseFlowThumbnailUploadFailTitle: 'サムネイルのアップロード失敗',
  browseFlowThumbnailUploadSuccessTitle: 'サムネイルのアップロード完了',
  browseFlowTooManyOrgs: '現在のブラウズフロー編集を利用するには組織が多すぎます。Documotoブラウズフロー編集のサポートを受けるにはDocumotoサポート（{email}）までお問い合わせください。',
  browserPartiallySupport: 'このブラウザのセンスの良さやその他の変更点に気がつくと思います。',
  browseFlowSaved: 'ブラウズフローを保存しました',
  browserDeprecation: {
    title: 'ブラウザ廃止予定機能の通知',
    text: 'マイクロソフトのインターネット エクスプローラー（全バージョン）、および関連セキュリティの段階的サポート中止に合わせて、2021年9月30日を以て、インターネット エクスプローラー（全バージョン）を使った本サイトへのユーザーアクセスのサポートを終了します。'
  },
  buildHotpointLinks: 'ホットポイントリンクを構築',
  buildHotpointLinksInfo: 'このアクションは、追加式で、既存のホットポイントリンクを除去することはありません。このアクションを実行すると、新しいホットポイントリンクが部品と一致するアセンブリページとの間に追加されます（ホットポイントリンクが既に存在しない場合）。',
  buildHotpointLinksTitle: 'この本にホットポイントリンクを作成しますか?',
  bulkIndexingLane: '大量のインデックスレーン',
  bulkInsert: 'バルクインサート',
  bulkExportConfirm: '{n} {unit}をエクスポートしますか?',
  bulkExportLimit: '制限はエクスポートジョブ当たりの{limit}本です',
  bulkPageInsertJob: 'ページ一括挿入',
  business: 'ビジネス',
  cancel: 'キャンセル',
  canceled: 'キャンセルされました',
  cancelAssemblyCreatorTitle: '作成アセンブリを破棄しますか？',
  cancelAssemblyCreatorSubtitle: '作成アセンブリは処理されません。',
  cannotExceed100: '100%を超えることはできません',
  cart: 'カート | カート（複数）',
  cartBehaviorEnabled: 'カート数量プロンプトへの追加を有効化',
  cartBehaviorDisabled: 'カート数量プロンプトへの追加を無効化',
  cartBehaviorLabel: 'カート数量ビヘイビアへ追加',
  cartColumn: 'カートカラム | カートカラム(複数)',
  cartColumn1: '第1ショッピングカート価格カラム',
  cartColumn2: '第2ショッピングカート価格カラム',
  cartColumn3: '第3ショッピングカート価格カラム',
  cartColumnPartLocationContent1: '本機能を使えるようにすることは、以下を意味します:  ',
  cartColumnPartLocationContent2: 'ショッピングカートの部品横列の一意性もまた、該当する場合、コンテンツの場所に基づきます。',
  cartColumnPartLocationContent3: '本設定は組織レベルでは無効にできず、全ユーザーに適用されます。',
  cartColumnPartLocationContent4: 'Documotoカスタマーサクセスマネージャーと最初に連絡せずに送信カート統合が行われた場合、本設定は無効となります。',
  cartColumnPartLocationOption: 'カート内の部品位置データを有効化',
  cartColumnPartLocationTitle: 'カート内の部品位置データを有効にしますか？',
  cartDisable: 'ショッピングカートを無効化',
  cartEmailFailed: 'カートEメールを送信できませんでした',
  cartEmailSent: 'カートEメールを送信しました',
  cartEnable: 'ショッピングカートを有効化',
  cartSaveEnable: 'ショッピングカートの保存を有効化',
  cartErpMessage: 'Erpへの送信完了',
  cartImportError: 'カートのインポートエラー',
  cartLoadedWithItemsRemoved: '{count}アイテムは、お客様が現時点で注文する権限をお持ちでないため削除されました',
  cartSharedAddEdit: '共有ショッピングカートを追加 / 編集',
  cartUpdated: 'カートが更新されました',
  cartUpdatedAndRemovedCount: 'カートを更新; 注文できない部品{count}個を削除',
  category: 'カテゴリ | カテゴリ（複数）',
  categoryCreated: 'カテゴリが作成されました',
  categoryUpdated: 'カテゴリが更新されました',
  centimeter: 'センチメートル | センチメートル（複数）',
  changeNotification: '通知変更',
  changeScope: '{n} {type}が影響を受けます',
  changeUserOrganization: '組織を変更',
  chapter: 'チャプター | チャプター（複数）',
  chapterHeader: 'チャプターヘッダー',
  chaptersOnly: 'チャプターのみ',
  chineseDescription: '中国語の説明',
  chineseName: '中国語の名前',
  circle: '円',
  city: '市',
  classicAdminMessage: 'Flexに戻り、管理モジュールを利用できます。',
  classicAdmins: 'クラシック管理',
  classicExporter: 'クラシックエクスポーター',
  classicExporterMessage: 'レガシーフラッシュ対応コンテンツエクスポートモジュール',
  classicOrganizationAdmin: 'クラシック組織管理',
  classicOrganizationAdminMessage: '組織およびユーザー管理用レガシーフラッシュ対応ユーザー管理モジュール',
  classicPublisher: 'クラシックパブリッシャー',
  classicPublisherMessage: 'コンテンツ作成および印刷設定のレガシーフラッシュ対応パブリッシャーモジュール',
  classicReporting: 'クラシックレポーティング',
  classicReportingMessage: 'レガシーフラッシュ対応レポーティングモジュール',
  classicTenantAdmin: 'クラシックテナント管理',
  classicTenantAdminMessage: 'テナントワイド設定用のレガシーフラッシュ対応管理モジュール',
  className: '分類名',
  clear: 'クリア',
  clearItemNumber: 'アイテム番号を消去',
  clearItemNumberTitle: 'アイテム番号を消去してもよろしいですか？',
  clearItemNumberMessage: 'アイテム番号を消去すると、以下のデータが削除されます。:',
  clearFilters: 'フィルタをクリア',
  clearHotpoints: 'ホットポイントをクリア',
  clearSearch: '検索をクリア',
  clickHereToUpload: 'ファイルをドロップするまたはクリックして、オーバーライドをアップロードします。',
  clickMagnifyingGlass: '虫眼鏡をクリックして高度な検索機能に直接アクセスします。',
  clickOkToContinue: '「OK」をクリックして進みます。',
  clickToDownload: 'クリックしてダウンロード',
  clickToEnlarge: 'クリックして拡大する',
  clickToOpen: 'クリックして開く',
  clipboard: 'クリップボード',
  clipboardAccessError: 'ブラウザの設定を調整してクリップボードのアクセスを許可してください',
  close: '閉じる',
  closed: '閉',
  collapseAll: 'すべてを折り畳む',
  color: 'カラー |カラー（複数）',
  column: 'カラム | カラム',
  columnName: 'コラム名',
  columnItemType: {
    customText: 'カスタムテキスト',
    documentTitle: '文書題名',
    horizontalLine: '横線',
    pageName: 'ページ名',
    pageNumber: 'ページ番号',
    printDate: '印刷日',
    tenantName: 'テナント名',
    userName: 'ユーザー名'
  },
  combinedPage: '組み合わせページ',
  combinedPageMessage: 'ダイアグラムは頂部、部品リストは底部',
  comingSoon: '近日公開',
  commaDelimited: 'カンマ区切り',
  comment: 'コメント | コメント（複数）',
  commentAdded: '追加したコメント',
  commentDeleted: '削除したコメント',
  commentEdit: 'コメントを追加 / 編集',
  commentEditAll: 'コメントすべてを編集',
  commentUpdated: 'コメントがアップデートされました',
  commentView: 'コメントを閲覧',
  commerceSystem: 'コマースシステム',
  completed: '完成',
  confirm: '確定',
  confirmBrowseFlowApplyToChildren: 'このブラウズフローを子組織に適用しますか?',
  confirmBrowseFlowOverrideToChildren: '子組織におけるブラウズフローオーバーライドセットすべてが除去されます。',
  confirmPrintSettingsApplyToChildren: 'この印刷設定を子組織に適用しますか?',
  confirmPrintSettingsOverrideToChildren: '子組織における印刷設定オーバーライドセットすべてが除去されます。',
  contains: '構成',
  containsHeaderRow: 'ヘッダー行を含める',
  content: 'コンテンツ | コンテンツ(複数)',
  contentAccessControls: '{content} アクセス制御',
  contentEntity: 'コンテンツエンティティ | コンテンツエンティティ（複数）',
  contentNotFound: 'コンテンツが見つかりません',
  contentUpdatedQueueJob: 'コンテンツのアップデート待ち',
  contourShape: '輪郭形状',
  convertEntity: '{entity}を変換',
  convertToEntity: '{entity}に変換',
  copyAccessControls: 'アクセス制御をコピー',
  copyAccessControlsWarning: '選択した組織から現在の組織へ割り当てられたアクセス制御すべてがコピーされます。',
  copy: 'コピー',
  copyAndRetain: '統合使用用にコピーし保存',
  copyAndRetainWidget: 'コピー & 保持、これはウィジェットの認証値がアプリケーション内に表示されるときに限ります',
  copyAtoB: '{a}を{b}にコピー',
  copyContent: '{type}をコピー？{name}',
  copyingItem: '{item}のコピー中',
  copyItem: '{item}をコピー',
  copyItemQuestion: '{item}をコピーしますか？',
  copyTo: '{target}にコピー',
  copyMeMessage: 'このメッセージを自分にコピー',
  copyrightMessage: '© 2010-{year} Documoto Inc.無断複製禁止。',
  count: 'カウント',
  country: '国 | 国（複数）',
  coverPages: 'カバーページ',
  coverAmpStitchPages: 'カバー&ステッチページ',
  createAssembly: 'アセンブリを作成',
  created: '作成済み',
  createdBy: '作成者{name}',
  createdDate: '作成日',
  createdOn: '作成日',
  currentPassword: '現行パスワード',
  custom: 'カスタム',
  czechDescription: 'チェコ語の説明',
  czechName: 'チェコ語の名前',
  dashboard: 'ダッシュボード | ダッシュボード',
  dashboardLibrary: 'ダッシュボードライブラリ',
  dataInsights: 'データについての洞察',
  dataInsightsInfoMessage: 'コンテンツ、ユーザーおよびライブラリのデータについての洞察の報告およびダッシュボードセンター。',
  dataMapping: 'データマッピング/サンプルデータ（最大10行まで）',
  dataOptions: 'データオプション',
  date: '日付',
  dateModified: '変更日',
  debugPageBuilderPLZ: 'デバッグページビルダーPLZ',
  december: '12月',
  decreaseHotpoint: 'ホットポイントを減らす',
  default: 'デフォルト',
  defaultAddress: 'デフォルト住所',
  defaultBrowseFlow: 'デフォルトブラウズフロー',
  defaultCurrencyCode: 'デフォルト現行コード',
  defaultCurrencySymbolOn: 'デフォルトの通貨記号をONにする',
  defaultEmailRecipient: 'デフォルトＥメール受信者 | デフォルトＥメール受信者(複数)',
  defaultImage: 'デフォルト画像 | デフォルト画像(複数)',
  defaultImagesManager: 'デフォルト画像マネージャー',
  defaultItemImage: '{item}デフォルト画像',
  defaultPartsListLayout: 'デフォルト部品リストレイアウト',
  defaultPartsListLayoutHelp: '左側または図下にデフォルト部品レイアウト',
  defaultSearchMode: 'デフォルト検索モード',
  defaultSearchModeHelp: "'構成' または '一致'へのデフォルトグローバル検索方法",
  defaultSortMode: 'デフォルトソートモード',
  defaultSupplier: 'デフォルト納入業者',
  defaultTocStateOpen: 'デフォルトTOCステート開',
  defaultTocStateOpenHelp: '本の閲覧時にTOCが自動的に開くかどうかを決定',
  defaultUom: 'デフォルトUOM（数量単位）',
  defaultValue: 'デフォルト値',
  defaults: 'デフォルト',
  defaultsAndCapabilities: 'デフォルトおよび性能',
  delete: '削除',
  deleteAll: '親から継承',
  deleteAnyPartnerApiKeys: 'SmartEquipに提供されたパートナーAPI Keyを削除します。',
  deleteContent: '{type}を削除しますか? {name}',
deletePart: {
    infoOne: '部分的な削除はライブラリとコンテンツ管理内に即座に反映します。削除した部分は、インデックスが数分以内に再作成されるまで引き続き検索可能です。',
    deletingPartNumber: '{partNumber}は削除されます。',
    affectedCarts: 'この部分を含むすべてのカートには、削除された部分が永久的に含まれます。',
    infoTwo: 'このアクションはDocumotoのサポート支援がないと実行できません。削除した記録と関連事項すべては90日後に永久的に消去されます。'
  },
deletePartInUse: {
    title: 'この部分はページの一部なので削除できません。',
    body: 'Documotoは現在、ページに属さない部分の削除に対してのみサポートを行っています。削除を続行するには、この部分を公開済みのページまたは下書きのページから削除してください。',
    submitLabel: '使用中のページに進む'
  },
  deleteRelated: '{count} {type}を削除してもよろしいですか？',
  deletedItem: '{item}が削除されました',
  deleteFilesMessage: '{count} ファイルを削除しますか?',
  deleteHotpointLinksTitle: 'ホットポイントリンクを削除してもよろしいですか？',
  deleteMessage: '削除しますか?',
  deleteSelectedCriteria: '選択基準を削除',
  deleteTagsTitle: 'タグを削除してもよろしいですか？',
  deleteTaxonMessage: '子と一緒にこのタクソンが完全削除されます。処理を実行しますか?',
  deleteTaxonomyMessage: 'この組織またはテナントのタクソンすぺてが永久に削除されます。処理を実行しますか?',
  deleteTenantSupplier: 'テナントからこのサプライヤーを削除してもよろしいですか？',
  demo: 'デモ',
  description: '内容',
  deselectAll: 'すべて選択解除',
  deselectCriteria: '基準を非選択',
  detail: '詳細 | 詳細（複数）',
  diagonal: '斜線',
  diameter: '直径',
  disable: '無効化',
  disableAll: 'すべてを無効にする',
  disableDocumotoTenant: 'Documotoのテナントを無効にする',
  disableFwdReindex: 'DSWのターゲットメディアのインデックス再構築転送を無効にする',
  disableLibraryBannerImage: 'ライブラリバナーイメージを無効にする',
  disableSmartEquipModalTitle: 'このライセンスを無効にしますか？',
  disableTEK: '直接URLログインの無効化',
  disabled: '無効化済み',
  discard: '破棄',
  discardChanges: '変更を破棄',
  discardQ: '変更を破棄?',
  discounted: 'ディスカウント済み',
  discountedPrice: 'ディスカウント価格',
  discountedTotal: 'ディスカウント総計',
  discountPrice: '割引価格',
  displayInOrdersList: '注文リストに表示',
  displayOrder: '注文を表示',
  displayOrderDescription: 'コンテンツタイプタグのフィルタドロップダウン表示順序を設定',
  document: 'ドキュメント | ドキュメント（複数）',
  documentLabel: 'ドキュメント | ドキュメント（複数）',
  documentSettings: '文書設定',
  documotoAcademy: 'Documotoアカデミー',
  documotoLicensePackage: 'Documotoライセンスパッケージ',
  domain: 'ドメイン',
  domainEmptyMessage: '許可されたドメインは空欄にできません',
  domainInvalidMessage: '有効なドメイン名は必須です',
  download: 'ダウンロード',
  downloadBrowser: '重要なブラウザをダウンロードします。',
  downloadFailed: 'ダウンロードエラー {name}',
  downloadStarted: ' ダウンロードしています{name}',
  draft: 'ドラフト | ドラフト',
  draftBomDeleteTitle: 'このパーツをページから削除してもよろしいですか？',
  draftBomMultipleDeleteTitle: 'これらのパーツをページから削除してもよろしいですか？',
  draftBomDropError: '5レベルを超える深さのパーツをネストさせることはできません',
  draftBomEmptyField: 'パーツリストのすべてのフィールドに入力する必要があります',
  draftBomQuantityError: '数量は数値でなければなりません',
  draftBomError: '部品表草案の操作エラー',
  draftPage: '草案ページ',
  draftPageDelete: '草案の削除',
  draftPageDeleteMessage: '草案ページが正常に削除されました。',
  draftPageDeleteWarning: 'ページ草案を削除すると、進行中の更新が削除されます。',
  draftPageLastUpdated: '草案 - 前回の更新は{date}に{username}によって行われました',
  draftPageLoadError: '下書きページの読み込みに失敗しました。',
  draftPageProcessingPlz: 'PLZファイルから読み込んでいるページ',
  draftPagePublishInProgress: 'ページ公開ジョブが進行中です',
  draftPageSaveSuccess: '草案ページが正常に保存されました。',
  draftPageSaveUnsuccessful: '草案ページの保存に失敗しました。',
  draftSheetImageHotpointsFailure: 'ドラフトシート画像の{algorithm}ホットポイントを生成できません。',
  draftValidationMessage: {
    item: '注意：BOM部品番号{partNumber}は、アイテム番号が指定されていません。',
  empty: '検証エラー/警告なし',
  hotpointOrHotpointLink: 'BOMアイテム{item}は、指定されたホットポイント/ホットポイントリンクがありません',
  quantity: 'BOMアイテム{item}は、指定された数量がありません',
  tag: 'BOMアイテム{item}のタグ{tagName}は、タグ属性がありません',
  translationDescription: 'BOM item {item}は、{lang}語で翻訳された説明がありません',
  uom: 'BOMアイテム{item}は、指定された測定単位がありません',
    supersede: '部品{partNumber}は、入れ替えにより{supersededPartNumber}に置き換わりました。'
  },
  drawAutohotpointRegion: '{shape}のサイズを描く',
  drawMinimumArea: '最小面積を描く',
  dropFileMessage: 'ここにファイルをドロップするまたはクリックしてアップロードします。',
  duplicate: '重複',
  duplicateSaveError: '重複記入です。保存できません。',
  dutchDescription: 'オランダ語の説明',
  dutchName: 'オランダ語の名前',
  dynamic: 'ダイナミック',
  dynamicNaming: '動的命名',
  ecommerce: 'eコマース',
  ecommerceEmailFormatCreated: '電子商取引Eメールフォーマットを作成しました',
  ecommerceEmailFormatSaved: '電子商取引Eメールフォーマットを保存しました',
  ecommerceLogo: '通信販売ロゴ',
  ecommerceUseExtOrder: 'eコマースUse External注文番号',
  edit: '編集',
  editItem: '{item}を編集',
  email: 'Ｅメール',
  emailFormat: 'Eメールフォーマット',
  emailFormatTemplate: 'Eメールフォーマットテンプレート | Eメールフォーマットテンプレート（複数）',
  emailList: '電子メールリスト',
  emailMessage: 'Ｅメールは必須です',
  emailRecipient: 'Ｅメール受信者 | Ｅメール受信者(複数)',
  emailRecipientMessage: '注文ステータスが変更されると、これらのアドレス宛てにＥメールが送信されます',
  emptyMessage: {
    orderSuggestions: 'この部品の注文提案はないようです。'
  },
  emptyBomState: 'ページにBOMアイテムが定義されていません。+{add}をクリックして開始してください',
  enable: '有効化',
  enable3d: '3Dを有効にする',
  enableAll: 'すべてを有効にする',
  enableAPIKeyAdmin: 'API Key管理者を有効にする',
  enabled: '有効化済み',
  enableCommerce: 'コマースを有効化',
  enableEcommerceOrderManagement: '発注管理を有効にする',
  enableExporter: '輸出業者を有効にする',
  enableExportToLinkOne: 'LinkOneへのエクスポートを有効にする',
  enableExportToSmartEquip: 'SmartEquipへのエクスポートを有効にする',
  enablePDFDownloadWarning: 'サイズの大きいPDFをダウンロードする際の警告を有効にする',
  largePDFDownloadWarning: 'PDFのサイズが大きいため、通常よりもアプリケーション内に文書を読み込んで表示するのに時間がかかる場合があります。閲覧用にファイルがダウンロードされますので、しばらくお待ちください。',
  enableIframeSupport: 'iFrameサポートを有効にする',
  enableLibraryBetaFeatures: 'ライブラリ・ベータ機能を有効にする',
  enablePerformanceLogging: 'パフォーマンスロギングを有効にする',
  enablePLZToDraft: 'ドラフトへのPLZプロセスを有効にする',
  enableQuickAddToCart: 'カートへのクイック追加を有効にする',
  enableQuickAddToCartHelpContext: '「有効」に設定することで、このコンテンツのコンテンツビューアーの上にあるカートへのクイック追加機能が有効になります。',
  enableRocketUi: 'ロケットユーザーエクスペリエンスを有効化',
  enableSmartEquipApiIntegration: 'SmartEquip API統合を有効にする',
  enableWidgetLogo: 'Documotoのウィジェット・ロゴを有効にする',
  enableWidgets: 'ウィジェットを有効にする',
  encryptedKey: '暗号キー',
  endDate: '最終日',
  endDateBad: '開始日は同じにするか、終了日より前の日にしなければなりません。',
  enforceNumericSize: '数値サイズの強制',
  englishDescription: '英語の説明',
  englishName: '英語の名前',
  enterprise: '企業向け',
  enterReportParameters: 'レポートパラメーターを入力',
  enterUniqueNameForNewItem: '新しい{item}の固有名を入力',
  enterValidNumber: '有効な数を入力してください',
  enterValidEmail: '有効なＥメールを入力してください',
  entityType: 'エンティティタイプ | エンティティタイプ（複数）',
  erp: 'ERP',
  erpEndpointConfig: 'ERP-エンドポイントの設定',
  erpPartInfo: 'ERP部分情報 キャッシュ持続時間 分',
  erpSystem: 'ERPシステム',
  erpSystems: 'ERPシステム',
  erpSystemSaved: 'ERPシステムが保存されました',
  error: 'エラー',
  errorDeletePageMessage: 'ページの削除中にエラーが発生しました。',
  errorInputParsing: '入力ファイルの解析に問題があります。',
  errorPageMessage: 'エラーが発生しました。',
  errorPageTitle: '不具合があります･･･',
  errorType: {
  error: 'エラー',
  warning: '警告'
  },
  essential: '重要点 | 重要点',
  eta: 'ETA',
  etlJob: '抽出・変換・格納（ETL）',
  exact: '一致',
  excelFile: 'エクセルファイル',
  expandAll: 'すべて拡張',
  export: 'エクスポート',
  exportSearch: '検索をエクスポート',
  exportAsCsv: 'CSVでエクスポート (インポートに不可欠なフォーマット)',
  exportAsCSVDropdown: 'CSVとしてエクスポート',
  exportAsHtml: 'HTMLとしてエクスポート',
  exportAsPdf: 'PDFでエクスポート',
  exportAsPlz: 'PLZでエクスポート',
  exportAsXlsx: 'XLSXでエクスポート',
  exportBasicPartInfo: '基本部品情報をエクスポート',
  exportCartToXlsxDisabled: 'XLSXへのカートエクスポートを無効化',
  exportCartToXlsxEnabled: 'XLSXへのカートエクスポートを有効化',
  exportDisplayedCartColumns: '表示されたカートカラムをエクスポート',
  exportJob: 'ジョブをエクスポート',
  exportLane: 'レーンをエクスポートする',
  exportMessage: 'エクスポートジョブの送信完了。',
  exporter: 'エクスポーター',
  exporterMessage: 'コンテンツのエクスポートが可能です',
  exportParts: 'エクスポートした部品',
  external: '外部 | 外部（複数）',
  externalUrl: '外部URL',
  faceted: 'ファセット',
  facetedTag: 'フィルタ値をファセットしてください',
  facetedTagTooMany: 'タグが固有タグ値制限1,000を超えています',
  failedAction: '{content}の{action}に失敗。',
  failedProcessContentSql: '不正なテキストにより、コンテンツの処理に失敗しました。対応しているコンテンツをアップロードし直してください。',
  failedToCopy: '{content}はコピーできませんでした。',
  failedToDelete: '{content} 削除に失敗。',
  failedToLoad: '{content}の読み込みに失敗。',
  failedToSave: '{content}の保存に失敗。',
  failedToSubmitItem: '{item}の送信に失敗',
  failedToUpdate: '{content}はアップデートできませんでした。',
  false: '偽',
  faq: 'よくある質問',
  favorites: 'お気に入り',
  favoritesLists: 'お気に入りリスト',
  february: '2月',
  feedbackForm: 'フィードバックを送信',
  feedbackFormMessage: '確認したい機能を入力してください',
  fieldDefinition: '欄の定義',
  field: 'フィールド | フィールド',
  fieldName: 'フィールド名',
  file: 'ファイル | ファイル（複数）',
  filename: 'ファイル名',
  filesSubmitted: '処理用に送信されたファイル',
  fileUpload: 'ファイルのアップロード',
  fill: '塗りつぶし',
  filter: 'フィルタ | フィルタ（複数）',
  filterOrgNameOnly: 'フィルター: 組織名のみ',
  filterOrgParentNames: 'フィルター: 組織および親名',
  filterable: 'フィルタリング可能',
  filterableTag: 'フィルタ可能タグ',
  filterBy: 'フィルタ',
  filterBoundsError: 'フィルタは3文字以上でなければなりません',
  finnishDescription: 'フィンランド語の説明',
  finnishName: 'フィンランド語の名前',
  firstName: '名',
  firstNameEmpty: '名は空欄にできません',
  fixedHotpointSize: '固定ホットポイントサイズ',
  font: 'フォント | フォント（複数）',
  footer: 'フッター',
  forgotPassword: 'パスワードを忘れました',
  forgotPasswordMessage: 'パスワードのリセットについて説明した電子メールが登録されたユーザー名に送信されます。',
  forgotPasswordSuccess: '登録されたユーザー名が有効な場合、パスワードのリセットについて説明した電子メールはファイル上のアドレスに送信されます。',
  form: 'フォーム | フォーム',
  format: 'フォーマット',
  formattingOptions: 'フォーマットオプション',
  formCount: '書式数',
  formName: 'フォーム名',
  formType: 'フォームタイプ',
  freezeImage: '画像をフリーズ',
  frenchDescription: 'フランス語の説明',
  frenchName: 'フランス語の名前',
  fri: '金曜',
  from: '送信元',
  fromDate: '送信日',
  frontCover: 'フロントカバー',
  frontStitch: 'フロントステッチ',
  fullScreen: '全画面',
  gdprFaqs: 'GDPR FAQS',
  gdprMessage: '当ウェブサイトのこちらのセクションにログインすることで、当ウェブサイトで必須Cookieが使用されることをご理解いただけたものとみなされます。これらのCookieは、当社のサービスをご利用いただくのに必須のものとなります。これらのCookieがないと、ご依頼いただいたサービスを提供できません。詳細は、{policy}をご参照ください。',
  general: '一般',
  generalSearch: '一般検索',
  generic: '総称',
  germanDescription: 'ドイツ語の説明',
  germanName: 'ドイツ語の名前',
  gotIt: 'OK',
  goTo: '移動',
  goToHome: 'ホームに進む',
  goToJobManager: 'ジョブマネージャに進む',
  goToSearch: '検索に進む',
  hashKey: 'ハッシュキー',
  headerAmpFooter: 'ヘッダー&フッター',
  header: 'ヘッダー',
  help: 'ヘルプ',
  helpCenter: 'ヘルプセンサー',
  highlightDescription: 'このタグで部品リストの部品を強調表示',
  history: '履歴',
  home: 'ホーム',
  horizontal: '水平',
  host: 'ホスト',
  hotpoint: 'ホットポイント | ホットポイント（複数）',
  hotpointCalloutBehavior: 'ホットポイントの呼び出し動作',
  hotpointLinksWillBeCreated: 'ホットポイントリンクが{count} {content}に作成されます',
  hotpointLink: 'ホットポイントリンク | ホットポイントリンク（複数）',
  hotpointsGenerated: 'イメージで{count}個のホットスポットが見つかりました。',
  hotpointShift: 'ホットポイント（シフト+H）',
  hourlyDWSRequestLimit: '1時間ごとのDWSリクエスト制限',
  hourlyRESTRequestLimit: '1時間ごとのRESTリクエスト制限',
  htmlUiUserSwitchEnabled: 'HTML UIユーザースイッチが有効になっています',
  hungarianDescription: 'ハンガリー語の説明',
  hungarianName: 'ハンガリー語の名前',
  icon: 'アイコン',
  id: 'ID',
  identifier: '識別子',
  ignore: '無視',
  ignoreAccessControls: 'アクセス制御を無視',
  ignoreMatchingTranslations: 'インポート時にロケールの一致による翻訳を無視する',
  illustration: 'イラスト | イラスト（複数）',
  image: '画像 | 画像（複数）',
  imageDeleteMessage: 'このページ画像を削除してもよろしいですか？',
  imageUploadFailure: '画像をアップロードできませんでした',
  imageSaveFail: '画像を保存できませんでした',
  imageOptimizer: '画像オプティマイザ',
  imageSizeExceededTitle: '最大画像サイズを超えました',
  imageSizeExceededMessage: 'Documotoで可能な画像ファイルのサイズは、最大{size}メガバイトまでです。アップロードしようとしている画像が最大値を超えているため、読み込めません。画像のサイズを再調整して、このページに追加してください。',
  imageDimensionsExceededMessage: 'Documotoの最大画像サイズは65.5メガピクセル（8,191×8,191ピクセル）です。  アップロードしようとしている画像が最大値を超えているため、読み込めません。「サイズ変更」をクリックしてDocumotoが画像サイズの変更後に挿入できるようにするか、「キャンセル」をクリックして画像を編集後に手動で挿入してください。',
  import: 'インポート',
  importBOM: 'BOMをインポートする',
  importBOMError: 'サンプルデータを処理できませんでした。入力情報を再度お確かめください',
  importParts: 'パーツをインポートする',
  inCart: 'カート内',
  inCartDescription: 'カート内にタグを表示',
  includePagePartDetails: 'ページ部品レベルの詳細を含める',
  includePartDetails: '部品レベルの詳細を含める',
  indexQueueJob: 'インデックスキュー',
  inPartsList: '部品リスト内',
  inPartsListDescription: '部品リストのタグを表示',
  inputSource: '入力情報',
  inch: 'インチ | インチ（複数）',
  includeComments: 'コメントを含む',
  includeCommentsAuthorAndDate: 'コメント執筆者および日付を含む',
  includeHeaderRow: 'ヘッダー行を含める',
  includeImages: '画像を含む',
  includePagesIndex: 'ページ索引を含む',
  includePartsIndex: '部品索引を含む',
  includePartsList: '部品リストを含む',
  includeSubChapters: 'サブチャプターを含む',
  indexLane: 'インデックスレーン',
  useSequentialPageNumbering: 'シーケンシャルページナンバリングを使用',
  hideBookInSearchAndRecent: '検索結果&最新追加の本を非表示',
  hideDuplicatePages: '重複ページを非表示',
  hidePageInSearchAndRecent: '検索結果&最新追加のページを非表示',
  hidePartInSearchAndRecent: '検索結果&最新追加の部品を非表示',
  homePageSettings: 'ホームページ設定',
  hotpointZoomBehavior: 'ホットポイントのズーム動作',
  includeNewParts: '「新しい」部品のみを含める（Documotoにはない）',
  includeWatermark: '透かし模様はありますか？',
  increaseHotpoint: 'ホットポイントを増やす',
  indexed: '索引付け済み',
  indexingConfiguration: '設定のインデックス化',
  indexIncludeTimestamp: 'タイムスタンプを含むインデックス',
  info: '情報',
  information: '情報',
  inherit: '継承',
  inheritedParent: '親から継承済み。',
  inputSpecification: '仕様を入力',
  inputType: '入力タイプ',
  inputTypeListboxWarning: '保存するとソート設定が表示されます',
  integration: '統合 | 統合(複数)',
  integrationsAddPartToErpCart: '{action}部品をERPカートに追加',
  integrationsAddToShoppingCartPricingFromErp: '{action}ERPからのショッピングカート価格設定に追加',
  integrationsDisableErp: 'ERPを無効化',
  integrationsDoErpForQuotes: '見積もりをERPで実施',
  integrationsEnableErp: 'ERPを有効化',
  integrationsEnableFetchPartInfoFromErp: 'ERPから部品情報の取得を有効化',
  integrationsEnableScopeErp: '{scope} ERPを有効化',
  integrationsEnableSendShoppingCartToErp: 'ERPへのショッピングカートの送信を有効化',
  integrationsEnableSyncPoAddressWithErp: 'ERPでSync PO住所を有効化',
  integrationsErpAvailabilityMappedFrom: 'マップしたERPの可用性',
  integrationsErpCurrencyCodeMappedFrom: 'マップしたERPの通貨コード',
  integrationsErpDiscountedPriceMappedFrom: 'マップしたERPのディスカウント価格',
  integrationsErpEtaMappedFrom: 'マップしたERPのETA',
  integrationsErpMappings: 'ERPマッピング',
  integrationsErpRetailPriceMappedFrom: 'マップしたERPの小売価格',
  integrationsErpWholesalePriceMappedFrom: 'マップしたERPの卸売価格',
  integrationsInvokeGetPartInfoFromClient: 'クライアントからのGetPartInfoを実行',
  integrationsParseAddressForType: '住所タイプを解析',
  integrationsShoppingCartAndPartsTitle: 'ショッピングカートと部品',
  integrationsShoppingCartAvailability: '{action}ショッピングカートの可用性',
  integrationsShoppingCartEtaFromErp: '{action}ERPからのショッピングカートのETA',
  internalApiKey: '内部API Key',
  internalDescription: '内部記述',
  internalName: '内部名',
  internalNameOptional: 'タグの個人識別子',
  internalNameRequired: '名前は必須です',
  invalidDecimals: '小数点第二位までのみ入力可能です',
  invalidField: '{field} が無効です',
  invalidFileType: 'サポートされていないファイルタイプ。サポートされているファイル拡張子の一覧表は、Documotoサポートセンターで確認してください。',
  isSearchable: '検索可能',
  insertDataField: 'データフィールドを挿入',
  isSystem: 'システム',
  italianDescription: 'イタリア語の説明',
  italianName: 'イタリア語の名前',
  item: '#',
  itemAlt: '項目',
  itemCopied: 'コピー済み{item}',
  itemDeleted: '削除済み{item}',
  itemInProgress: '進行中の{item}',
  itemInformation: '{item}情報',
  itemMustBeLteItem: '{itemA}は{itemB}と同一またはその前でなければなりません',
  itemMustBeUnique: '{item}は固有のものでなければなりません',
  itemMustBeUniqueAndNotEmpty: '{item}は固有のものでなければならず、また必須です',
  itemName: '{item}名',
  itemNotShown: '',
  itemSaved: '保存済み{item}',
  itemSpecification: 'アイテム仕様',
  showChapterTOCForHiddenPages: '非表示ページのチャプタTOCを表示',
  january: '1月',
  japaneseDescription: '日本語の説明',
  japaneseName: '日本語の名前',
  job: 'ジョブ | ジョブ（複数）',
  jobManager: 'ジョブマネージャ',
  jobManagerMessage: 'エクスポート、発行、印刷/ダウンロードジョブ',
  july: '7月',
  june: '6月',
  koreanDescription: '韓国語の説明',
  koreanName: '韓国語の名前',
  label: 'ラベル | ラベル(複数)',
  labelKey: 'ラベルキー',
  landscape: 'ランドスケープ',
  laneConfiguration: 'レーンの設定',
  language: '言語',
  lastModifiedDate: '最終更新日',
  lastGenerated: '最後に生成',
  lastName: '氏',
  lastNameEmpty: '氏は空欄にできません',
  lastUpdatedOn: '最終更新日',
  layout: 'レイアウト',
  layoutOptions: 'レイアウトオプション',
  ledger: '元帳',
  left: '左',
  legal: '法定',
  letter: 'レター',
  library: 'ライブラリ',
  libraryAdmin: 'ライブラリ管理',
  libraryBannerLogo: 'ライブラリのバナーロゴ',
  librarySettings: 'ライブラリ設定',
  librarySettingsDescription: 'これらの設定は様々なライブラリ要素とオプションを制御します。変更する際は注意してください。詳細はDocumotoサービスまでお問い合わせください。',
  licenseConfiguration: 'ライセンスの設定',
  line: '行',
  lineTool: 'ラインツール',
  link: 'リンク | リンク（複数）',
  listBox: 'リストボックス',
  listBoxValue: 'リストボックス値｜リストボックス値（複数）',
  listValue: 'リスト値｜リスト値（複数）',
  load: '読み込み',
  loadMore: 'さらに読み込む',
  loading: '読み込み中',
  locale: 'ロケール',
  login: 'ログイン',
  loginBackground: 'バックグラウンドにログイン',
  loginGDPRMessageBeginning: 'このウェブサイトはCookieを使用しています。当ウェブサイトを引き続きご利用いただくと、Cookieの使用を承認いただいたものとみなされます。詳細は次を参照 ',
  loginGDPRMessageEnd: 'および',
  loginSSO: 'SSOにログイン',
  logiReportName: 'ロジレポート名',
  logiReportToggle: 'ロジレポート？',
  logo: 'ロゴ | ロゴ（複数）',
  logout: 'ログアウト',
  lookupLoadParts: 'インポートでDocumotoからパーツを検索/読み込む',
  lowerBound: '下限',
  makePagesDivisibleBy: '次でページを分割:  ',
  mainInfo: 'メイン情報',
  mainLogo: 'メインロゴ',
  manageItem: '{item}を管理',
  management: 'マネージメント',
  march: '3月',
  margin: '余白| 余白（複数）',
  markdown: 'マークダウン',
  may: '5月',
  media: 'メディア | メディア（複数）',
  mediaCategory: 'メディアカテゴリ | メディアカテゴリ（複数）',
  mediaCategoryEditor: 'メディアカテゴリエディター',
  mediaCategoryName: 'メディアカテゴリ名',
  mediaCategoryNameMissing: 'メディアカテゴリすべてに名が必要です',
  mediaBookDeleteMessage: 'このメディアを削除すると、ライブラリおよびコンテンツ管理に含まれるこのコンテンツへのアクセスが除去されます そしてユーザーまたはコンテンツ管理者が利用できなくなります。この本にあるが他の本にはない部品もライブラリ内で利用できなくなります。',
  mediaDeleteMessage: 'このテンプレートを削除すると、コンテンツ管理から除去されます 新規メディアへのコピーはできなくなります。',
  mediaDeleteSuccessText: '索引付けが完了すると、リストから除去されます。',
  mediaDescription: 'メディアの説明',
  mediaIdentifier: 'メディアの識別子',
  mediaName: 'メディア名',
  mediaPrintSettingsSaveSuccess: '保存したメディア印刷設定',
  mediaWhereUsed: '使用したメディア',
  menu: 'メニュー',
  merge: 'マージ',
  mergeOrOverrideMessage: '項目を上書きしますか、それともマージしますか?',
  mergeWarning: 'インポートするデータは、既存パーツをアップデートして新しいパーツを追加します',
  message: 'メッセージ',
  messageLevel: 'メッセージレベル',
  microsite: 'マイクロサイト | マイクロサイト（複数）',
  micrositeAdmin: {
    cardSubtitle: '圧縮コンテンツファイルとHTMLのエントリ情報',
    cardTitle: 'サイトコンテンツ',
    errorRequiredFile: 'サイトコンテンツのZIPファイルが必要です',
    fieldFile: 'サイトコンテンツのZIPファイル',
    fieldPoint: 'HTMLのエントリポイント',
  },
  minArea: '最小エリア',
  minAreaMessage: '最小値は必須です。値は1～1500以内でなければなりません。  整数値でなければなりません。',
  moreInfo: '詳細情報',
  multipleAlternatingPages: '複数の代替ページ',
  multipleAlternatingPagesMessage: 'ダイアグラムは1および3ページ、部品リストは2および4ページ。',
  mToM: 'M2M',
  multiplePages: '複数ページ',
  multiplePagesMessage: 'ダイアグラムは独自ページ、その次に部品リストページ',
  requiredField: '必須フィールド',
  messageMessage: 'メッセージは必須です',
  metadata: 'メタデータ',
  mine: '宝庫',
  miscellaneous: 'その他',
  mon: '月曜',
  myCart: 'マイカート | マイカート（複数）',
  navigation: 'ナビゲーション',
  nItemsCreated: '作成済み{n} {items}',
  nItemsDeleted: '削除済み{n} {items}',
  nOfMItems: '{m} {items}の{n}',
  name: '名',
  nameDescriptionHelpText: '名およびオプション内容',
  new: '新規',
  newAPIKey: '新しいAPI Key',
  newERPSystem: 'ERPの新システム',
  newItem: '新しい{item}',
  newKeyGenerated: '新しい統合Keyが生成されました、今後の使用のためコピーし保存してください',
  newMediaCategory: '新しいメディアカテゴリ',
  newPassword: '新しいパスワード',
  newsItem: 'ニュース項目 | ニュース項目(複数)',
  newTenant: '新しいテナント',
  newWidgetKey: '新しいウィジェット・キー',
  newWidgetKeyGenerated: '新しいウィジェット・キーが生成されました。今後使用するためにコピー & 保持してください',
  noTenantSuppliers: 'テナントに割り当てられているサプライヤーはいません。',
  noAPIKeys: 'API Keyが定義されていません.',
  node: 'ノード',
  noData: 'データがありません',
  noFilesSelected: 'ファイル未選択',
  noIllustrationFound: '図が見つかりませんでした',
  noImageFound: '画像が見つかりませんでした',
  noItemAvailable: '{item}使用不可',
  noItemFound: '{item}がありません。',
  noItemSelected: '{item}未選択',
  noItemAddedToTarget: '{target}に追加された{item}はありません',
  noItemsFound: '項目ヒットなし',
  noMoreResults: 'これ以上の結果なし',
  noFormsMessage: 'フォームが定義されていません。',
  noMediaDefined: 'メディアが設定されていません',
  nonSearchableFieldsSuccessfullyUpdated: '検索不可フィールドが正常に更新されました。',
  noPartsSelected: '部品未選択。',
  noPrice: '価格についてはお問い合わせ下さい',
  noResults: '結果なし',
  norwegianDescription: 'ノルウェー語の説明',
  norwegianName: 'ノルウェー語の名前',
  noSearchableTags: '検索可能タグなし',
  noSuggestions: 'おすすめの検索用語はありません',
  notOrderable: '注文不可',
  notSelected: '選択されていません',
  noTagsFound: 'タグが見つかりません',
  noUnsavedChanges: '変更の未保存なし',
  none: 'なし',
  notImplemented: '未インプリメント',
  notification: '非通知 | 非通知（複数）',
  notificationsMessage: '重要発表およびニュース',
  notMapped: '地図上にありません',
  november: '11月',
  noWidgetKeys: 'ウィジェット・キーが定義されていません。',
  numeric: '数',
  numberOf: '#/{item}',
  october: '10月',
  of: 'の',
  off: 'OFF',
  offlineContent: 'オフラインコンテンツ',
  ok: 'OK',
  oldPassword: '古いパスワード',
  on: 'ON',
  opacity: '透明度',
  open: '開く',
  openItem: '{item}を開く',
  optimizeIndexJob: 'インデックスの最適化',
  options: 'オプション',
  openTab: '新しいタブにリンクを開く',
  order: '注文 | 注文（複数）',
  orderable: '注文可',
  orderableDescription: '部品をカートと購入済みに追加できます',
  orderableLocal: 'このページで注文可能',
  orderableLocalBlocked: 'グローバル注文不可の部品',
  orderDisable: '注文履歴を無効化',
  orderEmailRecipient: '注文Ｅメール受信者 | 注文Ｅメール受信者(複数)',
  orderEntity: '注文エンティティ | 注文エンティティ（複数）',
  orderFieldUneditableRequired: '注文/見積もり欄を「必須項目」および「ユーザーによる編集不可」に設定すると、注文/見積もりフォームへ入力できなくなる場合があります。注文/見積もりフォームへ入力できることを確認してください。',
  orderFieldsEmptyMessage: '注文/見積もり欄が定義されていません。',
  orderFieldsEmptyPrompt: '注文/見積もり欄を追加',
  orderField: {
    nameMessage: '注文/見積もり欄名を変更する場合、この欄で使用する注文/見積もりフォームまたは電子メール書式を必ず更新してください.。',
  },
  orderFieldCopyMessage: '新しい注文/見積もり欄に固有名を入力',
  orderForm: {
  uniqueNameInputHint: '新しいフォームの固有名を入力。'
  },
  orderFormField: '注文/見積もり欄 | 注文/見積もり欄（複数）',
  orderFormLayout: '注文/見積もりフォームのレイアウト | 注文/見積もりフォームのレイアウト（複数）',
  orderFormSetDefault: 'デフォルトの注文/見積もりフォームセット',
  orderFormType: '注文フォーム',
  orderLayout: '注文レイアウト | 注文レイアウト(複数)',
  orderManagementApprover: '注文マネージメント承認者',
  orderManagementDisable: '注文マネージメント管理を無効化',
  orderManagementViewer: '注文マネージメント承認者',
  orderNumber: '注文番号',
  orderQuoteEmail: '注文／見積もりEメール | 注文／見積もりEメール（複数）',
  orderQuoteEmailFormat: '注文／見積もりEメールフォーマット | 注文／見積もりEメールフォーマット（複数）',
  orderQuoteEmailFormatCopyMessage: '新しい注文／見積もりEメールフォーマットの言語を選択します。',
  orderQuoteFetchError: '注文／見積もりフォームのデータ取得エラー',
  orderQuoteForm: 'ご注文/お見積りフォーム | ご注文/お見積りフォーム',
  orderQuoteSaveConfirmation:', 注文／見積もりフォームへの変更を保存しますか？',
  orderQuoteSaveError: '注文／見積もりフォームのデータ保存エラー',
  orderQuoteFormSaved: '注文/見積もりフォームが保存されました',
  orderStatus: '注文/見積もりステータス | 注文/見積もりステータス（複数）',
  orderStatusDeleteMessage: 'このステータスは注文に使われており、削除できません。',
  orderSubmittedMessage: '送信完了',
  orderSuggestion: '注文提案 |注文提案(複数)',
  orderSuggestionEnable: '注文提案を有効化',
  orderSuggestionRemovalWarning: '部品が注文提案として表示されなくなります。| 部品が注文提案として表示されなくなります。',
  orderSuggestionThreshold: '注文提案閾値',
  ordersLogo: '注文ロゴ',
  ordersMessage: '注文送信履歴',
  orientation: '仕向地',
  org: '組織',
  orgEmailRecipients: '組織Ｅメール受信者',
  organization: '組織 | 組織（複数）',
  organizationAdmin: '組織管理',
  organizationAdminBrowseFlow: '組織はデフォルトブラウザフローを使用するか、またはオーバーライドして他のブラウザフローを使用することができます。',
  organizationAdminMessage: 'ユーザーマネージメントと組織設定の管理',
  organizationEmpty: '組織の選択は必須です',
  organizationEmailRecipients: '組織Ｅメール受信者',
  organizationEnforceAccessControlsForQuickAdd: 'クイック追加のためのアクセス制御の実施',
  organizationKey: '組織キー',
  organizationLibrarySettingsDescription: 'これら設定で、様々な組織レベルライブラリ要素とオプションを制御します。変更する際は注意してください。詳細はDocumotoサービスまでお問い合わせください。',
  organizationMoveToRoot: 'ここにドロップしてルートに移動します',
  organizationName: '組織名',
  organizationRearrange: '組織の再編成',
  organizationRearrangeDescription: '親子組織構造は、印刷設定、スタイル等、組織の多岐にわたる継承可能な属性を規定します。組織をドラックドロップし、所定の階層を反映します。',
  organizationReportSettingsSuccessMessage: '保存したレポート設定',
  organizationSelect: '組織を選択',
  organizationTags: '組織タグ',
  organizationsEmpty: '組織が未作成のようです。',
  orgNameOnly: '組織名のみ',
  other: 'その他',
  otherSettings: 'その他設定',
  outboundCustomerIdParameter: 'アウトバウンドカスタマーIDパラメータ名',
  outboundSessionIdParameter: 'アウトバウンドセッションIDパラメータ名',
  outlineMode: 'アウトラインモード（シフト+O）',
  output: '出力',
  outputCatalog: '出力カタログ',
  outputDefinition: '出力定義 | 出力定義（複数）',
  outputDefinitionDeleted: '出力定義が削除されました',
  outputDefinitionSaved: '出力定義が保存されました',
  outputFileName: '出力ファイル名',
  outputName: '出力名',
  outputDestination: '出力先',
  outputSpecification: '出力仕様',
  outputSubType: '出力サブタイプ',
  outputType: '出力タイプ',
  overridden: '上書き済み',
  overriddenByOrg: '組織による上書き',
  override: '上書き',
  overrideBrowseFlow: 'ブラウズフローを上書き',
  overrideFacetLimitValue: 'ファセット制限値をオーバーライドする',
  overrideHotpointScale: 'ホットポイントスケールを上書き',
  overridePdfNamingStandard: 'PDFのファイル名基準を無効にする',
  overridePrintSettings: '印刷設定を上書き',
  overrideFontSettings: 'フォント設定を上書き',
  overrideOrderForm: '注文フォームをオーバーライド',
  overrideQuoteForm: '見積もりフォームをオーバーライド',
  overrideSvgOpacity: 'SVG不透明度を上書き',
  overrideWarning: 'インポートするデータは現在のBOMリストと置き換えられます',
  owner: 'オーナー | オーナー（複数）',
  page: 'ページ | ページ（複数）',
  pageBulkInsertConfirmTitle: 'バルクインサートを完了しますか?',
  pageBulkInsertConfirmInsertAfter: 'これは、{original}後に{whereUsedBookCount} {bookUnits}と{whereUsedTemplateCount} {templateUnits}にある{replacement}に挿入されます。',
  pageBulkInsertConfirmInsertBefore: 'これは、{original}前に、{whereUsedBookCount} {bookUnits}と{whereUsedTemplateCount} {templateUnits}にある{replacement}に挿入されます。',
  pageBulkInsertConfirmInsertReplace: 'これは、{replacement}に挿入され、{whereUsedBookCount} {bookUnits}と{whereUsedTemplateCount} {templateUnits}にある{original}を削除します。',
  pageBulkInsertBooksConfirmInsertAfter: 'これは、{original}後に、{whereUsedBookCount} {bookUnits}にある{replacement}に挿入します。',
  pageBulkInsertBooksConfirmInsertBefore: 'これは、{original}前に、{whereUsedBookCount} {bookUnits}にある{replacement}に挿入します。',
  pageBulkInsertBooksConfirmInsertReplace: 'これは、{replacement}に挿入し、{whereUsedBookCount} {bookUnits}にある{original}を削除します。',
  pageBulkInsertTemplatesConfirmInsertAfter: 'これは、{original}後に、{whereUsedTemplateCount} {templateUnits}にある{replacement}に挿入します。',
  pageBulkInsertTemplatesConfirmInsertBefore: 'これは、{original}前に、{whereUsedTemplateCount} {templateUnits}にある{replacement}に挿入します。',
  pageBulkInsertTemplatesConfirmInsertReplace: 'これは、{replacement}に挿入し、{whereUsedTemplateCount} {templateUnits}にある{original}を削除します。',
  pageBulkInsertDiscard: 'バルクインサートは処理されません。',
  pageBulkInsertHelpText: 'この機能は、他のページ{originalDisplayName}がすでに存在している選択済み部品本へのこのソースページ{thisDisplayName}の挿入を可能にします。または、{originalDisplayName}をこれらの本から削除することもできます。',
  pageBulkInsertInsertAfter: '{replacement}を{original}の後に挿入',
  pageBulkInsertInsertBefore: '{replacement}を{original}の前に挿入',
  pageBulkInsertInsertReplace: '{replacement}を挿入し、{original}を除去',
  pageBulkInsertScopeAll: 'すべての{type}でターゲットページ{originalDisplayName}を有する ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'いずれの{type}でもターゲットページ{originalDisplayName}を有しない',
  pageBulkInsertScopeSome: '一部の{type}でターゲットページ{originalDisplayName}を有する',
  pageBulkInsertStep1: 'ステップ1: 所定のターゲットページを検索',
  pageBulkInsertStep2: 'ステップ2: ページの挿入または交換方法を決定',
  pageBulkInsertStep3: 'ステップ3: ページを挿入したい本を選択してください',
  pageBulkInsertStep4: 'ステップ4：一括挿入で処理したいテンプレートを選択してください。',
  pageBulkInsertSuccessTitle: '送信済みバルクインサートジョブ',
  pageBulkInsertSuccessBody: '本の目次変更が直ちに行われます。索引付けが完了したら、検索の結果が反映されます。',
  pageBulkErrorTooManyBooks: '選択する本の数は、1000冊以内にしてください',
  pageBuilder: 'ページビルダー',
  pageComposer: 'Page Composer',
  pageCreated: 'ページが作成されました',
  pageDeleteMessage: 'ページの削除は、ライブラリとコンテンツ管理内に即座に反映します。削除したページは、インデックスが数分以内に再作成されるまで引き続き検索可能です。',
  pageDeleteMessage2: 'このアクションはDocumotoのサポート支援がないと実行できません。削除した記録と関連事項すべては90日後に永久的に消去されます。',
  pageDescription: 'ページの説明',
  pageFile: 'ページファイル',
  pageFilename: 'ページのファイル名',
  pageFilenameDeleted: '{filename}は削除されます。',
  pageFileRequired: 'ページファイルは必須です。',
  pageBomPartsDeleted: 'BOMページの{count}部分は{filename}を介してはアクセスできなくなります。',
  pageDraftsDeleted: 'このページに紐づく下書きのページは永久的に削除されます。',
  pageFit: 'ページフィット',
  pageHeader: 'ページヘッダー',
  pageLabel: 'ページ | ページ（複数）',
  pageLayout: 'ページレイアウト',
  pageName: 'ページ名',
  pagePart: 'ページパーツ | ページパーツ（複数）',
  pagePartTag: '部品タグページ | 部品タグページ',
  pagePartData: 'ページパーツデータ',
  pageUsageDataCouldNotBeLoaded: 'ページの使用量データの読み込みができませんでした',
  pagePartLevelDetails: 'ページ部品注文可能／表示、ページ部品タグ',
  pagePartNumberVisible: '部品番号が閲覧可能なページ',
  pagePartOrderable: '部品が注文可能なページ',
  pagePartOrderableDescription: 'このページから部品が注文可能',
  pagePartVisibleDescription: 'このページで部品番号が閲覧可能',
  pageUpdated: 'ページが更新されました',
  pageViewer: 'ページビューア',
  pageWidth: 'ページ幅',
  pagesWhereUsed: '使用したページ',
  parentName: '親名| 親名（複数）',
  part: '部品 | 部品（複数）',
  partCode: '部品コード | 部品コード（複数）',
  partCodeAddError: '部品コードを追加できません',
  partCodeErrorEmpty: '保存できませんでした: 部品コードは空欄にできません',
  partCodeErrorInUse: 'この部品コードはDocumotoの部品に使用され、削除できません。Documotoサポート（support{\'@\'}documoto.com）までお問い合わせください。',
  partCodeErrorNotFound: 'このページコードは見つからないので削除できません。すべての変更が保存されたことを確認してください。',
  partCodesManager: '部品コードマネージャー',
  partCodesMessage: '部品コードは部品番号の代わりに使用することができ、またBOM品目が廃止されている、入手不可である、または参照のみのアイテム(例：\'PNNA\', \'-\', \'REF\')の場合に使用されることがあります。',
  partCreated: '部品が作成されました',
  partData: 'パーツデータ',
  partUpdated: '部品更新',
  partDebouncerEmailQueueJob: 'メール見積もり',
  partDescription: '部分説明',
  partLabel: '部品 | 部品（複数）',
  partLevelDetails: '部品価格、注文可能／表示、追加翻訳、部品タグ',
  partName: '部品名',
  partnerApiKey: 'パートナーAPI Key',
  partnerRESTRequestLimit: 'パートナーRESTリクエスト制限',
  partNumber: '部品番号',
  partNumberDescription: '検索対象であるエンドユーザーの内部部品番号',
  partNumberVisible: '部品番号を閲覧可能',
  partNumberVisibleDescription: '部品番号をエンドユーザーは閲覧可能',
  partNumberVisibleLocal: 'このページで閲覧可能',
  partNumberVisibleLocalBlocked: "グローバル閲覧ができない部品番号'",
  partOrderable: '部品注文可能',
  partRequired: '最低一個の部品が必要です',
  partTagProperty: '部品タグプロパティ | 部品タグプロパティ(複数)',
  partTag: '部品タグ | 部品タグ',
  partThumbnailDescription: '',
  partialSupport: '一部サポート',
  partsListColumn: '部品リストカラム |部品リストカラム(複数)',
  partsBook: '部品本',
  partsColumns: '部品カラム',
  partsNotSelected: '部品サムネイルに使用されているデフォルト画像。カスタムサムネイルを適用できない場合、Documotoは標準デフォルトサムネイルを表示します。',
  partsPage: 'パーツページ | パーツページ（複数）',
  partsPages: '部品ページ',
  partsPageGroup: {
    excludePartCodesFromPartsLists: '部品コードを部品リストから除外',
    highlightAlternateRows: '代替横列を強調表示',
    rotateImagesAutomatically: '画像を自動的に回転',
    printBorderAroundImages: '画像の周りに境界を印刷',
    keepImagesOnLeft: '画像を左側に維持',
    keepBOMOnRight: 'BOMを右側に維持',
    columnsUpdateErrorMsg: '部品カラムの更新時、エラー発生',
    columnsUpdateSuccessMsg: '部品カラムが更新されました',
    columnsLoadErrorMsg: '部品カラムのロード失敗',
  printHotpointsOnImages: 'ホットポイントを画像で印刷',
  },
  partsList: 'パーツリスト',
  partsListData: '部品リストデータ',
  partsListGridHeader: '部品リストグリッドヘッダー',
  password: 'パスワード',
  passwordExpiredHeader: 'パスワードの期限が切れています',
  passwordExpiredInfo: 'パスワードの期限が切れています。新しいパスワードは以下の基準を満たす必要があります。',
  passwordCriteriaInfo: '新しいパスワードは以下の基準を満たす必要があります。',
  passwordBlankMessage: 'パスワードは空欄にできません',
  passwordCannotBeSame: '現在のパスワードと異なるパスワードにしてください',
  passwordCasingMassage: '小文字と大文字、それぞれを少なくとも1文字含む必要があります',
  passwordLowerCaseMessage: 'パスワードには小文字を最低一つ含めなければなりません',
  passwordMinLengthMessage: 'パスワードは最低8文字必要です',
  passwordNotMatchingMessage: 'パスワードが一致しません',
  passwordSpecialCharactersMessage: 'パスワードには特殊文字を最低一つ含めなければなりません',
  passwordUpperCaseMessage: 'パスワードには大文字を最低一つ含めなければなりません',
  passwordWillExpire1Day: 'ログイン用パスワードの有効期限は1日です',
  passwordWillExpire5Days: 'ログイン用パスワードの有効期限は5日です',
  passwordsMatch: 'パスワードの一致',
  permission: '許可 | 許可(複数)',
  phoneNumber: '電話番号',
  phoneNumberExt: '内線',
  phonePrimary: '代表電話番号',
  phoneSecondary: '予備電話番号',
  pinFilter: '結果ページを検索するピンフィルタ',
  pinned: 'ピン留め',
  plusMoreOrgs: '+ {totalOrgCount}他の組織',
  polishDescription: 'ポーランド語の説明',
  polishName: 'ポーランド語の名前',
  populatePdfNameWith: 'PDFのファイル名を入力する',
  populatePdfNameSelection: 'PDFのファイル名選択を入力する',
  port: 'ポート',
  portrait: 'ポートレイト',
  portugueseDescription: 'ポルトガル語の説明',
  portugueseName: 'ポルトガル語の名前',
  postComment: 'コメント投稿',
  postalCode: '郵便番号',
  poweredBy: '動力源',
  preferences: 'プリファレンス',
  preferencesDisable: 'ユーザープリファレンスを無効化',
  prefix: '接頭辞 | 接頭辞(複数)',
  preview: 'プレビュー',
  previewOfName: '{name}のプレビュー',
  pricing: '価格設定',
  pricingDiscountEnabled: 'ディスカウント価格を表示',
  pricingDisplayInCart: 'ショッピングカートの価格を表示',
  pricingDisplayInPartsList: '部品リストの価格を表示',
  pricingGlobal: 'グローバル価格設定',
  pricingGlobalDescription: '組織の上書きがない場合の通常価格',
  pricingHidePricingButton: '非表示価格設定ボタンを有効化',
  pricingHidePricingButtonDisable: '非表示価格設定ボタンを無効化',
  pricingOrganization: '組織価格設定',
  pricingOrganizationDeleteConfirm: '組織価格設定を削除しますか?',
  pricingOrganizationDescription: '組織価格設定は、組織および子組織内の全ユーザーのグローバル価格設定を上書きします。',
  pricingOrganizationDuplicate: 'この組織はすでに上書き済みです',
  pricingOrganizationEmptyState: '組織の上書きが未設定。{add}をクリックして開始します。',
  pricingRetailEnabled: '小売価格を表示',
  pricingWholesaleEnabled: '卸売価格を表示',
  primary: 'プライマリ',
  print: '印刷',
  printerLane: 'プリンターレーン',
  printJob: '印刷ジョブ',
  printLogo: '印刷用ロゴ',
  printSetting: '印刷設定',
  printSettingDeleteFail: '印刷設定の削除に失敗',
  printSettingSaveFail: '印刷設定の保存に失敗',
  printSettingEmptyMessage: '印刷設定が未設定。印刷設定を追加して開始します。',
  printSettingSetDefaultSuccess: 'デフォルト印刷設定セット',
  printSettingUpdateFail: '印刷設定の更新に失敗',
  printSettingCopyMessage: '新しい印刷設定の固有名を入力します。',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: '印刷設定が作成されました',
  printSettings: '印刷設定',
  printSettingsLogo: '印刷設定ロゴ',
  printing: '印刷中',
  printingDisabled: '実行中の印刷すべてを無効化',
  privacyPolicy: 'プライバシー方針',
  proceed: '続行',
  process: '処理',
  processContent: 'プロセスコンテンツ',
  processing: '処理中',
  processToDraft: 'ドラフトまでのプロセス',
  processedPlzToDraft: 'ドラフトまでに処理されたPLZ',
  production: '製品',
  professional: '専門的',
  profile: 'プロフィール',
  prohibitExportDownloadOfContent: 'コンテンツのエクスポート／ダウンロードの禁止',
  prohibitExportDownloadOfContentHelpText: '\'Enable\'に設定すると、いずれのユーザー（権限のある者）も本コンテンツをエクスポートもしくはダウンロードすることはできなくなります。',
  property: 'プロパティ | プロパティ（複数）',
  province: '都道府県',
  public: '公共',
  publish: '発行',
  publisherLane: '出版社レーン',
  publisherManualLane: '出版社のマニュアルレーン',
  publishThumbnailJob: 'サムネイルを公開',
  publishDocuments: '文書を公開',
  publishDraft: 'ドラフトを公開',
  publisher: '発行者 | 発行者(複数)',
  publishingErrors: '公開エラー',
  publishingErrorsWithName: 'エラー{name}を公開',
  publishingInProgress: '公開処理中のため送信不可',
  publisherJob: '発行者ジョブ',
  publisherMessage: 'コンテンツ作成、マネージメント、および印刷設定',
  purchaseOrder: '購入注文',
  purchaseOrderTemplateEnabled: '注文テンプレートを有効化',
  purchaseOrderTemplateDisabled: '注文テンプレートを無効化',
  qty: '数量',
  quantity: '数量',
  query: 'クエリー',
  quickAdd: 'クイック追加',
  quickAddHelpText: 'タイピングを開始してショッピングカートに追加する部品を探します。',
  quickAddInCartEnabled: 'カート内のクイック追加を有効化',
  quickLinks: 'クイックリンク',
  quote: '見積もり | 見積もり',
  quoteFormType: '見積もりフォーム',
  quoteTemplateDisabled: '見積もりテンプレートを無効化',
  quoteTemplateEnabled: '見積もりテンプレートを有効化',
  range: '範囲',
  rearrange: '並べ替え',
  rebuildIndexJob: 'インデックスを再構築',
  recentSearches: '最近の検索',
  recentlyAdded: '最近の追加',
  recentlyEdited: '最近の編集',
  recentlyViewed: '最近の閲覧',
  rectangle: '長方形',
  redirect: '転送',
  refresh: 'リフレッシュ',
  related: '関連',
  relatedDescription: 'ライブラリーにあるメディアをこの{type}に関連付けます。\'関連\'タブで確認する場合、関連メディアにユーザーのアクセス制御が割り当てられていることが必要である点に注意してください。',
  relatedInfo: '関連情報',
  relatedView: '関連メディアの閲覧',
  relationship: '関連性 | 関連性(複数)',
  releaseNotes: '公開メモ',
  rememberMe: '覚え書き',
  remainingWidth: '残りの幅',
  remove: '除去',
  removeAll: 'すべて除去',
  removeItemConfirmation: '{item}を除去しますか?',
  removeItemCount: '{count} {type}を削除してもよろしいですか？',
  replace: '置き換える',
  replaceExisting: '既存を置換',
  report: 'レポート | レポート(複数)',
  reportAnIssue: '問題を報告',
  reportAnIssueMessage: 'Eメール送信完了',
  reportCatalogName: 'レポートカタログ名',
  reportCatalogNotSet: 'レポートカタログがレポートに定義されていません。',
  reportDefinition: 'レポート定義 | レポート定義（複数）',
  reportDefinitionDeleteError: 'レポート定義を削除できませんでした',
  reportsEmptyState: 'レポートがありません',
  reportExportTimeWarning: '記録数や選択した書式によっては、エクスポートが完了するまでに数分かかる場合があります。',
  reporting: 'レポーティング',
  reportingMessage: '様々なレポートとメトリクスにアクセス',
  reportSettings: 'レポートの設定',
  reportsLibrary: 'レポートライブラリ',
  requestForQuote: '引用の要請',
  requestFormType: '見積もりフォーム',
  required: '必須',
  requiredItem: '{item}は必須です。',
  requiredMessage: '{item}は必須です。',
  requiredMessageAndUnique: '{item}は必須で、一意である必要があります。',
  requireAuthentication: '認証は必須',
  requiredReading: '必須読み込み',
  requiredReadingMessage: '次を確認してください：  ',
  requiredUrlMessage: '完全なURLが必須です。',
  reset: 'リセット',
  resetPassword: 'パスワードリセット',
  resetPasswordError: 'パスワードリセットでトラブルが生じたので、もう一度行って下さい。',
  resetPasswordTokenError: 'パスワードリセットトークンが無効です。',
  resetZoom: 'ズームをリセット',
  resource: 'リソース | リソース(複数)',
  resize: 'サイズ変更',
  resizeThumbnails: 'サムネイルのサイズを自動的に再調整',
  result: '結果 | 結果（複数）',
  resultFor: ' {item} の結果 |  {item} の結果（複数）',
  retail: '小売',
  retailPrice: '小売価格',
  retailTotal: '小売合計',
  return: '戻る',
  returnToItem: '{item}に戻る',
  returnToPartList: '部品リストに戻る',
  returnToMedia: 'メディアに戻る',
  returnToTemplate: 'テンプレートに戻る',
  returnToViewer: 'ビューアーに戻る',
  revertChanges: '変更を元に戻す',
  right: '右',
  romanianDescription: 'ルーマニア語の説明',
  romanianName: 'ルーマニア語の名前',
  rootLevelBrowseFlow: 'ルートレベル',
  row: '横列',
  russianDescription: 'ロシア語の説明',
  russianName: 'ロシア語の名前',
  sampleData: 'サンプルデータ',
  samplePreview: 'サンプルプレビュー',
  sandbox: 'サンドボックス',
  sat: '土曜',
  save: '保存',
  saveChanges: '{item}の変更を保存しますか? {name}',
  saveChangesToItem: 'この{item}の変更を保存しますか?',
  saved: '保存済み',
  saveNewAPIKey: '新しい統合Keyは保存されませんでした.設定を完了するに戻ってください',
  saveNewWidgetKey: '新しいウィジェット・キーは保存されていません。全体の設定に戻る。',
  search: '検索',
  searchableFieldsSuccessfullyUpdated: '検索可能なフィールドが正常に更新されました。',
  searchAllContent: 'すべてのコンテンツを検索',
  searchConfiguration: '検索コンフィギュレーション',
  searchCriteria: '検索基準',
  searchDocumoto: 'Documoto検索',
  searchFeatures: '検索機能',
  searchFieldConfigDescription: '以下のシステムフィールド検索設定は、ライブラリの一般検索、検索結果ページ、カート内クイック追加にのみ適用されます。その他の検索機能（管理者センター検索や使用中のライブラリなど）には影響しません。',
  searchInContent: '{contentName}で検索...',
  searchingInLanguage: '{lang}のみで検索',
  searchingInTwoLanguages: '{lang1}と{lang2}のみで検索',
  searchPlaceholder: 'すべてのコンテンツを検索...',
  searchPrompt: 'すべてのコンテンツを検索...',
  searchProperty: '検索プロパティ | 検索プロパティ（複数）',
  searchResultsConfiguration: '検索結果のコンフィギュレーション',
  searchSettings: '検索の設定',
  searchSettingsDescription: 'これらの設定はさまざまなライブラリ検索の動作、機能、結果を制御します。',
  searchTerm: '検索用語',
  searchTips: '検索のヒント：',
  searchable: '検索可能',
  searchableTag: '検索可能タグ',
  section: 'セクション',
  select: '選択',
  selectAField: '{field} を選択',
  selectAll: 'すべて選択',
  selectAndClose: '選択して閉',
  selected: '選択済み',
  selectedTargetPage: '選択済みターゲットページ',
  selectChapters: 'チャプターを選択',
  selectChildren: '子を選択',
  selectFields: '{fields}を選択',
  selectItem: '{item}を選択',
  selectMedia: 'メディアを選択',
  selectPage: 'ページを選択',
  selectPages: 'ページを選択',
  selectTargetPage: 'ターゲットページを選択',
  send: '送信',
  september: '9月',
  serbianDescription: 'セルビア語の説明',
  serbianName: 'セルビア語の名前',
  serviceUri: 'サービスURI',
  sessionLog: 'セッションログ',
  set: '設定',
  setAsDefault: 'デフォルトとして設定',
  settings: '設定',
  settingsMessage: 'ユーザープリファレンスとパスワードをアップデートしてください',
  sequence: 'シーケンス',
  shape: '形状',
  shapeTool: 'シェイプツール',
  shared: '共有済み',
  sharedCart: '共有カート | 共有カート（複数）',
  sharedCartDeleteContent: '共有されていた全組織の共有カートが削除されます。',
  sharedCartOrganizationContent: '共有カートを使用できる組織を選択します。',
  sharedShoppingCartsEnabled: '共有ショッピングカートを有効化',
  shoppingCartExportJob: '買い物かごのエクスポート',
  shoppingCartLoadingLongTime: 'シッピングカートを読み込んでいます。処理が完了すると、カートアイコンを利用できるようになります。問題が解決しない場合、Documoto管理人にご連絡のうえ、サポートをご依頼ください。',
  showExportFromSearch: '検索結果のエクスポートを可能にします',
  showExportFromSearchHelp: '検索結果に基づき、本のリストエクスポートを可能にします',
  showHelp: 'ヘルプを表示',
  showIdentifierInSearch: '検索でメディア識別機能を表示',
  showItem: '{item}を表示',
  showLess: '少なく表示',
  showMediaDescriptionFilter: 'メディア内容フィルタを表示',
  showMediaIdentifierFilter: 'メディア識別子フィルタを表示',
  showMediaNameFilter: 'メディア名フィルタを表示',
  showMore: '多く表示',
  showOrganizationFilter: '組織フィルタを表示',
  showPartNameFilter: '部品名フィルタを表示',
  showPartNumberFilter: '部品番号フィルタを表示',
  showRecentlyAdded: '最近の追加を表示',
  showRecentlyViewed: '最近の閲覧を表示',
  showSearchButton: 'ホームページ検索ボタンを表示',
  showSearchButtonHelp: 'ライブラリヘッダーの\'検索に進む\'ボタンを表示',
  signature: '署名',
  size: 'サイズ',
  snapshot: 'スナップショット',
  solrSlaveSvrOverride: 'SOLRスレーブサーバーベースURLのオーバーライド',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: '最適',
  sortType: 'ソートタイプ',
  spanishDescription: 'スペイン語の説明',
  spanishName: 'スペイン語の名前',
  square: '四角',
  ssoConfiguration: 'SSO設定',
  ssoIdpMetadataUrl: 'SSO IDPメタデータURL',
  ssoLoginMaxAuthenticationAgeSeconds: 'SSOログイン認証時最高年齢 秒',
  ssoSamlDnAttributeName: 'SSO SAML DN属性名',
  ssoSamlEmailAttributeName: 'SSO SAML電子メール属性名',
  ssoSamlMemberofAttributeName: 'SSO SAML MemberOf属性名',
  ssoSniForSsoMetadataRetrieval: 'SSOメタデータの検索にSNIを使用する',
  standard: '標準',
  standardUserGroup: '標準ユーザー・グループ',
  startDate: '開始日',
  startDateRequired: '開始日は必須です。',
  state: '州',
  static: 'スタティック型',
  status: 'ステータス',
  statusCode: 'ステータスコード | ステータスコード（複数）',
  stitchPages: 'ステッチページ',
  storeFrontAdmin: '店頭管理',
  storefront: '店頭',
  storefrontIntegration: '店頭統合 | 店頭統合(複数)',
  storefrontIntegrationSubtitle: 'これら設定は様々な店頭統合要素とオプションを制御します。変更する際は注意してください。詳細はDocumotoサービスまでお問い合わせください。',
  storefrontSettings: '店頭設定',
  storefrontSettingsDescription: 'これらの設定は様々な店頭要素とオプションを制御します。変更する際は注意してください。詳細はDocumotoサービスまでお問い合わせください。',
  storefrontSettingsOrgDescription: 'これら設定は様々な組織レベル店頭要素とオプションを制御します。変更する際は注意してください。詳細はDocumotoサービスまでお問い合わせください。',
  storeFilterValues: 'フィルター値の保存',
  storeFilterValuesDescription: 'この設定を有効にすると、ユーザーが検索ページのフィルターで設定した値がこのタグに保存されます。',
  stroke: 'ストローク',
  style: 'スタイル | スタイル',
  styles: {
    colorHotpoint: 'ホットポイントの初期設定',
    invalidHexcode: '無効な16進コード',
    inheritedFromOrg: '組織からの継承{org}',
    inheritedFromSystem: 'システム初期設定からの継承',
    inheritedFromTenant: 'テナントからの継承',
    organization: '組織のスタイル | 組織のスタイル',
    organizationSaved: '組織のスタイルを保存しました',
    sampleNavigation: 'ナビゲーションタブ',
    samplePrimary: 'ナビゲーション/ボタン',
    sampleSelectedHotpoint: '選択済みホットポイント',
    tenant: 'テナントのスタイル | テナントのスタイル',
    tenantSaved: 'テナントのスタイルを保存しました'
  },
  subChapter: 'サブチャプター | サブチャプター(複数)',
  subject: '件名',
  subjectMessage: '件名は必須',
  submit: '送信',
  submitPurchaseOrder: '購入注文',
  submitRequestForQuote: '引用の要請',
  submitted: '送信済み',
  submitter: '送信者',
  submittedBy: '送信者',
  submitterEmail: '投稿者のメールアドレス',
  submitterFirstName: '投稿者の名',
  submittedFilesMessage: '{fileCount}ファイルを送信。',
  submitterLastName: '投稿者の姓',
  submitterOrgName: '投稿者の組織名',
  submitterUserName: '投稿者のユーザーネーム',
  success: '成功',
  imageSaveSuccess: '画像が正常に更新されました',
  successNotificationDuration: 'カートに追加－成功のトースト通知持続時間',
  suffix: '接尾辞 | 接尾辞(複数)',
  suggestedKeywords: 'おすすめのキーワード',
  suggestedResults: 'おすすめの結果',
  suggestion: '提案 | 提案（複数）',
  sun: '日曜',
  superadmin: 'スーパー管理者',
  superAdminSettings: "スーパー管理者の設定",
  supersede: '入れ替え',
  supersedeComplete: '上位権限を入れ替えますか?',
  supersedeDiscardChanges: '上位権限を破棄しますか?',
  supersedeMainText: '旧部品がある部品ページすべてを対象に、単数または複数の他の部品と現在の部品を入れ替えます。旧部品番号は削除されますがタグを介して検索が可能なので、旧番号または新番号を使ってコンテンツを検索できます。',
  supersedeMessage: '部品更新が直ちに有効になります。検索は数分以内にこの更新を反映します。',
  supersedeNotProcessed: '入れ替えは処理されません。',
  supersedeReplacePart: '部品を交換する単数または複数の部品を検索します',
  supersedeSuccessful: '入れ替え完了',
  supersedeWith: '･･･と部品を入れ替え',
  supplier: '納入業者 | 納入業者（複数）',
  supplierKey: 'サプライヤーキー',
  supplierDescription: 'この部品の供給先である会社',
  supportedImageFormats: 'サポートされる画像フォーマット: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'サポートされていないファイル。ドラフトファイルはサポートされています。MDZ, PLZ',
  surroundWithQuotes: '複数の検索用語を引用符で囲んで完全に一致するようにします。',
  svgFinder: 'SVGファインダー',
  swedishDescription: 'スウェーデン語の説明',
  swedishName: 'スウェーデン語の名前',
  system: 'システム',
  systemAdmin: 'システム管理',
  systemFields: 'システムフィールド',
  systemFieldSearchAndFiltering: 'システムフィールドの検索 & フィルタリング',
  systemStatus: 'システムステータス',
  systemName: 'システム名',
  systemVersion: 'システムバージョン',
  tabDelimited: 'タブ区切り',
  tableOfContents: '目次',
  tabloid: 'タブロイド',
  tag: 'タグ | タグ（複数）',
  tagName: 'タグ名',
  tagEditor: {
    rangeBoundLengthInvalid: '{limit}桁を超えることはできません。',
    tagEditorModal: 'タグ編集様式',
    filterPrompt: '最初の100値を表示中。詳細の確認はフィルタを使用してください。'
  },
  tagSearchAndFiltering: 'タグの検索 & フィルタリング',
  tagSearchAndFilteringHelpText: 'ヒント：一部の検索とフィルターの設定は、タグマネージャーのタグ編集でも設定できます。こちらをご参照ください：',
  tagSubmitAffected: '任意の{type}コンテンツは、変更に加えてタグ{tag}を自動的にドロップします。',
  tagDeleteAffectedCount: 'タグが削除され、{count}項目からタグ情報が除去されます。',
  tagDeleteAffectedNone: '現在このタグを使用している項目はありません。',
  tagManagerDeleteWarning: '{deleteCategoryName}の任意の既存メディアは、削除されるとこのカテゴリを失います。',
  tagsDisplayMore: '最初の100値を表示中。追加の閲覧はフィルタを使用してください。',
  tagsManager: 'タグマネージャー',
  tagValue: 'タグ値 | タグ値',
  tagValueMissing: 'タグ値を入力しなければなりません',
  tagValueMultiple: '複数入力を区切るセミコロン',
  tagValueTooLong: 'タグ値が25文字制限を超越 | タグ値が25文字制限を超越(複数)',
  taxon: 'タクソン',
  taxonomy: 'タクソノミー',
  template: 'テンプレート | テンプレート（複数）',
  templateEmailRecipients: 'テンプレートＥメール受信者',
  templateLayout: {
    addRow: '行を追加',
    addItem: '項目を追加',
    deleteRow: '行を削除',
    deleteColumn: '列を削除',
    fullWidth: '全幅',
    oneColumn: '1列',
    threeColumns: '3列',
    twoColumns: '2列'
  },
  tenant: 'テナント | テナント（複数）',
  tenantAdmin: 'テナント管理',
  tenantAdminMessage: 'テナント全体設定の管理',
  tenantCleanerJob: 'テナントのメンテナンス',
  tenantConfiguration: 'テナントの設定',
  tenantEmailRecipients: 'テナントＥメール受信者',
  tenantEmailRecipientsOverrideMessage: '組織Ｅメール受信者が特定されている場合は上書きされます',
  tenantIndexQueueJob: 'テナントのインデックス待ち',
  tenantKey: 'テナントキー',
  tenantKeyError: 'テナントキーが一意ではありません',
  tenantKeyMessage: 'テナントキーは一意でなければなりません',
  tenantLabelDeleteMessage: 'このラベルを削除すると、デフォルトDocumoto名に戻ります。',
  tenantReindexModulus: 'テナントのインデックスを再作成するモジュール',
  tenantSettings: {
    collapseTOCLabel: '一致ページのTOCを閉じる',
    defaultLocaleLabel: 'デフォルトロケール',
    defaultLocaleMessage: 'テナントデフォルトロケール',
    deleteMissingTranslationsOnPublishLabel: '出版時に欠けている翻訳を削除しますか？',
    deleteMissingTranslationsOnPublishMessage: '出版時の翻訳を一部削除するを有効にします',
    defaultSupplierLabel: 'デフォルト納入業者',
    defaultSupplierMessage: 'テナントデフォルト納入業者',
    enableChapterIndexingMessage: '索引チャプター',
    enableChapterIndexingLabel: 'メディアチャプターに関する索引を有効にする',
    enableIndexingLabel: '公開に関するインデックス',
    enableIndexingMessage: '公開するためにインデックスをオンにします。',
    helpUrlLabel: 'デフォルトヘルプURL',
    helpUrlMessage: '代替ヘルプシステムのURL。',
    hotPointLinkPageInBookOnlyLabel: '本のみのページホットポイントリンク',
    hotPointLinkPageInBookOnlyMessage: '本のみのページからのホットポイントリンク',
    mediaTimestampLabel: 'アクセス制御変更のメディアタイムスタンプを更新',
    mediaTimestampMessage: 'アクセス制御の変更によりメディアのタイムスタンプを更新しなければならない場合、有効にします。',
    publisherDefaultUom: '出版社デフォルトUOM',
    publishingContactLabel: '発行元への連絡先Ｅメール',
    publishingContactMessage: 'コンテンツ問題が報告された場合に受信するデフォルトＥメールアドレス。',
    replaceHotpointLabel: '発行元のホットポイントリンクを必ず交換します',
    replaceHotpointMessage: 'Docustudio発行元に限定。',
    ssoLoginSystemLabel: 'SSOログインシステム',
    ssoLoginSystemMessage: 'SSOのためのIDプロバイダーシステムです。',
    userPasswordExpirationLabel: 'ユーザーパスワードの有効日数',
    userPasswordExpirationMessage: 'パスワードのリセットが必要となった後の残日数。有効期限がない場合は空欄のままにしてください',
    lockPartTrOnPublishLabel: '出版時の部分翻訳をロックする',
    lockPartTrOnPublishMessage: '出版中に部分翻訳が変更されないようにする',
    exportPgAsHashLabel: 'ページファイル名をハッシュキーとしてエクスポートする',
    exportPgAsHashMessage: 'エクスポートされたコンテンツ内でページハッシュキーをページファイル名として使用する'
  },
  tenantSettingsTitle: 'テナント設定',
  tenantSettingsSaved: 'テナント設定が保存されました',
  tenantSettingsSubtitle: 'これらの設定はテナント全体の物件と行動を管理します.変更する際はご注意ください.詳しくはDocumotoサービスにご連絡ください.',
  tenantSuppliers: 'テナントのサプライヤー',
  tenantLabelsPageText: 'ラベルは、Documotoのライブラリおよび管理画面の多くの物の名称をカスタマイズするのに使用します。',
  tenantPrintSettingsDescription: 'テナント対象デフォルト印刷設定を選択します。組織またはメディアを上書きしない場合、本印刷すべてに使用されます。',
  tenantType: 'テナントタイプ',
  tenantWebserviceUser: 'テナントのWebサービスユーザー',
  text: 'テキスト',
  textArea: 'テキスト領域',
  textField: 'テキストフィールド',
  textFile: 'テキストファイル（CSV、TXT）',
  textTool: 'テキストツール',
  threeD: {
    addFile: '3Dパーツファイルを追加',
    beforeSaveModal: {
      title: 'Documoto内にあるこのファイル名のCADファイルはすでに存在します。',
      message: '存在するこのファイルすべてを置き換えますか？それともこの部分のみに添付しますか？ファイルを置き換える場合、以下のようになります。',
      messageBulletOne: '{count}パーツは新しいCADファイルに更新されます',
      messageBulletTwo: 'このファイルの古いバージョンはDocumotoのファイルシステムから削除されます',
      tip: 'ヒント：添付処理中にファイルが上書きされるのを防ぐため、インポートする前に添付しようとしているファイルの名前を変更できます。'
    },
    deleteTitle: 'この3Dビューを削除してもよろしいですか？',
    drawMode: {
      label: '描画モード',
      hiddenLines: '線を非表示にする',
      shaded: '影を描く',
      wireframe: 'ワイヤーフレーム',
      wireframeShaded: 'ワイヤーフレームで影を描く',
    },
    explode: '爆発',
    fileSizeModal: {
      message: 'Documotoで許容される最大ファイルサイズは25メガバイトです。アップロードしようとしているファイルが最大値を超えているため、読み込めません。続行するには「OK」をクリックしてください。',
      title: 'ファイルサイズの上限を超えました',
    },
    orientationAndProjection: {
      back: '戻る',
      bottom: '下部',
      front: '前',
      isometric: '等尺の',
      left: '左',
      orthographic: '正字法の',
      projection: 'プロジェクション',
      perspective: '視点',
      right: '右',
      top: '上部'
    },
    resetCamera: '最近のカメラ',
    slice: {
      label: 'スライス',
      planeX: '切断プレートX',
      planeY: '切断プレートY',
      planeZ: '切断プレートZ',
      reset: '切断プレートのリセット',
      section: '切断プレートセクショントグル',
      visibility: '切断プレート可視化トグル',
    },
    supportedFilesMessage: 'Documotoでは多くのCADファイルフォーマットをサポートしています。ご自身のCADファイル様式がサポートされているかご不明な場合は、サポートセンターにご確認ください。',
    toolsLabel: '3Dツール',
    topAssemblyFilename: '上部アセンブリのファイル名',
    topAssemblyFilenameRequired: '上部アセンブリのファイル名は必須です',
    viewerTitle: '3Dビューアー',
  },
  thu: '木曜',
  thumbnail: 'サムネイル',
  thumbnailDeleted: 'サムネイルの削除完了',
  thumbnailDeletedError: 'サムネイルの削除失敗',
  thumbnailUpdated: 'サムネイルの更新完了',
  thumbnailUpdatedError: 'サムネイルの更新失敗',
  thumbnailReapplyWarning: 'メディアのコンテンツに応じて、システムによりサムネイルの再適用が行われる場合があります。数分かかります。',
  thumbnailResizeTooltip: 'チェックしている場合は、サムネイルを500x500ピクセルより小さいサイズに再調整します',
  title: 'タイトル',
  to: '宛先',
  toastNotifications: 'トースト通知',
  toDate: '日付',
  toc: '目次',
  tocClipBoardSuccessTitle: 'クリップボードにコピー済み',
  tocClipBoardWarningText: '警告: 未保存の変更はクリップボードにコピーできません',
  tocClipBoardWarningTitle: 'クリップボードにコピー済み',
  tocDisplayingFirstValues: '最初の100値を表示中。追加の閲覧はフィルタを使用してください。',
  tocDuplicateWarningText: '重複ページがチャプター内に検出されました。重複を除去しました。',
  tocEmptyMessage: '目次が作成されていないようです。',
  toGetStarted: '始めるには',
  tocMissingChapterName: 'チャプター名なし',
  tocModalTableCaption: 'タグ編集様式',
  tocNothingSelected: 'すべて未選択',
  tocOpenEditor: '編集を開く',
  tocSaveRequired: 'チャプターの細部を編集する前に、保存してください',
  tocState: '目次の初期状態',
  tocThumbnailDeleteFailTitle: 'サムネイルの削除失敗',
  tocThumbnailDeleteSuccessTitle: 'サムネイルの削除完了',
  tocThumbnailUploadFailTitle: 'サムネイルのアップロード失敗',
  tocThumbnailUploadSuccessTitle: 'サムネイルのアップロード完了',
  tocTranslationMessage: 'このチャプターの名前およびオプション内容',
  tocUntitledPage: '無題ページ',
  tooManySelections: 'リストが{limit}の選択上限を超えています',
  togglePage: '切り替えページ',
  top: '頂部',
  total: '合計',
  totalItems: '総項目',
  translation: '翻訳 | 翻訳（複数）',
  translationHelpTextItem: 'この{item}の名前およびオプション内容',
  true: '真',
  tryAgain: 'もう一度行ってください。',
  tue: '火曜',
  turkishDescription: 'トルコ語の説明',
  turkishName: 'トルコ語の名前',
  twoSided: '2面',
  type: 'タイプ | タイプ（複数）',
  typeAtLeastTwoChars: '一致しているリストを見るには、少なくとも2文字入力してください。',
  typeToConfirm: '{value}をタイプして確定',
  typography: '印刷',
  ukraineDescription: 'ウクライナ語の説明',
  ukraineName: 'ウクライナ語の名前',
  unableToRetrieveResults: 'クエリー/検索の結果がヒットしません',
  unauthorized: '不正',
  unknownError: '原因不明のエラー、Documotoサポート（support{\'@\'}documoto.com）までお問い合わせください',
  unknownPart: '不明部品',
  unknownType: '不明なカテゴリー',
  unsavedAPIKeyWarning: '戻らない場合、API Keyは失われます',
  unsavedChanges: '変更が未保存です。続けますか?',
  unsavedChangesWarning: '保存しないと変更が無効となります。',
  unsavedWidgetKeyWarning: 'ウィジェット・キーは、返却されない場合、失われます。',
  unsupportedBrowserMessage: '当社はこのアプリケーションを最新の技術を活用して構築しました。アプリケーションが速くなり、使い方も簡単になりました。残念ながら、お使いのブラウザはこのテクノロジーをサポートしていません。',
  uom: 'よくある質問',
  update: '更新',
  updateFailed: '更新失敗',
  updateIndex: 'インデックスを更新',
  updatePassword: 'パスワードを更新',
  updatePasswordMessage: 'パスワード更新を完了',
  updatePasswordPending: 'パスワード変更保存待ち。',
  updated: '更新',
  upgradeBrowser: 'お使いのブラウザをアップグレードしてください。',
  upload: 'アップロード',
  uploadComplete: 'アップロード完了',
  uploadInProgress: 'アップロード中',
  uponSavingDisabledTenant: 'この設定を有効にしてこのページを保存すると、以下のようになります。',
  uponSavingThisLicense: 'この設定を有効にしてこのページを保存すると、以下に従わなければなりません。',
  upperBound: '上限',
  untitledOrg: '無題組織',
  usCurrency: '米ドル',
  useDefaultCartColumns: 'デフォルトカートカラムを使用',
  useDefaultPartsListColumns: 'デフォルト部品リストカラムを使用',
  user: 'ユーザー',
  userAddress: 'ユーザー住所 | ユーザー住所(複数)',
  userCount: 'ユーザーカウント',
  userEditable: 'ユーザー編集可能',
  userExpirationDate: 'ユーザーの有効期限',
  userExpirationDateInvalid: 'ユーザーの有効期限は必ず入力してください',
  userGroup: 'ユーザーグループ | ユーザーグループ(複数)',
  userGroupEmpty: 'ユーザーグループは空欄にはできません',
  userGroupType: 'ユーザーグループの種類',
  userGroupUniqueMessage: 'このテナントのその他ユーザーグループが使用していない固有名でなければなりません',
  userGroupUsers: 'ユーザーグループユーザー',
  username: 'ユーザー名',
  usernameEmpty: 'ユーザー名の空欄不可',
  users: 'ユーザー',
  usersWillReceiveAnUnauthorizedError: 'テナントにログインしようとすると、ユーザーには「無許可」のエラーが表示されます。',
  useSniForSsoMetadataRetrieval: 'SSOメタデータの検索にSNIを使用する',
  value: '値',
  validate: '検証',
  validateTenantDirectories: {
    buttonLabel: 'コンテンツディレクトリを検証する',
    error: 'ディレクトリの検証に失敗しました',
    success: 'テナントディレクトリを検証しました'
  },
  variantSKU: '可変SKU',
  validationMessage: '検証メッセージ | 検証メッセージ',
  valueGteError: '値は{max}またはそれ以下でなければなりません',
  valueLteError: '値は{min}またはそれ以上でなければなりません',
  vendorName: 'ベンダー名',
  verifyPassword: 'パスワードの確認',
  vertical: '垂直',
  version: 'バージョン',
  video: 'ビデオ | ビデオ（複数）',
  view: '閲覧',
  viewAll: 'すべて表示',
  viewAllPagesAndBooks: '閲覧：ページと書籍のすべて',
  viewAllResults: 'すべての結果を表示',
  viewBooks: '閲覧：書籍',
  viewMore: 'さらに表示',
  viewPages: '閲覧：ページ',
  viewModeList: 'リスト閲覧',
  viewModeTable: 'テーブル閲覧',
  visibility: '可視化',
  visibilityDescription: 'この{entity}を閲覧できる人物',
  visible: '可視化',
  waitForUpload: 'アップロードが完了するまでお待ちください。',
  watermark: '透かし模様',
  watermarkFont: '透かし模様のフォント',
  watermarkOpacityPercentage: '透かし模様の不透明度（%）',
  watermarkOrientation: '透かし模様の方向性',
  watermarkSaved: '透かし模様が保存されました',
  watermarkSaveFailed: '透かし模様の保存に失敗しました。',
  watermarkSelectionRequired: 'ユーザー名またはテキストの選択が必要です。',
  watermarkText: '透かし模様のテキスト',
  wed: '水曜',
  whereUsed: '使用場所',
  whoCanViewComment: 'このコメントを閲覧できる人物',
  wholesale: '卸売',
  wholesalePrice: '卸売価格',
  wholesaleTotal: '卸売合計',
  widget: 'ウィジェット',
  widgetAuthHeader: 'ウィジェットの認証値',
  widgetKey: 'ウィジェット・キー | ウィジェット・キー',
  widgetKeyAdmin: 'ウィジェット・キーの管理者',
  widgetKeyCopied: 'ウィジェットの認証値がクリップボードにコピーされました',
  widgetKeyDeleted: 'ウィジェット・キーが削除されました',
  widgetKeyDeletedError: 'ウィジェット・キー削除エラー',
  widgetKeyDeleteMsg: 'ウィジェット・キーを削除すると、ウィジェット・キーは使用できなくなります',
  widgetKeyManager: 'ウィジェット・キー・マネージャー',
  widgetKeyNameEmpty: '氏名は一意とし、入力が必須です。この値は、あらゆるウィジェット活動を行う際のユーザー名として、報告やロギング目的においても使用されます',
  widgetKeyRegenError: 'ウィジェット・キーの再生成エラー',
  widgetKeyRegenMsg: 'ウィジェット・キーを再生成すると、既存の統合が無効になる場合があります',
  widgetKeyRegenTitle: 'このウィジェット・キーを再生成しますか？',
  widgetKeySaved: 'ウィジェット・キーが保存されました',
  widgetPermissions: 'ウィジェット・ユーザーは読み取り専用であり、機能へのアクセスはウィジェットレベルの設定により管理されます。標準ユーザー・グループの権限はウィジェット・ユーザーには適用されません。メディア・カテゴリーのアクセス権が作動します。メディア・カテゴリーのアクセス制御の割り当てを行ってください。',
  widgetRefreshErrorMsg: "リクエスト内容を確認するにはページを更新してください",
  widgetUserGroup: 'ウィジェット・ユーザー・グループ',
  widgetUserGroupDeleteMsg: 'ユーザーを含むウィジェット・グループを削除することはできません',
  willBeAffected: '影響を受けます。',
  willBeDeleted: '{content}が削除されます',
  willBeInsertedIntoItem: '{name}が{item}に挿入されます',
  willBeUpdated: '更新されます。',
  zipCode: '郵便番号',
  zoomHotpointToCanvas: 'ホットポイントをキャンバスサイズにズーム',
  zoomIn: 'ズームイン',
  zoomOut: 'ズームアウト'
}
/* eslint-enable max-len */
