import http from '@/http'
import router from '@/router'

import {
  TOC_RECEIVED,
  TOC_BOOK_RECIEVED,
  TOC_UPDATE_OPEN,
  TOC_UPDATE_SEARCH,
  TOC_LOADING,
  TOC_RESIZE,
  CONTENT_RECEIVED,
  BOM_ILLUSTRATION_RECEIVED,
  CLEAR_TOC
} from './contentTypes'
import { entityTypePluralize, routeToEntityTypeAndId } from './contentHelpers'
import { getRoute } from "@/router/getRoute"

const state = getDefaultState()

const getters = {
  tocChildren: (state, getters, rootState) => {
    const item = state.itemsMap[`${rootState.content.type}:${rootState.content.id}`]
    return item ? item.children : state.items
  },
  /*
   * state.items is a nested Array of item DTOs.
   * Each DTO contains metadata for chapters & pages in the book.
   *  e.g. { id: number, type: 'chapter' || 'page',
   *          children?: Array, ... }
   * Chapters may contain a 'children' field,
   * which is an embedded list of nested item DTOs.
   * This getter returns a flattened Map that uses as keys
   * the stringified route path in use in Library BOM TOC.
   * Each key's value is an object of the item DTO,
   * index position of the item in a flat list,
   * & a set of chapter Id showing how nested it is.
   * e.g "/chapter:71/page:1711" -> {
   *    chapters: Set[71]
   *    index: 0,
   *    item: ItemDto {}
   * }
   * It allows O(1) lookups of metadata with the item route path.
  */
  widgetTocFlatMap(state) {
    return state.items.reduce(recursiveReducer(), new Map())

    function recursiveReducer(prefix = '', previousChapters = []) {
      return function(flatMap, item) {
        const id = `${prefix}/${item.type}:${item.id}`
        const chapters = previousChapters
          .concat((item.type === 'chapter') ? item.id : [])

        flatMap.set(id,
          { item, index: flatMap.size, chapters: new Set(chapters) })

        if (!!item.children) {
          item.children.forEach(child => {
            recursiveReducer(id, chapters)(flatMap, child)
          })
        }
        return flatMap
      }
    }
  },
  /*
   * This list returns a flat list to be used, in contrast to
   * the above flat Map, for O(1) lookups when the index is known
   * rather than the parsed Item route path.
   */
  widgetTocFlatList(state, getters) {
    return [ ...getters.widgetTocFlatMap.values() ]
  }
}

const actions = {
  async getTOC ({ commit, rootState, state, dispatch }, { id, type }) {
    try {
      const entities = routeToEntityTypeAndId(id, type)
      const { 0: first } = entities

      if (type === 'book') {
        if (!state.book || id !== state.book.id) {
          commit(TOC_LOADING)
          const tocDimensions = await dispatch('getLocalStorage', { key: 'toc' }, { root: true })
          if (tocDimensions) {
            commit(TOC_RESIZE, tocDimensions)
          }

          const entityTypePlural = entityTypePluralize(type)
          const fetch = [
            http.get(`${entityTypePlural}/${id}/table-of-contents`, {
              params: {
                'nest-children': true
              }
            })
          ]

          /*
           * This if-check and the one below it help ensure that
           * multiple GET requests for the same content
           * are not performed to "/media/:id"
           * Equivalent code to this block conditionally runs in the
           * getContent action found in content.js
          */
          fetch.push(http.get(`${entityTypePlural}/${id}`))
          const [result1, result2] = await Promise.all(fetch)

          if (result2.data) {
            const content = result2.data
            content.bookId = entities.length > 1 ? first.id : ''
            commit(CONTENT_RECEIVED, { content })
            commit(BOM_ILLUSTRATION_RECEIVED, {
              illustration: rootState.content.contentType === 'illustration' ? content : null
            })
          }

          commit(TOC_RECEIVED, { toc: result1.data })
          commit(TOC_BOOK_RECIEVED, { book: result2 ? result2.data : rootState.content })

          const currentRoute = getRoute()
          if (rootState.content.id === id && currentRoute.name === 'Asset') {
            commit(TOC_UPDATE_OPEN, { isTocOpen: rootState.user.preferences.tocState })
          }
        }
      } else {
        commit(TOC_RECEIVED, { toc: [] })
        commit(TOC_BOOK_RECIEVED, { book: null })
      }
    } catch (err) {
      // na
    }
  },
  async updateTocOpen ({ commit }, isOpen) {
    commit(TOC_UPDATE_OPEN, { isTocOpen: isOpen })
  },
  async tocSearchWithReturn ({ commit, state }, { query }) {
    try {
      if (query) {
        const length = Object.keys(state.itemsMap).length

        // offset not supported by the toc-search endpoint
        const params = {
          limit: length || 1000,
          fk_media_id: state.book.id,
          q: query
        }

        const { data } = await http.get('toc-search', { params })
        commit(TOC_UPDATE_SEARCH, { query })
        return data.items
      }
      commit(TOC_UPDATE_SEARCH, { query: '' })
    } catch (err) {
      // na
    }
    return []
  },
  async tocResize ({ commit, dispatch }, { width, height }) {
    try {
      commit(TOC_RESIZE, { width, height })
      dispatch('setLocalStorage', {
        key: 'toc',
        value: {
          width,
          height
        }
      }, { root: true })
    } catch (err) {
      // na
    }
    return []
  }
}

const mutations = {
  [TOC_RECEIVED] (state, { toc }) {
    state.items = toc
    state.query = ''

    const flatten = (arr, result = {}) => {
      arr.forEach((item) => {
        result[`${item.type}:${item.id}`] = item; // eslint-disable-line
        if (item.children) {
          flatten(item.children, result)
        }
      })
      return result
    }
    state.itemsMap = flatten(toc)

    const listify = (arr, result = [], prefix = '') => {
      arr.forEach((item) => {
        const id = `${prefix}/${item.type}:${item.id}`
        if (item.type === 'page' || item.type === 'chapter') {
          result.push(id)
        }
        if (item.children) {
          listify(item.children, result, id)
        }
      })
      return result
    }
    state.itemsList = listify(toc)

    state.isLoaded = true
  },
  [TOC_BOOK_RECIEVED] (state, { book }) {
    state.book = book
      ? {
          id: book.id || '',
          identifier: book.identifier || '',
          name: book.name || '',
          type: book.type || '',
          contentType: book.contentType || '',
          disableExport: book.disableExport || false
        }
      : null
  },
  [TOC_UPDATE_SEARCH] (state, { query }) {
    state.query = query
  },
  [TOC_UPDATE_OPEN] (state, { isTocOpen }) {
    state.isTocOpen = isTocOpen
  },
  [TOC_LOADING] (state) {
    state.isLoaded = false
  },
  [TOC_RESIZE] (state, { width, height }) {
    state.width = width
    state.height = height
  },
  [CLEAR_TOC] (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

export function getDefaultState() {
  return {
    book: undefined,
    query: '',
    items: [],
    itemsMap: {},
    itemsList: [],
    isTocOpen: false,
    isLoaded: false,
    width: 500,
    height: 500
  }
}
