<template>
  <table class="search-result-table table is-hoverable is-fullwidth">
    <thead class="is-sticky">
      <tr class="has-background-grey-lighter">
        <th class="has-background-grey-lighter">
          {{ $i18n.t('name') }}
          <search-sort-toggle field="name" :locale="locale" />
        </th>
        <th class="has-background-grey-lighter">
          {{ $i18n.t('description') }}
          <search-sort-toggle field="description" :locale="locale" />
        </th>
        <th class="has-background-grey-lighter">
          {{ $i18n.t('identifier') }}
          <search-sort-toggle field="identifier" />
        </th>
        <th class="has-background-grey-lighter">
          {{ $i18n.tc('type') }}
          <search-sort-toggle field="searchable_type" :no-modifier="true" />
        </th>
        <th class="has-background-grey-lighter">
          {{ $i18n.t('updated') }}
          <search-sort-toggle field="updated" :no-modifier="true" />
        </th>
        <th class="has-background-grey-lighter">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="result in items" :key="result.id" @dblclick.stop="$emit('open', result)">
        <td>
          <i class="fas fa-star" v-if="result.favorited" />
          <span v-if="isWidget"
                class="has-text-link widget-link"
                @click="widgetNavToContent(result)">
            {{ result.name }}
          </span>
          <router-link  v-else
                        :to="routerLink(result)"
                        @click="$store.dispatch('search/keepPagination')">
            {{ result.name }}
          </router-link>
        </td>
        <td>{{ result.description }}</td>
        <td>{{ result.identifier }}</td>
        <td>
          <span v-if="result.mediaType">
            {{ $t(result.mediaType) }}
          </span>
          <span v-if="result.entityType">
            {{ $t(result.entityType) }}
          </span>
        </td>
        <td>
          <span v-if="result.updated">
            {{ $d(result.updated) }}
          </span>
        </td>
        <td>
          <a class="icon is-medium" @click.stop.prevent="$emit('info', result)">
            <i class="fa fa-2x fa-info-circle"/>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import SearchResult from './SearchResult';
import SearchSortToggle from './SearchSortToggle';

export default {
  components: {
    SearchResult,
    SearchSortToggle
  },
  props: {
    items: {type: Array, required: true}
  },
  emits: ["widgetNavToContent"],
  setup(props, context) {
    const store = useStore()
    const isWidget = computed(() => store.getters["widgets/isWidget"])

    function widgetNavToContent(result) {
      store.dispatch("search/keepPagination")
      context.emit("widgetNavToContent", result)
    }

    return { isWidget, widgetNavToContent }
  },
  computed: {
    locale() {
      return this.$i18n.locale.replace('-', '_');
    }
  },
  methods: {
    routerLink (result) {
      const entityType = result.mediaType || result.entityType;
      let {query: { q }} = this.$route
      return {
        query: { q },
        path: `/${entityType}/${result.entityId}`
      };
    }
  }
}
</script>

<style scoped>
thead.is-sticky,
thead.is-sticky th {
  position: sticky;
  top: 0;
}
.widget-link:hover {
  cursor: pointer;
}
</style>
