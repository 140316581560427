/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} av {b}',
  about: 'Om',
  aboutThisItem: 'Om denna artikel',
  accessApiKeyManager: 'Få åtkomst till API Key Manager för denna hyresgäst.',
  accessControls: 'Åtkomstkontroller',
  accessControlsCopiedText: 'Åtkomstkontroller uppdateras omedelbart. Mediesäkerhetsprivilegier uppdateras inom några minuter.',
  accessControlsCopiedTitle: 'Åtkomstkontroller har kopierats',
  accessControlsCopyConfirmation: 'Detta tar bort alla befintliga åtkomstkontroller från {to} och ersätter dem med åtkomstkontroller från {from}. Denna åtgärd kan inte ångras.',
  accessControlsDescription: 'Åtkomstkontroller definierar vem som kan se dessa medier, baserat på valda organisationer och mediekategorier.',
  accessControlsIndeterminance: 'Obs! Överordnade organisationer har alltid åtkomst till innehåll som tilldelats någon av deras underordnade organisationer.',
  account: 'Konto',
  accountErrorMessage: 'Felmeddelande för konto',
  accountErrorMessageWarning: 'Fältet accountErrorMessage i databasen kommer att ställas in som "Ej tillåtet" för hyresgästen.',
  action: 'Åtgärd | Åtgärder',
  actionDelete: 'ta bort',
  actionLoad: 'läs in',
  actionPaste: 'Klistra in',
  actionSave: 'Spara',
  actionRun: 'kör',
  actionUndoWarning: 'Denna åtgärd kan inte ångras.',
  actionUpload: 'ladda upp',
  actualSize: 'Verklig storlek',
  add: 'Lägg till',
  addAPIKey: 'Lägg till en API-Nyckel',
  addItem: 'Lägg till {item}',
  addABookText: 'Lägg till en bok',
  addASharedCart: 'Lägg till en delad kundvagn',
  addATemplate: 'Lägg till en mall',
  addATag: 'Lägg till en etikett',
  addABookToStart: '{addABookText} för att komma igång.',
  addAForm: 'Lägg till ett Formulär för att komma igång',
  addAFormOnly: 'Lägg till ett formulär',
  addAMedia: 'Lägg till media',
  addAnOrderQuoteEmailFormat: 'Lägg till ett format för en beställning, förfrågan eller ett mejl',
  addAPrintSettingText: 'Lägg till en utskriftsinställning',
  addAPrintSettingToStart: '{addAPrintSettingText} för att komma igång.',
  addBooks: 'Lägg till böcker',
  addChildBrowseFlow: 'Lägg till underordnat flöde',
  addChapter: 'Lägg till Kapitel',
  addComment: 'Lägg till en kommentar',
  addExistingPart: 'Lägg till en befintlig artikel',
  addGroupsToUsers: 'Lägg till {groups} grupper till {users} användare',
  additionalInformation: 'Mer information',
  additionalNotification: 'Ytterligare meddelande',
  additionalPartNumberDescription: 'Använd som ytterligare fält för artikelnummer',
  addBrowseFlow: 'Lägg till ett flöde',
  addMedia: 'Lägg till medium',
  addPages: 'Lägg till sidor',
  addPart: 'Lägg till en artikel',
  addReports: 'Lägg till Rapporter',
  addRootBrowseFlow: 'Lägg till rotflöde',
  addRow: 'Lägg till rad',
  addTemplates: 'Lägg till mallar',
  addToCartMessage: '{partNumber} har lagts till',
  addToUserGroup: 'Lägg till i Grupper',
  address: 'Adress | Adresser',
  addressInUse: 'En adress är för närvarande inställd som standardadress och kan inte tas bort',
  addressInUseSubtitle: 'Om du vill ta bort denna adress måste du först ta bort den som standardadress.',
  addressLine: 'Adressrad {number}',
  addWidgetKey: 'Lägg till en widgetnyckel',
  admin: 'Admin',
  adminCenter: 'Admincenter',
adminCenterBanner: {
    bodyLabel: 'Bannerns kropp',
    enableLabel: 'Aktivera banner för administratör',
    headerGreenOption: 'Grön',
    headerLabel: 'Banner för sidhuvud',
    headerRedOption: 'Röd',
    headerYellowOption: 'Gul',
    title: 'Banner för administrationscentral',
    typeLabel: 'Typ av banner'
  },
  adminCenterMessage: 'Administrativt center för innehåll, användare, biblioteksinställningar, rapportering och export',
  adminMessage: 'Funktioner läggs till när de blir tillgängliga',
  administration: 'Administration',
  affectedBookMedia: '{count} Bokmedia kommer att uppdateras.',
  affectedTemplateMedia: '{count} Bokmallar kommer att uppdateras.',
  after: 'Efter',
  aggregatedFromChildren: 'Sammanslaget från underordnade',
  aiAlgorithm: 'AI-skanner',
  all: 'Alla',
  alphanumeric: 'Alfanumeriskt',
  algorithm: 'Algoritm',
  alignHorizontal: 'Justera Vågrätt',
  alignVertical: 'Justera Lodrätt',
  allUsersDisabled: 'Alla användare som hör till hyresgästen kommer att inaktiveras.',
  analyticsJSON: 'Analyser JSON',
  andCountMore: 'och {count} andra ...',
  any: 'Valfri',
  apiKey: 'API-Nyckel | API-Nycklar',
  apiKeyAdmin: 'Administratör för API-Nycklar',
  apiAuthHeader: 'Rubrik för Godkännande av API',
  apiKeyCopied: 'Värde för Rubrik för Godkännande av API kopierat till Urklipp',
  apiKeyDeleted: 'API-Nyckel raderad',
  apiKeyDeletedError: 'Fel vid radering av API-Nyckel',
  apiKeyDeleteMsg: 'Radering av denna API-Nyckel kommer att inaktivera dess användning av integreringar',
  apiKeyDeleteTitle: 'Är du säker på att du vill radera denna API-Nyckel?',
  apiKeyInstructions: 'Nyckel kommer att genereras när den Sparas',
  apiKeyManager: 'Hantering av API-Nycklar',
  apiKeyNameEmpty: 'Namn får inte vara tomt',
  apiKeyRegenError: 'Fel när API-Nyckel återskapades',
  apiKeyRegenMsg: 'Återskapande av denna API-Nyckel kan inaktivera befintliga integreringar',
  apiKeyRegenTitle: 'Är du säker på att du vill återskapa denna API-Nyckel?',
  apiKeySaved: 'API-Nyckel Sparad',
  apiKeyType: 'Typ av API-nyckel',
  appliesTo: 'Gäller för',
  appliesToEmpty: 'Kräver att minst en enhetstyp har valts',
  appliesToPopulated: 'Ställ in vilka enheter taggen kan tillämpas på',
  applyItem: 'Tillämpa {item}',
  applyToChildItems: 'Tillämpa på underordnad {item}',
  applyToPartOnly: 'Använd endast på denna del',
  april: 'April',
  area: 'Område',
  areYouSure: 'Är du säker på att du vill ta bort {item}?',
  areYouSureCopy: 'Är du säker på att du vill kopiera {item}?',
  areYouSureYouWantToDisableTheTenant: 'Är du säker på att du vill inaktivera hyresgästen?',
  assembly: 'Sammansättning | Sammansättningar',
  assemblyCreatorSuccessTitle: 'Förfrågan om att skapa monteringsjobb har skickats',
  assemblyCreatorSuccessSubtitle: 'Ändringar i bokens innehållsförteckning och hotpoints utförs omedelbart. Sökresultat visas när indexeringen har slutförts.',
  assemblyConfirmationTitle: 'Vill du slutföra Skapa sammansättning?',
  assemblyCreatorHelpText1: 'Med denna funktion kan du infoga en vald sida i böcker på alla platser där denna artikel {partName} finns, vilket automatiskt kopplar artikeln till den valda sidan via en hotpoint-länk. Om du vill kan du begränsa infogningen till bara vissa böcker.',
  assemblyCreatorHelpText2: 'Den valda sidan infogas en gång per bok efter den första förekomsten av {partName}.',
  assemblyStepOne: 'Steg 1: Välj sidan som ska infogas',
  assemblyStepTwo: 'Steg 2: Välj de böcker där du vill att sidan ska infogas',
  asset: 'Tillgång | Tillgångar',
  assetsNotFound: 'Ingen {type} hittades',
  assignBrowseFlow: 'Tilldela flöde',
  assignedOrganizations: 'Tilldelade organisationer',
  atLeastFiveError: 'Vänligen välj ett värde på 5 eller mer.',
  attachment: 'Bilaga | Bilagor',
  attachmentEditorDescription: 'Bifoga bilder, videor och dokument direkt till denna enhet. Observera att de kommer att finnas under fliken Relaterat.',
  attachmentView: 'Visa bilagor',
  attachRelatedPDFs: 'Bifoga PDF-filer med relaterade artikelsidor',
  attributions: 'Attributioner',
  august: 'Augusti',
  automaticZoom: 'Automatisk zoom',
  autoHotpoint: 'Auto Hotpoint',
  autoPopulate: 'Fyll i automatiskt',
  availability: 'Tillgänglighet',
  back: 'Tillbaka',
  backCover: 'Bakre pärm',
  backStitch: 'Bakre häftning',
  backToLibrary: 'Tillbaka till biblioteket',
  backToSearch: 'Tillbaka till sökning',
  badCredentials: 'Ogiltiga autentiseringsuppgifter',
  bahasaDescription: 'Beskrivning på indonesiska',
  bahasaName: 'Indonesiskt Namn',
  banner: 'Banner',
  before: 'Före',
  beginTypingToFindAnything: 'Börja skriva för att hitta något',
  betaAccessKeys: 'Nycklar för beta-tillgång',
  betaResources: 'Betaresurser',
  betaReleaseVideo: 'Betaversion av video',
  blobFinder: 'BLOB-sökare',
  body: 'Brödtext',
  bomDefaultSupplier: 'Standardleverantör av BOM',
  bomItem: 'BOM-Artikel',
  bom: 'BOM',
  bomExportSuccess: 'Exportering av BOM har slutförts',
  bomLayout: 'Layout för materiallista',
  bomListOptions: 'Val för Stycklista',
  bomListSettings: 'Inställningar för Stycklista',
  both: 'Båda',
  book: 'Bok | Böcker',
  bookInserts: 'Bokinlägg',
  bookLabel: 'Bok | Böcker',
  bookPrintingDisable: 'Inaktivera bokutskrift',
  booksAndPagesDisplay: 'Visning av böcker och sidor',
  boost: 'Boosta sökning',
  boostDescription: 'Lägg till ett värde större än 1,5 för att boosta sökrelevansen av denna tagg över artikelnummer, medieidentifierare och namn',
  boostRangeInvalid: 'Ökning måste vara mellan 0,5 och 100,0',
  border: 'Gräns',
  bottom: 'Nederst',
  branding: 'Varumärke',
  browse: 'Sök',
  browseComputer: 'Sök på dator',
  browseFlow: 'Sök i flöde | Sök i flöden',
  browseFlowAssigned: 'Tilldelat flöde',
  browseFlowCopyMessage: 'Ange ett unikt namn för det nya flödet.',
  browseFlowDeleted: 'Webbläsarflöde har raderats',
  browseFlowDeleteMessage: 'Om ett flöde tas bort tas inte bara flödet bort utan även alla dess underordnade flöden.',
  browseFlowEmptyState: 'Inga flöden har definierats. {new} för att komma igång.',
  browseFlowHeirarchy: 'Bläddra bland flödeshierarki',
  browseFlowNoExistingCriteria: 'Inga sökkriterier har konfigurerats för detta flöde.  Använd kontrollerna ovan för att välja sökkriterier.',
  browseFlowMoveToRoot: 'Släpp nod här för att skapa underordnad på första nivån',
  browseFlowNoAssignedOrgs: 'Inga tilldelade organisationer',
  browseFlowSaveFailed: 'Flödet kunde inte uppdateras',
  browseFlowSetDefaultSuccess: 'Standarduppsättning av flöden',
  browseFlowThumbnailDeleteFailTitle: 'Det gick inte att ta bort miniatyrbilden',
  browseFlowThumbnailDeleteSuccessTitle: 'Miniatyrbilden togs bort',
  browseFlowThumbnailUploadFailTitle: 'Det gick inte att ladda upp miniatyrbilden',
  browseFlowThumbnailUploadSuccessTitle: 'Miniatyrbilden har laddats upp',
  browseFlowTooManyOrgs: 'Du har för många organisationer för att kunna använda den nuvarande flödesredigeraren. Kontakta Documoto Support på {email} för hjälp med att redigera dina Documoto-flöden.',
  browserPartiallySupport: 'Du kan se estetiska skillnader och andra ändringar i denna webbläsare.',
  browseFlowSaved: 'Flödet har sparats',
  browserDeprecation: {
    title: 'Meddelande om utfasning av webbläsare',
    text: 'I linje med att Microsoft utfasar stöd för Internet Explorer (alla versioner) och relaterade säkerhetsproblem, kommer vi inte längre att stödja användaråtkomst till denna webbplats via Internet Explorer (alla versioner) efter 30 september 2021.'
  },
  buildHotpointLinks: 'Bygg hotpoint-länkar',
  buildHotpointLinksInfo: 'Denna åtgärd tar först bort alla befintliga hotpoint-länkar från bokens sidor och skapar sedan nya hotpoint-länkar mellan artiklar och eventuella matchande sammansättningssidor.',
  buildHotpointLinksTitle: 'Vill du bygga hotpoint-länkar för denna bok?',
  bulkIndexingLane: 'Volym för indexeringsbana',
  bulkInsert: 'Massinlägg',
  bulkExportConfirm: 'Vill du exportera {n} {unit}?',
  bulkExportLimit: 'Gränsen är {limit} böcker per exportjobb',
  bulkPageInsertJob: 'Infoga Sidor i grupp',
  business: 'Affär',
  cancel: 'Avbryt',
  canceled: 'Avbrutet',
  cancelAssemblyCreatorTitle: 'Kassera din skapade montering?',
  cancelAssemblyCreatorSubtitle: 'Din skapade montering bearbetas inte.',
  cannotExceed100: 'Får inte överstiga 100 %',
  cart: 'Kundvagn | Kundvagnar',
  cartBehaviorEnabled: 'Aktivera kvantitetsprompt för Lägg till i kundvagn',
  cartBehaviorDisabled: 'Inaktivera kvantitetsprompt för Lägg till i kundvagn',
  cartBehaviorLabel: 'Kvantitetsbeteende för Lägg till i kundvagn',
  cartColumn: 'Kundvagnskolumn | Kundvagnskolumner',
  cartColumn1: 'Första priskolumn för kundvagn',
  cartColumn2: 'Andra priskolumn för kundvagn',
  cartColumn3: 'Tredje priskolumn för kundvagn',
  cartColumnPartLocationContent1: 'Om denna funktion aktiveras innebär det följande:  ',
  cartColumnPartLocationContent2: 'En artikelrads unikhet i kundvagnen kommer även att baseras på var i innehållet det finns, om tillämpligt.',
  cartColumnPartLocationContent3: 'Denna inställning kan inte inaktiveras på organisationsnivå och kommer att gälla alla användare.',
  cartColumnPartLocationContent4: 'Denna inställning bör inte aktiveras om du har integrerat ”Skicka kundvagn” utan att först kontakta din Documoto Customer Success Manager.',
  cartColumnPartLocationOption: 'Aktivera data om artikelns plats i kundvagnen',
  cartColumnPartLocationTitle: 'Vill du aktivera data om artikelns plats i kundvagnen?',
  cartDisable: 'Inaktivera kundvagn',
  cartEmailFailed: 'Mejl om varukorg gick ej att skicka',
  cartEmailSent: 'Mejl om varukorg har skickats',
  cartEnable: 'Aktivera kundvagn',
  cartSaveEnable: 'Aktivera Spara kundvagn',
  cartErpMessage: 'Har skickats till ERP',
  cartImportError: 'Fel vid import av kundvagn',
  cartLoadedWithItemsRemoved: '{count} artikel eller artiklar raderade eftersom du saknar rättigheter att beställa dem nu',
  cartSharedAddEdit: 'Lägg till/redigera delade kundvagnar',
  cartUpdated: 'Kundvagn Uppdaterad',
  cartUpdatedAndRemovedCount: 'Kundvagnen har uppdaterats; {count} artiklar som inte kan beställas har tagits bort',
  category: 'Kategori | Kategorier',
  categoryCreated: 'Kategori har skapats',
  categoryUpdated: 'Kategori har uppdaterats',
  centimeter: 'Centimeter | Centimeter',
  changeNotification: 'Meddelande om förändring',
  changeScope: '{n} {type} påverkas',
  changeUserOrganization: 'Ändra organisation',
  chapter: 'Kapitel | Kapitel',
  chapterHeader: 'Kapitelrubrik',
  chaptersOnly: 'Endast kapitel',
  chineseDescription: 'Beskrivning på kinesiska',
  chineseName: 'Kinesiskt Namn',
  circle: 'Cirkel',
  city: 'Ort',
  classicAdminMessage: 'Detta tar dig tillbaka till Flex för användning av adminmoduler.',
  classicAdmins: 'Klassiska admin',
  classicExporter: 'Klassisk exportör',
  classicExporterMessage: 'Äldre versionens Flash-baserade modul för innehållsexport',
  classicOrganizationAdmin: 'Klassisk organisationsadmin',
  classicOrganizationAdminMessage: 'Äldre versionens Flash-baserade modul för användaradministration och hantering av organisationer och användare',
  classicPublisher: 'Klassisk publicerare',
  classicPublisherMessage: 'Äldre versionens Flash-baserade publiceringsmodul för innehållsskapande och utskriftsinställningar',
  classicReporting: 'Klassisk rapportering',
  classicReportingMessage: 'Äldre versionens Flash-baserade rapporteringsmodul',
  classicTenantAdmin: 'Klassisk innehavaradmin',
  classicTenantAdminMessage: 'Äldre versionens Flash-baserade administrationsmodul för innehavarinställningar',
  className: 'Namn på klass',
  clear: 'Rensa',
  clearItemNumber: 'Rensa artikelnummer',
  clearItemNumberTitle: 'Vill du rensa artikelnumret?',
  clearItemNumberMessage: 'Följande data raderas om artikelnumret tas bort:',
  clearFilters: 'Rensa filter',
  clearHotpoints: 'Rensa hotpoints',
  clearSearch: 'Rensa Sökfältet',
  clickHereToUpload: 'Släpp fil eller klicka för att ladda upp en åsidosättning.',
  clickMagnifyingGlass: 'Klicka på förstoringsglaset för att gå direkt till den avancerade sökningen.',
  clickOkToContinue: 'Klicka på "OK" för att fortsätta.',
  clickToDownload: 'Klicka för att ladda ned',
  clickToEnlarge: 'Klicka för att förstora',
  clickToOpen: 'Klicka för att öppna',
  clipboard: 'Urklipp',
  clipboardAccessError: 'Vänligen justera inställningarna i din webbläsare för att tillåta tillgång till Urklipp',
  close: 'Stäng',
  closed: 'Stängd',
  collapseAll: 'Dölj allt',
  color: 'Färg | Färger',
  column: 'Kolumn | Kolumner',
  columnName: 'Namn på kolumn',
  columnItemType: {
    customText: 'Anpassa text',
    documentTitle: 'Dokumenttitel',
    horizontalLine: 'Horisontell linje',
    pageName: 'Sidnamn',
    pageNumber: 'Sidnummer',
    printDate: 'Utskriftsdatum',
    tenantName: 'Klientnamn',
    userName: 'Användarnamn'
  },
  combinedPage: 'Kombinerad sida',
  combinedPageMessage: 'Diagram överst, artikellista nederst',
  comingSoon: 'Kommer snart',
  commaDelimited: 'Kommaavgränsad',
  comment: 'Kommentar | Kommentarer',
  commentAdded: 'Kommentar tillagd',
  commentDeleted: 'Kommentar borttagen',
  commentEdit: 'Lägg till/redigera kommentarer',
  commentEditAll: 'Redigera alla kommentarer',
  commentUpdated: 'Kommentar uppdaterad',
  commentView: 'Visa kommentarer',
  commerceSystem: 'Handelssystem',
  completed: 'Slutfört',
  confirm: 'Bekräfta',
  confirmBrowseFlowApplyToChildren: 'Vill du tillämpa detta flöde på underordnade organisationer?',
  confirmBrowseFlowOverrideToChildren: 'Detta tar bort alla åsidosättningar av flöden som ställts in för de underordnade organisationerna.',
  confirmPrintSettingsApplyToChildren: 'Vill du tillämpa denna utskriftsinställning på underordnade organisationer?',
  confirmPrintSettingsOverrideToChildren: 'Detta tar bort alla åsidosättningar av utskriftsinställningar som ställts in för de underordnade organisationerna.',
  contains: 'Innehåller',
  containsHeaderRow: 'Innehåller Rad med Rubrik',
  content: 'Innehåll | Innehåll',
  contentAccessControls: 'Åtkomstkontroller för {content}',
  contentEntity: 'Innehållsenhet | Innehållsenheter',
  contentNotFound: 'Innehåll hittades inte',
  contentUpdatedQueueJob: 'Kö för Uppdaterat innehåll',
  contourShape: 'KonturForm',
  convertEntity: 'Konvertera {entity}',
  convertToEntity: 'Konvertera till {entity}',
  copyAccessControls: 'Kopiera åtkomstkontroller',
  copyAccessControlsWarning: 'Detta kopierar alla tilldelade åtkomstkontroller från en vald organisation till denna aktuella organisation',
  copy: 'Kopiera',
  copyAndRetain: 'Kopiera & Spara för Användning vid Integrering',
  copyAndRetainWidget: 'Kopiera och behåll, det här är den enda gången som värdet för widget-tillstånd visas för dig i applikationen',
  copyAtoB: 'Kopiera {a} till {b}',
  copyContent: 'Kopiera {type}? {name}',
  copyingItem: 'Kopierar {item}',
  copyItem: 'Kopiera {item}',
  copyItemQuestion: 'Kopiera {item}?',
  copyTo: 'Kopiera till {target}',
  copyMeMessage: 'Skicka en kopia av det här meddelandet till mig',
  copyrightMessage: '© 2010-{year} Documoto Inc. Alla rättigheter förbehållna.',
  count: 'Antal',
  country: 'Land | Länder',
  coverPages: 'Omslagssidor',
  coverAmpStitchPages: 'Täck och sammanfoga sidor',
  createAssembly: 'Skapa sammansättning',
  created: 'Skapad',
  createdBy: 'Skapad av {name}',
  createdDate: 'Skapad den',
  createdOn: 'Skapad den',
  currentPassword: 'Nuvarande lösenord',
  custom: 'Specialtillverkad',
  czechDescription: 'Beskrivning på tjeckiska',
  czechName: 'Tjeckiskt Namn',
  dashboard: 'Instrumentpanel | Instrumentpaneler',
  dashboardLibrary: 'Kontrollpanelens Bibliotek',
  dataInsights: 'Data-Insikter',
  dataInsightsInfoMessage: 'Center för Rapportering och Kontrollpanelen för insikter om innehåll, användare och data.',
  dataMapping: 'Kartläggning av data/stickprov (upp till tio rader)',
  dataOptions: 'Val för data',
  date: 'Datum',
  dateModified: 'Datum ändrat',
  debugPageBuilderPLZ: 'Felsök Page Builder PLZ',
  december: 'December',
  decreaseHotpoint: 'Minska Hotpoint',
  default: 'Standardvärde',
  defaultAddress: 'Standardadress',
  defaultBrowseFlow: 'Standardflöde',
  defaultCurrencyCode: 'Standardvalutakod',
  defaultCurrencySymbolOn: 'Standard valutasymbol på',
  defaultEmailRecipient: 'Standardmottagare av e-post | Standardmottagare av e-post',
  defaultImage: 'Standardbild | Standardbilder',
  defaultImagesManager: 'Standardbildhanterare',
  defaultItemImage: '{item} standardbild',
  defaultPartsListLayout: 'Använd standardlayout för artikellista',
  defaultPartsListLayoutHelp: 'Standard artikellayout till vänster eller under illustrationen',
  defaultSearchMode: 'Standardsökläge',
  defaultSearchModeHelp: 'Ställ in global sökmetod till \'innehåller\' eller \'exakt\'',
  defaultSortMode: 'Standardsorteringsläge',
  defaultSupplier: 'Standardleverantör',
  defaultTocStateOpen: 'Innehållsförteckning öppnas som standard',
  defaultTocStateOpenHelp: 'Anger om innehållsförteckningen ska öppnas automatiskt när en bok visas',
  defaultUom: 'Standardenhet för mått',
  defaultValue: 'Standardvärde',
  defaults: 'Standardvärden',
  defaultsAndCapabilities: 'Standardvärden och funktioner',
  delete: 'Ta bort',
  deleteAll: 'Ärv från överordnad',
  deleteAnyPartnerApiKeys: 'Radera alla API-nycklar från partners som tillhandahölls för SmartEquip.',
  deleteContent: 'Vill du ta bort {type}? {name}',
deletePart: {
    infoOne: 'Delar kommer att tas bort omedelbart i administration av bibliotek och innehåll. Den borttagna delen kommer att vara sökbar tills en ny registrering har skett inom ett par minuter.',
    deletingPartNumber: '{partNumber} kommer att raderas.',
    affectedCarts: 'Delen kommer att raderas permanent från alla kundvagnar som innehåller denna del.',
    infoTwo: 'Denna åtgärd kan inte ångras utan hjälp från Documoto Support. Efter 90 dagar kommer den borttagna uppgiften och allt som är kopplat till den att raderas permanent.'
  },
deletePartInUse: {
    title: 'Denna del tillhör en sida eller sidor och kan inte raderas.',
    body: 'Documoto tillåter i nuläget endast radering av delar som inte tillhör sidor. Vänligen avlägsna denna del från alla publicerade sidor eller utkast för att fortsätta med radering.',
    submitLabel: 'Gå till sida där denna används'
  },
  deleteRelated: 'Är du säker på att du vill ta bort {count} {type}?',
  deletedItem: '{item} raderad',
  deleteFilesMessage: 'Vill du ta bort {count} fil(er)?',
  deleteHotpointLinksTitle: 'Är du säker på att du vill radera länk(ar) till höjdpunkter?',
  deleteMessage: 'Vill du ta bort?',
  deleteSelectedCriteria: 'Ta bort valda kriterier',
  deleteTagsTitle: 'Är du säker på att du vill radera tagg(ar)?',
  deleteTaxonMessage: 'Detta tar bort denna taxonomi permanent tillsammans med alla underordnade objekt. Vill du fortsätta?',
  deleteTaxonomyMessage: 'Detta tar bort alla taxonomier permanent för denna organisation eller innehavare. Vill du fortsätta?',
  deleteTenantSupplier: 'Är du säker på att du vill ta bort denna leverantör för hyresgästen?',
  demo: 'Demo',
  description: 'Beskrivning',
  deselectAll: 'Avmarkera alla',
  deselectCriteria: 'Avmarkera kriterier',
  detail: 'Detalj | Detaljer',
  diagonal: 'Diagonal',
  diameter: 'Diameter',
  disable: 'Inaktivera',
  disableAll: 'Inaktivera alla',
  disableDocumotoTenant: 'Inaktivera Documoto Tenant',
  disableFwdReindex: 'Inaktivera vidarebefordran av återindexerad media för DSW',
  disableLibraryBannerImage: 'Inaktivera bild för bibliotekets annonsruta',
  disableSmartEquipModalTitle: 'Är du säker på att du vill inaktivera denna licens?',
  disableTEK: 'Inaktivera Inloggning Direkt från URL',
  disabled: 'Inaktiverat',
  discard: 'Ignorera',
  discardChanges: 'Ignorera ändringar',
  discardQ: 'Ignorera ändringar?',
  discounted: 'Rabatterat',
  discountedPrice: 'Rabatterat pris',
  discountedTotal: 'Summa efter rabatt',
  discountPrice: 'Rabatterat Pris',
  displayInOrdersList: 'Visa i Lista med Beställningar',
  displayOrder: 'Visningsordning',
  displayOrderDescription: 'Ställer in visningsordning för rullgardinsmenyn Filter för innehållstyptaggar',
  document: 'Dokument | Dokument',
  documentLabel: 'Dokument | Dokument',
  documentSettings: 'Inställningar för dokument',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Licenspaket för Documoto',
  domain: 'Domän',
  domainEmptyMessage: 'Tillåten domän får inte vara tom',
  domainInvalidMessage: 'Ett giltigt domännamn krävs',
  download: 'Ladda ned',
  downloadBrowser: 'Ladda ned en av dessa utmärkta webbläsare.',
  downloadFailed: 'Fel vid nedladdning {name}',
  downloadStarted: 'Nedladdning pågår för {name}',
  draft: 'Utkast | Utkast',
  draftBomDeleteTitle: 'Är du säker på att du vill radera denna denna del av sidan?',
  draftBomMultipleDeleteTitle: 'Är du säker på att du vill radera dessa delar av sidan?',
  draftBomDropError: 'Det går inte att nästla delar som är djupare än 5 nivåer',
  draftBomEmptyField: 'Alla fält i Listan med Delar måste fyllas i',
  draftBomQuantityError: 'Kvantitet måste vara ett nummer',
  draftBomError: 'Fel vid manipulering av Materialförteckningens utkast',
  draftPage: 'Utkast för Sida',
  draftPageDelete: 'Radera Utkast',
  draftPageDeleteMessage: 'Utkast för Sida har raderats.',
  draftPageDeleteWarning: 'Radering av Utkast för Sida kommer att avbryta pågående uppdateringar.',
  draftPageLastUpdated: 'Utkast - Senast Uppdaterat {date} av {username}',
  draftPageLoadError: 'Sidan med utkast kunde inte laddas.',
  draftPageProcessingPlz: 'Sidan laddas från PLZ-fil',
  draftPagePublishInProgress: 'Jobb för Publicering av Sidan pågår',
  draftPageSaveSuccess: 'Utkast för Sida har sparats.',
  draftPageSaveUnsuccessful: 'Misslyckades att spara Utkast för Sida.',
  draftSheetImageHotpointsFailure: 'Kunde inte generera {algorithm} hotpoints för bild av utkast.',
  draftValidationMessage: {
    item: 'VARNING: Del nummer {partNumber} i materialförteckningen har inget angivet artikelnummer',
    empty: 'Inga Fel/Varningar vid Bekräftelse',
    hotpointOrHotpointLink: 'Artikel på Stycklista {item} saknar hotpoints/hotpoint-länkar',
    quantity: 'Artikel på Stycklista {item} saknar angiven kvantitet',
    tag: 'Artikel på Stycklista {item} etikett {tagName} saknar värde för etikett',
    translationDescription: 'Artikel på Stycklista {item} saknar {lang} beskrivning av översättning',
    uom: 'Artikel på Stycklista {item} saknar angiven måttenhet',
    supersede: 'Delen {partNumber} har ersatt delen {supersededPartNumber}.'
  },
  drawAutohotpointRegion: 'Rita {shape} i storlek',
  drawMinimumArea: 'Rita minsta område',
  dropFileMessage: 'Släpp fil här eller klicka för att ladda upp.',
  duplicate: 'Duplicera',
  duplicateSaveError: 'Dubbel artikel. Kan inte spara.',
  dutchDescription: 'Beskrivning på holländska',
  dutchName: 'Holländskt Namn',
  dynamic: 'Dynamisk',
  dynamicNaming: 'Dynamisk namngivning',
  ecommerce: 'e-handel',
  ecommerceEmailFormatCreated: 'ECommerce mejlformat har skapats',
  ecommerceEmailFormatSaved: 'ECommerce mejlformat har sparats',
  ecommerceLogo: 'Logotyp för e-handel',
  ecommerceUseExtOrder: 'Använd externt beställningsnummer för e-handel',
  edit: 'Redigera',
  editItem: 'Redigera {item}',
  email: 'E-postadress',
  emailFormat: 'Mejlformat',
  emailFormatTemplate: 'Mall för mejlformat | Mallar för mejlformat ',
  emailList: 'Mejlinglista',
  emailMessage: 'En e-postadress krävs',
  emailRecipient: 'E-postmottagare | E-postmottagare',
  emailRecipientMessage: 'Dessa e-postadresser får ett e-postmeddelande när beställningsstatus ändras till',
  emptyMessage: {
    orderSuggestions: 'Det ser inte ut att finnas några beställningsförslag för denna artikel.'
  },
  emptyBomState: 'Inga BOM-Artiklar har angivits för sidan. Klicka på +{add} för att starta',
  enable: 'Aktivera',
  enable3d: 'Aktivera 3D',
  enableAll: 'Aktivera alla',
  enableAPIKeyAdmin: 'Aktivera administratör för API-Nycklar',
  enabled: 'Aktiverad',
  enableCommerce: 'Aktivera handel',
  enableEcommerceOrderManagement: 'Aktivera beställningshantering',
  enableExporter: 'Aktivera exportör',
  enableExportToLinkOne: 'Aktivera export till LinkOne',
  enableExportToSmartEquip: 'Aktivera export till SmartEquip',
  enablePDFDownloadWarning: 'Aktivera varning för nedladdning av stor PDF',
  largePDFDownloadWarning: 'På grund av PDF-filens storlek så kan det ta längre tid än vanligt att ladda och visa dokumentet i applikationen – vänligen vänta medan filen laddas ned för att visas.',
  enableIframeSupport: 'Aktivera stöd för iFrame',
  enableLibraryBetaFeatures: 'Aktivera bibliotekets betafunktioner',
  enablePerformanceLogging: 'Aktivera prestandaloggning',
  enablePLZToDraft: 'Aktivera PLZ-process för utkast',
  enableQuickAddToCart: 'Aktivera Lägg till snabbt i kundvagnen',
  enableQuickAddToCartHelpContext: 'Att ställa in detta till \’Aktivera\’ kommer att aktivera funktionen Lägg till snabbt i kundvagnen ovanför innehållsvyn.',
  enableRocketUi: 'Aktivera Rocket-användarupplevelse',
  enableSmartEquipApiIntegration: 'Aktivera integration med SmartEquip API',
  enableWidgetLogo: 'Aktivera logotyp för Documotos widget',
  enableWidgets: 'Aktivera widgetar',
  encryptedKey: 'Krypterad nyckel',
  endDate: 'Slutdatum',
  endDateBad: 'Startdatum måste vara innan eller samtidigt som slutdatum.',
  enforceNumericSize: 'Genomdriv numerisk storlek',
  englishDescription: 'Beskrivning på engelska',
  englishName: 'Engelskt Namn',
  enterprise: 'Företag',
  enterReportParameters: 'Ange RapportParametrar',
  enterUniqueNameForNewItem: 'Ange ett unikt namn för den nya {item}',
  enterValidNumber: 'Ange ett giltigt nummer.',
  enterValidEmail: 'Ange en giltig e-postadress',
  entityType: 'Enhetstyp | Enhetstyper',
  erp: 'ERP',
  erpEndpointConfig: 'ERP-konfigurering för slutpunkt',
  erpPartInfo: 'Varaktighet i minuter för ERP-delens informationscache',
  erpSystem: 'ERP-system',
  erpSystems: 'ERP-system',
  erpSystemSaved: 'ERP-system har sparats',
  error: 'Fel',
  errorDeletePageMessage: 'Ett fel inträffade när sidan raderades.',
  errorInputParsing: 'Problem vid analys av ingående fil.',
  errorPageMessage: 'Ett fel har uppstått.',
  errorPageTitle: 'Något gick snett här ...',
  errorType: {
    error: 'Fel',
    warning: 'Varning'
  },
  essential: 'Väsentlig | Väsentligheter',
  eta: 'Beräknad leveranstid',
  etlJob: 'ETL',
  exact: 'Exakt',
  excelFile: 'Excel-Fil',
  expandAll: 'Visa allt',
  export: 'Exportera',
  exportSearch: 'Exportera sökning',
  exportAsCsv: 'Exportera som CSV (obligatoriskt format för import)',
  exportAsCSVDropdown: 'Exportera som CSV',
  exportAsHtml: 'Exportera som HTML',
  exportAsPdf: 'Exportera som PDF',
  exportAsPlz: 'Exportera som PLZ',
  exportAsXlsx: 'Exportera som XLSX',
  exportBasicPartInfo: 'Exportera grundläggande information om artikeln',
  exportCartToXlsxDisabled: 'Inaktivera Exportera kundvagn till XLSX',
  exportCartToXlsxEnabled: 'Aktivera Exportera kundvagn till XLSX',
  exportDisplayedCartColumns: 'Exportera visade kundvagnskolumner',
  exportJob: 'Exportera jobb',
  exportLane: 'Exportbana',
  exportMessage: 'Exportjobb har skickats in.',
  exporter: 'Exportör',
  exporterMessage: 'Möjliggör export av innehåll',
  exportParts: 'Exportera Delar',
  external: 'Extern | Externa',
  externalUrl: 'Extern URL',
  faceted: 'Fasetterad',
  facetedTag: 'Filtervärden bör vara fasetterade',
  facetedTagTooMany: 'Taggen överskrider gränsen på 1 000 unika taggvärden',
  failedAction: 'Det gick inte att {action} {content}.',
  failedProcessContentSql: 'Bearbeta Innehåll misslyckades på grund av otillåten text. Vänligen ladda upp innehåll som stöds igen.',
  failedToCopy: 'Det gick inte att kopiera {content}.',
  failedToDelete: 'Det gick inte att ta bort {content}.',
  failedToLoad: 'Det gick inte att läsa in {content}.',
  failedToSave: 'Det gick inte att spara {content}.',
  failedToSubmitItem: 'Misslyckades att skicka {item}',
  failedToUpdate: 'Det gick inte att uppdatera {content}.',
  false: 'Falsk',
  faq: 'Vanliga frågor',
  favorites: 'Favoriter',
  favoritesLists: 'Lista med favoriter',
  february: 'Februari',
  feedbackForm: 'Skicka feedback',
  feedbackFormMessage: 'Ge synpunkter på funktioner du skulle vilja ha',
  fieldDefinition: 'Definiering av Fält',
  field: 'Fält | Fält',
  fieldName: 'Fältets namn',
  file: 'Fil | Filer',
  filename: 'Filnamn',
  filesSubmitted: 'Fil(er) skickade för bearbetning',
  fileUpload: 'Filuppladdning',
  fill: 'Fyll',
  filter: 'Filter | Filter',
  filterOrgNameOnly: 'Filter: Endast Namn på Org',
  filterOrgParentNames: 'Filter: Namn på Org & Överordnad Org',
  filterable: 'Kan filtreras',
  filterableTag: 'Filtrerbar tagg',
  filterBy: 'Filtrera efter',
  filterBoundsError: 'Filtret måste innehålla 3 eller fler tecken',
  finnishDescription: 'Beskrivning på finska',
  finnishName: 'Finskt Namn',
  firstName: 'Förnamn',
  firstNameEmpty: 'Förnamn kan inte vara tomt.',
  fixedHotpointSize: 'Fast hotpoint-storlek',
  font: 'Teckensnitt | Teckensnitt',
  footer: 'Sidfot',
  forgotPassword: 'Glömt lösenordet',
  forgotPasswordMessage: 'Ett mejl med instruktioner om hur du återställer ditt lösenord skickas till det angivna användarnamnet.',
  forgotPasswordSuccess: 'Om det angivna användarnamnet är giltigt så skickas ett mejl med instruktioner om hur du återställer ditt lösenord till den sparade adressen.',
  form: 'Formulär | Formulär',
  format: 'Format',
  formattingOptions: 'Formateringsalternativ',
  formCount: 'Antal Formulär',
  formName: 'Formulärets namn',
  formType: 'Typ av formulär',
  freezeImage: 'Frys bild',
  frenchDescription: 'Beskrivning på franska',
  frenchName: 'Franskt Namn',
  fri: 'Fre',
  from: 'Från',
  fromDate: 'Från datum',
  frontCover: 'Främre pärm',
  frontStitch: 'Främre häftning',
  fullScreen: 'Helskärm',
  gdprFaqs: 'Vanliga frågor och svar om GDPR',
  gdprMessage: 'Genom att logga in på den här sektionen av vår webbplats så förstår du att vi använder nödvändiga kakor. Dessa kakor är väsentliga för att du ska kunna navigera bland våra tjänster. Utan dessa kakor kan vi inte tillhandahålla de tjänster du har efterfrågat. För ytterligare information, se vår {policy}.',
  general: 'Allmänt',
  generalSearch: 'Allmän sökning',
  generic: 'Generiskt',
  germanDescription: 'Beskrivning på tyska',
  germanName: 'Tyskt Namn',
  gotIt: 'Jag förstår',
  goTo: 'Gå till',
  goToHome: 'Gå till Start',
  goToJobManager: 'Gå till Jobbhanteraren',
  goToSearch: 'Gå till Sök',
  hashKey: 'Hash-nyckel',
  headerAmpFooter: 'Sidhuvud och sidfot',
  header: 'Rubrik',
  help: 'Hjälp',
  helpCenter: 'Hjälpcenter',
  highlightDescription: 'Markera artiklar i artikellistan med denna tagg',
  history: 'Historik',
  home: 'Hem',
  horizontal: 'Vågrät',
  host: 'Värd',
  hotpoint: 'Hotpoint | Hotpoints',
  hotpointCalloutBehavior: 'Beteende för Hotpoint Callout',
  hotpointLinksWillBeCreated: 'Hotpoint-länkar skapas i {count} {content}',
  hotpointLink: 'Länk till Höjdpunkt | Länkar till Höjdpunkter',
  hotpointsGenerated: '{count} hotpoints hittades på bilden.',
  hotpointShift: 'Hotpoint (Skift H)',
  hourlyDWSRequestLimit: 'Gräns per timme för DWS-begäran',
  hourlyRESTRequestLimit: 'Gräns per timme för REST-begäran',
  htmlUiUserSwitchEnabled: 'Växling av användare aktiverad för HTML UI',
  hungarianDescription: 'Beskrivning på ungerska',
  hungarianName: 'Ungerskt Namn',
  icon: 'Ikon',
  id: 'Id',
  identifier: 'Identifierare',
  ignore: 'IGNORERA',
  ignoreAccessControls: 'Ignorera åtkomstkontroller',
  ignoreMatchingTranslations: 'Ignorera översättningar med samma platser vid import',
  illustration: 'Illustration | Illustrationer',
  image: 'Bild | Bilder',
  imageDeleteMessage: 'Är du säker på att du vill radera Bilden på denna Sida?',
  imageUploadFailure: 'Det gick inte att Ladda upp bilden',
  imageSaveFail: 'Det gick inte att Spara bilden',
  imageOptimizer: 'Bildoptimerare',
  imageSizeExceededTitle: 'Maximal Storlek för Bild har Överskridits',
  imageSizeExceededMessage: 'Den största storleken på bildfiler i Documoto är {size} megabyte. Bilden som du försöker ladda upp har överskridit maximum och kan inte laddas. Vänligen ändra storlek på bilden som ska läggas till på denna sida.',
  imageDimensionsExceededMessage: 'Den största storleken för bilder i Documoto är 65,5 megapixlar (8 191 X 8 191 pixlar).  Bilden som du försöker ladda upp har överskridit maximum och kan inte laddas. Klicka på "Ändra storlek" för att låta Documoto ändra bildens storlek och infoga den, eller "Avbryt" för att redigera bilden och infoga den manuellt',
  import: 'Importera',
  importBOM: 'Importera stycklista',
  importBOMError: 'Det gick inte att bearbeta provdata, kontrollera ingångskällan igen',
  importParts: 'Importera Delar',
  inCart: 'I kundvagnen',
  inCartDescription: 'Visa taggar i kundvagnen',
  includePagePartDetails: 'Inkludera detaljer om delnivå på sidan',
  includePartDetails: 'Inkludera detaljer om delnivå',
  indexQueueJob: 'Indexeringskö',
  inPartsList: 'I artikellistan',
  inPartsListDescription: 'Visa taggar i artikellistan',
  inputSource: 'Ingångskälla',
  inch: 'Tum | Tum',
  includeComments: 'Inkludera kommentarer',
  includeCommentsAuthorAndDate: 'Inkludera kommentarens författare och datum',
  includeHeaderRow: 'Inkludera rad med rubrik',
  includeImages: 'Inkludera bilder',
  includePagesIndex: 'Inkludera sidindex',
  includePartsIndex: 'Inkludera artikelindex',
  includePartsList: 'Inkludera artikellista',
  includeSubChapters: 'Inkludera underkapitel',
  indexLane: 'Indexbana',
  useSequentialPageNumbering: 'Använd sekventiell sidnumrering',
  hideBookInSearchAndRecent: 'Dölj böcker i sökresultat och nyligen tillagda',
  hideDuplicatePages: 'Dölj dubblettsidor',
  hidePageInSearchAndRecent: 'Dölj sidor i sökresultat och nyligen tillagda',
  hidePartInSearchAndRecent: 'Dölj artiklar i sökresultat och nyligen tillagda',
  homePageSettings: 'Inställningar för startsida',
  hotpointZoomBehavior: 'Beteende för Hotpoint Zoom',
  includeNewParts: 'Inkludera endast "nya" delar (inte i Documoto)',
  includeWatermark: 'Inkludera vattenstämpel?',
  increaseHotpoint: 'Öka Hotpoint',
  indexed: 'Indexerad',
  indexingConfiguration: 'Konfigurering av indexering',
  indexIncludeTimestamp: 'Index inkluderar tidsstämpel',
  info: 'Info',
  information: 'Information',
  inherit: 'Ärv',
  inheritedParent: 'Ärvd från överordnad.',
  inputSpecification: 'Ange ingång',
  inputType: 'Inmatningstyp',
  inputTypeListboxWarning: 'Sorteringskonfigurationer visas när man sparar',
  integration: 'Integration | Integrationer',
  integrationsAddPartToErpCart: '{action} Lägg till artikel i ERP-kundvagn',
  integrationsAddToShoppingCartPricingFromErp: '{action} Lägg till i kundvagnens prissättning från ERP',
  integrationsDisableErp: 'Inaktivera ERP',
  integrationsDoErpForQuotes: 'Använd ERP för offerter',
  integrationsEnableErp: 'Aktivera ERP',
  integrationsEnableFetchPartInfoFromErp: 'Aktivera Hämta artikelinfo från ERP',
  integrationsEnableScopeErp: 'Aktivera {scope} ERP',
  integrationsEnableSendShoppingCartToErp: 'Aktivera Skicka kundvagn till ERP',
  integrationsEnableSyncPoAddressWithErp: 'Aktivera Synkronisera postadress med ERP',
  integrationsErpAvailabilityMappedFrom: 'ERP-tillgänglighet kartlagd från',
  integrationsErpCurrencyCodeMappedFrom: 'ERP-valutakod kartlagd från',
  integrationsErpDiscountedPriceMappedFrom: 'ERP-pris efter rabatt kartlagt från',
  integrationsErpEtaMappedFrom: 'ERP beräknad leveranstid kartlagd från',
  integrationsErpMappings: 'ERP-kartläggning',
  integrationsErpRetailPriceMappedFrom: 'ERP-detaljhandelspris kartlagt från',
  integrationsErpWholesalePriceMappedFrom: 'ERP-grossistpris kartlagt från',
  integrationsInvokeGetPartInfoFromClient: 'Anropa HämtaArtikelInfo från klient',
  integrationsParseAddressForType: 'Tolka adress för typ',
  integrationsShoppingCartAndPartsTitle: 'Kundvagn och artiklar',
  integrationsShoppingCartAvailability: '{action} Kundvagnstillgänglighet',
  integrationsShoppingCartEtaFromErp: '{action} Beräknad leveranstid för kundvagnen från ERP',
  internalApiKey: 'Intern API-Nyckel',
  internalDescription: 'Intern beskrivning',
  internalName: 'Internt namn',
  internalNameOptional: 'Din identifierare för taggen',
  internalNameRequired: 'Ett namn krävs',
  invalidDecimals: 'Endast två decimaler är tillåtna',
  invalidField: '{field} är ogiltigt',
  invalidFileType: 'Filtypen stöds inte. Se en fullständig lista över filnamnstillägg som stöds i Documoto Support Center.',
  isSearchable: 'Är sökbar',
  insertDataField: 'Infoga datafält',
  isSystem: 'Är system',
  italianDescription: 'Beskrivning på italienska',
  italianName: 'Italienskt Namn',
  item: '#',
  itemAlt: 'Artikel',
  itemCopied: '{item} kopierat',
  itemDeleted: '{item} borttaget',
  itemInProgress: '{item} pågår',
  itemInformation: 'Information om {item}',
  itemMustBeLteItem: '{itemA} måste vara lika med eller innan {itemB}.',
  itemMustBeUnique: '{item} måste vara unik',
  itemMustBeUniqueAndNotEmpty: '{item} måste vara unik och är obligatorisk',
  itemName: '{item} Namn',
  itemNotShown: '',
  itemSaved: '{item} har sparats',
  itemSpecification: 'Artikelspecifikation',
  showChapterTOCForHiddenPages: 'Visa innehållsförteckning över kapitel för dolda sidor',
  january: 'Januari',
  japaneseDescription: 'Beskrivning på japanska',
  japaneseName: 'Japanskt Namn',
  job: 'Jobb | Jobb',
  jobManager: 'Jobbhanterare',
  jobManagerMessage: 'Exportera, publicera och skriv ut/ladda ned jobb',
  july: 'Juli',
  june: 'Juni',
  koreanDescription: 'Beskrivning på koreanska',
  koreanName: 'Koreanskt Namn',
  label: 'Etikett | Etiketter',
  labelKey: 'Etikettnyckel',
  landscape: 'Liggande',
  laneConfiguration: 'Konfigurering av bana',
  language: 'Språk',
  lastModifiedDate: 'Senast ändrad den',
  lastGenerated: 'Senast Återskapad',
  lastName: 'Efternamn',
  lastNameEmpty: 'Efternamnet kan inte vara tomt',
  lastUpdatedOn: 'Senast uppdaterat den',
  layout: 'Layout',
  layoutOptions: 'Layoutalternativ',
  ledger: 'Register',
  left: 'Vänster',
  legal: 'Juridisk information',
  letter: 'Brev',
  library: 'Bibliotek',
  libraryAdmin: 'Biblioteksadmin',
  libraryBannerLogo: 'Webbannons för biblioteks logotyp',
  librarySettings: 'Biblioteksinställningar',
  librarySettingsDescription: 'Dessa inställningar styr olika biblioteksdelar och alternativ. Var försiktig när du utför ändringar. Kontakta Documoto-tjänster för mer information.',
  licenseConfiguration: 'Konfigurering av licenser',
  line: 'Rad',
  lineTool: 'Linjeverktyg',
  link: 'Länk | Länkar',
  listBox: 'Listruta',
  listBoxValue: 'Värde för Listruta | Värden för Listrutor',
  listValue: 'Värde för Lista | Värden för Listor',
  load: 'Ladda',
  loadMore: 'Läs in fler',
  loading: 'Läser in',
  locale: 'Nationella inställningar',
  login: 'Inloggning',
  loginBackground: 'Inloggningsbakgrund',
  loginGDPRMessageBeginning: 'Denna webbplats använder kakor. Genom att fortsätta använda webbplatsen samtycker du till användningen av kakor. För ytterligare information, se vår ',
  loginGDPRMessageEnd: 'och vår',
  loginSSO: 'Logga in med enkel inloggning',
  logiReportName: 'Namn på Rapport för Logi',
  logiReportToggle: 'Rapport för Logi?',
  logo: 'Logotyp | Logotyper',
  logout: 'Logga ut',
  lookupLoadParts: 'Sök/Ladda Delar från Documoto vid Import',
  lowerBound: 'Nedre gräns',
  makePagesDivisibleBy: 'Gör sidorna delbara med:',
  mainInfo: 'Huvudinfo',
  mainLogo: 'Huvudlogotyp',
  manageItem: 'Hantera {item}',
  management: 'Hantering',
  march: 'Mars',
  margin: 'Marginal | Marginaler',
  markdown: 'Prissänkning',
  may: 'Maj',
  media: 'Medium | Medier',
  mediaCategory: 'Mediekategori | Mediekategorier',
  mediaCategoryEditor: 'Redigerare för mediekategori',
  mediaCategoryName: 'Namn på mediekategori',
  mediaCategoryNameMissing: 'Alla mediekategorier måste ha ett namn',
  mediaBookDeleteMessage: 'Om du raderar detta medium tas åtkomst till detta innehåll bort i både Biblioteket och Innehållsadmin och kommer inte längre vara tillgängligt för användare eller innehållsadministratörer. Artiklar och sidor i denna bok som inte finns i andra böcker kommer inte heller vara tillgängliga i Biblioteket.',
  mediaDeleteMessage: 'Om du tar bort denna mall tas den bort från Innehållsadmin och kommer inte längre vara tillgänglig för kopiering till nytt medium.',
  mediaDeleteSuccessText: 'Den tas bort från listor när indexering har slutförts.',
  mediaDescription: 'Beskrivning av media',
  mediaIdentifier: 'Identifierare för media',
  mediaName: 'Namn på media',
  mediaPrintSettingsSaveSuccess: 'Utskriftsinställningar för medier har sparats',
  mediaWhereUsed: 'Medier där detta används',
  menu: 'Meny',
  merge: 'Slå samman',
  mergeOrOverrideMessage: 'Vill du skriva över eller slå samman de här objekten?',
  mergeWarning: 'Import av data kommer att uppdatera befintliga delar och lägga till nya poster',
  message: 'Meddelande',
  messageLevel: 'Meddelandenivå',
  microsite: 'Mikrosajt | Mikrosajter',
  micrositeAdmin: {
    cardSubtitle: 'Filen med det komprimerade innehållet och HTML-information',
    cardTitle: 'Sidans innehåll',
    errorRequiredFile: 'Kräver en zip-fil med sidans innehåll',
    fieldFile: 'Zip-fil med sidans innehåll',
    fieldPoint: 'Start för HTML',
  },
  minArea: 'Minsta Område',
  minAreaMessage: 'Lägsta värde krävs. Värdet får inte överskrida omfånget 1 till 1 500.  Måste vara ett siffervärde.',
  moreInfo: 'Ytterligare information',
  multipleAlternatingPages: 'Flera alternerande sidor',
  multipleAlternatingPagesMessage: 'Diagram på sida 1 och 3, artikellista på sida 2 och 4.',
  mToM: 'M2M',
  multiplePages: 'Flera sidor',
  multiplePagesMessage: 'Diagram på sin egen sida följt av sidorna med artikellistan',
  requiredField: 'Obligatoriskt fält',
  messageMessage: 'Ett meddelande krävs',
  metadata: 'Metadata',
  mine: 'Min',
  miscellaneous: 'Diverse',
  mon: 'Mån',
  myCart: 'Min kundvagn | Mina kundvagnar',
  navigation: 'Navigering',
  nItemsCreated: '{n} {items} har skapats',
  nItemsDeleted: '{n} {items} har tagits bort',
  nOfMItems: '{n} av {m} {items}',
  name: 'Namn',
  nameDescriptionHelpText: 'Namnet och valfri beskrivning',
  new: 'Ny',
  newAPIKey: 'Ny API-Nyckel',
  newERPSystem: 'Nytt ERP-system',
  newItem: 'Ny {item}',
  newKeyGenerated: 'Ny Nyckel för Integrering har Skapats, Kopiera & Spara för framtida bruk',
  newMediaCategory: 'Ny mediekategori',
  newPassword: 'Nytt lösenord',
  newsItem: 'Nyhet | Nyheter',
  newTenant: 'Ny hyresgäst',
  newWidgetKey: 'Ny widget-nyckel',
  newWidgetKeyGenerated: 'Ny widget-nyckel har skapats, kopiera och behåll för framtida bruk',
  noTenantSuppliers: 'Tenant har inga leverantörer tilldelade.',
  noAPIKeys: 'Inga API-Nycklar har angivits.',
  node: 'Nod',
  noData: 'Inga Data',
  noFilesSelected: 'Inga filer har valts',
  noIllustrationFound: 'Inga Illustrationer Hittades',
  noImageFound: 'Ingen Bild Hittades',
  noItemAvailable: 'Ingen {item} tillgänglig',
  noItemFound: 'Ingen {item} hittades.',
  noItemSelected: 'Ingen {item} vald',
  noItemAddedToTarget: 'Ingen {item} har lagts till i {target}. ',
  noItemsFound: 'Inga poster hittades',
  noMoreResults: 'Inga fler resultat',
  noFormsMessage: 'Inga Formulär har fastställts.',
  noMediaDefined: 'Ingen media har angivits',
  nonSearchableFieldsSuccessfullyUpdated: 'Icke sökbara fält har uppdaterats.',
  noPartsSelected: 'Inga artiklar valda.',
  noPrice: 'Ring för pris',
  noResults: 'Inga resultat',
  norwegianDescription: 'Beskrivning på norska',
  norwegianName: 'Norskt Namn',
  noSearchableTags: 'Inga sökbara taggar',
  noSuggestions: 'Inga förslag för sökterm',
  notOrderable: 'Kan inte beställas',
  notSelected: 'Ej vald',
  noTagsFound: 'Inga taggar hittades',
  noUnsavedChanges: 'Inga osparade ändringar',
  none: 'Ingen',
  notImplemented: 'Inte implementerat',
  notification: 'Meddelande | Meddelanden',
  notificationsMessage: 'Viktiga meddelanden och nyheter',
  notMapped: 'Ej Kartlagd',
  november: 'November',
  noWidgetKeys: 'Inga widget-nycklar har angivits.',
  numeric: 'Numerisk',
  numberOf: 'antal {item}',
  october: 'Oktober',
  of: 'av',
  off: 'Av',
  offlineContent: 'Offline-innehåll',
  ok: 'OK',
  oldPassword: 'Tidigare lösenord',
  on: 'På',
  opacity: 'Opacitet',
  open: 'Öppna',
  openItem: 'Öppna {item}',
  optimizeIndexJob: 'Optimera Index',
  options: 'Alternativ',
  openTab: 'Öppna länk i ny flik',
  order: 'Beställning | Beställningar',
  orderable: 'Beställningsbar',
  orderableDescription: 'Artikeln kan läggas till i kundvagnen och köpas',
  orderableLocal: 'Kan beställas på denna sida',
  orderableLocalBlocked: 'Artikeln kan inte beställas globalt',
  orderDisable: 'Inaktivera Beställningshistorik',
  orderEmailRecipient: 'Mottagare av e-post om beställning | Mottagare av e-post om beställning',
  orderEntity: 'Beställningsenhet | Beställningsenheter',
  orderFieldUneditableRequired: 'Att ställa in ett fält för beställning/anbud som ”obligatoriskt” och ”går ej att redigera av användare” kan göra att beställningen/anbudet inte går att slutföra. Vänligen säkerställ att din beställning/ditt anbud kan slutföras.',
  orderFieldsEmptyMessage: 'Inga fält för beställning/anbud har angivits.',
  orderFieldsEmptyPrompt: 'Lägg till ett fält för beställning/anbud',
  orderField: {
    nameMessage: 'Om du ändrar fältet med beställningens/anbudets namn, säkerställ att alla beställningar, anbud eller mejlformat som använder detta fält också är uppdaterade.',
  },
  orderFieldCopyMessage: 'Ange ett unikt namn för det nya fältet för beställning/anbud',
  orderForm: {
    uniqueNameInputHint: 'Ange ett unikt namn för det nya Formuläret.'
  },
  orderFormField: 'Fält för beställning/anbud | Fält för beställning/anbud',
  orderFormLayout: 'Layout för beställnings-/anbudsformulär | Layouter för beställnings-/anbudsformulär',
  orderFormSetDefault: 'Ange Standardbeställning/Anbudsformulär',
  orderFormType: 'Beställningsformulär',
  orderLayout: 'Beställningslayout | Beställningslayouter',
  orderManagementApprover: 'Godkännare för beställningshantering',
  orderManagementDisable: 'Inaktivera admin för beställningshantering',
  orderManagementViewer: 'Läsare för beställningshantering',
  orderNumber: 'Beställningsnummer',
  orderQuoteEmail: 'Beställning/mejl med anbud | Beställning/mejl med anbud',
  orderQuoteEmailFormat: 'Format för beställning/mejl med anbud | Format för beställning/mejl med anbud',
  orderQuoteEmailFormatCopyMessage: 'Välj ett språk för den nya beställningen/mejlet med anbud',
  orderQuoteFetchError: 'Fel vid hämtning av data för beställning/anbudsformulär',
  orderQuoteForm: 'Formulär för beställning/offert | Formulär för beställningar/offerter',
  orderQuoteSaveConfirmation: 'Spara dina förändringar på organisatorisk beställning/anbudsformulär?',
  orderQuoteSaveError: 'Fel då beställningens/anbudsformulärets data sparades',
  orderQuoteFormSaved: 'Beställnings-/anbudsformulär har sparats',
  orderStatus: 'Status för beställning/anbud | Statusar för beställningar/anbud',
  orderStatusDeleteMessage: 'Denna status har använts för beställningar och kan inte tas bort.',
  orderSubmittedMessage: 'Skickad',
  orderSuggestion: 'Beställningsförslag | Beställningsförslag',
  orderSuggestionEnable: 'Aktivera beställningsförslag',
  orderSuggestionRemovalWarning: 'Artikeln kommer inte längre att visas som beställningsförslag. | Artiklarna kommer inte längre att visas som beställningsförslag.',
  orderSuggestionThreshold: 'Tröskel för beställningsförslag',
  ordersLogo: 'Beställningslogotyp',
  ordersMessage: 'Historik över inlämnade beställningar',
  orientation: 'Orientering',
  org: 'Org',
  orgEmailRecipients: 'Organisationens e-postmottagare',
  organization: 'Organisation | Organisationer',
  organizationAdmin: 'Organisationens administratör',
  organizationAdminBrowseFlow: 'En organisation kan använda standardflödet, eller så kan du åsidosätta och använda något annat flöde.',
  organizationAdminMessage: 'Styr användarhantering och organisationens inställningar',
  organizationEmpty: 'Organisationen måste väljas',
  organizationEmailRecipients: 'Organisationens e-postmottagare',
  organizationEnforceAccessControlsForQuickAdd: 'Tillämpa ÅtkomstKontroller för Snabb Tilläggning',
  organizationKey: 'Organisationsnyckel',
  organizationLibrarySettingsDescription: 'Dessa inställningar styr olika biblioteksdelar och alternativ på organisationsnivå. Var försiktig när du utför ändringar. Kontakta Documoto-tjänster för mer information.',
  organizationMoveToRoot: 'Släpp här för att flytta till roten',
  organizationName: 'Namn på organisation',
  organizationRearrange: 'Ordna om organisationer',
  organizationRearrangeDescription: 'Organisationsstrukturen ”överordnad-underordnad” definierar många ärftliga attribut för en organisation, så som utskriftsinställningar, stilar med flera. Dra och släpp organisationer för att visa önskad hierarki.',
  organizationReportSettingsSuccessMessage: 'Rapportinställningar har sparats',
  organizationSelect: 'Välj organisation',
  organizationTags: 'Organisationstaggar',
  organizationsEmpty: 'Det verkar som att du inte har skapat några organisationer än',
  orgNameOnly: 'Endast Namn på Org',
  other: 'Annat',
  otherSettings: 'Andra inställningar',
  outboundCustomerIdParameter: 'Parameternamn för utgående kund-ID',
  outboundSessionIdParameter: 'Parameternamn för utgående sessions-ID',
  outlineMode: 'Översiktsläge (Skift O)',
  output: 'Utmatning',
  outputCatalog: 'Katalog för Utmatning',
  outputDefinition: 'Definition för Utmatning | Definitioner för Utmatningar',
  outputDefinitionDeleted: 'Definition för Utmatning raderad',
  outputDefinitionSaved: 'Definition för Utmatning Sparad',
  outputFileName: 'Namn på Fil med Utmatning',
  outputName: 'Namn på Utmatning',
  outputDestination: 'Destination för utmatning',
  outputSpecification: 'Uppgift om utmatning',
  outputSubType: 'Utmatningens Undertyp',
  outputType: 'Typ av Utmatning',
  overridden: 'Åsidosatt',
  overriddenByOrg: 'Åsidosatt av organisationen',
  override: 'Åsidosätt',
  overrideBrowseFlow: 'Åsidosätt flöde',
  overrideFacetLimitValue: 'Åsidosätt gränsvärde för aspekt',
  overrideHotpointScale: 'Åsidosätt hotpoint-skala',
  overridePdfNamingStandard: 'Åsidosätt standard för namngivning av PDF-filer',
  overridePrintSettings: 'Åsidosätt utskriftsinställningar',
  overrideFontSettings: 'Åsidosätt teckensnittsinställningar',
  overrideOrderForm: 'Åsidosätt beställningsformulär',
  overrideQuoteForm: 'Åsidosätt anbudsformulär',
  overrideSvgOpacity: 'Åsidosätt SVG-opacitet',
  overrideWarning: 'Import av data kommer att ersätta din befintliga stycklista',
  owner: 'Ägare | Ägare',
  page: 'Sida | Sidor',
  pageBulkInsertConfirmTitle: 'Vill du slutföra massinfogningen?',
  pageBulkInsertConfirmInsertAfter: 'Detta kommer att föra in {replacement} efter {original} i {whereUsedBookCount} {bookUnits} och {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'Detta kommer att föra in {replacement} innan {original} i {whereUsedBookCount} {bookUnits} och {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'Detta kommer att föra in {replacement} och radera {original} i {whereUsedBookCount} {bookUnits} och {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'Detta kommer att föra in {replacement} efter {original} i {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'Detta kommer att föra in {replacement} innan {original} i {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'Detta kommer att föra in {replacement} och radera {original} i {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Detta kommer att föra in {replacement} efter {original} i {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Detta kommer att föra in {replacement} innan {original} i {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Detta kommer att föra in {replacement} och radera {original} i {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'Din massinfogning bearbetas inte.',
  pageBulkInsertHelpText: 'Denna funktion möjliggör att du infogar denna källsida {thisDisplayName} i valda delar av böcker där en annan sida {originalDisplayName} redan finns. Om du vill kan du även ta bort {originalDisplayName} från böckerna.',
  pageBulkInsertInsertAfter: 'Infoga {replacement} EFTER {original}',
  pageBulkInsertInsertBefore: 'Infoga {replacement} FÖRE {original}',
  pageBulkInsertInsertReplace: 'Infoga {replacement} och TA BORT {original}',
  pageBulkInsertScopeAll: 'Alla {type} som har målsidan {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Ingen av {type} som har målsidan {originalDisplayName}',
  pageBulkInsertScopeSome: 'Några av {type} som har målsidan {originalDisplayName}',
  pageBulkInsertStep1: 'Steg 1: Hitta önskad målsida',
  pageBulkInsertStep2: 'Steg 2: Bestäm hur du vill infoga eller ersätta sidorna',
  pageBulkInsertStep3: 'Steg 3: Välj de böcker där du vill att massinfogningen ska bearbetas',
  pageBulkInsertStep4: 'Steg 4: Välj de mallar där du vill att massinfogningen ska bearbetas.',
  pageBulkInsertSuccessTitle: 'Massinfogningsjobb har lämnats in',
  pageBulkInsertSuccessBody: 'Ändringar till bokens innehållsförteckning görs omedelbart. Sökresultat visas när indexeringen har slutförts.',
  pageBulkErrorTooManyBooks: 'Begränsa antal valda böcker till maximalt 1 000 stycken',
  pageBuilder: 'SidByggare',
  pageComposer: 'Page Composer',
  pageCreated: 'Sida skapad',
  pageDeleteMessage: 'Raderade sidor kommer omedelbart att tas bort i administration av bibliotek och innehåll. Den borttagna sidan kommer att vara sökbar tills en ny registrering har skett inom några minuter.',
  pageDeleteMessage2: 'Denna åtgärd kan inte ångras utan hjälp från Documoto Support. Efter 90 dagar kommer den borttagna uppgiften och allt som är kopplat till den att raderas permanent.',
  pageDescription: 'Beskrivning av sida',
  pageFile: 'Sidfil',
  pageFilename: 'Sidans filnamn',
  pageFilenameDeleted: '{filename} kommer att raderas.',
  pageFileRequired: 'En sidfil krävs.',
  pageBomPartsDeleted: '{count} delar av BOM-sidan kommer inte längre att vara tillgängliga från {filename}.',
  pageDraftsDeleted: 'Sidor med utkast som är kopplade till denna sida kommer att raderas permanent.',
  pageFit: 'Anpassa sida',
  pageHeader: 'Sidrubrik',
  pageLabel: 'Sida | Sidor',
  pageLayout: 'Sidlayout',
  pageName: 'Sidans namn',
  pagePart: 'Del av sida | Delar av sida',
  pagePartTag: 'Tagg för Del av sida | Taggar för Delar av sidor',
  pagePartData: 'Data för Del av Sida',
  pageUsageDataCouldNotBeLoaded: 'Det gick inte att ladda data för sidanvändning',
  pagePartLevelDetails: 'Del av sida för beställningsbara/synliga, taggar för del av sida',
  pagePartNumberVisible: 'Sidnummer Synligt',
  pagePartOrderable: 'Del av sida kan Beställas',
  pagePartOrderableDescription: 'Del kan Beställas från denna sida',
  pagePartVisibleDescription: 'Delnummer synligt på denna sida',
  pageUpdated: 'Sida uppdaterad',
  pageViewer: 'SidGranskare',
  pageWidth: 'Sidbredd',
  pagesWhereUsed: 'Sidor där detta används',
  parentName: 'Namn på överordnad',
  part: 'Artikel | Artiklar',
  partCode: 'Artikelkod | Artikelkoder',
  partCodeAddError: 'Det går inte att lägga till artikelkod(er)',
  partCodeErrorEmpty: 'Det gick inte att spara: Artikelkod får inte vara tom',
  partCodeErrorInUse: 'Denna artikelkod används av artiklar i Documoto och kan inte tas bort. Kontakta Documoto Support på support{\'@\'}documoto.com för hjälp.',
  partCodeErrorNotFound: 'Denna artikelkod kunde inte tas bort eftersom den inte hittades. Se till att alla ändringar har sparats.',
  partCodesManager: 'Artikelkodhanterare',
  partCodesMessage: 'Artikelkoder kan användas i stället för artikelnummer och används ofta för att ange när strukturposter är inaktuella, ej tillgängliga eller endast referens (t.ex. \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Artikel skapad',
  partData: 'Data om Del',
  partUpdated: 'Del uppdaterad',
  partDebouncerEmailQueueJob: 'Mejlkö',
  partDescription: 'Beskrivning av artikel',
  partLabel: 'Artikel | Artiklar',
  partLevelDetails: 'Prissättning av delar, beställningsbara/synliga, ytterligare översättningar, taggar för delar',
  partName: 'Artikelnamn',
  partnerApiKey: 'Partners API-Nyckel',
  partnerRESTRequestLimit: 'Gräns för partners REST-begäran',
  partNumber: 'Artikelnummer',
  partNumberDescription: 'Det interna artikelnumret som slutanvändare kan söka',
  partNumberVisible: 'Artikelnummer synligt',
  partNumberVisibleDescription: 'Artikelnummer synligt för slutanvändare',
  partNumberVisibleLocal: 'Synlig på denna sida',
  partNumberVisibleLocalBlocked: 'Artikelnummer inte synligt globalt',
  partOrderable: 'Del kan Beställas',
  partRequired: 'Minst en artikel krävs',
  partTagProperty: 'Egenskap för artikeltagg | Egenskaper för artikeltagg',
  partTag: 'Tagg för del | Taggar för delar',
  partThumbnailDescription: '',
  partialSupport: 'Delvis support',
  partsListColumn: 'Kolumn i artikellista | Kolumner i artikellista',
  partsBook: 'Artikelbok | Artikelböcker',
  partsColumns: 'Artikelkolumner',
  partsNotSelected: 'Standardbild som används för artikelminiatyrbilder. Om en anpassad miniatyrbild inte tillämpas visar Documoto en standardminiatyrbild.',
  partsPage: 'Sida med Delar | Sidor med Delar',
  partsPages: 'Artikelsidor',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Exkludera artikelkoder från artikellistorna',
    highlightAlternateRows: 'Markera alternativa rader',
    rotateImagesAutomatically: 'Rotera bilder automatiskt',
    printBorderAroundImages: 'Skriv ut kant runt bilderna',
    keepImagesOnLeft: 'Håll bilderna till vänster',
    keepBOMOnRight: 'Håll strukturposter till höger',
    columnsUpdateErrorMsg: 'Ett fel uppstod vid försök att uppdatera artikelkolumnerna',
    columnsUpdateSuccessMsg: 'Artikelkolumnerna har uppdaterats',
    columnsLoadErrorMsg: 'Det gick inte att läsa in artikelkolumnerna',
    printHotpointsOnImages: 'Pränta Hotpoints på Bilder',
  },
  partsList: 'Lista över Delar',
  partsListData: 'Artikellistdata',
  partsListGridHeader: 'Artikellistans rutnätsrubrik',
  password: 'Lösenord',
  passwordExpiredHeader: 'Lösenordet har gått ut',
  passwordExpiredInfo: 'Ditt lösenord har gått ut. Ditt nya lösenord måste uppfylla följande kriterier:',
  passwordCriteriaInfo: 'Ditt nya lösenord måste uppfylla följande kriterier:',
  passwordBlankMessage: 'Lösenordet kan inte vara tomt',
  passwordCannotBeSame: 'Får inte vara samma som ditt nuvarande lösenord',
  passwordCasingMassage: 'Måste innehålla åtminstone en liten och en stor bokstav',
  passwordLowerCaseMessage: 'Lösenordet måste innehålla minst en gemen',
  passwordMinLengthMessage: 'Lösenord måste innehålla minst 8 tecken',
  passwordNotMatchingMessage: 'Lösenorden stämmer inte överens',
  passwordSpecialCharactersMessage: 'Lösenordet måste innehålla minst ett specialtecken',
  passwordUpperCaseMessage: 'Lösenordet måste innehålla minst en versal',
  passwordWillExpire1Day: 'Lösenord för inloggning kommer att gå ut om mindre än en dag',
  passwordWillExpire5Days: 'Lösenord för inloggning kommer att gå ut om mindre än fem dagar',
  passwordsMatch: 'Lösenorden matchar',
  permission: 'Behörighet | Behörigheter',
  phoneNumber: 'Telefonnummer',
  phoneNumberExt: 'Ankn',
  phonePrimary: 'Primärt telefonnummer',
  phoneSecondary: 'Sekundärt telefonnummer',
  pinFilter: 'Fäst filter på sökresultatsidan',
  pinned: 'Fastnålad',
  plusMoreOrgs: '+ {totalOrgCount} ytterligare organisationer',
  polishDescription: 'Beskrivning på polska',
  polishName: 'Polskt Namn',
  populatePdfNameWith: 'Befolka PDF:ens filnamn med',
  populatePdfNameSelection: 'Val för att befolka PDF:ens filnamn',
  port: 'Port',
  portrait: 'Stående',
  portugueseDescription: 'Beskrivning på portugisiska',
  portugueseName: 'Portugisiskt Namn',
  postComment: 'Publicera kommentar',
  postalCode: 'Postnummer',
  poweredBy: 'Drivs av',
  preferences: 'Preferenser',
  preferencesDisable: 'Inaktivera användarpreferenser',
  prefix: 'Prefix | Prefix',
  preview: 'Förhandsvisning',
  previewOfName: 'Förhandsgranskning av {name}',
  pricing: 'Prissättning',
  pricingDiscountEnabled: 'Visa rabatterat pris',
  pricingDisplayInCart: 'Visa priser i kundvagnen',
  pricingDisplayInPartsList: 'Visa priser i artikellistan',
  pricingGlobal: 'Global prissättning',
  pricingGlobalDescription: 'Det normala priset om det inte finns någon åsidosättning av organisationen',
  pricingHidePricingButton: 'Aktivera knappen Dölj pris',
  pricingHidePricingButtonDisable: 'Inaktivera knappen Dölj pris',
  pricingOrganization: 'Organisationspris',
  pricingOrganizationDeleteConfirm: 'Vill du ta bort denna organisationsprissättning?',
  pricingOrganizationDescription: 'Organisationsprissättning åsidosätter global prissättning för alla användare inom en organisation och dess underordnade organisationer.',
  pricingOrganizationDuplicate: 'Denna organisation åsidosätts redan',
  pricingOrganizationEmptyState: 'Inga organisationsåsidosättningar har definierats. Klicka på {add} för att komma igång.',
  pricingRetailEnabled: 'Visa detaljhandelspris',
  pricingWholesaleEnabled: 'Visa grossistpris',
  primary: 'Primär',
  print: 'Skriv ut',
  printerLane: 'Skrivarbana',
  printJob: 'Utskriftsjobb',
  printLogo: 'Skriv ut logotyp',
  printSetting: 'Utskriftsinställning',
  printSettingDeleteFail: 'Det gick inte att ta bort utskriftsinställningen',
  printSettingSaveFail: 'Det gick inte att spara utskriftsinställningen',
  printSettingEmptyMessage: 'Inga utskriftsinställningar har definierats. Lägg till en utskriftsinställning för att komma igång.',
  printSettingSetDefaultSuccess: 'Standardinställning för utskrift har ställts in',
  printSettingUpdateFail: 'Det gick inte att uppdatera utskriftsinställningen',
  printSettingCopyMessage: 'Ange ett unikt namn för den nya utskriftsinställningen.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Utskriftsinställning har skapats',
  printSettings: 'Utskriftsinställningar',
  printSettingsLogo: 'Utskriftsinställningar logotyp',
  printing: 'Skriver ut',
  printingDisabled: 'Inaktivera all utskrift',
  privacyPolicy: 'Integritetspolicy',
  proceed: 'Fortsätt',
  process: 'Process',
  processContent: 'Bearbeta innehåll',
  processing: 'Bearbetar',
  processToDraft: 'Behandla för utkast',
  processedPlzToDraft: 'Behandlade PLZ(er) till utkast',
  production: 'Produktion',
  professional: 'Expert',
  profile: 'Profil',
  prohibitExportDownloadOfContent: 'Förbjud exportering/nedladdning av innehåll',
  prohibitExportDownloadOfContentHelpText: 'Om \'Aktiverat\' har angivits så kommer det att hindra alla (privilegierade) användare från att ladda ner detta innehåll.',
  property: 'Egenskap | Egenskaper',
  province: 'Provins',
  public: 'Offentlig',
  publish: 'Publicera',
  publisherLane: 'Utgivarbana',
  publisherManualLane: 'Manuell utgivarbana',
  publishThumbnailJob: 'Publicera Miniatyr',
  publishDocuments: 'Publicera Dokument',
  publishDraft: 'Publicera Utkast',
  publisher: 'Publicerare | Publicerare',
  publishingErrors: 'PubliceringsFel',
  publishingErrorsWithName: 'Publiceringsfel {name}',
  publishingInProgress: 'Publicering pågår, kan inte skicka',
  publisherJob: 'Publicerarjobb',
  publisherMessage: 'Skapa och hantera innehåll och utskriftsinställningar',
  purchaseOrder: 'Inköpsorder',
  purchaseOrderTemplateEnabled: 'Aktivera beställningsmallar',
  purchaseOrderTemplateDisabled: 'Inaktivera beställningsmallar',
  qty: 'Antal',
  quantity: 'Antal',
  query: 'Fråga',
  quickAdd: 'Lägg till snabbt',
  quickAddHelpText: 'Börja skriva för att hitta artiklar att lägga till i kundvagnen.',
  quickAddInCartEnabled: 'Aktivera Lägg till snabbt i kundvagnen',
  quickLinks: 'Snabblänkar',
  quote: 'Anbud | Anbud',
  quoteFormType: 'Anbudsformulär',
  quoteTemplateDisabled: 'Inaktivera offertmallar',
  quoteTemplateEnabled: 'Aktivera offertmallar',
  range: 'Intervall',
  rearrange: 'Ordna om',
  rebuildIndexJob: 'Återuppbygg Index',
  recentSearches: 'Senaste sökningar',
  recentlyAdded: 'Nyligen tillagda',
  recentlyEdited: 'Nyligen redigerade',
  recentlyViewed: 'Nyligen visade',
  rectangle: 'Rektangel',
  redirect: 'Omdirigera',
  refresh: 'Uppdatera',
  related: 'Relaterade',
  relatedDescription: 'Relatera media som finns i biblioteket till denna {type}. Observera att relaterade media måste tillämpa åtkomstkontroller för att användare ska kunna se dem i fliken \'Relaterat\' .',
  relatedInfo: 'Relaterad info',
  relatedView: 'Visa relaterade medier',
  relationship: 'Relation | Relationer',
  releaseNotes: 'Versionsmeddelande',
  rememberMe: 'Kom ihåg mig',
  remainingWidth: 'Återstående bredd',
  remove: 'Ta bort',
  removeAll: 'Ta bort alla',
  removeItemConfirmation: 'Vill du ta bort {item}?',
  removeItemCount: 'Är du säker på att du vill ta bort {count} {type}?',
  replace: 'Ersätt',
  replaceExisting: 'Ersätt befintliga',
  report: 'Rapport | Rapporter',
  reportAnIssue: 'Rapportera ett problem',
  reportAnIssueMessage: 'E-postmeddelandet har skickats.',
  reportCatalogName: 'Namn på RapportKatalog',
  reportCatalogNotSet: 'Rapportkatalog har inte angivits för rapporten.',
  reportDefinition: 'Definition av Rapport | Definitioner av Rapport',
  reportDefinitionDeleteError: 'Kunde inte radera definition av rapport',
  reportsEmptyState: 'Inga Rapporter',
  reportExportTimeWarning: 'En export kan ta flera minuter att slutföra beroende på antal uppgifter och det valda formatet.',
  reporting: 'Rapportering',
  reportingMessage: 'Åtkomst till olika rapporter och mått',
  reportSettings: 'Rapportinställningar',
  reportsLibrary: 'Rapportbibliotek',
  requestForQuote: 'Offertförfrågan',
  requestFormType: 'Anbudsformulär',
  required: 'Obligatoriskt',
  requiredItem: '{item} krävs.',
  requiredMessage: 'En {item} krävs.',
  requiredMessageAndUnique: 'En {item} är obligatorisk och måste vara unik.',
  requireAuthentication: 'Kräv autentisering',
  requiredReading: 'Obligatorisk läsning',
  requiredReadingMessage: 'Granska följande:  ',
  requiredUrlMessage: 'En URL i rätt format krävs.',
  reset: 'Återställ',
  resetPassword: 'Återställ lösenordet',
  resetPasswordError: 'Det gick inte att återställa ditt lösenord, försök igen.',
  resetPasswordTokenError: 'Lösenordets återställningstoken är ogiltig.',
  resetZoom: 'Återställ Zoom',
  resource: 'Resurs | Resurser',
  resize: 'Ändra storlek',
  resizeThumbnails: 'Ändra automatiskt storlek på miniatyrbilder',
  result: 'Resultat | Resultat',
  resultFor: 'Resultat för {item} | Resultat för {item}',
  retail: 'Detaljhandel',
  retailPrice: 'Detaljhandelspris',
  retailTotal: 'Summa detaljhandel',
  return: 'Återvänd',
  returnToItem: 'Gå tillbaka till {item}',
  returnToPartList: 'Gå tillbaka till artikellistan',
  returnToMedia: 'Gå tillbaka till medier',
  returnToTemplate: 'Gå tillbaka till mall',
  returnToViewer: 'Återvänd till granskning',
  revertChanges: 'Ångra ändringar',
  right: 'Höger',
  romanianDescription: 'Beskrivning på rumänska',
  romanianName: 'Rumänskt Namn',
  rootLevelBrowseFlow: 'Rotnivå',
  row: 'Rad',
  russianDescription: 'Beskrivning på ryska',
  russianName: 'Ryskt Namn',
  sampleData: 'Provdata',
  samplePreview: 'Förhandsvisning av urval',
  sandbox: 'Sandlåda',
  sat: 'Lör',
  save: 'Spara',
  saveChanges: 'Spara dina ändringar till {item}? {name}',
  saveChangesToItem: 'Spara dina ändringar till denna {item}?',
  saved: 'Sparat',
  saveNewAPIKey: 'Ny Nyckel för Integrering är inte Sparad, Gå tillbaka för att slutföra installationen',
  saveNewWidgetKey: 'Ny widget-nyckel har inte sparats. Återvänd för att slutföra installation.',
  search: 'Sök',
  searchableFieldsSuccessfullyUpdated: 'Sökbara fält har uppdaterats.',
  searchAllContent: 'Sök i allt innehåll',
  searchConfiguration: 'Sök-konfiguration',
  searchCriteria: 'Sökkriterier',
  searchDocumoto: 'Sök i Documoto',
  searchFeatures: 'Sökfunktioner',
  searchFieldConfigDescription: 'Följande sökinställningar för systemfältet gäller endast för allmänna biblioteksökningar, sidan för sökresultat och snabbtillägg i kundvagnen. Andra sökfunktioner (som exempelvis sökningar på administrationscentral och bibliotek där det används) påverkas inte.',
  searchInContent: 'Sök i {contentName}...',
  searchingInLanguage: 'Söker endast på {lang}',
  searchingInTwoLanguages: 'Söker endast på {lang1} och {lang2}',
  searchPlaceholder: 'Sök i allt innehåll...',
  searchPrompt: 'Sök i allt innehåll...',
  searchProperty: 'Sökegenskap | Sökegenskaper',
  searchResultsConfiguration: 'Konfiguration av sökresultat',
  searchSettings: 'Sökinställningar',
  searchSettingsDescription: 'Dessa inställningar styr olika sökbeteenden, funktioner och resultat i biblioteket.',
  searchTerm: 'Sökterm',
  searchTips: 'Söktips:',
  searchable: 'Sökbar',
  searchableTag: 'Sökbar tagg',
  section: 'Avsnitt',
  select: 'Välj',
  selectAField: 'Välj ett {field}',
  selectAll: 'Välj alla',
  selectAndClose: 'Välj och stäng',
  selected: 'Vald',
  selectedTargetPage: 'Vald målsida',
  selectChapters: 'Välj Kapitel',
  selectChildren: 'Välj underordnade',
  selectFields: 'Välj {field}',
  selectItem: 'Välj {item}',
  selectMedia: 'Välj Media',
  selectPage: 'Välj sida',
  selectPages: 'Välj Sida (Sidor)',
  selectTargetPage: 'Välj målsida',
  send: 'Skicka',
  september: 'September',
  serbianDescription: 'Beskrivning på serbiska',
  serbianName: 'Serbiskt Namn',
  serviceUri: 'Tjänstens URI',
  sessionLog: 'Sessionslogg',
  set: 'Ställ in',
  setAsDefault: 'Ställ in som standard',
  settings: 'Inställningar',
  settingsMessage: 'Uppdatera dina användarinställningar och lösenord',
  sequence: 'Sekvens',
  shape: 'Form',
  shapeTool: 'Formverktyg',
  shared: 'Delat',
  sharedCart: 'Delad kundvagn | Delade kundvagnar',
  sharedCartDeleteContent: 'Det här tar bort denna delade kundvagn för alla organisationer där den har delats.',
  sharedCartOrganizationContent: 'Välj organisationer som kan använda denna delade kundvagn.',
  sharedShoppingCartsEnabled: 'Aktivera delade kundvagnar',
  shoppingCartExportJob: 'Exportera Kundvagn',
  shoppingCartLoadingLongTime: 'Kundvagnen laddas. Ikonerna för kundvagnen kommer att bli tillgängliga när processen slutförts. Om problemet kvarstår, vänligen kontakta din Documoto-administratör för hjälp.',
  showExportFromSearch: 'Tillåt export från sökresultat',
  showExportFromSearchHelp: 'Tillåt export av boklista från en sökning',
  showHelp: 'Visa Hjälp',
  showIdentifierInSearch: 'Visa Media-identifierare i sökningen',
  showItem: 'Visa {item}',
  showLess: 'Visa färre',
  showMediaDescriptionFilter: 'Visa beskrivningsfilter för medier',
  showMediaIdentifierFilter: 'Visa identifieringsfilter för medier',
  showMediaNameFilter: 'Visa namnfilter för medier',
  showMore: 'Visa fler',
  showOrganizationFilter: 'Visa organisationsfilter',
  showPartNameFilter: 'Visa filter för artikelnamn',
  showPartNumberFilter: 'Visa filter för artikelnummer',
  showRecentlyAdded: 'Visa nyligen tillagda',
  showRecentlyViewed: 'Visa nyligen visade',
  showSearchButton: 'Visa sökknapp på startsidan',
  showSearchButtonHelp: 'Visa knappen \'Gå till sökning\' i bibliotekssidhuvudet',
  signature: 'Underskrift',
  size: 'Storlek',
  snapshot: 'Ögonblicksbild | Ögonblicksbilder',
  solrSlaveSvrOverride: 'Åsidosätt SOLR slav-servrars grundläggande URL',
  sortAsc: '{value} A-Ö',
  sortDesc: '{value} Ö-A',
  sortRelevant: 'Mest relevant',
  sortType: 'Sorteringstyp',
  spanishDescription: 'Beskrivning på spanska',
  spanishName: 'Spanskt Namn',
  square: 'Kvadrat',
  ssoConfiguration: 'SSO-konfigurering',
  ssoIdpMetadataUrl: 'URL för SSO IDP-metadata',
  ssoLoginMaxAuthenticationAgeSeconds: 'Maximal autentiseringsålder i sekunder för SSO-inloggning',
  ssoSamlDnAttributeName: 'SSO SAML DN-namn på attribut',
  ssoSamlEmailAttributeName: 'SSO SAML-namn på mejlattribut',
  ssoSamlMemberofAttributeName: 'SSO SAML-namn på attribut MemberOf',
  ssoSniForSsoMetadataRetrieval: 'Använd SNI för att hämta metadata för SSO',
  standard: 'Standard',
  standardUserGroup: 'Vanlig användargrupp',
  startDate: 'Startdatum',
  startDateRequired: 'Ett startdatum krävs.',
  state: 'Delstat',
  static: 'Statisk',
  status: 'Status',
  statusCode: 'Statuskod | Statuskoder',
  stitchPages: 'Sammanfoga sidor',
  storeFrontAdmin: 'Butiksadmin',
  storefront: 'Butik',
  storefrontIntegration: 'Butiksintegration | Butiksintegrationer',
  storefrontIntegrationSubtitle: 'Dessa inställningar styr olika element och alternativ inom butiksintegration. Var försiktig när du utför ändringar. Kontakta Documoto Support för mer information.',
  storefrontSettings: 'Butiksinställningar',
  storefrontSettingsDescription: 'Dessa inställningar styr olika element och alternativ inom butiken. Var försiktig när du utför ändringar. Kontakta Documoto Services för mer information.',
  storefrontSettingsOrgDescription: 'Dessa inställningar styr olika element och alternativ inom butiken på organisationsnivå. Var försiktig när du utför ändringar. Kontakta Documoto Services för mer information.',
  storeFilterValues: 'Lagra filtervärden',
  storeFilterValuesDescription: 'Aktivering av denna inställning lagrar de värden som användare ställt in för sina filter på söksidan för denna tagg.',
  stroke: 'Slag',
  style: 'Stil | Stilar',
  styles: {
    colorHotpoint: 'Standard Hotpoint',
    invalidHexcode: 'Ogiltig hex-kod',
    inheritedFromOrg: 'Ärvd från organisation {org}',
    inheritedFromSystem: 'Ärvd från systemets standardvärde',
    inheritedFromTenant: 'Ärvd från hyresgäst',
    organization: 'Organisationsstil | Organisationsstilar',
    organizationSaved: 'Organisationsstilar har sparats',
    sampleNavigation: 'Navigeringsflikar',
    samplePrimary: 'Navigering / knappar',
    sampleSelectedHotpoint: 'Valda Hotpoints',
    tenant: 'Stil för hyresgäst | Stilar för hyresgäster',
    tenantSaved: 'Stilar för hyresgäster har sparats'
  },
  subChapter: 'Underkapitel | Underkapitel',
  subject: 'Ämne',
  subjectMessage: 'Ett ämne krävs',
  submit: 'Skicka',
  submitPurchaseOrder: 'Inköpsorder',
  submitRequestForQuote: 'Offertförfrågan',
  submitted: 'Skickad',
  submitter: 'Avsändare',
  submittedBy: 'Skickad av',
  submitterEmail: 'Insändarens mejl',
  submitterFirstName: 'Insändarens förnamn',
  submittedFilesMessage: 'Skickade {fileCount} fil(er).',
  submitterLastName: 'Insändarens efternamn',
  submitterOrgName: 'Insändarens organisations namn',
  submitterUserName: 'Insändarens användarnamn',
  success: 'Slutfört',
  imageSaveSuccess: 'Uppdatering av Bilder slutförd',
  successNotificationDuration: 'Lägg till i Kundvagn – Varaktighet för Meddelande om Slutförande',
  suffix: 'Suffix | Suffix',
  suggestedKeywords: 'Föreslagna nyckelord',
  suggestedResults: 'Föreslagna resultat',
  suggestion: 'Förslag | Förslag',
  sun: 'Sön',
  superadmin: 'Superadmin',
  superAdminSettings: "Inställningar för Super Admin",
  supersede: 'Ersätt',
  supersedeComplete: 'Vill du slutföra ersättningen?',
  supersedeDiscardChanges: 'Ignorera din ersättning?',
  supersedeMainText: 'Ersättning ersätter en befintlig artikel med en eller flera andra artiklar på varje artikelsida som innehåller den gamla artikeln. Det gamla artikelnumret tas bort men förblir sökbart via taggar så att det går att hitta innehåll med det gamla numret eller det nya numret/numren.',
  supersedeMessage: 'Artikeluppdateringar träder i kraft omedelbart. Uppdateringarna visas i sökning inom några minuter.',
  supersedeNotProcessed: 'Din ersättning bearbetas inte.',
  supersedeReplacePart: 'Hitta en eller flera artiklar som ska ersätta artikeln',
  supersedeSuccessful: 'Ersättningen lyckades',
  supersedeWith: 'Ersätt artikel med ...',
  supplier: 'Leverantör | Leverantörer',
  supplierKey: 'Leverantörsnyckel',
  supplierDescription: 'Företaget som tillhandahåller denna artikel',
  supportedImageFormats: 'Bildformat som stöds: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Filen stöds inte; utkastfiler som stöds: MDZ, PLZ',
  surroundWithQuotes: 'Använd citattecken runt flera söktermer för en exakt sökträff.',
  svgFinder: 'SVG-Sökare',
  swedishDescription: 'Beskrivning på svenska',
  swedishName: 'Svenskt Namn',
  system: 'System',
  systemAdmin: 'Systemadmin',
  systemFields: 'Systemfält',
  systemFieldSearchAndFiltering: 'Sökning och filtrering för systemfältet',
  systemStatus: 'Systemstatus',
  systemName: 'Namn på systemet',
  systemVersion: 'Systemversion',
  tabDelimited: 'Tabbavgränsad',
  tableOfContents: 'Innehållsförteckning',
  tabloid: 'Tabloid',
  tag: 'Tagg | Taggar',
  tagName: 'Namn på tagg',
  tagEditor: {
    rangeBoundLengthInvalid: 'Bindning får inte ha mer än {limit} siffror.',
    tagEditorModal: 'Taggredigerare modal',
    filterPrompt: 'Visar de första 100 värdena. Använd filter för att visa fler.'
  },
  tagSearchAndFiltering: 'Taggsökning och filtrering',
  tagSearchAndFilteringHelpText: 'Tips: några inställningar för sökning och filtrering kan även konfigureras i redigerare för tagghantering, som finns här: ',
  tagSubmitAffected: 'Allt {type} innehåll förlorar taggen {tag} när ändringarna görs.',
  tagDeleteAffectedCount: 'Detta tar bort taggen och tar bort tagginformationen från {count} objekt.',
  tagDeleteAffectedNone: 'Inga objekt använder denna tagg för närvarande.',
  tagManagerDeleteWarning: 'All befintlig media med {deleteCategoryName} förlorar denna kategori efter radering.',
  tagsDisplayMore: 'Visar de första 100 värdena. Använd filter för att visa fler.',
  tagsManager: 'Tagghanterare',
  tagValue: 'Taggvärde | Taggvärden',
  tagValueMissing: 'Du måste ange ett taggvärde',
  tagValueMultiple: 'Semikolonseparerat för flera poster',
  tagValueTooLong: 'Taggvärde överskrider gränsen på 25 tecken | Taggvärden överskrider gränsen på 25 tecken',
  taxon: 'Taxon',
  taxonomy: 'Taxonomi',
  template: 'Mall | Mallar',
  templateEmailRecipients: 'Mallens e-postmottagare',
  templateLayout: {
    addRow: 'Lägg till rad',
    addItem: 'Lägg till artikel',
    deleteRow: 'Radera rad',
    deleteColumn: 'Radera kolumn',
    fullWidth: 'Fullständig bredd',
    oneColumn: 'En kolumn',
    threeColumns: 'Tre kolumner',
    twoColumns: 'Två kolumner'
  },
  tenant: 'Innehavare | Innehavare',
  tenantAdmin: 'Innehavaradmin',
  tenantAdminMessage: 'Styr alla inställningar för innehavaren',
  tenantCleanerJob: 'Underhåll av innehavare',
  tenantConfiguration: 'Konfigurering av hyresgäst',
  tenantEmailRecipients: 'Klientens e-postmottagare',
  tenantEmailRecipientsOverrideMessage: 'Dessa kommer att åsidosättas om organisationens e-postmottagare specificeras',
  tenantIndexQueueJob: 'Indexkö för innehavare',
  tenantKey: 'Hyresgästens nyckel',
  tenantKeyError: 'Hyresgästens nyckel är inte unik',
  tenantKeyMessage: 'Hyresgästens nyckel måste vara unik',
  tenantLabelDeleteMessage: 'Om du raderar denna etikett används Documoto-namnet som standard.',
  tenantReindexModulus: 'Hyresgästens Reindex-modul',
  tenantSettings: {
    collapseTOCLabel: 'Minimera innehållsförteckning på matchande sida',
    defaultLocaleLabel: 'Standardplats',
    defaultLocaleMessage: 'Innehavarens standardplats.',
    deleteMissingTranslationsOnPublishLabel: 'Radera översättningar som saknas vid publicering?',
    deleteMissingTranslationsOnPublishMessage: 'Aktivera för att radera översatta delar vid publicering',
    defaultSupplierLabel: 'Standardleverantör',
    defaultSupplierMessage: 'Innehavarens standardleverantör.',
    enableChapterIndexingMessage: 'Kapitelindexering',
    enableChapterIndexingLabel: 'Slår på indexering för mediakapitel',
    enableIndexingLabel: 'Index på Publicering',
    enableIndexingMessage: 'Aktiverar indexering för publicering.',
    helpUrlLabel: 'Standard URL för Hjälp',
    helpUrlMessage: 'URL för ett annat hjälpsystem.',
    hotPointLinkPageInBookOnlyLabel: 'Länkar till Sidans Höjdpunkter Endast i Bok',
    hotPointLinkPageInBookOnlyMessage: 'Länkar till Höjdpunkter Endast från Bokens Sidor',
    mediaTimestampLabel: 'Uppdatera mediatidsstämpel vid ändring av åtkomstkontroll',
    mediaTimestampMessage: 'Aktivera om du vill att en ändring av åtkomstkontroller ska uppdatera medians tidsstämpel.',
    publisherDefaultUom: 'Utgivarens förinställda UOM',
    publishingContactLabel: 'E-postadress för publiceringskontakt',
    publishingContactMessage: 'E-postadressen som tar emot meddelanden som standard när innehållsproblem rapporteras.',
    replaceHotpointLabel: 'Ersätt alltid hotpoint-länkar vid publicering',
    replaceHotpointMessage: 'Endast för Docustudio-publicering.',
    ssoLoginSystemLabel: 'SSO InloggningsSystem',
    ssoLoginSystemMessage: 'IdentitetsLeverantörssystem för SSO.',
    userPasswordExpirationLabel: 'Dagar tills användarens lösenord går ut',
    userPasswordExpirationMessage: 'Antal dagar tills lösenordet måste bytas; lämna blankt för ett lösenord som aldrig går ut',
    lockPartTrOnPublishLabel: 'Lås delar av översättning vid publicering',
    lockPartTrOnPublishMessage: 'Förhindrar att delar av översättning modifieras vid publicering',
    exportPgAsHashLabel: 'Exportera namn på sidfilen som hashnyckel',
    exportPgAsHashMessage: 'Använd sidans hashnycklar som namn på sidfiler i innehåll som exporteras'
  },
  tenantSettingsTitle: 'Inställningar för Innehavare',
  tenantSettingsSaved: 'Inställningar för Innehavare har Sparats',
  tenantSettingsSubtitle: 'Dessa inställningar styr egenskaper och beteenden för alla innehavare. Var försiktig när ändringar utförs. Kontakta Documoto Services för ytterligare information.',
  tenantSuppliers: 'Hyresgästers leverantörer',
  tenantLabelsPageText: 'Etiketter används för att anpassa namnen på många objekt som finns på Documotos biblioteks- och administrationsskärmar.',
  tenantPrintSettingsDescription: 'Välj standard utskriftsinställning för innehavaren. Denna inställning används för alla bokutskrifter om den inte åsidosätts av organisationen eller media.',
  tenantType: 'Typ av hyresgäst',
  tenantWebserviceUser: 'Hyresgästens webbtjänst-användare',
  text: 'Text',
  textArea: 'Textområde',
  textField: 'Textfält',
  textFile: 'Textfil (CSV, TXT)',
  textTool: 'Textverktyg',
  threeD: {
    addFile: 'Lägg till en fil för en 3D-artikel',
    beforeSaveModal: {
      title: 'En CAD-fil med detta filnamn finns redan i Documoto.',
      message: 'Vill du ersätta denna fil på alla dess platser eller endast koppla den till denna artikel? Om du väljer att ersätta filen så kommer följande att ske:',
      messageBulletOne: '{count} artikel / artiklar kommer att uppdateras med den nya CAD-filen',
      messageBulletTwo: 'Filens föregående version kommer att raderas från Documotos filsystem',
      tip: 'Tips: För att förhindra att någon fil skrivs över under denna process så kan du ändra filnamnet på filen du försöker koppla innan du importerar den.'
    },
    deleteTitle: 'Är du säker på att du vill ta bort den här 3D-vyn?',
    drawMode: {
      label: 'Ritläge',
      hiddenLines: 'Dolda linjer',
      shaded: 'Skuggad',
      wireframe: 'Trådram',
      wireframeShaded: 'Skuggad trådram',
    },
    explode: 'Explodera',
    fileSizeModal: {
      message: 'Den största storleken på filer i Documoto är 25 megabyte. Filen som du försöker ladda upp har överskridit maximal storlek och kan inte laddas. Klicka på "OK" för att fortsätta.',
      title: 'Maximal filstorlek har överskridits',
    },
    orientationAndProjection: {
      back: 'Tillbaka',
      bottom: 'Undersida',
      front: 'Framsida',
      isometric: 'Isometrisk',
      left: 'Vänster',
      orthographic: 'Ortografisk',
      projection: 'Projektion',
      perspective: 'Perspektiv',
      right: 'Höger',
      top: 'Ovansida'
    },
    resetCamera: 'Återställ kamera',
    skiva: {
      label: 'Skiva',
      planeX: 'Skärplan X',
      planeY: 'Skärplan Y',
      planeZ: 'Skärplan Z',
      reset: 'Återställ skärplan',
      section: 'Växla sektion för skärplan',
      visibility: 'Växla synlighet på skärplan',
    },
    supportedFilesMessage: 'Documoto stödjer många olika format på CAD-filer. Se supportcentralen om du är osäker på om ditt CAD-filformat stöds.',
    toolsLabel: '3D-verktyg',
    topAssemblyFilename: 'Filnamn på övre montering',
    topAssemblyFilenameRequired: 'Ett filnamn krävs för övre montering',
    viewerTitle: '3D-vy',
  },
  thu: 'Tor',
  thumbnail: 'Miniatyrbild',
  thumbnailDeleted: 'Miniatyrbilden har tagits bort',
  thumbnailDeletedError: 'Det gick inte att ta bort miniatyrbilden',
  thumbnailUpdated: 'Miniatyrbilden har uppdaterats',
  thumbnailUpdatedError: 'Det gick inte att uppdatera miniatyrbilden',
  thumbnailReapplyWarning: 'Systemet kan tillämpa en miniatyrbild igen baserat på medieinnehållet. Detta kan ta några minuter.',
  thumbnailResizeTooltip: 'Ändrar storlek på miniatyrer till maximalt 500x500 pixlar om detta är valt',
  title: 'Titel',
  to: 'Till',
  toastNotifications: 'Meddelanden',
  toDate: 'Till datumet',
  toc: 'Innehållsförteckning',
  tocClipBoardSuccessTitle: 'Kopierat till Urklipp',
  tocClipBoardWarningText: 'VARNING: Det går inte att kopiera osparade ändringar till dina urklipp',
  tocClipBoardWarningTitle: 'Kopierat till Urklipp',
  tocDisplayingFirstValues: 'Visar de första 100 värdena. Använd filter för att visa fler.',
  tocDuplicateWarningText: 'Dubblettsida hittades i ett kapitel. Dubblettsidan har tagits bort.',
  tocEmptyMessage: 'Det verkar som att du inte har skapat någon innehållsförteckning än',
  toGetStarted: 'för att sätta igång.',
  tocMissingChapterName: 'Kapitelnamn saknas',
  tocModalTableCaption: 'Taggredigerare modal',
  tocNothingSelected: 'Inget valt',
  tocOpenEditor: 'Öppna redigerare',
  tocSaveRequired: 'Du måste spara innan du redigerar kapitelinformation',
  tocState: 'Innehållsförteckningens ursprungliga status',
  tocThumbnailDeleteFailTitle: 'Det gick inte att ta bort miniatyrbilden',
  tocThumbnailDeleteSuccessTitle: 'Miniatyrbilden togs bort',
  tocThumbnailUploadFailTitle: 'Det gick inte att ladda upp miniatyrbilden',
  tocThumbnailUploadSuccessTitle: 'Miniatyrbilden har laddats upp',
  tocTranslationMessage: 'Namn på och valfri beskrivning av detta kapitel',
  tocUntitledPage: 'Namnlös sida',
  tooManySelections: 'Listan överskrider begränsningen på {limit} val',
  togglePage: 'Växla Sida',
  top: 'Överst',
  total: 'Summa',
  totalItems: 'Objekt totalt',
  translation: 'Översättning | Översättningar',
  translationHelpTextItem: 'Namn på och valfri beskrivning av denna {item}',
  true: 'Sant',
  tryAgain: 'Försök igen.',
  tue: 'Tis',
  turkishDescription: 'Beskrivning på turkiska',
  turkishName: 'Turkiskt Namn',
  twoSided: 'Dubbelsidig',
  type: 'Typ | Typer',
  typeAtLeastTwoChars: 'Skriv åtminstone två bokstäver för att se en lista med träffar.',
  typeToConfirm: 'Typ {value} som ska bekräftas',
  typography: 'Typografi',
  ukraineDescription: 'Beskrivning på ukrainska',
  ukraineName: 'Ukrainskt Namn',
  unableToRetrieveResults: 'Det gick inte att hämta resultat för din sökning',
  unauthorized: 'Obehörig',
  unknownError: 'Okänt fel, kontakta Documoto support på support{\'@\'}documoto.com för hjälp',
  unknownPart: 'Okänd artikel',
  unknownType: 'Okänd typ',
  unsavedAPIKeyWarning: 'Din API-Nyckel kommer att gå förlorad om du inte Går tillbaka',
  unsavedChanges: 'Dina ändringar har inte sparats. Fortsätt?',
  unsavedChangesWarning: 'Dina ändringar kommer att förloras om du inte sparar dem.',
  unsavedWidgetKeyWarning: 'Din widgetnyckel kommer att gå förlorad om du inte återvänder.',
  unsupportedBrowserMessage: 'Vi har byggt det här programmet med den senaste teknologin. Det gör att programmet är snabbare och lättare att använda. Tyvärr stöder inte din webbläsare den här teknologin.',
  uom: 'Enhet',
  update: 'Uppdatera',
  updateFailed: 'Uppdatering misslyckades',
  updateIndex: 'Uppdatera index',
  updatePassword: 'Uppdatera lösenord',
  updatePasswordMessage: 'Lösenord uppdaterat',
  updatePasswordPending: 'Lösenordsändring väntar på att sparas.',
  updated: 'Uppdaterad',
  upgradeBrowser: 'Vänligen uppgradera din webbläsare.',
  upload: 'Ladda upp',
  uploadComplete: 'Uppladdning slutförd',
  uploadInProgress: 'Uppladdning pågår',
  uponSavingDisabledTenant: 'När denna sida sparas med denna inställning aktiverad så kommer följande att ske:',
  uponSavingThisLicense: 'DU MÅSTE GÖRA FÖLJANDE när denna sida sparas med denna inställning:',
  upperBound: 'Övre gräns',
  untitledOrg: 'Namnlös organisation',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Använd standardkolumner för kundvagn',
  useDefaultPartsListColumns: 'Använd standardkolumner för artikellista',
  user: 'Användare',
  userAddress: 'Användaradresser | Användaradresser',
  userCount: 'Användarantal',
  userEditable: 'Går att redigera av Användare',
  userExpirationDate: 'Utgångsdatum för användare',
  userExpirationDateInvalid: 'Utgångsdatum för användare måste anges',
  userGroup: 'Användargrupp | Användargrupper',
  userGroupEmpty: 'Användargrupper får inte vara tomma',
  userGroupType: 'Typ av användargrupp',
  userGroupUniqueMessage: 'Detta namn får inte vara samma som något annat användargruppnamn för denna innehavare',
  userGroupUsers: 'Användare i användargrupper',
  username: 'Användarnamn',
  usernameEmpty: 'Användarnamnet får inte vara tomt',
  users: 'Användare',
  usersWillReceiveAnUnauthorizedError: 'Användare kommer att få ett felmeddelande om "Otillåten åtkomst" när de försöker logga in på hyresgästen.',
  useSniForSsoMetadataRetrieval: 'Använd SNI för att hämta metadata för SSO',
  value: 'Värde',
  validate: 'Bekräfta',
  validateTenantDirectories: {
    buttonLabel: 'Bekräfta kataloger med innehåll',
    error: 'Kunde inte bekräfta kataloger',
    success: 'Bekräftade hyresgästers kataloger'
  },
  variantSKU: 'SKU-variant',
  validationMessage: 'Meddelande om bekräftelse | Meddelanden om bekräftelse',
  valueGteError: 'Värdet måste vara mindre än eller lika med {max}',
  valueLteError: 'Värdet måste vara större än eller lika med {min}',
  vendorName: 'Namn på återförsäljare',
  verifyPassword: 'Bekräfta lösenord',
  vertical: 'Lodrät',
  version: 'Version',
  video: 'Video | Videor',
  view: 'Vy',
  viewAll: 'Visa alla',
  viewAllPagesAndBooks: 'Granska: Alla sidor och böcker',
  viewAllResults: 'Visa alla resultat',
  viewBooks: 'Granska: Böcker',
  viewMore: 'Visa mer',
  viewPages: 'Granska: Sidor',
  viewModeList: 'Listvy',
  viewModeTable: 'Tabellvy',
  visibility: 'Synlighet',
  visibilityDescription: 'Vem kan se denna {entity}',
  visible: 'Synlig',
  waitForUpload: 'Vänta tills uppladdningen slutförs.',
  watermark: 'Vattenstämpel',
  watermarkFont: 'Teckensnitt för vattenstämpel',
  watermarkOpacityPercentage: 'Opacitet för vattenstämpel i procent',
  watermarkOrientation: 'Vattenstämpelns riktning',
  watermarkSaved: 'Vattenstämpel sparad',
  watermarkSaveFailed: 'Kunde inte spara vattenstämpel.',
  watermarkSelectionRequired: 'Val av användarnamn eller text krävs.',
  watermarkText: 'Text för vattenstämpel',
  wed: 'Ons',
  whereUsed: 'Var de används',
  whoCanViewComment: 'Vem kan se denna kommentar?',
  wholesale: 'Grossisthandel',
  wholesalePrice: 'Grossistpris',
  wholesaleTotal: 'Summa grossisthandel',
  widget: 'Widget',
  widgetAuthHeader: 'Värde för godkännande av widget',
  widgetKey: 'Widgetnyckel | Widgetnycklar',
  widgetKeyAdmin: 'Admin för widgetnyckel',
  widgetKeyCopied: 'Värde för godkännande av widget har kopierats till urklipp',
  widgetKeyDeleted: 'Widgetnyckel har raderats',
  widgetKeyDeletedError: 'Fel vid radering av widgetnyckel',
  widgetKeyDeleteMsg: 'Radering av widgetnyckel leder till att den inte kan användas',
  widgetKeyManager: 'Hantering av widgetnyckel',
  widgetKeyNameEmpty: 'Namnet måste vara unikt och är obligatoriskt. Detta värde kommer också att användas som ett användarnamn för all widgetaktivitet för rapportering och loggsyften',
  widgetKeyRegenError: 'Fel vid återskapande av widgetnyckel',
  widgetKeyRegenMsg: 'Återskapande av denna widgetnyckel kan inaktivera befintliga integrationer',
  widgetKeyRegenTitle: 'Är du säker på att du vill återskapa denna widgetnyckel?',
  widgetKeySaved: 'Widgetnyckeln har sparats',
  widgetPermissions: 'Widgetanvändare kan endast visas. Tillgång till funktioner hanteras av konfiguration på widgetnivå. Rättigheter för vanliga användargrupper gäller inte för widgetanvändare, då bör istället rättigheter för tillgång till mediakategorier användas. Vänligen fortsätt till tilldelning av rättigheter för tillgång till mediakategorier.',
  widgetRefreshErrorMsg: "Vänligen uppdatera sidan för att se det begärda innehållet",
  widgetUserGroup: 'Användargrupp för widgetanvändare',
  widgetUserGroupDeleteMsg: 'Kan inte radera widgetgrupp med användare',
  willBeAffected: 'kommer att påverkas.',
  willBeDeleted: '{content} kommer att tas bort.',
  willBeInsertedIntoItem: '{name} kommer att infogas i {item}',
  willBeUpdated: 'kommer att uppdateras.',
  zipCode: 'Postnummer',
  zoomHotpointToCanvas: 'Zooma Hotpoint till dukstorlek',
  zoomIn: 'Zooma In',
  zoomOut: 'Zooma Ut'
}
/* eslint-enable max-len */
