import { DrawMode } from '@/plugins/hoops/types/web-viewer'
import { readonly } from 'vue'

export type HoopsDrawMode = {
  iconType: string
  labelKey: string
  value: DrawMode
}

export const HoopsDrawModeOptions = readonly<HoopsDrawMode[]>([
  {
    iconType: 'wireframeshaded',
    labelKey: 'threeD.drawMode.wireframeShaded',
    value: DrawMode.WireframeOnShaded
  },
  {
    iconType: 'shaded',
    labelKey: 'threeD.drawMode.shaded',
    value: DrawMode.Shaded
  },
  {
    iconType: 'hidden-line',
    labelKey: 'threeD.drawMode.hiddenLines',
    value: DrawMode.HiddenLine
  },
  {
    iconType: 'wireframe',
    labelKey: 'threeD.drawMode.wireframe',
    value: DrawMode.Wireframe
  },
  {
    iconType: 'xray',
    labelKey: 'threeD.drawMode.label',
    value: DrawMode.XRay
  },
])
