<template>
  <stepper-btns :disablePrevious="disablePrevious"
                :disableNext="disableNext"
                @goToPrevious="goToPrevious"
                @goToNext="goToNext"/>
</template>

<script setup>
import StepperBtns from '@/components/common/StepperBtns'
import { computed } from 'vue'
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { useWidgetToc } from '@/components/widgets/composables/useWidgetToc'

const { contentPath } = useWidgetNavigator()
const { widgetTocFlatMap, widgetTocFlatList,
  goToContentInBook,  } = useWidgetToc()
const { isLoading } = useFetch()

const selectedIndex = computed(() => {
  return widgetTocFlatMap?.value?.get(contentPath.value)?.index
    ?? -1
})
const previousIndex = computed(() => selectedIndex.value - 1)
const previousPage = computed(() => {
  return widgetTocFlatList.value[previousIndex.value]
})
const disablePrevious = computed(() => !previousPage.value
  || isLoading.value)

const nextIndex = computed(() => selectedIndex.value + 1)
const nextPage = computed(() => {
  return widgetTocFlatList.value[nextIndex.value]
})
const disableNext = computed(() => !nextPage.value
  || isLoading.value)

async function goToPrevious() {
  if (disablePrevious.value) return
  await goToContentInBook(previousPage.value)

}
async function goToNext() {
  if (disableNext.value) return
  await goToContentInBook(nextPage.value)
}
</script>
