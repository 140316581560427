/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} de {b}',
  about: 'Sobre',
  aboutThisItem: 'Sobre este item',
  accessApiKeyManager: 'Aceder ao Gestor de Chave API para este locatário.',
  accessControls: 'Controlos de Acesso',
  accessControlsCopiedText: 'Os controlos de acesso são imediatamente atualizados. Os privilégios de segurança de suporte serão atualizados durante os próximos minutos.',
  accessControlsCopiedTitle: 'Controlos de acesso copiados com sucesso',
  accessControlsCopyConfirmation: 'Irá remover todos os controlos de acesso existentes de {to} e irá substituí-los com controlos de acesso de {from}. Esta ação não pode ser desfeita.',
  accessControlsDescription: 'Os controlos de acesso definem quem pode ver este suporte, com base nas Categorias de Suporte e Organizações selecionadas.',
  accessControlsIndeterminance: 'Nota: As organizações principais têm sempre acesso aos conteúdos atribuídos a qualquer uma das suas organizações secundárias.',
  account: 'Conta',
  accountErrorMessage: 'Mensagem Erro Conta',
  accountErrorMessageWarning: 'O campo Mensagem de Erro da conta na base de dados será configurado para «Não Autorizado» para este locatário.',
  action: 'Ação | Ações',
  actionDelete: 'eliminar',
  actionLoad: 'carregar',
  actionPaste: 'Colar',
  actionSave: 'Guardar',
  actionRun: 'executar',
  actionUndoWarning: 'Esta ação não pode ser desfeita.',
  actionUpload: 'carregar',
  actualSize: 'Tamanho Real',
  add: 'Adicionar',
  addAPIKey: 'Adicionar uma Chave API',
  addItem: 'Adicionar {item}',
  addABookText: 'Adicionar um livro',
  addASharedCart: 'Adicionar um carrinho partilhado',
  addATemplate: 'Adicionar um Modelo',
  addATag: 'Adicionar uma Etiqueta',
  addABookToStart: '{addABookText} para começar.',
  addAForm: 'Adicionar um formulário para começar',
  addAFormOnly: 'Adicionar um Formulário',
  addAMedia: 'Adicionar um Media',
  addAnOrderQuoteEmailFormat: 'Adicionar um Formato de Encomenda/Email de Cotação',
  addAPrintSettingText: 'Adicionar uma Configuração de Impressão',
  addAPrintSettingToStart: '{addAPrintSettingText} para começar.',
  addBooks: 'Adicionar Livros',
  addChildBrowseFlow: 'Adicionar um Fluxo de Navegação Secundário',
  addChapter: 'Adicionar Capítulo',
  addComment: 'Adicionar um comentário',
  addExistingPart: 'Adicionar Peça Existente',
  addGroupsToUsers: 'Adicionar grupos {groups} a utilizadores {users}',
  additionalInformation: 'Informação Adicional',
  additionalNotification: 'Notificação Adicional',
  additionalPartNumberDescription: 'Utilizar como campo de número de peça adicional',
  addBrowseFlow: 'Adicionar um Fluxo de Navegação',
  addMedia: 'Adicionar Suporte',
  addPages: 'Adicionar Páginas',
  addPart: 'Adicionar uma Peça',
  addReports: 'Adicionar Relatórios',
  addRootBrowseFlow: 'Adicionar um Fluxo de Navegação Raiz',
  addRow: 'Adicionar Linha',
  addTemplates: 'Adicionar Modelos',
  addToCartMessage: 'Adicionado com sucesso {partNumber}',
  addToUserGroup: 'Adicionar a Grupos',
  address: 'Endereço | Endereços',
  addressInUse: 'Atualmente encontra-se definido um endereço como o endereço predefinido e não pode ser eliminado',
  addressInUseSubtitle: 'Para eliminar este endereço, primeiro deve remover o mesmo como predefinição.',
  addressLine: 'Linha de Endereço {number}',
  addWidgetKey: 'Adicionar uma chave de widget',
  admin: 'Admin',
  adminCenter: 'Centro de Admin',
  adminCenterBanner: {
    bodyLabel: 'Corpo da faixa',
    enableLabel: 'Ativar faixa de administrador',
    headerGreenOption: 'Verde',
    headerLabel: 'Cabeçalho da faixa',
    headerRedOption: 'Vermelho',
    headerYellowOption: 'Amarelo',
    title: 'Faixa do Centro de Administração',
    typeLabel: 'Tipo de faixa'
  },
  adminCenterMessage: 'Centro de administração para conteúdos, utilizadores, definições de biblioteca, relatórios e exportação',
  adminMessage: 'Funcionalidades adicionadas à medida que ficam disponíveis',
  administration: 'Administração',
  affectedBookMedia: '{count} O conteúdo de média dos livros será atualizado.',
  affectedTemplateMedia: '{count} Os modelos de livros serão atualizados.',
  after: 'Depois',
  aggregatedFromChildren: 'Agregado a partir dos Secundários',
  aiAlgorithm: 'Scanner de IA',
  all: 'Todos',
  alphanumeric: 'Alfanumérico',
  algorithm: 'Algoritmo',
  alignHorizontal: 'Alinhar Horizontal',
  alignVertical: 'Alinhar Vertical',
  allUsersDisabled: 'Todos os utilizadores que pertençam a este locatário serão desativados.',
  analyticsJSON: 'JSON Analítico',
  andCountMore: 'e mais {count}...',
  any: 'Qualquer um',
  apiKey: 'Chave API | Chaves API',
  apiKeyAdmin: 'Admin de Chave API',
  apiAuthHeader: 'Cabeçalho de Autorização API',
  apiKeyCopied: 'Valor do Cabeçalho de Autorização API copiado para Área de transferência',
  apiKeyDeleted: 'Chave API eliminada',
  apiKeyDeletedError: 'Erro ao eliminar Chave API',
  apiKeyDeleteMsg: 'Excluir esta Chave API irá desativar a sua utilização por integrações',
  apiKeyDeleteTitle: 'Tem a certeza de que pretende eliminar esta Chave API?',
  apiKeyInstructions: 'A chave será gerada quando Guardada',
  apiKeyManager: 'Gestor de Chave API',
  apiKeyNameEmpty: 'O nome não pode estar vazio',
  apiKeyRegenError: 'Erro ao gerar Chave API',
  apiKeyRegenMsg: 'Gerar novamente esta Chave de API pode desativar as integrações existentes',
  apiKeyRegenTitle: 'Tem a certeza de que pretende regenerar esta Chave API?',
  apiKeySaved: 'Chave API Guardada',
  apiKeyType: 'Tipo Chave API',
  appliesTo: 'Aplicável a',
  appliesToEmpty: 'Requer, pelo menos, um tipo de entidade selecionado',
  appliesToPopulated: 'Definir a que entidades pode ser aplicada a etiqueta',
  applyItem: 'Aplicar {item}',
  applyToChildItems: 'Aplicar a Secundário {item}',
  applyToPartOnly: 'Aplicar apenas a esta peça',
  april: 'Abril',
  area: 'Área',
  areYouSure: 'Tem a certeza de que pretende eliminar {item}?',
  areYouSureCopy: 'Tem a certeza de que pretende copiar {item}?',
  areYouSureYouWantToDisableTheTenant: 'Tem a certeza de que pretende desativar o locatário?',
  assembly: 'Montagem | Montagens',
  assemblyCreatorSuccessTitle: 'Criar trabalho de montagem submetido',
  assemblyCreatorSuccessSubtitle: 'As alterações nas ligações dos pontos quentes e no índice do livro serão feitas imediatamente. Os resultados de pesquisa serão aplicados assim que a indexação for concluída.',
  assemblyConfirmationTitle: 'Deseja concluir a criação da montagem?',
  assemblyCreatorHelpText1: 'Esta funcionalidade permite-lhe inserir a página selecionada em livros sempre que esta peça {partName} for encontrada, ligando automaticamente a peça e a página selecionada através de uma ligação hotpoint. Pode restringir opcionalmente a inserção para apenas livros selecionados.',
  assemblyCreatorHelpText2: 'A página selecionada é apenas inserida uma vez por livro após a primeira ocorrência de {partName}.',
  assemblyStepOne: 'Passo 1: Selecione a página a ser inserida',
  assemblyStepTwo: 'Passo 2: Escolha os livros onde pretende que a página seja inserida',
  asset: 'Ativo | Ativos',
  assetsNotFound: 'Sem {type} Encontrado',
  assignBrowseFlow: 'Atribuir Fluxo de Navegação',
  assignedOrganizations: 'Organizações Atribuídas',
  atLeastFiveError: 'Selecione um valor que não seja inferior a 5.',
  attachment: 'Acessório | Acessórios',
  attachmentEditorDescription: 'Anexe imagens, vídeos e documentos diretamente a esta entidade. Tenha em consideração que serão encontrados no separador “Relacionados”.',
  attachmentView: 'Ver Anexos',
  attachRelatedPDFs: 'Anexar PDF de páginas de peças relacionadas',
  attributions: 'Atribuições',
  august: 'Agosto',
  automaticZoom: 'Zoom Automático',
  autoHotpoint: 'Ponto quente Automático',
  autoPopulate: 'Preencher Automaticamente',
  availability: 'Disponibilidade',
  back: 'Retroceder',
  backCover: 'Contracapa',
  backStitch: 'Pesponto',
  backToLibrary: 'Regressar à Biblioteca',
  backToSearch: 'Regressar à Pesquisa',
  badCredentials: 'Credenciais Incorretas',
  bahasaDescription: 'Descrição em Bahasa Indonésio',
  bahasaName: 'Nome em Bahasa Indonésio',
  banner: 'Banner',
  before: 'Antes',
  beginTypingToFindAnything: 'Comece a digitar para encontrar algo',
  betaAccessKeys: 'Chaves Acesso Beta',
  betaResources: 'Recursos Beta',
  betaReleaseVideo: 'Vídeo de Divulgação Beta',
  blobFinder: 'Localizador BLOB',
  body: 'Corpo',
  bomDefaultSupplier: 'Fornecedor Predefinido de Lista de Materiais (BOM)',
  bomItem: 'Item BOM',
  bom: 'BOM',
  bomExportSuccess: 'Exportação com sucesso de Lista de Materiais (BOM)',
  bomLayout: 'Configuração de Lista de Materiais',
  bomListOptions: 'Opções da Lista BOM',
  bomListSettings: 'Configurações da Lista BOM',
  both: 'Ambos',
  book: 'Livro | Livros',
  bookInserts: 'Inserções de Livro',
  bookLabel: 'Livro | Livros',
  bookPrintingDisable: 'Desativar Impressão de Livro',
  booksAndPagesDisplay: 'Apresentação de Livros e Páginas',
  boost: 'Boost de Pesquisa',
  boostDescription: 'Adicionar um valor acima de 1,5 para aumentar a relevância de pesquisa desta etiqueta em relação a números de peças, identificadores de suportes e nomes',
  boostRangeInvalid: 'O reforço deve estar entre 0,5 e 100,0',
  border: 'Limite',
  bottom: 'Fundo',
  branding: 'Marca',
  browse: 'Procurar',
  browseComputer: 'Pesquisar no Computador',
  browseFlow: 'Fluxo de Navegação | Fluxos de Navegação',
  browseFlowAssigned: 'Fluxo de Navegação Atribuído',
  browseFlowCopyMessage: 'Insira um nome único para o novo Fluxo de Navegação.',
  browseFlowDeleted: 'Fluxo de navegação excluído',
  browseFlowDeleteMessage: 'Apagar um fluxo de navegação não só apaga o fluxo de navegação como todos os seus fluxos de navegação secundários.',
  browseFlowEmptyState: 'Sem Fluxos de Navegação definidos. {new} para começar.',
  browseFlowHeirarchy: 'Hierarquia de Fluxo de Navegação',
  browseFlowNoExistingCriteria: 'Não foi definido nenhum critério de pesquisa para este fluxo de navegação. Utilize os controlos acima para selecionar o critério de pesquisa.',
  browseFlowMoveToRoot: 'Solte o nó aqui para criar secundários de primeiro nível',
  browseFlowNoAssignedOrgs: 'Sem organizações atribuídas',
  browseFlowSaveFailed: 'O Fluxo de Navegação não pode ser atualizado',
  browseFlowSetDefaultSuccess: 'Fluxo de Navegação Predefinido configurado',
  browseFlowThumbnailDeleteFailTitle: 'Erro ao Apagar de Miniatura',
  browseFlowThumbnailDeleteSuccessTitle: 'Miniatura Apagada com Sucesso',
  browseFlowThumbnailUploadFailTitle: 'Erro no Carregamento de Miniatura',
  browseFlowThumbnailUploadSuccessTitle: 'Miniatura Carregada com Sucesso',
  browseFlowTooManyOrgs: 'Tem demasiadas organizações a utilizarem o atual editor de fluxo de navegação. Contacte o Suporte Documoto através do email {email} para obter ajuda sobre a edição dos seus fluxos de navegação Documoto.',
  browserPartiallySupport: 'Poderá ver diferenças estéticas e outras alterações neste explorador.',
  browseFlowSaved: 'Fluxo de Navegação guardado',
  browserDeprecation: {
    title: 'Aviso de Depreciação de Navegador',
    text: 'Em linha com o suporte de eliminação progressiva da Microsoft para Internet Explorer (todas as versões) e questões de segurança relacionadas, não iremos suportar o acesso de utilizador a este site através do Internet Explorer (todas as versões) após 30 de setembro de 2021.'
  },
  buildHotpointLinks: 'Construir Ligações Hotpoint',
  buildHotpointLinksInfo: 'Esta ação irá primeiro remover todas as ligações hotpoint existentes de páginas de livros, depois cria novas ligações hotpoint entre peças e quaisquer páginas de montagem correspondentes.',
  buildHotpointLinksTitle: 'Deseja construir ligações hotpoint neste livro?',
  bulkIndexingLane: 'Faixa de Indexação em Massa',
  bulkInsert: 'Inserção em Massa',
  bulkExportConfirm: 'Deseja exportar {n} {unit}?',
  bulkExportLimit: 'O limite é {limit} livros por tarefa de exportação',
  bulkPageInsertJob: 'Inserção de Página em Volume',
  business: 'Business',
  cancel: 'Cancelar',
  canceled: 'Cancelado',
  cancelAssemblyCreatorTitle: 'Eliminar a sua montagem criada?',
  cancelAssemblyCreatorSubtitle: 'A sua montagem criada não será processada.',
  cannotExceed100: 'Não pode exceder 100%',
  cart: 'Carrinho | Carrinhos',
  cartBehaviorEnabled: 'Ativar Lembrete de Quantidade a Adicionar ao Carrinho',
  cartBehaviorDisabled: 'Desativar Lembrete de Quantidade a Adicionar ao Carrinho',
  cartBehaviorLabel: 'Comportamento de Quantidade a Adicionar ao Carrinho',
  cartColumn: 'Coluna de Carrinho | Colunas de Carrinho',
  cartColumn1: '1.ª Coluna de Preço de Carrinho de Compras',
  cartColumn2: '2.ª Coluna de Preço de Carrinho de Compras',
  cartColumn3: '3.ª Coluna de Preço de Carrinho de Compras',
  cartColumnPartLocationContent1: 'Ativar esta funcionalidade terá as seguintes implicações:',
  cartColumnPartLocationContent2: 'A singularidade da linha de peça no carrinho de compras também se irá basear na sua localização dentro de determinado conteúdo, se aplicável.',
  cartColumnPartLocationContent3: 'Esta configuração não pode ser desativada ao nível da organização e será aplicável a todos os utilizadores.',
  cartColumnPartLocationContent4: 'Esta configuração não deve ser ativada se tiver uma integração Submeter carrinho sem contactar primeiro o seu Gestor de Sucesso de Cliente Documoto.',
  cartColumnPartLocationOption: 'Ativar Dados de Localização de Peça no Carrinho',
  cartColumnPartLocationTitle: 'Tem a certeza de que deseja ativar os Dados de Localização de Peça no Carrinho?',
  cartDisable: 'Desativar Carrinho de Compras',
  cartEmailFailed: 'Falha no envio do e-mail do carrinho',
  cartEmailSent: 'E-mail do carrinho enviado',
  cartEnable: 'Ativar Carrinho de Compras',
  cartSaveEnable: 'Ativar Guardar Carrinho de Compras',
  cartErpMessage: 'Enviado com sucesso para Erp',
  cartImportError: 'Erro ao importar carrinho',
  cartLoadedWithItemsRemoved: '{count} item(ns) removidos porque não tem autorização para os encomendar neste momento',
  cartSharedAddEdit: 'Adicionar / Editar Carrinhos de Compras Partilhados',
  cartUpdated: 'Carrinho Atualizado',
  cartUpdatedAndRemovedCount: 'Carrinho Atualizado; {count} peças não disponíveis removidas',
  category: 'Categoria | Categorias',
  categoryCreated: 'Categoria Criada',
  categoryUpdated: 'Categoria Atualizada',
  centimeter: 'Centímetro | Centímetros',
  changeNotification: 'Alterar Notificação',
  changeScope: '{n} {type} será afetada',
  changeUserOrganization: 'Alterar Organização',
  chapter: 'Capítulo | Capítulos',
  chapterHeader: 'Cabeçalho de Capítulo',
  chaptersOnly: 'Apenas Capítulos',
  chineseDescription: 'Descrição em Chinês',
  chineseName: 'Nome em Chinês',
  circle: 'Círculo',
  city: 'Cidade',
  classicAdminMessage: 'Vai direcioná-lo novamente para o Flex para utilizar os módulos de administração.',
  classicAdmins: 'Admins Clássicas',
  classicExporter: 'Exportador Clássico',
  classicExporterMessage: 'Módulo de exportação de conteúdo Baseado em Flash antigo',
  classicOrganizationAdmin: 'Administrador Clássico da Organização',
  classicOrganizationAdminMessage: 'Módulo de administração de utilizador Baseado em Flash antigo para gerir organizações e utilizadores',
  classicPublisher: 'Publicador Clássico',
  classicPublisherMessage: 'Módulo de publicador Baseado em Flash antigo para criação de conteúdo e configurações de impressão',
  classicReporting: 'Relatório Clássico',
  classicReportingMessage: 'Módulo de relatório Baseado em Flash antigo',
  classicTenantAdmin: 'Administrador de Inquilino Clássico',
  classicTenantAdminMessage: 'Módulo de administração Baseado em Flash antigo para configurações ao nível do inquilino',
  className: 'Nome da classe',
  clear: 'Limpar',
  clearItemNumber: 'Limpar Número de Artigo',
  clearItemNumberTitle: 'Deseja limpar número de artigo?',
  clearItemNumberMessage: 'Limpar o número do artigo vai resultar na remoção dos seguintes dados:',
  clearFilters: 'Limpar Filtros',
  clearHotpoints: 'Limpar pontos quentes',
  clearSearch: 'Limpar Pesquisa',
  clickHereToUpload: 'Largar ficheiro ou clicar para carregar um substituto.',
  clickMagnifyingGlass: 'Clique na lupa para ir diretamente para a Pesquisa Avançada.',
  clickOkToContinue: 'Clique «OK» para continuar.',
  clickToDownload: 'Clicar para Transferir',
  clickToEnlarge: 'Clique para Aumentar',
  clickToOpen: 'Clicar para Abrir',
  clipboard: 'Área de Transferência',
  clipboardAccessError: 'Ajuste as suas configurações do navegador para permitir o acesso à Área de Transferência',
  close: 'Fechar',
  closed: 'Fechado',
  collapseAll: 'Ocultar Todos',
  color: 'Cor | Cores',
  column: 'Coluna | Colunas',
  columnName: 'Nome da Coluna',
  columnItemType: {
    customText: 'Texto Personalizado',
    documentTitle: 'Título do Documento',
    horizontalLine: 'Linha Horizontal',
    pageName: 'Nome da Página',
    pageNumber: 'Número da Página',
    printDate: 'Data de Impressão',
    tenantName: 'Nome do Inquilino',
    userName: 'Nome de Utilizador'
  },
  combinedPage: 'Página Combinada',
  combinedPageMessage: 'Diagrama no topo, lista de peças no fundo',
  comingSoon: 'Em Breve',
  commaDelimited: 'Delimitado por vírgula',
  comment: 'Comentário | Comentários',
  commentAdded: 'Comentário adicionado',
  commentDeleted: 'Comentário eliminado',
  commentEdit: 'Adicionar / Editar Comentários',
  commentEditAll: 'Editar Todos os Comentários',
  commentUpdated: 'Comentário atualizado',
  commentView: 'Ver Comentários',
  commerceSystem: 'Sistema Commerce',
  completed: 'Concluído',
  confirm: 'Confirmar',
  confirmBrowseFlowApplyToChildren: 'Deseja aplicar este fluxo de pesquisa às organizações secundárias?',
  confirmBrowseFlowOverrideToChildren: 'Isto irá remover quaisquer substituições de fluxo de navegação nas organizações secundárias.',
  confirmPrintSettingsApplyToChildren: 'Deseja aplicar esta configuração de impressão às organizações secundárias?',
  confirmPrintSettingsOverrideToChildren: 'Isto irá remover quaisquer substituições de configuração de impressão nas organizações secundárias.',
  contains: 'Contém',
  containsHeaderRow: 'Contém Linha do Cabeçalho',
  content: 'Conteúdo | Conteúdos',
  contentAccessControls: 'Controlos de Acesso {content}',
  contentEntity: 'Entidade de Conteúdo | Entidades de Conteúdo',
  contentNotFound: 'Conteúdo não encontrado',
  contentUpdatedQueueJob: 'Fila Atualizada de Conteúdo',
  contourShape: 'Forma de contorno',
  convertEntity: 'Converter {entity}',
  convertToEntity: 'Converter para {entity}',
  copyAccessControls: 'Copiar Controlos de Acesso',
  copyAccessControlsWarning: 'Isto irá copiar todos os controlos de acesso atribuídos a partir de uma Organização selecionada para esta Organização atual',
  copy: 'Copiar',
  copyAndRetain: 'Copiar e Guardar para Utilização de Integração',
  copyAndRetainWidget: 'Copiar e guardar, esta é a única vez que o valor de autorização do widget será visível para si na aplicação',
  copyAtoB: 'Copiar {a} para {b}',
  copyContent: 'Copiar {type}? {name}',
  copyingItem: 'Copiar {item}',
  copyItem: 'Copiar {item}',
  copyItemQuestion: 'Copiar {item}?',
  copyTo: 'Copiar para {target}',
  copyMeMessage: 'Copiar-me nesta mensagem',
  copyrightMessage: '© 2010-{year} Documoto Inc. Todos os direitos reservados.',
  count: 'Contar',
  country: 'País | Países',
  coverPages: 'Páginas de Capa',
  coverAmpStitchPages: 'Capa e Páginas de Costura',
  createAssembly: 'Criar Montagem',
  created: 'Criado',
  createdBy: 'Criado por {name}',
  createdDate: 'Data criada',
  createdOn: 'Criado em',
  currentPassword: 'Palavra-passe Atual',
  custom: 'Personalizar',
  czechDescription: 'Descrição em Checo',
  czechName: 'Nome em Checo',
  dashboard: 'Painel de Controlo | Painéis de Controlo',
  dashboardLibrary: 'Biblioteca de Dashboard',
  dataInsights: 'Informações de Dados',
  dataInsightsInfoMessage: 'Centro de Relatórios e Dashboard para informações de conteúdos, utilizadores e dados da biblioteca.',
  dataMapping: 'Mapeamento de Dados/Dados de Amostra (até 10 linhas)',
  dataOptions: 'Opções de dados',
  date: 'Data',
  dateModified: 'Data de Modificação',
  debugPageBuilderPLZ: 'Debug Construtor de Página PLZ',
  december: 'Dezembro',
  decreaseHotpoint: 'Reduzir Ponto quente',
  default: 'Predefinições',
  defaultAddress: 'Endereço Predefinido',
  defaultBrowseFlow: 'Fluxo de Navegação Predefinido',
  defaultCurrencyCode: 'Código de Moeda Predefinido',
  defaultCurrencySymbolOn: 'Símbolo Moeda Pré-Definido On',
  defaultEmailRecipient: 'Destinatário de Email Predefinido | Destinatários de Email Predefinidos',
  defaultImage: 'Imagem Predefinida | Imagens Predefinidas',
  defaultImagesManager: 'Gestor de Imagens Predefinido',
  defaultItemImage: 'Imagem Predefinida {item}',
  defaultPartsListLayout: 'Layout da Lista de Peças Predefinida',
  defaultPartsListLayoutHelp: 'Layout de peças predefinido para a esquerda ou por baixo da ilustração',
  defaultSearchMode: 'Modo de Pesquisa Predefinido',
  defaultSearchModeHelp: 'Método de pesquisa global predefinido para \'contains\' ou \'exact\'',
  defaultSortMode: 'Modo de Organização Predefinido',
  defaultSupplier: 'Fornecedor Predefinido',
  defaultTocStateOpen: 'Estado Predefinido do Índice Aberto',
  defaultTocStateOpenHelp: 'Determina se o Índice deve abrir automaticamente quando visualiza um livro',
  defaultUom: 'UOM Predefinido',
  defaultValue: 'Valor Padrão',
  defaults: 'Padrões',
  defaultsAndCapabilities: 'Predefinições e Capacidades',
  delete: 'Eliminar',
  deleteAll: 'Herdar Do Principal',
  deleteAnyPartnerApiKeys: 'Elimine quaisquer Chaves API de Parceiro que tenham sido fornecidas a SmartEquip.',
  deleteContent: 'Tem a certeza de que deseja eliminar {type}? {name}',
  deletePart: {
    infoOne: 'As exclusões de partes terão efeito imediato na Biblioteca e na Administração de Conteúdo. A parte excluída permanecerá pesquisável até que a reindexação ocorra dentro de alguns minutos.',
    deletingPartNumber: '{partNumber} será eliminada.',
    affectedCarts: 'Todos os carrinhos que contêm esta parte terão a parte removida permanentemente.',
    infoTwo: 'Esta ação não pode ser revertida sem a assistência do Suporte da Documoto. Após 90 dias, o registo eliminado e todas as associações serão permanentemente eliminados.'
  },
  deletePartInUse: {
    title: 'Esta parte pertence à(s) página(s) e não pode ser eliminada.',
    body: 'A Documoto atualmente apenas suporta a eliminação de partes que não pertencem a páginas. Por favor, remova esta parte de qualquer página publicada ou de rascunho para prosseguir com a sua eliminação.',
    submitLabel: 'Ir para a página onde foi utilizado'
  },
  deleteRelated: 'Tem a certeza que deseja eliminar {count} {type}?',
  deletedItem: '{item} eliminado',
  deleteFilesMessage: 'Tem a certeza de que deseja eliminar {count} ficheiro(s)?',
  deleteHotpointLinksTitle: 'Tem a certeza de que deseja eliminar ligação(ões) hotpoint?',
  deleteMessage: 'Tem a certeza de que deseja eliminar?',
  deleteSelectedCriteria: 'Eliminar critérios selecionados',
  deleteTagsTitle: 'Tem a certeza de que deseja eliminar tag(s)?',
  deleteTaxonMessage: 'Isto irá eliminar de forma permanente este táxon juntamente com qualquer um dos secundários. Tem a certeza de que pretende continuar?',
  deleteTaxonomyMessage: 'Isto irá eliminar de forma permanente todos os táxons para esta Org ou Inquilino. Tem a certeza de que pretende continuar?',
  deleteTenantSupplier: 'Tem a certeza que deseja eliminar este fornecedor do locatário?',
  demo: 'Demo',
  description: 'Descrição',
  deselectAll: 'Anular Seleção de Todos',
  deselectCriteria: 'Anular Seleção de Critérios',
  detail: 'Detalhe | Detalhes',
  diagonal: 'Diagonal',
  diameter: 'Diâmetro',
  disable: 'Desativar',
  disableAll: 'Desativar Todos',
  disableDocumotoTenant: 'Desativar Locatário Documoto',
  disableFwdReindex: 'Desativar Reindex Encaminhamento Media Alvo para DSW',
  disableLibraryBannerImage: 'Desativar Imagem de Banner de Biblioteca',
  disableSmartEquipModalTitle: 'Tem a certeza de que pretende desativar esta licença?',
  disableTEK: 'Desativar Login Direto URL',
  disabled: 'Desativado',
  discard: 'Eliminar',
  discardChanges: 'Eliminar alterações',
  discardQ: 'Eliminar alterações?',
  discounted: 'Desconto',
  discountedPrice: 'Preço Descontado',
  discountedTotal: 'Total Descontado',
  discountPrice: 'Preço de Desconto',
  displayInOrdersList: 'Exibir na Lista de Pedidos',
  displayOrder: 'Mostrar Pedido',
  displayOrderDescription: 'Define a ordem de apresentação do menu pendente de Filtros para etiquetas de tipo de conteúdo',
  document: 'Documento | Documentos',
  documentLabel: 'Documento | Documentos',
  documentSettings: 'Configurações de Documentos',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Pacote Licença Documoto',
  domain: 'Domínio',
  domainEmptyMessage: 'Domínio Permitido não pode ficar vazio',
  domainInvalidMessage: 'É necessário um nome de domínio válido',
  download: 'Transferir',
  downloadBrowser: 'Transferir um destes grandes navegadores.',
  downloadFailed: 'Erro ao descarregar {name}',
  downloadStarted: 'Transferência em andamento para {name}',
  draft: 'Rascunho | Rascunhos',
  draftBomDeleteTitle: 'Tem a certeza de que pretende eliminar esta peça da página?',
  draftBomMultipleDeleteTitle: 'Tem a certeza de que pretende eliminar estas partes da página?',
  draftBomDropError: 'Não pode agrupar peças com mais de 5 níveis de profundidade',
  draftBomEmptyField: 'Todos os campos da Lista de Peças devem ser preenchidos',
  draftBomQuantityError: 'A quantidade deve ser um número',
  draftBomError: 'Erro ao manipular o rascunho de Lista de Materiais',
  draftPage: 'Página de Rascunho',
  draftPageDelete: 'Eliminar Rascunho',
  draftPageDeleteMessage: 'Página de rascunho eliminada com êxito.',
  draftPageDeleteWarning: 'A eliminação da Página de Rascunho irá remover as atualizações em curso.',
  draftPageLastUpdated: 'Rascunho - Última Atualização {date} por {username}',
  draftPageLoadError: 'Falha no carregamento da página de rascunho.',
  draftPageProcessingPlz: 'Página a ser carregada a partir do ficheiro PLZ',
  draftPagePublishInProgress: 'Trabalho de Publicação de Página em curso',
  draftPageSaveSuccess: 'Página de rascunho guardada com sucesso.',
  draftPageSaveUnsuccessful: 'Página de rascunho guardada sem sucesso.',
  draftSheetImageHotpointsFailure: 'Não é possível gerar pontos quentes {algorithm} para imagem de folha de rascunho.',
  draftValidationMessage: {
    item: 'AVISO: Número {partNumber} de Peça BOM não tem Número de Item especificado',
    empty: 'Sem Erros / Avisos de Validação',
    hotpointOrHotpointLink: 'Artigo {item} BOM não tem ponto(s) quente(s)/ligação(ões) a ponto quente especificada',
    quantity: 'Artigo {item} BOM não tem quantidade especificada',
    tag: 'Artigo {item} BOM etiqueta {tagName} valor de etiqueta em falta',
    translationDescription: 'Artigo {item} BOM descrição de tradução {lang} em falta',
    uom: 'Artigo {item} BOM não tem unidade de medida especificada',
    supersede: 'Peça {partNumber} substituída {supersededPartNumber} devido a suplantação.'
  },
  drawAutohotpointRegion: 'Desenhar {shape} dimensão',
  drawMinimumArea: 'Desenhar área mínima',
  dropFileMessage: 'Largar ficheiro aqui ou clicar para carregar.',
  duplicate: 'Duplicar',
  duplicateSaveError: 'Entrada duplicada. Não é possível guardar.',
  dutchDescription: 'Descrição em Neerlandês',
  dutchName: 'Nome em Neerlandês',
  dynamic: 'Dinâmica',
  dynamicNaming: 'Nomeação Dinâmica',
  ecommerce: 'eCommerce',
  ecommerceEmailFormatCreated: 'Formato de E-mail de Comércio Eletrónico criado',
  ecommerceEmailFormatSaved: 'Formato de E-mail de Comércio Eletrónico guardado',
  ecommerceLogo: 'Logótipo de comércio eletrónico',
  ecommerceUseExtOrder: 'Número de Pedido Externo Utilização ecommerce',
  edit: 'Editar',
  editItem: 'Editar {item}',
  email: 'Email',
  emailFormat: 'Formato de E-mail',
  emailFormatTemplate: 'Modelo de Formato de E-mail | Modelos de Formatos de E-mail',
  emailList: 'Lista de Email',
  emailMessage: 'É necessário um email',
  emailRecipient: 'Destinatário de Email | Destinatários de Email',
  emailRecipientMessage: 'Estes endereços de email irão receber um email quando o estado da encomenda mudar para',
  emptyMessage: {
    orderSuggestions: 'Parece que ainda não existem Sugestões de Pedido para esta peça.'
  },
  emptyBomState: 'Nenhum item BOM definido para página. Clique  +{add} para começar',
  enable: 'Ativar',
  enable3d: 'Ativar 3D',
  enableAll: 'Ativar Todos',
  enableAPIKeyAdmin: 'Ativar Admin de Chave API',
  enabled: 'Ativado',
  enableCommerce: 'Ativar Commerce',
  enableEcommerceOrderManagement: 'Ativar Gestão de Pedido',
  enableExporter: 'Ativar Exportador',
  enableExportToLinkOne: 'Ativar Exportador para LinkOne',
  enableExportToSmartEquip: 'Ativar Exportador para SmartEquip',
  enablePDFDownloadWarning: 'Ativar Aviso de Transferência de PDF Grande',
  largePDFDownloadWarning: 'Devido ao elevado tamanho do ficheiro PDF, pode demorar mais tempo do que o habitual para carregar e apresentar o documento na aplicação - aguarde enquanto o ficheiro transfere para fins de visualização.',
  enableIframeSupport: 'Ativar Apoio iFrame',
  enableLibraryBetaFeatures: 'Ativar funcionalidades Beta da biblioteca',
  enablePerformanceLogging: 'Ativar Logging Desempenho',
  enablePLZToDraft: 'Ativar Processo PLZ para Draft',
  enableQuickAddToCart: 'Ativar Adicionar Rápido ao Carrinho',
  enableQuickAddToCartHelpContext: 'Configuração em \”Ativar\' irá ativar a função Adicionar Rápido ao Carrinho acima do visualizador de conteúdo para este conteúdo.',
  enableRocketUi: 'Ativar Experiência de Utilizador Rocket',
  enableSmartEquipApiIntegration: 'Ativar Integração API SmartEquip',
  enableWidgetLogo: 'Ativar o logótipo do Documoto Widget',
  enableWidgets: 'Ativar Widgets',
  encryptedKey: 'Chave Encriptada',
  endDate: 'Data Final',
  endDateBad: 'A data de início tem de ser igual ou anterior à data de fim.',
  enforceNumericSize: 'Aplicar Tamanho Numérico',
  englishDescription: 'Descrição em Inglês',
  englishName: 'Nome em Inglês',
  enterprise: 'Empresa',
  enterReportParameters: 'Inserir Parâmetros de Relatório',
  enterUniqueNameForNewItem: 'Insira um nome único para o novo {item}',
  enterValidNumber: 'Insira um número válido',
  enterValidEmail: 'Insira um email válido',
  entityType: 'Tipo de entidade | Tipos de entidade',
  erp: 'ERP',
  erpEndpointConfig: 'Configuração Terminal-ERP',
  erpPartInfo: 'ERP Part Info Cache Duração Minutos',
  erpSystem: 'Sistema ERP',
  erpSystems: 'Sistemas ERP',
  erpSystemSaved: 'Sistema ERP guardado',
  error: 'Erro',
  errorDeletePageMessage: 'Ocorreu um erro ao remover a página.',
  errorInputParsing: 'Problema ao analisar ficheiro de entrada.',
  errorPageMessage: 'Ocorreu um erro.',
  errorPageTitle: 'Ocorreu um erro aqui...',
  errorType: {
    error: 'Erro',
    warning: 'Aviso'
  },
  essential: 'Essencial | Essenciais',
  eta: 'Data Prevista de Entrega',
  etlJob: 'ETL',
  exact: 'Exato',
  excelFile: 'Ficheiro de Excel',
  expandAll: 'Expandir Todos',
  export: 'Exportar',
  exportSearch: 'Exportar Pesquisa',
  exportAsCsv: 'Exportar como CSV (Formato Necessário para Importação)',
  exportAsCSVDropdown: 'Exportar como CSV',
  exportAsHtml: 'Exportar como HTML',
  exportAsPdf: 'Exportar como PDF',
  exportAsPlz: 'Exportar como PLZ',
  exportAsXlsx: 'Exportar como XLSX',
  exportBasicPartInfo: 'Exportar Informação de Peça Básica',
  exportCartToXlsxDisabled: 'Desativar Exportação de Carrinho para XLSX',
  exportCartToXlsxEnabled: 'Ativar Exportação de Carrinho para XLSX',
  exportDisplayedCartColumns: 'Exportar Colunas de Carrinho Apresentadas',
  exportJob: 'Exportação de Tarefa',
  exportLane: 'Faixa Exportar',
  exportMessage: 'A tarefa exportada foi submetida com sucesso.',
  exporter: 'Exportador',
  exporterMessage: 'Permite-lhe exportar conteúdo',
  exportParts: 'Exportar Peças',
  external: 'Externo | Externos',
  externalUrl: 'URL Externo',
  faceted: 'Facetada',
  facetedTag: 'Os valores dos Filtros devem ser multifacetados',
  facetedTagTooMany: 'A etiqueta excede o valor limite de etiqueta única de 1000',
  failedAction: '{content} falhou a {action}.',
  failedProcessContentSql: 'Falha no Conteúdo do Processo devido a texto não autorizado. Volte a carregar conteúdo suportado.',
  failedToCopy: 'Não foi possível copiar {content}',
  failedToDelete: 'Não foi possível eliminar {content}.',
  failedToLoad: '{content} falhou no carregamento.',
  failedToSave: 'Não foi possível gravar {content}.',
  failedToSubmitItem: 'Falha a submeter {item}',
  failedToUpdate: 'Não foi possível atualizar {content}',
  false: 'Falso',
  faq: 'Perguntas Frequentes',
  favorites: 'Favoritos',
  favoritesLists: 'Listas de Favoritos',
  february: 'Fevereiro',
  feedbackForm: 'Enviar Feedback',
  feedbackFormMessage: 'Faculte informações sobre funções que gostaria de encontrar',
  fieldDefinition: 'Definição de Campo',
  field: 'Campo | Campos',
  fieldName: 'Nome do campo',
  file: 'Ficheiro | Ficheiros',
  filename: 'Nome do Ficheiro',
  filesSubmitted: 'Ficheiro(s) submetido(s) para processamento',
  fileUpload: 'Carregamento de Ficheiro',
  fill: 'Preencher',
  filter: 'Filtro | Filtros',
  filterOrgNameOnly: 'Filtro: Apenas Nome Org',
  filterOrgParentNames: 'Filtro: Nomes da Organização e dos Pais',
  filterable: 'Filtrável',
  filterableTag: 'Etiqueta Filtrável',
  filterBy: 'Filtrar Por',
  filterBoundsError: 'O filtro deve conter 3 ou mais caracteres',
  finnishDescription: 'Descrição em Finlandês',
  finnishName: 'Nome em Finlandês',
  firstName: 'Nome Próprio',
  firstNameEmpty: 'O nome próprio não pode estar vazio',
  fixedHotpointSize: 'Tamanho de Hotpoint Fixo',
  font: 'Fonte | Fontes',
  footer: 'Rodapé',
  forgotPassword: 'Esqueceu a Palavra-passe?',
  forgotPasswordMessage: 'Será enviado um email com instruções para redefinir a sua palavra-passe para o nome de utilizador facultado.',
  forgotPasswordSuccess: 'Se o nome de utilizador facultado for válido, será enviado um email com instruções para redefinir a sua palavra-passe para o endereço no processo.',
  form: 'Formulário | Formulários',
  format: 'Formato',
  formattingOptions: 'Opções de Formatação',
  formCount: 'Contagem de Formulários',
  formName: 'Nome de Formulário',
  formType: 'Tipo de Formulário',
  freezeImage: 'Congelar Imagem',
  frenchDescription: 'Descrição em Francês',
  frenchName: 'Nome em Francês',
  fri: 'Sex',
  from: 'De',
  fromDate: 'Da data',
  frontCover: 'Capa',
  frontStitch: 'Ponto Frontal',
  fullScreen: 'Ecrã completo',
  gdprFaqs: 'FAQ de RGPD',
  gdprMessage: 'Ao iniciar sessão nesta secção do nosso website compreende que utilizamos cookies essenciais. Estes cookies são essenciais para lhe permitir navegar nos nossos Serviços. Sem estes cookies, não podemos facultar-lhe os serviços que solicitou. Para mais informações, consulte a nossa {policy}.',
  general: 'Geral',
  generalSearch: 'Pesquisa Geral',
  generic: 'Genérico',
  germanDescription: 'Descrição em Alemão',
  germanName: 'Nome em Alemão',
  gotIt: 'Confirmado',
  goTo: 'Ir para',
  goToHome: 'Ir para Página Inicial',
  goToJobManager: 'Ir para Gestor de Tarefas',
  goToSearch: 'Ir para Pesquisar',
  hashKey: 'Tecla Cardinal',
  headerAmpFooter: 'Cabeçalho e Rodapé',
  header: 'Cabeçalho',
  help: 'Ajuda',
  helpCenter: 'Centro de Ajuda',
  highlightDescription: 'Realçar peças na lista de peças com esta etiqueta',
  history: 'Histórico',
  home: 'Início',
  horizontal: 'Horizontal',
  host: 'Anfitrião',
  hotpoint: 'Hotpoint | Hotpoints',
  hotpointCalloutBehavior: 'Comportamento Chamada Hotpoint',
  hotpointLinksWillBeCreated: 'Serão criadas ligações de hotpoint em {count} {content}',
  hotpointLink: 'Ligação Hotpoint | Ligações Hotpoint',
  hotpointsGenerated: '{count} pontos quentes encontrados na imagem.',
  hotpointShift: 'Ponto quente (Shift H)',
  hourlyDWSRequestLimit: 'Limite Pedido DWS por Hora',
  hourlyRESTRequestLimit: 'Limite Pedido REST por Hora',
  htmlUiUserSwitchEnabled: 'Comutador Utilizador HTML UI Ativado',
  hungarianDescription: 'Descrição em Húngaro',
  hungarianName: 'Nome em Húngaro',
  icon: 'Ícone',
  id: 'Identificação',
  identifier: 'Identificador',
  ignore: 'IGNORAR',
  ignoreAccessControls: 'Ignorar Controlos de Acesso',
  ignoreMatchingTranslations: 'Ignorar traduções com idiomas correspondentes ao importar',
  illustration: 'Ilustração | Ilustrações',
  image: 'Imagem | Imagens',
  imageDeleteMessage: 'Tem a certeza de que pretende eliminar esta Imagem de Página?',
  imageUploadFailure: 'Erro no Carregamento da Imagem',
  imageSaveFail: 'Erro a Guardar a Imagem',
  imageOptimizer: 'Optimizador de Imagem',
  imageSizeExceededTitle: 'Tamanho Máximo de Imagem Excedido',
  imageSizeExceededMessage: 'O tamanho máximo do ficheiro de imagem no Documoto é {size} megabytes. A imagem que está a tentar carregar excedeu o tamanho máximo e não pode ser carregada. Redimensione a imagem para adicionar a esta página.',
  imageDimensionsExceededMessage: 'O tamanho máximo da imagem em Documoto é de 65.5 megapixéis (8,191 X 8,191 pixels).  A imagem que está a tentar carregar excedeu o tamanho máximo e não pode ser carregada.Clique em "Redimensionar" para permitir ao Documoto redimensionar e inserir a imagem, ou "Cancelar" para editar a imagem e inserir manualmente',
  import: 'Importar',
  importBOM: 'Importar BOM',
  importBOMError: 'Falha no processamento de dados de amostra, voltar a confirmar fonte de entrada',
  importParts: 'Importar Peças',
  inCart: 'No Carrinho',
  inCartDescription: 'Apresentar etiquetas no carrinho',
  includePagePartDetails: 'Incluir detalhes de nível de Parte da Página',
  includePartDetails: 'Incluir detalhes de nível de Parte',
  indexQueueJob: 'Fila Index',
  inPartsList: 'Na Lista de Peças',
  inPartsListDescription: 'Apresentar etiquetas na lista de peças',
  inputSource: 'Fonte de Entrada',
  inch: 'Polegada | Polegadas',
  includeComments: 'Incluir Comentários',
  includeCommentsAuthorAndDate: 'Incluir Autor e Data de Comentário',
  includeHeaderRow: 'Incluir Linha de Cabeçalho',
  includeImages: 'Incluir Imagens',
  includePagesIndex: 'Incluir Índice de Páginas',
  includePartsIndex: 'Incluir Índice de Peças',
  includePartsList: 'Incluir Lista de Peças',
  includeSubChapters: 'Incluir Subcapítulos',
  indexLane: 'Faixa Index',
  useSequentialPageNumbering: 'Utilizar Numeração de Página Sequencial',
  hideBookInSearchAndRecent: 'Ocultar Livros nos Resultados de Pesquisa e Recentemente Adicionados',
  hideDuplicatePages: 'Ocultar Páginas Duplicadas',
  hidePageInSearchAndRecent: 'Ocultar Páginas nos Resultados de Pesquisa e Recentemente Adicionadas',
  hidePartInSearchAndRecent: 'Ocultar Peças nos Resultados de Pesquisa e Recentemente Adicionadas',
  homePageSettings: 'Configurações de Página Inicial',
  hotpointZoomBehavior: 'Comportamento de Zoom de Ponto Central',
  includeNewParts: 'Incluir apenas “novas” partes (não no Documoto)',
  includeWatermark: 'Incluir marca de água?',
  increaseHotpoint: 'Aumentar Ponto quente',
  indexed: 'Indexado',
  indexingConfiguration: 'Configuração Indexação',
  indexIncludeTimestamp: 'Index Inclui Carimbo Hora',
  info: 'Info',
  information: 'Informação',
  inherit: 'Herdar',
  inheritedParent: 'Herdado do Principal',
  inputSpecification: 'Especificação de Entrada',
  inputType: 'Tipo de Entrada',
  inputTypeListboxWarning: 'As configurações de classificação serão apresentadas após serem guardadas',
  integration: 'Integração | Integrações',
  integrationsAddPartToErpCart: '{action} Adicionar Peça ao Carrinho ERP',
  integrationsAddToShoppingCartPricingFromErp: '{action} Adicionar ao Preço do Carrinho de Compras a partir de ERP',
  integrationsDisableErp: 'Desativar ERP',
  integrationsDoErpForQuotes: 'Fazer ERP para Orçamentos',
  integrationsEnableErp: 'Ativar ERP',
  integrationsEnableFetchPartInfoFromErp: 'Ativar Informação de Procura de Peça a partir de ERP',
  integrationsEnableScopeErp: 'Ativar ERP {scope}',
  integrationsEnableSendShoppingCartToErp: 'Ativar Enviar Carrinho de Compras para ERP',
  integrationsEnableSyncPoAddressWithErp: 'Ativar Endereço Postal de Sincronização com ERP',
  integrationsErpAvailabilityMappedFrom: 'Disponibilidade ERP Mapeada a Partir de',
  integrationsErpCurrencyCodeMappedFrom: 'Código de Moeda ERP Mapeado a Partir de',
  integrationsErpDiscountedPriceMappedFrom: 'Preço de Desconto ERP Mapeado a Partir de',
  integrationsErpEtaMappedFrom: 'Data Prevista de Entrega ERP Mapeada a Partir de',
  integrationsErpMappings: 'Mapeamentos ERP',
  integrationsErpRetailPriceMappedFrom: 'Preço de Retalho ERP Mapeado a Partir de',
  integrationsErpWholesalePriceMappedFrom: 'Preço de Grossista ERP Mapeado a Partir de',
  integrationsInvokeGetPartInfoFromClient: 'Invocar GetPartInfo do Cliente',
  integrationsParseAddressForType: 'Endereço de Análise para Tipo',
  integrationsShoppingCartAndPartsTitle: 'Carrinho de Compras e Peças',
  integrationsShoppingCartAvailability: '{action} Disponibilidade do Carrinho de Compras',
  integrationsShoppingCartEtaFromErp: '{action} Data Prevista de Entrega de Carrinho de Compras a partir de ERP',
  internalApiKey: 'Chave API Interna',
  internalDescription: 'Descrição Interna',
  internalName: 'Nome Interno',
  internalNameOptional: 'O seu identificador da etiqueta',
  internalNameRequired: 'É necessário um nome',
  invalidDecimals: 'Só são permitidas duas casas decimais',
  invalidField: '{field} é inválido',
  invalidFileType: 'Tipo de ficheiro não suportado. Consulte o Centro de Apoio Documoto para obter uma lista completa de extensões de ficheiro suportadas.',
  isSearchable: 'Pesquisável Is',
  insertDataField: 'Inserir Campo de Dados',
  isSystem: 'Sistema Is',
  italianDescription: 'Descrição em Italiano',
  italianName: 'Nome em Italiano',
  item: '#',
  itemAlt: 'Item',
  itemCopied: '{item} copiado',
  itemDeleted: '{Item} eliminado',
  itemInProgress: '{item} em progresso',
  itemInformation: 'informação de {item}',
  itemMustBeLteItem: 'O {itemA} tem de ser igual ou anterior ao {itemB}.',
  itemMustBeUnique: 'O {item} tem de ser único',
  itemMustBeUniqueAndNotEmpty: 'O {item} tem de ser único e é obrigatório',
  itemName: 'Nome do {Item}',
  itemNotShown: '',
  itemSaved: '{Item} guardado',
  itemSpecification: 'Especificação de Artigo',
  showChapterTOCForHiddenPages: 'Mostrar Lista de Conteúdos de Capítulo para Páginas Ocultas',
  january: 'Janeiro',
  japaneseDescription: 'Descrição em Japonês',
  japaneseName: 'Nome em Japonês',
  job: 'Tarefa | Tarefas',
  jobManager: 'Gestor de Tarefas',
  jobManagerMessage: 'Exportar, publicar e imprimir/transferir tarefas',
  july: 'Julho',
  june: 'Junho',
  koreanDescription: 'Descrição em Coreano',
  koreanName: 'Nome em Coreano',
  label: 'Etiqueta| Etiquetas',
  labelKey: 'Tecla de etiqueta',
  landscape: 'Paisagem',
  laneConfiguration: 'Configuração Fila',
  language: 'Idioma',
  lastModifiedDate: 'Última Data Modificada',
  lastGenerated: 'Última Geração',
  lastName: 'Apelido',
  lastNameEmpty: 'O apelido não pode estar vazio',
  lastUpdatedOn: 'Última atualização em',
  layout: 'Layout',
  layoutOptions: 'Opções de Layout',
  ledger: 'Registo',
  left: 'Esquerda',
  legal: 'Legal',
  letter: 'Letra',
  library: 'Biblioteca',
  libraryAdmin: 'Administração de Biblioteca',
  libraryBannerLogo: 'Logótipo do banner da biblioteca',
  librarySettings: 'Configurações de Biblioteca',
  librarySettingsDescription: 'Estas configurações controlam vários elementos e opções de biblioteca. Tenha cuidado ao fazer alterações. Contacte os Serviços Documoto para obter mais informações.',
  licenseConfiguration: 'Configuração Licença',
  line: 'Linha',
  lineTool: 'Ferramenta de Linha',
  link: 'Ligação | Ligações',
  listBox: 'Caixa de lista',
  listBoxValue: 'Valor da Caixa de Listagem | Valores da Caixa de Listagem',
  listValue: 'Valor de Listagem | Valores de Listagem',
  load: 'Carregar',
  loadMore: 'Carregar mais',
  loading: 'A carregar',
  locale: 'Local',
  login: 'Iniciar sessão',
  loginBackground: 'Fundo de Início de Sessão',
  loginGDPRMessageBeginning: 'Este site utiliza cookies. Ao prosseguir na utilização deste site, consente na utilização de cookies. Para mais informações, consulte a nossa ',
  loginGDPRMessageEnd: 'e a nossa',
  loginSSO: 'Iniciar sessão em SSO',
  logiReportName: 'Nome do Relatório Logi',
  logiReportToggle: 'Relatório Logi?',
  logo: 'Logótipo | Logótipos',
  logout: 'Terminar sessão',
  lookupLoadParts: 'Procurar/Carregar Peças do Documoto em Importar',
  lowerBound: 'Limite Inferior',
  makePagesDivisibleBy: 'Tornar Páginas Divisíveis por:',
  mainInfo: 'Informação Principal',
  mainLogo: 'Logótipo Principal',
  manageItem: 'Gerir {item}',
  management: 'Gestão',
  march: 'Março',
  margin: 'Margem | Margens',
  markdown: 'Remarcação',
  may: 'Maio',
  media: 'Suporte | Suportes',
  mediaCategory: 'Categoria de Suporte | Categorias de Suporte',
  mediaCategoryEditor: 'Editor da Categoria de Suporte',
  mediaCategoryName: 'Nome de Categoria de Suporte',
  mediaCategoryNameMissing: 'Todas as categorias de suporte requerem um nome',
  mediaBookDeleteMessage: 'Eliminar este suporte irá remover o acesso a este conteúdo tanto na Biblioteca como na Administração de Conteúdo, deixando de estar disponível para utilizadores ou Administradores de Conteúdo. Quaisquer peças ou páginas encontradas neste livro que não se encontrem em outros livros, também deixarão de estar disponíveis na Biblioteca.',
  mediaDeleteMessage: 'Apagar este modelo irá removê-lo da Administração de Conteúdo e deixará de estar disponível para copiar para Suporte novo.',
  mediaDeleteSuccessText: 'Será removido das listas assim que a indexação estiver concluída.',
  mediaDescription: 'Descrição da média',
  mediaIdentifier: 'Identificador de média',
  mediaName: 'Nome da média',
  mediaPrintSettingsSaveSuccess: 'Configurações de Impressão do Suporte guardadas',
  mediaWhereUsed: 'Suportes Quando Utilizados',
  menu: 'Menu',
  merge: 'Unir',
  mergeOrOverrideMessage: 'Pretende sobrepor ou unir estes artigos?',
  mergeWarning: 'Importar dados vai atualizar peças existentes e adicionar novas entradas',
  message: 'Mensagem',
  messageLevel: 'Nível de Mensagem',
  microsite: 'Microsite | Microsites',
  micrositeAdmin: {
    cardSubtitle: 'A informação de entrada de HTML e do ficheiro de teor comprimido',
    cardTitle: 'Conteúdo do Site',
    errorRequiredFile: 'É necessário um ficheiro ZIP de Conteúdo do Site',
    fieldFile: 'Ficheiro ZIP de Conteúdo do Site',
    fieldPoint: 'Ponto de Entrada de HTML',
  },
  minArea: 'Área Mínima',
  minAreaMessage: 'É necessário valor mínimo O valor não pode exceder uma série de 1 a 1500.  Deve ser um valor inteiro.',
  moreInfo: 'Mais Info',
  multipleAlternatingPages: 'Múltiplas Páginas Alternativas',
  multipleAlternatingPagesMessage: 'Diagrama nas páginas 1 e 3, lista de peças nas páginas 2 e 4.',
  mToM: 'M2M',
  multiplePages: 'Múltiplas Páginas',
  multiplePagesMessage: 'O diagrama na sua própria página seguida por páginas da lista de peças',
  requiredField: 'Campo Obrigatório',
  messageMessage: 'É necessária uma mensagem',
  metadata: 'Metadados',
  mine: 'Meu',
  miscellaneous: 'Diversos',
  mon: 'Seg',
  myCart: 'O Meu Carrinho | Os Meus Carrinhos',
  navigation: 'Navegação',
  nItemsCreated: '{n} {items} Criado(s)',
  nItemsDeleted: '{n} {items} Eliminado(s)',
  nOfMItems: '{n} de {m} {items}',
  name: 'Nome',
  nameDescriptionHelpText: 'O nome e a descrição opcional',
  new: 'Novo',
  newAPIKey: 'Nova Chave API',
  newERPSystem: 'Novo sistema ERP',
  newItem: 'Novo {item}',
  newKeyGenerated: 'Nova Chave de Integração Gerada, Copie e Guarde para utilização futura',
  newMediaCategory: 'Nova Categoria de Suporte',
  newPassword: 'Nova Palavra-passe',
  newsItem: 'Artigo de Informações | Artigos de Informações',
  newTenant: 'Novo Locatário',
  newWidgetKey: 'Nova chave do widget',
  newWidgetKeyGenerated: 'Nova chave de widget gerada, copie e guarde para uso futuro',
  noTenantSuppliers: 'Sem Fornecedores atribuídos para Locatário.',
  noAPIKeys: 'Nenhuma Chave API definida.',
  node: 'Nó',
  noData: 'Sem Dados',
  noFilesSelected: 'Sem Ficheiros Selecionados',
  noIllustrationFound: 'Não foram encontradas ilustrações',
  noImageFound: 'Não foi encontrada imagem',
  noItemAvailable: 'Sem {item} disponível',
  noItemFound: 'Não foi encontrado {item}.',
  noItemSelected: 'Sem {item} selecionado',
  noItemAddedToTarget: 'Nenhum {item} adicionado a {target}. ',
  noItemsFound: 'Não Foram Encontrados Artigos',
  noMoreResults: 'Sem mais resultados',
  noFormsMessage: 'Sem Formulários definidos.',
  noMediaDefined: 'Sem Media Definido',
  nonSearchableFieldsSuccessfullyUpdated: 'Campos não pesquisáveis atualizados com sucesso.',
  noPartsSelected: 'Sem peças selecionadas.',
  noPrice: 'Pedido de Preço',
  noResults: 'Sem resultados',
  norwegianDescription: 'Descrição em Norueguês',
  norwegianName: 'Nome em Norueguês',
  noSearchableTags: 'Sem etiquetas pesquisáveis',
  noSuggestions: 'Sem sugestões para o termo de pesquisa',
  notOrderable: 'Não Disponível',
  notSelected: 'Não selecionado',
  noTagsFound: 'Não foram encontradas etiquetas',
  noUnsavedChanges: 'Sem Alterações não Guardadas',
  none: 'Nenhum',
  notImplemented: 'Não Implementado',
  notification: 'Notificação | Notificações',
  notificationsMessage: 'Informações importantes e novidades',
  notMapped: 'Não Mapeado',
  november: 'Novembro',
  noWidgetKeys: 'Não foram definidas chaves de widget.',
  numeric: 'Numérico',
  numberOf: '# de {item}',
  october: 'Outubro',
  of: 'de',
  off: 'Off',
  offlineContent: 'Conteúdo Offline',
  ok: 'OK',
  oldPassword: 'Palavra-passe Antiga',
  on: 'On',
  opacity: 'Opacidade',
  open: 'Abrir',
  openItem: 'Abrir {item}',
  optimizeIndexJob: 'Otimizar Índice',
  options: 'Opções',
  openTab: 'Abrir ligação num novo separador',
  order: 'Pedido| Pedidos',
  orderable: 'Possível Fazer Pedido',
  orderableDescription: 'A peça pode ser adicionada ao carrinho e comprada',
  orderableLocal: 'Possível de pedir nesta página',
  orderableLocalBlocked: 'Não é Possível Pedir a Peça Globalmente',
  orderDisable: 'Desativar Histórico de Modelos',
  orderEmailRecipient: 'Destinatário de Email de Pedido| Destinatários de Email de Pedido',
  orderEntity: 'Entidade de Pedido | Entidades de Pedido',
  orderFieldUneditableRequired: 'Definir um Campo de Cotação / Pedido como Obrigatório e Não Editável pelo utilizador pode tornar o Formulário de Cotação / Pedido impossível de preencher. Verifique se os seus Formulários de Cotação / Pedido podem ser preenchidos.',
  orderFieldsEmptyMessage: 'Sem Formulários de Cotação / Pedido definidos.',
  orderFieldsEmptyPrompt: 'Adicionar um Formulário de Cotação / Pedido',
  orderField: {
    nameMessage: 'Se alterar o nome do Campo de Cotação / Pedido, certifique-se de atualizar todos os Formulários de Cotação / Pedido ou Formatos de Email que usam este campo.',
  },
  orderFieldCopyMessage: 'Insira um nome único para o novo Campo de Cotação / Pedido',
  orderForm: {
    uniqueNameInputHint: 'Insira um nome único para o novo Formulário.'
  },
  orderFormField: 'Campo de Cotação / Pedido | Campos de Cotação /  Pedido',
  orderFormLayout: 'Layout de Formulário de Cotação / Pedido | Layouts de Formulário de Cotação / Pedido',
  orderFormSetDefault: 'Conjunto de Encomenda Predefinida/Formulário de Orçamento',
  orderFormType: 'Formulário de Encomenda',
  orderLayout: 'Layout de Pedido | Layouts de Pedidos',
  orderManagementApprover: 'Aprovador de Gestão de Pedido',
  orderManagementDisable: 'Desativar Administração de Gestão de Pedido',
  orderManagementViewer: 'Visualizador de Gestão de Pedido',
  orderNumber: 'Número de Pedido',
  orderQuoteEmail: 'E-mail de Cotação/Encomenda | E-mails de Cotação/Encomenda',
  orderQuoteEmailFormat: 'Formato de E-mail de Encomenda/Cotação | Formatos de E-mail de Encomenda/Cotação',
  orderQuoteEmailFormatCopyMessage: 'Selecione um idioma para um novo Formato de E-mail de Cotação/Encomenda.',
  orderQuoteFetchError: 'Erro na obtenção de Encomenda/dados de Formulário de Cotação',
  orderQuoteForm: 'Formulário de Encomenda/Cotação | Formulários de Encomenda/Cotação',
  orderQuoteSaveConfirmation: 'Guardar as suas alterações nos formulários de Cotação/Encomenda da Organização?',
  orderQuoteSaveError: 'Erro a guardar Encomenda/dados de Formulário de Cotação',
  orderQuoteFormSaved: 'Formulário de Cotação / Pedido Guardado',
  orderStatus: 'Situação de Cotação / Pedido | Situações de Cotação /  Pedido',
  orderStatusDeleteMessage: 'Este estado foi utilizado em pedidos e não pode ser eliminado.',
  orderSubmittedMessage: 'Enviado com Sucesso',
  orderSuggestion: 'Sugestão de Pedido | Sugestões de Pedido',
  orderSuggestionEnable: 'Ativar Sugestões de Pedido',
  orderSuggestionRemovalWarning: 'A peça deixará de aparecer como uma sugestão de pedido. | As peças deixarão de aparecer como sugestões de pedido.',
  orderSuggestionThreshold: 'Limite de Sugestão de Pedido',
  ordersLogo: 'Logótipo de Pedidos',
  ordersMessage: 'Histórico de apresentação de pedidos',
  orientation: 'Orientação',
  org: 'Org',
  orgEmailRecipients: 'Destinatários de Email da Organização',
  organization: 'Organização | Organizações',
  organizationAdmin: 'Administrador da Organização',
  organizationAdminBrowseFlow: 'Uma Organização pode utilizar o fluxo de navegação predefinido, ou pode substituir e utilizar qualquer outro fluxo de navegação.',
  organizationAdminMessage: 'Controlos de gestão de utilizador e definições de org',
  organizationEmpty: 'As organizações têm de estar selecionadas',
  organizationEmailRecipients: 'Destinatários de email da organização',
  organizationEnforceAccessControlsForQuickAdd: 'Aplicar Controlos de Acesso para Adição Rápida',
  organizationKey: 'Tecla de Organização',
  organizationLibrarySettingsDescription: 'Estas configurações controlam vários elementos e opções de Biblioteca ao nível da Organização. Tenha cuidado ao fazer alterações. Contacte os Serviços Documoto para obter mais informações.',
  organizationMoveToRoot: 'Solte aqui para mover para a raiz',
  organizationName: 'Nome da organização',
  organizationRearrange: 'Reorganizar Organizações',
  organizationRearrangeDescription: 'A estrutura Organizacional principal-secundário define muitos atributos possíveis de herdar de uma Organização como Configurações de Impressão, Estilos e outros. Arraste e solte Organizações para refletir a hierarquia desejada.',
  organizationReportSettingsSuccessMessage: 'Configurações de relatório guardadas',
  organizationSelect: 'Selecionar Organização',
  organizationTags: 'Etiquetas de Organização',
  organizationsEmpty: 'Parece que ainda não criou quaisquer organizações',
  orgNameOnly: 'Apenas Nome da Organização',
  other: 'Outro',
  otherSettings: 'Outras configurações',
  outboundCustomerIdParameter: 'Nome de Parâmetro de ID de Cliente de Saída',
  outboundSessionIdParameter: 'Nome de Parâmetro de ID de Sessão de Saída',
  outlineMode: 'Modo de Realce (Shift O)',
  output: 'Saída',
  outputCatalog: 'Catálogo de Saída',
  outputDefinition: 'Definição de Saída | Definições de Saída',
  outputDefinitionDeleted: 'Definição de Saída eliminada',
  outputDefinitionSaved: 'Definição de Saída Guardada',
  outputFileName: 'Nome do Ficheiro de Saída',
  outputName: 'Nome de Saída',
  outputDestination: 'Destino de Saída',
  outputSpecification: 'Especificação de Saída',
  outputSubType: 'Subtipo de Saída',
  outputType: 'Tipo de Saída',
  overridden: 'Sobreposto',
  overriddenByOrg: 'Sobreposto por Organização',
  override: 'Sobrepor',
  overrideBrowseFlow: 'Sobrepor Fluxo de Navegação',
  overrideFacetLimitValue: 'Sobrepor Valor Limite Facet',
  overrideHotpointScale: 'Sobrepor Escala Hotpoint',
  overridePdfNamingStandard: 'Substituir padrão de nome de ficheiro PDF',
  overridePrintSettings: 'Sobrepor Configurações de Impressão',
  overrideFontSettings: 'Sobrepor Configurações de Fonte',
  overrideOrderForm: 'Sobreposição de Formulário de Encomenda',
  overrideQuoteForm: 'Sobreposição de Formulário de Cotação',
  overrideSvgOpacity: 'Sobrepor Opacidade SVG',
  overrideWarning: 'Importar dados vai substituir a sua lista BOM atual',
  owner: 'Proprietário | Proprietários',
  page: 'Página | Páginas',
  pageBulkInsertConfirmTitle: 'Pretende concluir a inserção em massa?',
  pageBulkInsertConfirmInsertAfter: 'Isto vai inserir {replacement} após {original} em {whereUsedBookCount} {bookUnits} e {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'Isto vai inserir {replacement} antes {original} em {whereUsedBookCount} {bookUnits} e {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'Isto vai inserir {replacement} e remover {original} em {whereUsedBookCount} {bookUnits} e {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'Isto vai inserir {replacement} após {original} em {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'Isto vai inserir {replacement} antes {original} em {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'Isto vai inserir {replacement} e remover {original} em {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Isto vai inserir {replacement} após {original} em {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Isto vai inserir {replacement} antes {original} em {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Isto vai inserir {replacement} e remover {original} em {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'A sua inserção em massa não será processada.',
  pageBulkInsertHelpText: 'Esta funcionalidade permite-lhe inserir esta página fonte {thisDisplayName} em livros de peças selecionadas onde outra página {originalDisplayName} já existe. Opcionalmente, também pode remover {originalDisplayName} destes livros.',
  pageBulkInsertInsertAfter: 'Inserir {replacement} APÓS {original}',
  pageBulkInsertInsertBefore: 'Inserir {replacement} ANTES {original}',
  pageBulkInsertInsertReplace: 'Inserir {replacement} e REMOVER {original}',
  pageBulkInsertScopeAll: 'Todos {type} que têm a página alvo {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Nenhum dos {type} que têm a página alvo {originalDisplayName}',
  pageBulkInsertScopeSome: 'Alguns dos {type} que têm a página alvo {originalDisplayName}',
  pageBulkInsertStep1: 'Passo 1: Encontrar uma página de destino desejada',
  pageBulkInsertStep2: 'Passo 2: Decidir como inserir ou substituir as páginas',
  pageBulkInsertStep3: 'Passo 3: Escolher os livros onde deseja que a inserção em massa seja processada.',
  pageBulkInsertStep4: 'Passo 4: Escolha os modelos onde quer que a inserção em massa seja processada.',
  pageBulkInsertSuccessTitle: 'Tarefa de inserção em massa enviada',
  pageBulkInsertSuccessBody: 'As alterações no índice do livro serão feitas imediatamente. Os resultados de pesquisa serão aplicados assim que a indexação for concluída.',
  pageBulkErrorTooManyBooks: 'Limite o número selecionado de livros a não mais de 1000',
  pageBuilder: 'Construtor de Página',
  pageComposer: 'Page Composer',
  pageCreated: 'Página Criada',
  pageDeleteMessage: 'As eliminações de páginas terão efeito imediato na Biblioteca e na Administração de Conteúdos. A página eliminada permanecerá pesquisável até que a reindexação ocorra dentro de alguns minutos.',
  pageDeleteMessage2: 'Esta ação não pode ser revertida sem a assistência do Suporte da Documoto. Após 90 dias, o registo eliminado e todas as associações serão permanentemente eliminados.',
  pageDescription: 'Descrição da página',
  pageFile: 'Arquivo de Página',
  pageFilename: 'Nome do ficheiro da página',
  pageFilenameDeleted: '{filename} será eliminada.',
  pageFileRequired: 'É necessário um ficheiro de página.',
  pageBomPartsDeleted: '{count} As partes da página de lista de materiais deixarão de estar acessíveis via {filename}.',
  pageDraftsDeleted: 'As páginas de rascunho associadas a esta página serão permanentemente apagadas.',
  pageFit: 'Ajuste da Página',
  pageHeader: 'Cabeçalho da página',
  pageLabel: 'Página | Páginas',
  pageLayout: 'Layout da Página',
  pageName: 'Nome da página',
  pagePart: 'Peça de Página | Peças de Página',
  pagePartTag: 'Etiqueta de Peça de Página | Etiquetas de Peça de Página',
  pagePartData: 'Página de Dados da Peça',
  pageUsageDataCouldNotBeLoaded: 'Não foi possível carregar os dados de utilização da página',
  pagePartLevelDetails: 'Parte de Página para Encomendar/Visível, Etiquetas de Parte da Página',
  pagePartNumberVisible: 'Número de Peça da Página Visível',
  pagePartOrderable: 'Peça de Página para Encomendar',
  pagePartOrderableDescription: 'Peça para Encomenda a partir desta página',
  pagePartVisibleDescription: 'Número de Peça visível nesta página',
  pageUpdated: 'Página Atualizada',
  pageViewer: 'Visualizador de Página',
  pageWidth: 'Largura da Página',
  pagesWhereUsed: 'As Páginas Foram Utilizadas',
  parentName: 'Nome Principal | Nomes Principais',
  part: 'Peça | Peças',
  partCode: 'Código de Peça | Códigos de Peça',
  partCodeAddError: 'Não pode adicionar Código(s) de Peça',
  partCodeErrorEmpty: 'Não foi possível gravar: O código de peça não pode estar vazio',
  partCodeErrorInUse: 'Este código de peça está a ser utilizado por peças no Documoto e não pode ser apagado. Contacte o Suporte Documoto em support{\'@\'}documoto.com para obter assistência.',
  partCodeErrorNotFound: 'Estes códigos de peça não puderam ser apagados porque não foi possível encontrá-los, certifique-se de que todas as alterações são gravadas.',
  partCodesManager: 'Gestor de Códigos de Peça',
  partCodesMessage: 'Os códigos de peça podem ser utilizados no lugar dos números de peça e geralmente são utilizados para indicar quando os artigos da lista técnica estão obsoletos, indisponíveis ou são apenas artigos de referência (por exemplo, \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Peça Criada',
  partData: 'Dados da Peça',
  partUpdated: 'Parte Atualizada',
  partDebouncerEmailQueueJob: 'Fila de e-mail',
  partDescription: 'Descrição da parte',
  partLabel: 'Peça | Peças',
  partLevelDetails: 'Preço de Parte, para encomendar/visíveis, Traduções Adicionais, Etiquetas de Parte',
  partName: 'Nome da Peça',
  partnerApiKey: 'Chave API de Parceiro',
  partnerRESTRequestLimit: 'Limite de Pedido REST Parceiro',
  partNumber: 'Número de Peça',
  partNumberDescription: 'O número interno de peça para os utilizadores finais pesquisarem',
  partNumberVisible: 'Número de Peça Visível',
  partNumberVisibleDescription: 'O número de peça está visível para utilizadores finais',
  partNumberVisibleLocal: 'Visível nesta página',
  partNumberVisibleLocalBlocked: 'Número de Peça não Visível Globalmente',
  partOrderable: 'Peça para Encomendar',
  partRequired: 'É necessária pelo menos uma peça',
  partTagProperty: 'Propriedade de Etiqueta de Peça | Propriedades de Etiqueta de Peça',
  partTag: 'Etiqueta de Peça | Etiquetas de Peça',
  partThumbnailDescription: '',
  partialSupport: 'Suporte Parcial',
  partsListColumn: 'Coluna de Lista de Peças | Colunas de Lista de Peças',
  partsBook: 'Livro de Peças | Livros de Peças',
  partsColumns: 'Colunas de Peças',
  partsNotSelected: 'Imagem predefinida utilizada para miniaturas de peças. Se uma miniatura personalizada não estiver aplicada, o Documoto irá apresentar uma miniatura padrão predefinida.',
  partsPage: 'Página das Peças | Páginas das Peças',
  partsPages: 'Páginas de Peças',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Excluir Códigos de Peça das Listas de Peças',
    highlightAlternateRows: 'Realçar Linhas Alternativas',
    rotateImagesAutomatically: 'Rodar Imagens Automaticamente',
    printBorderAroundImages: 'Imprimir Margem à Volta das Imagens',
    keepImagesOnLeft: 'Manter Imagens à Esquerda',
    keepBOMOnRight: 'Manter Lista Técnica à Direita',
    columnsUpdateErrorMsg: 'Ocorreu um erro ao tentar atualizar as Colunas de Peças',
    columnsUpdateSuccessMsg: 'Colunas de Peças atualizadas',
    columnsLoadErrorMsg: 'Não foi possível carregar as colunas de peças',
    printHotpointsOnImages: 'Imprimir Pontos quentes nas Imagens',
  },
  partsList: 'Lista das Peças',
  partsListData: 'Dados de Lista de Peças',
  partsListGridHeader: 'Cabeçalho da Grelha da Lista de Peças',
  password: 'Palavra-passe',
  passwordExpiredHeader: 'Palavra-passe expirada',
  passwordExpiredInfo: 'A sua palavra-passe expirou. A sua nova palavra-passe deve cumprir os seguintes critérios:',
  passwordCriteriaInfo: 'A sua nova palavra-passe deve cumprir os seguintes critérios:',
  passwordBlankMessage: 'A palavra-passe não pode estar em branco',
  passwordCannotBeSame: 'Não pode ser a mesma do que a palavra-passe atual',
  passwordCasingMassage: 'Deve conter pelo menos uma maiúscula e um caracter em maiúsculas',
  passwordLowerCaseMessage: 'A palavra-passe deve conter pelo menos um caracter em minúsculas',
  passwordMinLengthMessage: 'As palavras-passe devem conter pelo menos 8 caracteres na extensão',
  passwordNotMatchingMessage: 'As palavras-passe não têm correspondência',
  passwordSpecialCharactersMessage: 'A palavra-passe deve conter pelo menos um caracter especial',
  passwordUpperCaseMessage: 'A palavra-passe deve conter pelo menos um caracter em maiúsculas',
  passwordWillExpire1Day: 'A palavra-passe de início de sessão vai expirar em menos de 1 dia',
  passwordWillExpire5Days: 'A palavra-passe de início de sessão vai expirar em menos de 5 dias',
  passwordsMatch: 'Palavras-Passe correspondem',
  permission: 'Permissão | Permissões',
  phoneNumber: 'Número de Telefone',
  phoneNumberExt: 'Ext',
  phonePrimary: 'Número de Telefone Primário',
  phoneSecondary: 'Número de Telefone Secundário',
  pinFilter: 'Fixar filtro para pesquisar página de resultados',
  pinned: 'Fixado',
  plusMoreOrgs: '+ {totalOrgCount} Organizações adicionais',
  polishDescription: 'Descrição em Polaco',
  polishName: 'Nome em Polaco',
  populatePdfNameWith: 'Preencher o nome do ficheiro PDF com',
  populatePdfNameSelection: 'Preencher a seleção do nome de ficheiro PDF',
  port: 'Porta',
  portrait: 'Retrato',
  portugueseDescription: 'Descrição em Português',
  portugueseName: 'Nome em Português',
  postComment: 'Publicar Comentário',
  postalCode: 'Código Postal',
  poweredBy: 'Desenvolvido por',
  preferences: 'Preferências',
  preferencesDisable: 'Desativar Preferências de Utilizador',
  prefix: 'Prefixo | Prefixos',
  preview: 'Pré-visualização',
  previewOfName: 'Pré-visualização de {name}',
  pricing: 'Preço',
  pricingDiscountEnabled: 'Apresentar Preço de Desconto',
  pricingDisplayInCart: 'Apresentar Preços no Carrinho de Compras',
  pricingDisplayInPartsList: 'Apresentar Preços na Lista de Peças',
  pricingGlobal: 'Preço Global',
  pricingGlobalDescription: 'O preço normal quando não existe substituição de Organização',
  pricingHidePricingButton: 'Ativar Ocultar Botão de Preço',
  pricingHidePricingButtonDisable: 'Desativar Ocultar Botão de Preço',
  pricingOrganization: 'Preço de Organização',
  pricingOrganizationDeleteConfirm: 'Tem a certeza de que pretende eliminar este preço de Organização?',
  pricingOrganizationDescription: 'O preço de organização substitui o preço global para todos os utilizadores dentro de uma Organização e as suas Organizações secundárias.',
  pricingOrganizationDuplicate: 'Esta organização já está a ser substituída',
  pricingOrganizationEmptyState: 'Sem substituições de organização definidas. Clique {add} para começar.',
  pricingRetailEnabled: 'Apresentar Preço de Retalho',
  pricingWholesaleEnabled: 'Apresentar Preço de Grossista',
  primary: 'Primário',
  print: 'Imprimir',
  printerLane: 'Faixa Impressora',
  printJob: 'Imprimir Tarefa',
  printLogo: 'Logótipo de impressão',
  printSetting: 'Configuração de Impressão',
  printSettingDeleteFail: 'Não foi possível Eliminar a Configuração de Impressão',
  printSettingSaveFail: 'Não foi possível Guardar a Configuração de Impressão',
  printSettingEmptyMessage: 'Sem Configurações de Impressão definidas. Adicionar uma Configuração de Impressão para começar.',
  printSettingSetDefaultSuccess: 'Conjunto de Configuração de Impressão predefinido',
  printSettingUpdateFail: 'Não foi possível Atualizar a Configuração de Impressão',
  printSettingCopyMessage: 'Insira um nome único para a nova Configuração de Impressão.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Configuração de Impressão criada',
  printSettings: 'Configurações de Impressão',
  printSettingsLogo: 'Logótipo de Configurações de Impressão',
  printing: 'Impressão',
  printingDisabled: 'Desativar Todas as Impressões',
  privacyPolicy: 'Política de Privacidade',
  proceed: 'Avançar',
  process: 'Processar',
  processContent: 'Processar Conteúdo',
  processing: 'Processar',
  processToDraft: 'Processo para Draft',
  processedPlzToDraft: 'PLZ(s) processado(s) para Draft(s)',
  production: 'Produção',
  professional: 'Profissional',
  profile: 'Perfil',
  prohibitExportDownloadOfContent: 'Proibir Export/Transferência de Conteúdo',
  prohibitExportDownloadOfContentHelpText: 'Definir para \'Enable\' vai evitar que qualquer utilizador (com privilégios) de exportar ou transferir este conteúdo.',
  property: 'Propriedade | Propriedades',
  province: 'Província',
  public: 'Público',
  publish: 'Publicar',
  publisherLane: 'Faixa Publisher',
  publisherManualLane: 'Faixa Publisher Manual',
  publishThumbnailJob: 'Publicar Miniaturas',
  publishDocuments: 'Publicar Documentos',
  publishDraft: 'Publicar Rascunho',
  publisher: 'Publicador | Publicadores',
  publishingErrors: 'Erros de Publicação',
  publishingErrorsWithName: 'Publicar Erros {name}',
  publishingInProgress: 'Publicação em progresso, não é possível submeter',
  publisherJob: 'Tarefa de Publicador',
  publisherMessage: 'Criação de conteúdo, gestão e definições de impressão',
  purchaseOrder: 'Nota de Pedido',
  purchaseOrderTemplateEnabled: 'Ativar Modelos de Pedido',
  purchaseOrderTemplateDisabled: 'Desativar Modelos de Pedido',
  qty: 'Qtd',
  quantity: 'Quantidade',
  query: 'Consulta',
  quickAdd: 'Adicionar Rápido',
  quickAddHelpText: 'Comece a digitar para encontrar peças a adicionar ao carrinho de compras.',
  quickAddInCartEnabled: 'Ativar Adicionar Rápido ao Carrinho',
  quickLinks: 'Ligações Rápidas',
  quote: 'Orçamento | Orçamentos',
  quoteFormType: 'Formulário de Cotação',
  quoteTemplateDisabled: 'Desativar Modelos de Orçamento',
  quoteTemplateEnabled: 'Ativar Modelos de Orçamento',
  range: 'Intervalo',
  rearrange: 'Reorganizar',
  rebuildIndexJob: 'Reconstruir Índice',
  recentSearches: 'Pesquisas Recentes',
  recentlyAdded: 'Adicionado Recentemente',
  recentlyEdited: 'Editado Recentemente',
  recentlyViewed: 'Visualizado Recentemente',
  rectangle: 'Retângulo',
  redirect: 'Redirecionar',
  refresh: 'Atualizar',
  related: 'Relacionado',
  relatedDescription: 'Relacionar suportes que existam na biblioteca para este {type}. Tenha em consideração que os suportes relacionados têm de ter controlos de acesso aplicados para que os utilizadores os vejam no separador \'Relacionado\' .',
  relatedInfo: 'Informação Relacionada',
  relatedView: 'Ver Suportes Relacionados',
  relationship: 'Relação | Relações',
  releaseNotes: 'Notas de Divulgação',
  rememberMe: 'Lembrar-me',
  remainingWidth: 'Largura restante',
  remove: 'Remover',
  removeAll: 'Remover Tudo',
  removeItemConfirmation: 'Tem a certeza de que deseja remover {item}?',
  removeItemCount: 'Tem a certeza que deseja eliminar {count} {type}?',
  replace: 'Substituir',
  replaceExisting: 'Substituir Existentes',
  report: 'Relatório | Relatórios',
  reportAnIssue: 'Reportar um Problema',
  reportAnIssueMessage: 'Email enviado com sucesso.',
  reportCatalogName: 'Nome do Catálogo do Relatório',
  reportCatalogNotSet: 'O catálogo do relatório não foi definido para o relatório.',
  reportDefinition: 'Definição do Relatório | Definiões do Relatório',
  reportDefinitionDeleteError: 'Não foi possível eliminar a definição do relatório',
  reportsEmptyState: 'Sem Relatórios',
  reportExportTimeWarning: 'Dependendo da contagem de registos e do formato selecionado, a exportação pode demorar vários minutos a ser concluída.',
  reporting: 'A reportar',
  reportingMessage: 'Acesso a vários relatórios e métricas',
  reportSettings: 'Configurações de Relatórios',
  reportsLibrary: 'Biblioteca de Relatórios',
  requestForQuote: 'Solicitar Orçamento',
  requestFormType: 'Formulário de Cotação',
  required: 'Obrigatório',
  requiredItem: 'É necessário um {item}.',
  requiredMessage: 'É necessário um {item}.',
  requiredMessageAndUnique: 'É necessário um {item} e deve ser único.',
  requireAuthentication: 'Autenticação Necessária',
  requiredReading: 'Leitura Necessária',
  requiredReadingMessage: 'Reveja o seguinte:',
  requiredUrlMessage: 'É necessário um URL bem formado.',
  reset: 'Redefinir',
  resetPassword: 'Redefinir Palavra-passe',
  resetPasswordError: 'Ocorreu um problema com a redefinição da sua palavra-passe, tente novamente.',
  resetPasswordTokenError: 'O token de restauro da palavra-passe é inválido.',
  resetZoom: 'Reconfigurar Zoom',
  resource: 'Recurso | Recursos',
  resize: 'Redimensionar',
  resizeThumbnails: 'Redimensiona automaticamente miniaturas',
  result: 'Resultado | Resultados',
  resultFor: 'Resultado para {item} | Resultados para {item}',
  retail: 'Retalho',
  retailPrice: 'Preço de Retalho',
  retailTotal: 'Total de Retalho',
  return: 'Retroceder',
  returnToItem: 'Devolver para {item}',
  returnToPartList: 'Voltar à Lista de Peças',
  returnToMedia: 'Voltar ao Suporte',
  returnToTemplate: 'Voltar ao Modelo',
  returnToViewer: 'Voltar ao Visualizador',
  revertChanges: 'Reverter Alterações',
  right: 'Direita',
  romanianDescription: 'Descrição em Romeno',
  romanianName: 'Nome em Romeno',
  rootLevelBrowseFlow: 'Nível Raiz',
  row: 'Linha',
  russianDescription: 'Descrição em Russo',
  russianName: 'Nome em Russo',
  sampleData: 'Dados de Amostra',
  samplePreview: 'Pré-visualização de amostra',
  sandbox: 'Sandbox',
  sat: 'Sáb',
  save: 'Guardar',
  saveChanges: 'Guardar as suas alterações para {item}? {name}',
  saveChangesToItem: 'Guardar as suas alterações para este {item}?',
  saved: 'Guardado',
  saveNewAPIKey: 'Nova Chave de Integração não Guardada, Regressar à configuração completa',
  saveNewWidgetKey: 'Nova chave do Widget não foi guardada. Retornar para concluir a configuração.',
  search: 'Pesquisar',
  searchableFieldsSuccessfullyUpdated: 'Campos pesquisáveis atualizados com sucesso.',
  searchAllContent: 'Pesquisar todo o Conteúdo',
  searchConfiguration: 'Configuração de pesquisa',
  searchCriteria: 'Critérios de pesquisa',
  searchDocumoto: 'Pesquisar Documoto',
  searchFeatures: 'Recursos de pesquisa',
  searchFieldConfigDescription: 'As seguintes configurações de pesquisa de campo do sistema só se aplicam à pesquisa geral da Biblioteca, à página de resultados da pesquisa e à Adição rápida no carrinho. Outros recursos de pesquisa (como a pesquisa no Centro de administração e a biblioteca Onde usado) não serão afetados.',
  searchInContent: 'Pesquisar em {contentName}...',
  searchingInLanguage: 'Pesquisar em {lang} apenas',
  searchingInTwoLanguages: 'Pesquisar em {lang1} e {lang2} apenas',
  searchPlaceholder: 'Pesquisar todo o conteúdo...',
  searchPrompt: 'Pesquisar todo o conteúdo...',
  searchProperty: 'Pesquisar Propriedade | Pesquisar Propriedades',
  searchResultsConfiguration: 'Configuração dos resultados da pesquisa',
  searchSettings: 'Configurações de pesquisa',
  searchSettingsDescription: 'Estas definições controlam vários comportamentos, funcionalidades e resultados da pesquisa da Biblioteca.',
  searchTerm: 'Termo de Pesquisa',
  searchTips: 'Sugestões de Pesquisa:',
  searchable: 'Pesquisável',
  searchableTag: 'Etiqueta Pesquisável',
  section: 'Secção',
  select: 'Selecionar',
  selectAField: 'Selecionar um {field}',
  selectAll: 'Selecionar Todos',
  selectAndClose: 'Selecionar e Fechar',
  selected: 'Selecionado',
  selectedTargetPage: 'Página de Alvo Selecionada',
  selectChapters: 'Selecionar Capítulo(s)',
  selectChildren: 'Selecionar Secundário',
  selectFields: 'Selecionar {fields}',
  selectItem: 'Selecionar {item}',
  selectMedia: 'Selecionar Media',
  selectPage: 'Selecionar Página',
  selectPages: 'Selecionar Página(s)',
  selectTargetPage: 'Selecionar Página de Alvo',
  send: 'Enviar',
  september: 'Setembro',
  serbianDescription: 'Descrição em Sérvio',
  serbianName: 'Nome em Sérvio',
  serviceUri: 'URI de Serviço',
   sessionLog: 'Registo de Sessão',
  set: 'Configurar',
  setAsDefault: 'Marcar como Predefinição',
  settings: 'Definições',
  settingsMessage: 'Atualizar as suas preferências de utilizador e palavra-passe',
  sequence: 'Sequência',
  shape: 'Forma',
  shapeTool: 'Ferramenta de Forma',
  shared: 'Partilhado',
  sharedCart: 'Carrinho Partilhado | Carrinhos Partilhados',
  sharedCartDeleteContent: 'Isto irá remover este Carrinho Partilhado para todas as organizações onde foi partilhado.',
  sharedCartOrganizationContent: 'Selecionar as Organizações que podem utilizar este Carrinho Partilhado.',
  sharedShoppingCartsEnabled: 'Ativar Carrinhos de compras Partilhados',
  shoppingCartExportJob: 'Exportação de Carrinho de Compras',
  shoppingCartLoadingLongTime: 'O carrinho de compras está a carregar. Os ícones do carrinho ficarão disponíveis assim que o processo estiver concluído. Se o problema persistir, contacte o seu Administrador Documoto para apoio.',
  showExportFromSearch: 'Permitir Exportação de Resultados de Pesquisa',
  showExportFromSearchHelp: 'Permitir exportar a lista de livros resultantes de uma pesquisa',
  showHelp: 'Mostrar Ajuda',
  showIdentifierInSearch: 'Mostrar Identificador de Media na Pesquisa',
  showItem: 'Mostrar {item}',
  showLess: 'Mostrar Menos',
  showMediaDescriptionFilter: 'Mostrar Filtro de Descrição do Suporte',
  showMediaIdentifierFilter: 'Mostrar Filtro de Identificador do Suporte',
  showMediaNameFilter: 'Mostrar Filtro do Nome do Suporte',
  showMore: 'Mostrar Mais',
  showOrganizationFilter: 'Mostrar Filtro da Organização',
  showPartNameFilter: 'Mostrar Filtro do Nome da Peça',
  showPartNumberFilter: 'Mostrar Filtro do Número da Peça',
  showRecentlyAdded: 'Mostrar Adicionados Recentemente',
  showRecentlyViewed: 'Mostrar Recentemente Visualizados',
  showSearchButton: 'Mostrar Botão de Pesquisa de Página de Início',
  showSearchButtonHelp: 'Mostrar o botão \'Go to Search\' no cabeçalho da Biblioteca',
  signature: 'Assinatura',
  size: 'Tamanho',
  snapshot: 'Instantâneo | Instantâneos',
  solrSlaveSvrOverride: 'Sobreposição URLs SOLR Subordinada Servidores Base',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Mais Relevante',
  sortType: 'Tipo de Organização',
  spanishDescription: 'Descrição em Espanhol',
  spanishName: 'Nome em Espanhol',
  square: 'Quadrado',
  ssoConfiguration: 'Configuração SSO',
  ssoIdpMetadataUrl: 'SSO IDP Metadata URL',
  ssoLoginMaxAuthenticationAgeSeconds: 'Autenticação SSO Login Max Idade Segundos',
  ssoSamlDnAttributeName: 'Nome Atributo SSO SAML DN',
  ssoSamlEmailAttributeName: 'Nome Atributo SSO SAML Email',
  ssoSamlMemberofAttributeName: 'Nome Atributo SSO SAML MembroDe',
  ssoSniForSsoMetadataRetrieval: 'Utilização SNI Para Recuperação SSO Metadata',
  standard: 'Padrão',
  standardUserGroup: 'Grupo de utilizadores padrão',
  startDate: 'Data de Início',
  startDateRequired: 'É necessária uma data de início.',
  state: 'Estado',
  static: 'Estática',
  status: 'Estado',
  statusCode: 'Código de Estado | Códigos de Estado',
  stitchPages: 'Páginas de Costura',
  storeFrontAdmin: 'Admin de Montra',
  storefront: 'Montra',
  storefrontIntegration: 'Integração de Montra | Integrações de Montra',
  storefrontIntegrationSubtitle: 'Estas configurações controlam vários elementos e opções de Integração de Montra. Tenha cuidado ao fazer alterações. Contacte o Suporte Documoto para obter mais informações.',
  storefrontSettings: 'Configurações de Montra',
  storefrontSettingsDescription: 'Estas configurações controlam vários elementos e opções de Montra. Tenha cuidado ao fazer alterações. Contacte os Serviços Documoto para obter mais informações.',
  storefrontSettingsOrgDescription: 'Estas configurações controlam vários elementos e opções de Montra ao nível da Organização. Tenha cuidado ao fazer alterações. Contacte os Serviços Documoto para obter mais informações.',
  storeFilterValues: 'Armazenar valores de filtro',
  storeFilterValuesDescription: 'A ativação desta definição armazena os valores definidos pelos utilizadores nos seus filtros de página de pesquisa para esta etiqueta.',
  stroke: 'Traço',
  style: 'Estilo | Estilos',
  styles: {
    colorHotpoint: 'Hotpoint predefinido',
    invalidHexcode: 'Código Hex inválido',
    inheritedFromOrg: 'Herdado da organização {org}',
    inheritedFromSystem: 'Herdado do padrão do sistema',
    inheritedFromTenant: 'Herdado do inquilino',
    organization: 'Estilo da organização | Estilos da organização',
    organizationSaved: 'Estilos da organização guardados',
    sampleNavigation: 'Abas de navegação',
    samplePrimary: 'Navegação/Botões',
    sampleSelectedHotpoint: 'Hotpoints selecionados',
    tenant: 'Estilo do inquilino | Estilos do inquilino',
    tenantSaved: 'Estilos do inquilino guardados'
  },
  subChapter: 'Subcapítulo | Subcapítulos',
  subject: 'Assunto',
  subjectMessage: 'É necessário um assunto',
  submit: 'Enviar',
  submitPurchaseOrder: 'Nota de Pedido',
  submitRequestForQuote: 'Solicitar Orçamento',
  submitted: 'Submetido',
  submitter: 'Autor',
  submittedBy: 'Submetido por',
  submitterEmail: 'E-mail do Responsável pela Apresentação',
  submitterFirstName: 'Nome do Responsável pela Apresentação',
  submittedFilesMessage: 'Ficheiro(s) {fileCount} submetido(s).',
  submitterLastName: 'Apelido do Responsável pela Apresentação',
  submitterOrgName: 'Nome da Org do Responsável pela Apresentação',
  submitterUserName: 'Nome do Utilizador do Responsável pela Apresentação',
  success: 'Sucesso',
  imageSaveSuccess: 'Imagens Atualizadas com Sucesso',
  successNotificationDuration: 'Adicionar ao Carrinho - Duração da Notificação de Brinde com Sucesso',
  suffix: 'Sufixo | Sufixos',
  suggestedKeywords: 'Palavras-passe sugeridas',
  suggestedResults: 'Resultados sugeridos',
  suggestion: 'Sugestão | Sugestões',
  sun: 'Dom',
  superadmin: 'Superadmin',
  superAdminSettings: "Definições Superadmin",
  supersede: 'Substituir',
  supersedeComplete: 'Deseja concluir a substituição?',
  supersedeDiscardChanges: 'Eliminar a sua substituição?',
  supersedeMainText: 'A substituição substitui qualquer peça existente com uma ou mais outras peças, em cada Página de Peças onde a peça antiga for encontrada. O número de peça antigo será apagado, mas continua a ser possível pesquisar o mesmo através de etiquetas para que o conteúdo possa ser encontrado utilizando o número antigo ou o(s) número(s) novo(s).',
  supersedeMessage: 'As atualizações de peça ficam imediatamente efetivas. A pesquisa irá refletir as atualizações dentro de alguns minutos.',
  supersedeNotProcessed: 'A sua substituição não será processada.',
  supersedeReplacePart: 'Encontre uma ou mais peças que irão substituir a peça',
  supersedeSuccessful: 'Substituição Concluída com Sucesso',
  supersedeWith: 'Substituir Peça com...',
  supplier: 'Fornecedor | Fornecedores',
  supplierKey: 'Chave Fornecedor',
  supplierDescription: 'A empresa que fornece esta peça',
  supportedImageFormats: 'Formatos de imagem suportados: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Ficheiro não suportado; ficheiros draft suportados: MDZ, PLZ',
  surroundWithQuotes: 'Rodeie múltiplos termos de pesquisa com citações para uma correspondência exata.',
  svgFinder: 'Localizador SVG',
  swedishDescription: 'Descrição em Sueco',
  swedishName: 'Nome em Sueco',
  system: 'Sistema',
  systemAdmin: 'Admin Sistema',
  systemFields: 'Campos de Sistema',
  systemFieldSearchAndFiltering: 'Pesquisa e filtragem de campos do sistema',
  systemStatus: 'Estado do Sistema',
  systemName: 'Nome do sistema',
  systemVersion: 'Versão do sistema',
  tabDelimited: 'Delimitação de Etiqueta',
  tableOfContents: 'Índice',
  tabloid: 'Tabloid',
  tag: 'Etiqueta | Etiquetas',
  tagName: 'Nome da etiqueta',
  tagEditor: {
    rangeBoundLengthInvalid: 'O limite não pode ser mais de {limit} dígitos.',
    tagEditorModal: 'Modal de Editor de Etiqueta',
    filterPrompt: 'Exibindo os 100 primeiros valores. Use o filtro para ver mais.'
  },
  tagSearchAndFiltering: 'Pesquisa e filtragem de etiquetas',
  tagSearchAndFilteringHelpText: 'Dica: algumas definições de pesquisa e filtragem também são configuráveis nos editores de etiquetas do Gestor de Etiquetas, que se encontram aquí: ',
  tagSubmitAffected: 'Qualquer conteúdo {type} irá automaticamente perder a etiqueta {tag} além das alterações.',
  tagDeleteAffectedCount: 'Isto irá apagar a etiqueta e remover a informação de etiqueta de {count} artigos.',
  tagDeleteAffectedNone: 'Não existem itens atualmente a utilizar esta etiqueta.',
  tagManagerDeleteWarning: 'Quaisquer suportes existentes com {deleteCategoryName} irão perder esta categoria após eliminação.',
  tagsDisplayMore: 'Exibindo os 100 primeiros valores. Use o filtro para ver mais.',
  tagsManager: 'Gestor de etiquetas',
  tagValue: 'Valor de Etiqueta | Valores de Etiqueta',
  tagValueMissing: 'Deve inserir um valor de etiqueta',
  tagValueMultiple: 'Delimitado por ponto e vírgula para múltiplas entradas',
  tagValueTooLong: 'O valor da etiqueta ultrapassa o limite de 25 caracteres | Os valores da etiqueta ultrapassam o limite de 25 caracteres',
  taxon: 'Táxon',
  taxonomy: 'Taxonomia',
  template: 'Modelo | Modelos',
  templateEmailRecipients: 'Destinatários de Email Modelo',
  templateLayout: {
    addRow: 'Adicionar Linha',
    addItem: 'Adicionar item',
    deleteRow: 'Eliminar Linha',
    deleteColumn: 'Eliminar Coluna',
    fullWidth: 'Largura Completa',
    oneColumn: 'Uma Coluna',
    threeColumns: 'Três Colunas',
    twoColumns: 'Duas Colunas'
  },
  tenant: 'Inquilino | Inquilinos',
  tenantAdmin: 'Admin Inquilino',
  tenantAdminMessage: 'Controlo de definições totais de inquilinos',
  tenantCleanerJob: 'Manutenção de Locatário',
  tenantConfiguration: 'Configuração Locatário',
  tenantEmailRecipients: 'Destinatários de Email do Inquilino',
  tenantEmailRecipientsOverrideMessage: 'Estes serão substituídos se forem especificados Destinatários de Email da Organização',
  tenantIndexQueueJob: 'Fila de Índice de Locatário',
  tenantKey: 'Chave Locatário',
  tenantKeyError: 'Chave Locatário não é única',
  tenantKeyMessage: 'Chave Locatário deve ser única',
  tenantLabelDeleteMessage: 'Eliminar esta etiqueta irá reverter o nome para a predefinição Documoto.',
  tenantReindexModulus: 'Módulo Reindex Locatário',
  tenantSettings: {
    collapseTOCLabel: 'Colapsar Índice em Página Correspondente',
    defaultLocaleLabel: 'Local Predefinido',
    defaultLocaleMessage: 'O local predefinido do inquilino.',
    deleteMissingTranslationsOnPublishLabel: 'Eliminar Traduções em Falta no Publish?',
    deleteMissingTranslationsOnPublishMessage: 'Ativar para Eliminar Traduções Parciais no Publishing',
    defaultSupplierLabel: 'Fornecedor Predefinido',
    defaultSupplierMessage: 'O fornecedor predefinido do inquilino.',
    enableChapterIndexingMessage: 'Capítulos de Índice',
    enableChapterIndexingLabel: 'Ativa Índice para Capítulos de Media',
    enableIndexingLabel: 'Índice de Publicação',
    enableIndexingMessage: 'Muda a indexação para publicação.',
    helpUrlLabel: 'URL de Ajuda predefinido',
    helpUrlMessage: 'O URL de um sistema de ajuda alternativo.',
    hotPointLinkPageInBookOnlyLabel: 'Página Ligações Hotpoint Apenas no Livro',
    hotPointLinkPageInBookOnlyMessage: 'Ligações Hotpoint de Páginas Apenas em Livro',
    mediaTimestampLabel: 'Atualizar Carimbo de Data/Hora do Suporte na Alteração de Controlo de Acesso',
    mediaTimestampMessage: 'Ativar se uma alteração nos controlos de acesso devem atualizar o carimbo de data/hora do suporte.',
    publisherDefaultUom: 'UOM Padrão do Editor',
    publishingContactLabel: 'Publicar Email de Contacto',
    publishingContactMessage: 'O endereço de email predefinido que irá receber os emails quando é reportada uma questão de conteúdo.',
    replaceHotpointLabel: 'Substituir Sempre as Ligações Hotpoint ao Publicar',
    replaceHotpointMessage: 'Apenas para publicação Docustudio.',
    ssoLoginSystemLabel: 'Sistema de Início de Sessão SSO',
    ssoLoginSystemMessage: 'Sistema de Provedor de Identidade para SSO.',
    userPasswordExpirationLabel: 'Dias Validade Palavra-passe Utilizador',
    userPasswordExpirationMessage: 'Número de Dias após os quais as palavras-passe devem ser redefinidas; deixar em branco para não expirar',
    lockPartTrOnPublishLabel: 'Bloquear Traduções Parciais no Publish',
    lockPartTrOnPublishMessage: 'Evita Traduções Parciais de serem modificadas durante Publishing',
    exportPgAsHashLabel: 'Exportar Arquivo de Página Name como Tecla Cardinal',
    exportPgAsHashMessage: 'Utilizar Tecla Cardinal como Nomes Arquivo Página em conteúdo Exportado'
  },
  tenantSettingsTitle: 'Configurações do Inquilino',
  tenantSettingsSaved: 'Configurações do Inquilino Guardadas',
  tenantSettingsSubtitle: 'Essas configurações controlam as propriedades e comportamentos de todos os inqulinos. Tenha cuidado ao fazer alterações. Entre em contato com a Documoto Services para mais informações.',
  tenantSuppliers: 'Fornecedores Locatários',
  tenantLabelsPageText: 'As etiquetas são utilizadas para personalizar os nomes de muitos objetos encontrados ao longo dos ecrãs de Biblioteca e Administração Documoto.',
  tenantPrintSettingsDescription: 'Escolha a configuração de impressão predefinida ao nível do inquilino. Isto será utilizado para todas as impressões de livro a não ser que sejam substituídas na Organização ou Suporte.',
  tenantType: 'Tipo Locatário',
  tenantWebserviceUser: 'Utilizador Webservice Locatário',
  text: 'Texto',
  textArea: 'Área de Texto',
  textField: 'Campo de Texto',
  textFile: 'Ficheiro de Texto (CSV, TXT)',
  textTool: 'Ferramenta de Texto',
  threeD: {
    addFile: 'Adicionar um Ficheiro de Peças 3D',
    beforeSaveModal: {
      title: 'Já existe um ficheiro CAD no Documoto com este nome de ficheiro.',
      message: 'Gostaria de substituir este ficheiro em todos os locais que exista, ou anexá-lo apenas a esta peça? Se escolher substituir o ficheiro, ocorrerá o seguinte:',
      messageBulletOne: '{count} peça(s) será(ão) atualizada(a) com este novo ficheiro CAD',
      messageBulletTwo: 'A versão anterior deste ficheiro será removida do sistema de ficheiros Documoto',
      tip: 'Sugestão: Para evitar qualquer sobreposição do ficheiro durante o processo de anexação, pode alterar o nome do ficheiro que está a tentar anexar antes de o importar.'
    },
    deleteTitle: 'Tem a certeza de que pretende eliminar esta visualização 3D?',
    drawMode: {
      label: 'Modo Desenho',
      hiddenLines: 'Linhas ocultas',
      shaded: 'Sombreadas',
      wireframe: 'Wireframe',
      wireframeShaded: 'Wireframe Sombreada',
    },
    explode: 'Explodir',
    fileSizeModal: {
      message: 'O tamanho máximo do ficheiro permitido em Documoto é de 25 megabytes. O ficheiro que está a tentar carregar excede o máximo e não pode ser carregado. Clique em "OK" para continuar.',
      title: 'Tamanho Máximo do Ficheiro Excedido',
    },
    orientationAndProjection: {
      back: 'Voltar',
      bottom: 'Fundo',
      front: 'Frente',
      isometric: 'Isométrico',
      left: 'Esquerda',
      orthographic: 'Ortográfico',
      projection: 'Projeção',
      perspective: 'Perspetiva',
      right: 'Direita',
      top: 'Top'
    },
    resetCamera: 'Reiniciar Câmara',
    slice: {
      label: 'Fatia',
      planeX: 'Plano de Corte X',
      planeY: 'Plano de Corte Y',
      planeZ: 'Plano de Corte Z',
      reset: 'Reiniciar Plano de Corte',
      section: 'Alternar Secção de Plano de Corte',
      visibility: 'Alternar Visibilidade de Plano de Corte',
    },
    supportedFilesMessage: 'Documoto suporta muitos formatos de ficheiro CAD. Verifique no Centro de Apoio caso não tenha a certeza se o seu formato de ficheiro CAD é suportado.',
    toolsLabel: 'Ferramentas 3D',
    topAssemblyFilename: 'Nome de ficheiro de Montagem de Topo',
    topAssemblyFilenameRequired: 'É necessário Nome de ficheiro de Montagem de Topo',
    viewerTitle: 'Visualizador 3D',
  },
  thu: 'Qui',
  thumbnail: 'Miniatura',
  thumbnailDeleted: 'Miniatura Apagada com Sucesso',
  thumbnailDeletedError: 'Erro ao Apagar Miniatura',
  thumbnailUpdated: 'Miniatura Atualizada com Sucesso',
  thumbnailUpdatedError: 'Erro na Atualização de Miniatura',
  thumbnailReapplyWarning: 'O sistema poderá tentar aplicar novamente uma miniatura com base nos conteúdos do suporte. Isto poderá demorar alguns minutos.',
  thumbnailResizeTooltip: 'Redimensiona miniaturas para o máximo de 500x500 pixels, se selecionado',
  title: 'Título',
  to: 'Para',
  toastNotifications: 'Notificações de Brinde',
  toDate: 'Até à Data',
  toc: 'Índice',
  tocClipBoardSuccessTitle: 'Copiado para o Clipboard',
  tocClipBoardWarningText: 'AVISO: As alterações não guardadas não podem ser copiadas para o seu clipboard',
  tocClipBoardWarningTitle: 'Copiado para o Clipboard',
  tocDisplayingFirstValues: 'Exibindo os 100 primeiros valores. Use o filtro para ver mais.',
  tocDuplicateWarningText: 'Foi detetada uma página duplicada num capítulo. O duplicado foi removido.',
  tocEmptyMessage: 'Parece que ainda não criou qualquer Índice.',
  toGetStarted: 'para começar',
  tocMissingChapterName: 'Nome de Capítulo em Falta',
  tocModalTableCaption: 'Modal de Editor de Etiqueta',
  tocNothingSelected: 'Sem qualquer Elemento Selecionado',
  tocOpenEditor: 'Abrir Editor',
  tocSaveRequired: 'Necessário guardar antes de editar os detalhes de capítulo',
  tocState: 'Estado Inicial de Índice',
  tocThumbnailDeleteFailTitle: 'Erro ao Apagar Miniatura',
  tocThumbnailDeleteSuccessTitle: 'Miniatura Apagada com Sucesso',
  tocThumbnailUploadFailTitle: 'Erro no Carregamento de Miniatura',
  tocThumbnailUploadSuccessTitle: 'Miniatura Carregada com Sucesso',
  tocTranslationMessage: 'O nome e a descrição opcional deste capítulo',
  tocUntitledPage: 'Página Sem Título',
  tooManySelections: 'Lista excede limite {limit} de seleção',
  togglePage: 'Página de Alternância',
  top: 'Topo',
  total: 'Total',
  totalItems: 'Artigos Totais',
  translation: 'Tradução | Traduções',
  translationHelpTextItem: 'O nome e a descrição opcional deste {item}',
  true: 'Verdadeiro',
  tryAgain: 'Tente novamente.',
  tue: 'Ter',
  turkishDescription: 'Descrição em Turco',
  turkishName: 'Nome em Turco',
  twoSided: '2 lados',
  type: 'Tipo | Tipos',
  typeAtLeastTwoChars: 'Digite pelo menos 2 caracteres para ver uma lista de correspondências.',
  typeToConfirm: 'Escreva {value} para confirmar',
  typography: 'Tipografia',
  ukraineDescription: 'Descrição em Ucraniano',
  ukraineName: 'Nome em Ucraniano',
  unableToRetrieveResults: 'Não foi possível recuperar resultados para a sua pergunta/pesquisa',
  unauthorized: 'Não autorizado',
  unknownError: 'Erro desconhecido, contacte o suporte Documoto em support{\'@\'}documoto.com para obter assistência',
  unknownPart: 'Peça Desconhecida',
  unknownType: 'Tipo desconhecido',
  unsavedAPIKeyWarning: 'A sua Chave API será perdida caso não faça Regressar',
  unsavedChanges: 'Tem alterações não guardadas. Pretende continuar?',
  unsavedChangesWarning: 'As suas alterações serão perdidas caso não as guarde.',
  unsavedWidgetKeyWarning: 'Sua chave de widget será perdida se não retornar.',
  unsupportedBrowserMessage: 'Concebemos esta aplicação com a tecnologia mais recente. Isto torna a aplicação mais rápida e fácil de utilizar. Lamentamos, mas o seu navegador não suporta estas tecnologias.',
  uom: 'Unidade de medida',
  update: 'Atualizar',
  updateFailed: 'Falha de Atualização',
  updateIndex: 'Atualizar Índice',
  updatePassword: 'Atualizar Palavra-passe',
  updatePasswordMessage: 'Palavra-passe atualizada com sucesso',
  updatePasswordPending: 'Alteração de palavra-passe pendente de ser guardada.',
  updated: 'Atualizado',
  upgradeBrowser: 'Atualize o seu navegador.',
  upload: 'Carregar',
  uploadComplete: 'Concluir Carregamento',
  uploadInProgress: 'Carregamento em Progresso',
  uponSavingDisabledTenant: 'Ao guardar esta página com esta configuração ativada, ocorrerá o seguinte:',
  uponSavingThisLicense: 'Ao guardar esta página com esta configuração, DEVE FAZER O SEGUINTE:',
  upperBound: 'Limite Superior',
  untitledOrg: 'Organização sem Título',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Utilizar Colunas de Carrinho Predefinidas',
  useDefaultPartsListColumns: 'Utilizar Colunas de Lista de Peças Predefinidas',
  user: 'Utilizador',
  userAddress: 'Endereços de Utilizador | Endereços de Utilizador',
  userCount: 'Contagem de Utilizador',
  userEditable: 'Editável pelo Utilizador',
  userExpirationDate: 'Data de expiração do utilizador',
  userExpirationDateInvalid: 'Data de expiração do utilizador deve ser inserida',
  userGroup: 'Grupo de Utilizador | Grupos de Utilizador',
  userGroupEmpty: 'Os Grupos de utilizadores não podem estar vazios',
  userGroupType: 'Tipo de grupo de utilizadores',
  userGroupUniqueMessage: 'Este nome tem de ser único em relação a todos os outros nomes de Grupo de Utilizador para este inquilino',
  userGroupUsers: 'Utilizadores do Grupo de Utilizador',
  username: 'Nome de Utilizador',
  usernameEmpty: 'O nome de utilizador não pode estar vazio',
  users: 'Utilizadores',
  usersWillReceiveAnUnauthorizedError: 'Os utilizadores vão receber um erro “Não autorizado” quando tentarem iniciar sessão ao locatário.',
  useSniForSsoMetadataRetrieval: 'Utilização SNI Para Recuperação SSO Metadata',
  value: 'Valor',
  validate: 'Validar',
  validateTenantDirectories: {
    buttonLabel: 'Validar Diretórios Conteúdo',
    error: 'Falha na validação diretórios',
    success: 'Diretórios locatário validados'
  },
  variantSKU: 'Variante de Unidade de Manutenção de Stock',
  validationMessage: 'Mensagem de Validação | Mensagens de Validação',
  valueGteError: 'O valor tem de ser inferior ou igual a {max}',
  valueLteError: 'O valor tem de ser superior ou igual a {min}',
  vendorName: 'Nome do fornecedor',
  verifyPassword: 'Verificar Palavra-passe',
  vertical: 'Vertical',
  version: 'Versão',
  video: 'Vídeo | Vídeos',
  view: 'Ver',
  viewAll: 'Visualizar todos',
  viewAllPagesAndBooks: 'Visualizar: Todas as páginas e livros',
  viewAllResults: 'Visualizar todos os Resultados',
  viewBooks: 'Visualizar: Livros',
  viewMore: 'Visualizar mais',
  viewPages: 'Visualizar: Páginas',
  viewModeList: 'Lista',
  viewModeTable: 'Tabela',
  visibility: 'Visibilidade',
  visibilityDescription: 'Quem pode ver esta {entity}',
  visible: 'Visível',
  waitForUpload: 'Aguarde para o carregamento estar concluído.',
  watermark: 'Marca de água',
  watermarkFont: 'Fonte da marca de água',
  watermarkOpacityPercentage: 'Opacidade da marca de água em %',
  watermarkOrientation: 'Orientação da marca de água',
  watermarkSaved: 'Marca de água guardada',
  watermarkSaveFailed: 'Falha ao salvar a marca de água.',
  watermarkSelectionRequired: 'É necessária a seleção do nome de utilizador ou do texto.',
  watermarkText: 'Texto da marca de água',
  wed: 'Qua',
  whereUsed: 'Onde são usados',
  whoCanViewComment: 'Quem pode ver este comentário?',
  wholesale: 'Grossista',
  wholesalePrice: 'Preço de Grossista',
  wholesaleTotal: 'Total de Grossista',
  widget: 'Widget',
  widgetAuthHeader: 'Valor de autorização do widget',
  widgetKey: 'Chave do widget | Chaves do widget',
  widgetKeyAdmin: 'Gestor de chave do widget',
  widgetKeyCopied: 'Valor de autorização do widget copiado para a área de transferência',
  widgetKeyDeleted: 'Chave de widget eliminada',
  widgetKeyDeletedError: 'Erro ao eliminar a chave do widget',
  widgetKeyDeleteMsg: 'A eliminação da chave do widget desativa a sua utilização',
  widgetKeyManager: 'Gestor de chaves do widget',
  widgetKeyNameEmpty: 'O nome tem de ser único e é obrigatório. Este valor também será utilizado para fins de relatório e registo como nome de utilizador para qualquer atividade do Widget',
  widgetKeyRegenError: 'Erro ao gerar novamente a chave do widget',
  widgetKeyRegenMsg: 'Gerar nova chave para o widget pode desativar as integrações existentes',
  widgetKeyRegenTitle: 'Tem a certeza de que pretende regenerar esta chave do widget?',
  widgetKeySaved: 'Chave do widget guardada',
  widgetPermissions: 'Os utilizadores do widget são de modo apenas de leitura e o acesso às funcionalidades é gerido pela configuração ao nível do widget. As permissões de grupo de utilizadores padrão não se aplicam aos utilizadores do widget. Os privilégios de acesso à categoria de média estão disponíveis. Prossiga com a atribuição dos controlos de acesso à categoria de média.',
  widgetRefreshErrorMsg: "Por favor, atualize a página para ver o conteúdo solicitado",
  widgetUserGroup: 'Grupo de utilizadores de widgets',
  widgetUserGroupDeleteMsg: 'Não é possível eliminar o grupo de widgets com utilizadores',
  willBeAffected: 'será afetado.',
  willBeDeleted: '{content} será eliminado.',
  willBeInsertedIntoItem: '{name} será inserido em {item}',
  willBeUpdated: 'será atualizado.',
  zipCode: 'Código postal',
  zoomHotpointToCanvas: 'Aumentar Hotpoint para tamanho do Ecrã',
  zoomIn: 'Aumentar Zoom',
  zoomOut: 'Reduzir Zoom'
}
/* eslint-enable max-len */
