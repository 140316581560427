/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a}/{b}',
  about: '정보',
  aboutThisItem: '이 항목 소개',
  accessApiKeyManager: '이 테넌트에 대한 API 키 관리자에 액세스합니다.',
  accessControls: '액세스 제어',
  accessControlsCopiedText: '액세스 제어가 즉시 업데이트됩니다. 미디어 보안 권한이 몇 분 후에 업데이트됩니다.',
  accessControlsCopiedTitle: '액세스 제어가 성공적으로 복사됨',
  accessControlsCopyConfirmation: '이렇게 하면 {to}의 기존 액세스 제어가 모두 제거되고 {from}의 액세스 제어로 대체됩니다. 이 작업은 취소할 수 없습니다.',
  accessControlsDescription: '액세스 제어는 선택한 조직 및 미디어 카테고리를 기반으로 이 미디어를 볼 수 있는 사람을 정의합니다.',
  accessControlsIndeterminance: '참고: 상위 조직은 항상 하위 조직에 할당된 콘텐츠에 액세스할 수 있습니다.',
  account: '계정',
  accountErrorMessage: '계정 오류 메시지',
  accountErrorMessageWarning: '데이터베이스의 accountErrorMessage 필드가 테넌트에 대해 ‘권한 없음’으로 설정됩니다.',
  action: '작업 | 작업',
  actionDelete: '삭제',
  actionLoad: '불러오기',
  actionPaste: '붙여넣기',
  actionSave: '저장',
  actionRun: '실행',
  actionUndoWarning: '이 작업은 취소할 수 없습니다.',
  actionUpload: '업로드',
  actualSize: '실제 크기',
  add: '추가',
  addAPIKey: 'API 키를 추가하기',
  addItem: '{item} 추가',
  addABookText: '책 추가',
  addASharedCart: '공유 장바구니 추가',
  addATemplate: '템플릿 추가',
  addATag: '태그 추가',
  addABookToStart: '{addABookText}을(를) 시작하십시오.',
  addAForm: '양식을 추가하여 시작',
  addAFormOnly: '양식 추가',
  addAMedia: '미디어 추가',
  addAnOrderQuoteEmailFormat: '주문/견적 이메일 형식 추가',
  addAPrintSettingText: '인쇄 설정 추가',
  addAPrintSettingToStart: '{addAPrintSettingText}을(를) 시작하십시오.',
  addBooks: '책 추가',
  addChildBrowseFlow: '하위 플로우 찾아보기 추가',
  addChapter: '챕터 추가하기',
  addComment: '댓글 추가',
  addExistingPart: '기존 부품 추가',
  addGroupsToUsers: '{users} 사용자에게 {groups} 그룹 추가',
  additionalInformation: '추가 정보',
  additionalNotification: '추가 알림',
  additionalPartNumberDescription: '추가 부품 번호 필드로 사용',
  addBrowseFlow: '플로우 찾아보기 추가',
  addMedia: '미디어 추가',
  addPages: '페이지 추가',
  addPart: '부품 추가',
  addReports: '보고서 추가하기',
  addRootBrowseFlow: '루트 플로우 찾아보기 추가’',
  addRow: '행 추가',
  addTemplates: '템플릿 추가',
  addToCartMessage: '{partNumber}을(를) 성공적으로 추가함',
  addToUserGroup: '그룹에 추가',
  address: '주소 | 주소',
  addressInUse: '주소가 현재 기본 주소로 설정되어 있어 삭제할 수 없습니다.',
  addressInUseSubtitle: '이 주소를 삭제하려면 먼저 이 주소를 기본값에서 제거해야 합니다.',
  addressLine: '주소 입력란{number}',
  addWidgetKey: '위젯 키 추가',
  admin: '관리자',
  adminCenter: '관리 센터',
adminCenterBanner: {
    bodyLabel: '배너 바디',
    enableLabel: '관리자 배너 활성화',
    headerGreenOption: '그린',
    headerLabel: '배너 헤더',
    headerRedOption: '레드',
    headerYellowOption: '옐로우',
    title: '관리자 센터 배너',
    typeLabel: '배너 유형'
  },
  adminCenterMessage: '콘텐츠, 사용자, 라이브러리 설정, 보고, 내보내기를 위한 관리 센터',
  adminMessage: '사용 가능한 기능이 추가됨',
  administration: '관리',
  affectedBookMedia: '{count} 북 미디어가 업데이트됩니다.',
  affectedTemplateMedia: '{count} 북 템플릿이 업데이트됩니다.',
  after: '이후',
  aggregatedFromChildren: '하위로부터 집계',
  aiAlgorithm: 'AI 스캐너',
  all: '모두',
  alphanumeric: '영숫자',
  algorithm: '알고리즘',
  alignHorizontal: '수평 정렬',
  alignVertical: '수직 정렬',
  allUsersDisabled: '테넌트에 속한 모든 사용자가 비활성화됩니다.',
  analyticsJSON: 'Analytics JSON',
  andCountMore: ' 및 {count}개 더...',
  any: '모든',
  apiKey: 'API 키 | API 키',
  apiKeyAdmin: 'API 키 관리자',
  apiAuthHeader: 'API 승인 헤더',
  apiKeyCopied: 'API 승인 헤더 값이 클립보드에 복사됨',
  apiKeyDeleted: 'API 키 삭제됨',
  apiKeyDeletedError: 'API 키를 삭제하는 동안 오류 발생',
  apiKeyDeleteMsg: '이 API 키를 삭제하면 통합에서 비활성화되어 사용할 수 없습니다',
  apiKeyDeleteTitle: '정말로 이 API 키를 삭제하시겠습니까?',
  apiKeyInstructions: '저장되면 키가 생성됩니다',
  apiKeyManager: 'API 키 관리자',
  apiKeyNameEmpty: '이름을 입력해야 합니다',
  apiKeyRegenError: 'API 키 생성하는 동안 오류 발생',
  apiKeyRegenMsg: '이 API 키를 다시 생성하면 기존 통합을 사용할 수 없게 될 수 있습니다.',
  apiKeyRegenTitle: '이 API 키를 다시 생성하시겠습니까?',
  apiKeySaved: 'API 키가 저장되었습니다',
  apiKeyType: 'API 키 유형',
  appliesTo: '적용 대상',
  appliesToEmpty: '하나 이상의 엔티티 유형을 선택해야 합니다',
  appliesToPopulated: '태그를 적용할 수 있는 엔티티 설정',
  applyItem: '{item} 적용',
  applyToChildItems: '하위 항목에 적용 {item}',
  applyToPartOnly: '이 부품에만 적용',
  april: '4월',
  area: '분야',
  areYouSure: '{item}을(를) 정말 삭제하시겠습니까?',
  areYouSureCopy: '{item}을(를) 정말 복사하시겠습니까?',
  areYouSureYouWantToDisableTheTenant: '테넌트를 비활성화하시겠습니까?',
  assembly: '어셈블리 | 어셈블리',
  assemblyCreatorSuccessTitle: '제출된 어셈블리 작업 만들기',
  assemblyCreatorSuccessSubtitle: '핫포인트 링크 및 도서 목차 변경 사항이 즉시 적용됩니다. 인덱싱이 완료되면 검색 결과가 반영됩니다.',
  assemblyConfirmationTitle: '어셈블리 작성을 완료하시겠습니까?',
  assemblyCreatorHelpText1: '이 기능을 사용하면 {partName} 부품이 발견되면 핫포인트 링크를 통해 이 부품과 선택한 페이지를 자동으로 연결하여 선택한 페이지를 책에 삽입할 수 있습니다. 선택한 책으로의 삽입을 선택적으로 제한할 수도 있습니다.',
  assemblyCreatorHelpText2: '선택한 페이지는 {partname}의 첫 번째 발생 이후 책당 한 번씩 삽입됩니다.',
  assemblyStepOne: '1단계: 삽입할 페이지를 선택하십시오.',
  assemblyStepTwo: '2단계: 페이지를 삽입할 책을 선택하십시오.',
  asset: '자산 | 자산',
  assetsNotFound: '{type}을(를) 찾을 수 없음',
  assignBrowseFlow: '플로우 찾아보기 지정',
  assignedOrganizations: '지정된 조직',
  atLeastFiveError: '5 이상의 값을 선택하십시오.',
  attachment: '첨부 파일 | 첨부 파일',
  attachmentEditorDescription: '이미지, 동영상, 문서를 이 엔티티에 직접 첨부하십시오. 참고: "관련" 탭에서 찾을 수 있습니다.',
  attachmentView: '첨부 파일 보기',
  attachRelatedPDFs: '관련 부품 페이지의 PDF 첨부',
  attributions: '속성',
  august: '8월',
  automaticZoom: '자동 확대/축소',
  autoHotpoint: '자동 핫포인트',
  autoPopulate: '자동 채우기',
  availability: '가용성',
  back: '뒤로',
  backCover: '뒷표지',
  backStitch: '백 스티치',
  backToLibrary: '라이브러리로 돌아가기',
  backToSearch: '검색으로 돌아가기',
  badCredentials: '잘못된 자격 증명',
  bahasaDescription: '바하사 인도네시아어 설명',
  bahasaName: '바하사 인도네시아어 이름',
  banner: '배너',
  before: '이전',
  beginTypingToFindAnything: '무엇이든 검색하려면 입력을 시작합니다',
  betaAccessKeys: '베타 엑세스 키',
  betaResources: '베타 리소스',
  betaReleaseVideo: '베타 릴리스 동영상',
  blobFinder: 'BLOB 파인더',
  body: '바디',
  bomDefaultSupplier: 'BOM 기본 공급자',
  bomItem: '재료표 항목',
  bom: '재료표',
  bomExportSuccess: 'BOM 내보내기 성공',
  bomLayout: '자재 명세서 레이아웃',
  bomListOptions: 'BOM 목록 옵션',
  bomListSettings: 'BOM 목록 설정',
  both: '모두',
  book: '책 | 책',
  bookInserts: '책 삽입',
  bookLabel: '책 | 책',
  bookPrintingDisable: '책 인쇄 비활성화',
  booksAndPagesDisplay: '책 및 페이지 표시',
  boost: '부스트 검색',
  boostDescription: '부품 번호, 미디어 식별자 및 이름에 대해 이 태그의 검색 관련성을 높이려면 1.5가 넘는 값을 추가하십시오.',
  boostRangeInvalid: '부스트는 0.5~100.0 사이어야 합니다',
  border: '경계',
  bottom: '아래',
  branding: '브랜딩',
  browse: '찾아보기',
  browseComputer: '컴퓨터 찾아보기',
  browseFlow: '플로우 찾아보기 | 플로우 찾아보기',
  browseFlowAssigned: '지정된 플로우 찾아보기',
  browseFlowCopyMessage: '새 플로우 찾아보기의 고유한 이름을 입력하십시오.',
  browseFlowDeleted: '검색 흐름 삭제됨.',
  browseFlowDeleteMessage: '플로우 찾아보기 삭제 시 플로우 찾아보기 및 모든 하위 플로우 찾아보기가 삭제됨',
  browseFlowEmptyState: '정의된 플로우 찾아보기 없음. {new}을(를) 시작하십시오.',
  browseFlowHeirarchy: '플로우 찾아보기 체계',
  browseFlowNoExistingCriteria: '이 플로우 찾아보기에 대해 설정된 검색 기준이 설정되지 않았습니다. 검색 기준을 선택하려면 위의 제어장치를 이용하십시오.',
  browseFlowMoveToRoot: '1차 레벨 하위 생성을 위해 여기에 노드를 놓으십시오',
  browseFlowNoAssignedOrgs: '지정된 조직 없음',
  browseFlowSaveFailed: '플로우 찾아보기를 업데이트할 수 없습니다',
  browseFlowSetDefaultSuccess: '기본 플로우 찾아보기 설정',
  browseFlowThumbnailDeleteFailTitle: '썸네일 삭제 실패',
  browseFlowThumbnailDeleteSuccessTitle: '썸네일 삭제 성공',
  browseFlowThumbnailUploadFailTitle: '썸네일 업로드 실패',
  browseFlowThumbnailUploadSuccessTitle: '썸네일 업로드 성공',
  browseFlowTooManyOrgs: '현재 탐색 플로우 편집기를 사용할 조직이 너무 많습니다. Documoto 찾아보기 플로우 편집에 대한 도움말은 {email}(으)로 Documoto 지원 팀에 문의하십시오.',
  browserPartiallySupport: '이 브라우저에는 미적인 차이와 기타 변경 사항이 있을 수 있습니다.',
  browseFlowSaved: '플로우 찾아보기 저장됨',
  browserDeprecation: {
    title: '브라우저 중단 안내',
    text: '마이크로소프트의 인터넷 익스플로러(모든 버전)에 대한 지원 중단 및 관련 보안 문제와 관련하여 2021년 9월 30일 이후에는 이 사이트에 인터넷 익스플로러(모든 버전)를 이용한 사용자의 접속을 더 이상 지원하지 않습니다.'
  },
  buildHotpointLinks: '핫포인트 링크 구축',
  buildHotpointLinksInfo: '이 작업을 수행하면 먼저 책 페이지에서 기존 핫포인트 링크를 모두 제거한 다음 부분과 일치하는 어셈블리 페이지 간에 새 핫포인트 링크를 만듭니다.',
  buildHotpointLinksTitle: '이 책에 핫포인트 링크를 구축하시겠습니까?',
  bulkIndexingLane: '벌크 인덱싱 레인',
  bulkInsert: '대량 삽입',
  bulkExportConfirm: '{n}{unit}을(를) 내보내시겠습니까?',
  bulkExportLimit: '내보내기 작업당 제한은 책 {limit}권입니다',
  bulkPageInsertJob: '대량 페이지 삽입',
  business: '비즈니스',
  cancel: '취소',
  canceled: '취소됨',
  cancelAssemblyCreatorTitle: '생성 어셈블리를 삭제하시겠습니까?',
  cancelAssemblyCreatorSubtitle: '생성 어셈블리가 처리되지 않습니다.',
  cannotExceed100: '100%를 초과할 수 없습니다',
  cart: '장바구니 | 장바구니',
  cartBehaviorEnabled: '장바구니에 추가 수량 프롬프트 활성화',
  cartBehaviorDisabled: '장바구니에 추가 수량 프롬프트 비활성화',
  cartBehaviorLabel: '장바구니에 추가 수량 동작',
  cartColumn: '장바구니 열 | 장바구니 열',
  cartColumn1: '첫 번째 장바구니 가격 열',
  cartColumn2: '두 번째 장바구니 가격 열',
  cartColumn3: '세 번째 장바구니 가격 열',
  cartColumnPartLocationContent1: '이 기능을 활성화하면 다음과 같은 영향이 있습니다:  ',
  cartColumnPartLocationContent2: '장바구니의 부품 행 고유성도 해당되는 경우 콘텐츠 조각 내의 위치에 기반합니다.',
  cartColumnPartLocationContent3: '이 설정은 조직-단계에서 비활성화할 수 없으며 모든 사용자에 해당됩니다.',
  cartColumnPartLocationContent4: 'Documoto 고객 성공 관리자에게 먼저 연락하지 않고 장바구니 통합 제출이 있을 경우 이 설정이 활성화되어선 안 됩니다.',
  cartColumnPartLocationOption: '장바구니의 부품-위치 데이터 활성화',
  cartColumnPartLocationTitle: '장바구니의 부품-위치 데이터 활성화를 하시겠습니까?',
  cartDisable: '장바구니 비활성화',
  cartEmailFailed: '장바구니 이메일 실패',
  cartEmailSent: '장바구니 이메일 전송',
  cartEnable: '장바구니 활성화',
  cartSaveEnable: '장바구니 저장 활성화',
  cartErpMessage: 'Erp에 성공적으로 보냈습니다.',
  cartImportError: '장바구니 가져오기 오류',
  cartLoadedWithItemsRemoved: '{count} 현재 주문 허가가 없으므로 항목(들)이 삭제됨',
  cartSharedAddEdit: '공유 장바구니 추가/편집',
  cartUpdated: '카트 업데이트됨',
  cartUpdatedAndRemovedCount: '장바구니 업데이트됨; {count}개의 주문불가한 부품 제거됨',
  category: '카테고리 | 카테고리',
  categoryCreated: '카테고리 생성됨',
  categoryUpdated: '카테고리 업데이트됨',
  centimeter: '센티미터 | 센티미터',
  changeNotification: '변경 알림',
  changeScope: '{n}{type}이(가) 영향을 받습니다.',
  changeUserOrganization: '조직 변경',
  chapter: '챕터 | 챕터',
  chapterHeader: '머리글 챕터',
  chaptersOnly: '챕터만',
  chineseDescription: '중국어 설명',
  chineseName: '중국 이름',
  circle: '원',
  city: '도시',
  classicAdminMessage: '이를 통해 Flex로 돌아가 관리자 모듈을 사용할 수 있습니다.',
  classicAdmins: '클래식 관리자',
  classicExporter: '클래식 엑스포터',
  classicExporterMessage: '레거시 플래시 기반 콘텐츠 내보내기 모듈',
  classicOrganizationAdmin: '클래식 조직 관리자',
  classicOrganizationAdminMessage: '조직 및 사용자 관리를 위한 레거시 플래시 기반 사용자 관리 모듈',
  classicPublisher: '클래식 퍼블리셔',
  classicPublisherMessage: '콘텐츠 제작 및 인쇄 설정을 위한 레거시 플래시 기반 게시자 모듈',
  classicReporting: '클래식 보고',
  classicReportingMessage: '레거시 플래시 기반 보고 모듈',
  classicTenantAdmin: '클래식 테넌트 관리자',
  classicTenantAdminMessage: '테넌트 전체 설정을 위한 레거시 플래시 기반 관리 모듈',
  className: '클래스 이름',
  clear: '지우기',
  clearItemNumber: '항목 번호 지우기',
  clearItemNumberTitle: '항목 번호를 지우시겠습니까?',
  clearItemNumberMessage: '항목 번호를 지우면 다음 데이터가 제거됩니다.',
  clearFilters: '필터 지우기',
  clearHotpoints: '핫포인트 지우기',
  clearSearch: '검색 지우기',
  clickHereToUpload: '파일을 놓거나 클릭하여 재정의를 업로드합니다.',
  clickMagnifyingGlass: '돋보기를 클릭하여 고급 검색으로 바로 이동합니다.',
  clickOkToContinue: '계속하려면 ‘확인‘을 클릭하십시오.',
  clickToDownload: '클릭하여 다운로드',
  clickToEnlarge: '클릭하여 확대',
  clickToOpen: '클릭하여 열기',
  clipboard: '클립보드',
  clipboardAccessError: '클립보드 액세스를 허용하려면 브라우저 설정을 조정하십시오.',
  close: '닫기',
  closed: '닫힘',
  collapseAll: '모든 창 접기',
  color: '색상 | 색상',
  column: '열 | 열 ',
  columnName: '열 이름',
  columnItemType: {
    customText: '커스텀 텍스트',
    documentTitle: '문서 표제',
    horizontalLine: '가로줄',
    pageName: '페이지 이름',
    pageNumber: '페이지 번호',
    printDate: '인쇄 날짜',
    tenantName: '테넌트 이름',
    userName: '사용자 이름'
  },
  combinedPage: '결합 페이지',
  combinedPageMessage: '상단에 다이어그램, 하단에 부품 목록',
  comingSoon: '제공 예정',
  commaDelimited: '쉼표로 구분',
  comment: '댓글 | 댓글',
  commentAdded: '댓글 추가됨',
  commentDeleted: '댓글 삭제됨',
  commentEdit: '댓글 추가/편집',
  commentEditAll: '모든 댓글 편집',
  commentUpdated: '댓글 업데이트됨',
  commentView: '댓글 보기',
  commerceSystem: '상거래 시스템',
  completed: '완료됨',
  confirm: '확인',
  confirmBrowseFlowApplyToChildren: '이 플로우 찾아보기를 하위 조직에 적용하시겠습니까?',
  confirmBrowseFlowOverrideToChildren: '하위 조직에서 설정한 모든 플로우 찾아보기 무시가 제거됩니다.',
  confirmPrintSettingsApplyToChildren: '이 인쇄 설정을 하위 조직에 적용하시겠습니까?',
  confirmPrintSettingsOverrideToChildren: '하위 조직에서 설정한 모든 인쇄 설정 무시가 제거됩니다.',
  contains: '포함',
  containsHeaderRow: '헤더 행 포함',
  content: '콘텐츠 | 콘텐츠',
  contentAccessControls: '{content} 액세스 제어',
  contentEntity: '콘텐츠 엔티티 | 콘텐츠 엔티티',
  contentNotFound: '콘텐츠를 찾을 수 없음',
  contentUpdatedQueueJob: '콘텐츠 업데이트 대기열',
  contourShape: '윤곽 모양',
  convertEntity: '{entity} 변환',
  convertToEntity: '{entity}(으)로 변환',
  copyAccessControls: '액세스 제어 복사',
  copyAccessControlsWarning: '선택한 조직의 모든 할당된 액세스 제어를 현재 조직으로 복사합니다.',
  copy: '복사',
  copyAndRetain: '통합 사용을 위한 복사 & 유지',
  copyAndRetainWidget: '복사 및 유지, 애플리케이션에서 위젯 인증값이 표시되는 유일한 시간입니다.',
  copyAtoB: '{a}을(를) {b}(으)로 복사',
  copyContent: '{type}을 복사하시겠습니까? {name}',
  copyingItem: '복사중 {item}',
  copyItem: '{item} 복사',
  copyItemQuestion: '{item} 복사하시겠습니까?',
  copyTo: '{target}에 복사',
  copyMeMessage: '이 메시지를 자신에게 복사해서 보내기',
  copyrightMessage: '© 2010-{년} Documoto Inc. 모든 권리 보유.',
  count: '개수',
  country: '국가 | 국가',
  coverPages: '표지',
  coverAmpStitchPages: '표지 & 스티치 페이지',
  createAssembly: '어셈블리 생성',
  created: '생성됨',
  createdBy: '{name}에 의해 생성됨',
  createdDate: '생성된 날짜',
  createdOn: '작성 날짜',
  currentPassword: '현재 암호',
  custom: '커스텀',
  czechDescription: '체코어 설명',
  czechName: '체코어 이름',
  dashboard: '대시보드 | 대시보드',
  dashboardLibrary: '대시보드 라이브러리',
  dataInsights: '데이터 인사이트',
  dataInsightsInfoMessage: '콘텐츠, 사용자, 라이브러리 데이터 인사이트 보고 및 대시보드 센터.',
  dataMapping: '데이터 매핑/샘플 데이터(최대 10개 행)',
  dataOptions: '데이터 옵션',
  date: '날짜',
  dateModified: '수정된 날짜',
  debugPageBuilderPLZ: '디버그 페이지 작성기 PLZ',
  december: '12월',
  decreaseHotpoint: '핫포인트 감소',
  default: '기본값',
  defaultAddress: '기본 주소',
  defaultBrowseFlow: '기본 플로우 찾아보기',
  defaultCurrencyCode: '기본 통화 코드',
  defaultCurrencySymbolOn: '기본 통화 기호 켜짐',
  defaultEmailRecipient: '기본 이메일 수신자 | 기본 이메일 수신자',
  defaultImage: '기본 이미지 | 기본 이미지',
  defaultImagesManager: '기본 이미지 관리자',
  defaultItemImage: '{item} 기본 이미지',
  defaultPartsListLayout: '기본 부품 목록 레이아웃',
  defaultPartsListLayoutHelp: '그림의 왼쪽 또는 아래에 있는 기본 부품 레이아웃',
  defaultSearchMode: '기본 검색 모드',
  defaultSearchModeHelp: '기본 글로벌 검색 방법: \'포함\' 또는 \'정확히\'',
  defaultSortMode: '기본 정렬 모드',
  defaultSupplier: '기본 공급 업체',
  defaultTocStateOpen: '기본 TOC 상태 열기',
  defaultTocStateOpenHelp: '책을 볼 때 TOC가 자동으로 열려야 하는지 확인',
  defaultUom: '기본 단위',
  defaultValue: '기본 값',
  defaults: '기본값',
  defaultsAndCapabilities: '장애 및 기능',
  delete: '삭제',
  deleteAll: '상위로부터 상속',
  deleteAnyPartnerApiKeys: 'SmartEquip에 제공된 모든 파트너 API 키를 삭제합니다.',
  deleteContent: '{type}을(를) 삭제하시겠습니까? {name}',
deletePart: {
    infoOne: '파트 삭제는 라이브러리 및 콘텐츠 관리에 즉시 적용됩니다. 삭제된 파트는 몇 분 내에 색인이 다시 생성될 때까지 검색 가능한 상태로 유지됩니다.',
    deletingPartNumber: '{partNumber}이(가) 삭제됩니다.',
    affectedCarts: '이 파트가 포함된 모든 카트에는 해당 부품이 영구적으로 제거됩니다.',
    infoTwo: 'Documoto 지원의 도움 없이는 이 작업을 취소할 수 없습니다. 90일 이후에, 삭제된 기록 및 모든 연결이 영구적으로 삭제됩니다.'
  },
deletePartInUse: {
    title: '이 파트는 페이지에 속해 있으므로 삭제할 수 없습니다.',
    body: 'Documoto는 현재 페이지에 속하지 않는 파트 삭제만 지원합니다. 삭제를 계속하려면 게시된 페이지나 초안 페이지에서 이 파트를 제거하십시오.',
    submitLabel: '사용처 페이지로 이동'
  },
  deleteRelated: '정말 삭제하시겠습니까{count}{type}?',
  deletedItem: '{item} 삭제됨',
  deleteFilesMessage: '{count}개의 파일을 삭제하시겠습니까?',
  deleteHotpointLinksTitle: '핫포인트 링크(들)을 삭제하시겠습니까?',
  deleteMessage: '정말 삭제하시겠습니까?',
  deleteSelectedCriteria: '선택된 조건 삭제',
  deleteTagsTitle: '태그(들)를 삭제하시겠습니까?',
  deleteTaxonMessage: '이 분류군을 하위 분류와 함께 영구적으로 삭제합니다. 계속하시겠습니까?',
  deleteTaxonomyMessage: '이 조직 또는 테넌트의 모든 분류군을 영구적으로 삭제합니다. 계속하시겠습니까?',
  deleteTenantSupplier: '이 테넌트 공급자를 삭제하시겠습니까?',
  demo: '데모',
  description: '설명',
  deselectAll: '모두 선택 취소',
  deselectCriteria: '조건 선택 취소',
  detail: '세부 사항 | 세부 사항들',
  diagonal: '대각선',
  diameter: '지름',
  disable: '비활성화',
  disableAll: '모두 비활성화',
  disableDocumotoTenant: 'Documoto 테넌트 비활성화',
  disableFwdReindex: 'DSW에 대한 대상 미디어 재색인 포워딩 비활성화',
  disableLibraryBannerImage: '라이브러리 배너 이미지 비활성화',
  disableSmartEquipModalTitle: '이 라이선스를 비활성화하시겠습니까?',
  disableTEK: '다이렉트 URL 로그인 비활성화하기',
  disabled: '비활성화됨',
  discard: '취소',
  discardChanges: '변경 사항 취소',
  discardQ: '변경 사항 취소?',
  discounted: '할인됨',
  discountedPrice: '할인 가격',
  discountedTotal: '할인 총계',
  discountPrice: '할인 가격',
  displayInOrdersList: '주문 목록 내 표시',
  displayOrder: '표시 순서',
  displayOrderDescription: '콘텐츠 형식 태그에 대한 필터 드롭다운 표시 순서 설정',
  document: '문서 | 문서',
  documentLabel: '문서 | 문서',
  documentSettings: '문서 설정',
  documotoAcademy: 'Documoto 아카데미',
  documotoLicensePackage: 'Documoto 라이선스 패키지',
  domain: '도메인',
  domainEmptyMessage: '허용된 도메인은 비워둘 수 없습니다',
  domainInvalidMessage: '유효한 도메인 이름이 필요합니다',
  download: '다운로드',
  downloadBrowser: '이 훌륭한 브라우저 중 하나를 다운로드하십시오.',
  downloadFailed: '오류 다운로드 중{name}',
  downloadStarted: '다운로드 진행 중{name}',
  draft: '초안 | 체커',
  draftBomDeleteTitle: '이 부분을 페이지에서 삭제하시겠습니까?',
  draftBomMultipleDeleteTitle: '이 부분을 페이지에서 삭제하시겠습니까?',
  draftBomDropError: '5레벨 심층 이상의 파트를 끼워 넣을 수 없습니다',
  draftBomEmptyField: '파트 목록의 모든 항목을 채워야 합니다',
  draftBomQuantityError: '수량은 숫자로 적어야 합니다',
  draftBomError: '재료표 작성 처리 오류',
  draftPage: '초안 페이지',
  draftPageDelete: '초안 삭제하기',
  draftPageDeleteMessage: '초안 페이지가 삭제되었습니다.',
  draftPageDeleteWarning: '초안 페이지 삭제하기는 진행 중인 업데이트를 제거합니다.',
  draftPageLastUpdated: '초안 - 최근 업데이트 날짜 {date} 및 작성자 {username}',
  draftPageLoadError: '초안 페이지를 로드하는 데 실패했습니다.',
  draftPageProcessingPlz: 'PLZ 파일에서 페이지가 로드되는 중입니다',
  draftPagePublishInProgress: '페이지 발행 작업 진행 중',
  draftPageSaveSuccess: '초안 페이지가 저장되었습니다.',
  draftPageSaveUnsuccessful: '초안 페이지가 저장되지 않았습니다.',
  draftSheetImageHotpointsFailure: '드래프트 시트 이미지에 대한 {algorithm} 핫포인트를 생성할 수 없습니다.',
  draftValidationMessage: {
    item: '경고: BOM 부품 번호 {partNumber}에 항목 번호가 지정되지 않았습니다.',
  empty: '검증 오류/경고 없음',
  hotpointOrHotpointLink: 'BOM 항목 {item}에 핫포인트/핫포인트 링크가 지정되지 않음',
  quantity: 'BOM 항목 {item}에 지정된 수량이 없습니다.',
  tag: 'BOM 항목 {item} 태그 {tagName} 누락된 태그 값',
  translationDescription: 'BOM 항목 {item} 누락된 {lang} 번역 설명',
  uom: 'BOM 항목 {item}에 지정된 측정 단위가 없음',
    supersede: '슈퍼세션으로 인해 {partNumber} 파트가 {supersededPartNumber}(으)로 대체되었습니다.'
  },
  drawAutohotpointRegion: '{shape} 크기 그리기',
  drawMinimumArea: '최소 면적 그리기',
  dropFileMessage: '파일을 여기에 놓거나 클릭하여 업로드합니다.',
  duplicate: '복제',
  duplicateSaveError: '중복된 항목. 저장할 수 없습니다.',
  dutchDescription: '네덜란드어 설명',
  dutchName: '네덜란드 이름',
  dynamic: '동적',
  dynamicNaming: '동적 이름 지정',
  ecommerce: '전자상거래',
  ecommerceEmailFormatCreated: '전자상거래 이메일 형식 생성됨',
  ecommerceEmailFormatSaved: '전자상거래 이메일 형식 저장됨',
  ecommerceLogo: '전자상거래 로고',
  ecommerceUseExtOrder: '전자상거래가 외부 주문 번호를 사용합니다',
  edit: '편집',
  editItem: '{item} 편집',
  email: '이메일',
  emailFormat: '이메일 형식',
  emailFormatTemplate: '이메일 형식 템플릿 | 이메일 형식 템플릿',
  emailList: '이메일 목록',
  emailMessage: '이메일이 필요합니다',
  emailRecipient: '이메일 수신자 | 이메일 수신자',
  emailRecipientMessage: '주문 상태가 변경되면 이 이메일 주소로 메일이 발송됩니다',
  emptyMessage: {
    orderSuggestions: '이 부품에 대한 주문 제안이 아직 없습니다.'
  },
  emptyBomState: '페이지에 해당하는 재료표 항목이 정의되지 않았습니다. +{add}을(를) 클릭하여 시작하십시오',
  enable: '활성화',
  enable3d: '3D 활성화',
  enableAll: '모두 활성화',
  enableAPIKeyAdmin: 'API 키 관리자 활성화',
  enabled: '활성화됨',
  enableCommerce: '상거래 활성화',
  enableEcommerceOrderManagement: '주문 관리 활성화',
  enableExporter: '엑스포터 활성화',
  enableExportToLinkOne: 'LinkOne으로 내보내기 활성화',
  enableExportToSmartEquip: 'SmartEquip으로 내보내기 활성화',
  enablePDFDownloadWarning: '대용량 PDF 다운로드 활성화 경고',
  largePDFDownloadWarning: 'PDF 파일의 크기가 크기 때문에 애플리케이션 내에서 문서를 로드하고 표시하는 데 평소보다 시간이 더 걸릴 수 있습니다. 문서를 보기 위해 다운로드 중이니 기다려 주세요.',
  enableIframeSupport: 'iFrame 지원 활성화',
  enableLibraryBetaFeatures: '라이브러리 베타 기능 활성화',
  enablePerformanceLogging: '성능 로깅 활성화',
  enablePLZToDraft: 'PLZ 프로세스 초안 작성 활성화',
  enableQuickAddToCart: '장바구니에 빠른 추가 활성화',
  enableQuickAddToCartHelpContext: '\'활성화\'로 설정하면 이 콘텐츠에 대한 콘텐츠 뷰어 위에 있는 빠른 장바구니 추가 기능이 활성화됩니다.',
  enableRocketUi: 'Rocket 사용자 환경 활성화',
  enableSmartEquipApiIntegration: 'SmartEquip API 통합 활성화',
  enableWidgetLogo: 'Documoto 위젯 로고 활성화',
  enableWidgets: '위젯 활성화',
  encryptedKey: '암호화된 키',
  endDate: '종료일',
  endDateBad: '시작일은 종료일과 동일하거나 이전이어야 합니다.',
  enforceNumericSize: '숫자 크기 적용',
  englishDescription: '영어 설명',
  englishName: '영어 이름',
  enterprise: '엔터프라이즈',
  enterReportParameters: '보고서 매개변수 입력',
  enterUniqueNameForNewItem: '새 {item}의 고유한 이름을 입력하십시오',
  enterValidNumber: '올바른 숫자를 입력하세요',
  enterValidEmail: '올바른 이메일을 입력하세요',
  entityType: '엔티티 유형 | 엔티티 유형',
  erp: 'ERP',
  erpEndpointConfig: 'ERP 엔드포인트 구성',
  erpPartInfo: 'ERP 부품 정보 캐시 기간(분)',
  erpSystem: 'ERP 시스템',
  erpSystems: 'ERP 시스템',
  erpSystemSaved: 'ERP 시스템 저장됨',
  error: '오류',
  errorDeletePageMessage: '페이지를 삭제하는 중에 오류가 발생했습니다.',
  errorInputParsing: '문제 분석 입력 파일.',
  errorPageMessage: '오류가 발생했습니다.',
  errorPageTitle: '여기에 뭔가 잘못됐습니다...',
  errorType: {
  error: '오류',
  warning: '경고'
  },
  essential: '에센셜 | 에센셜',
  eta: 'ETA',
  etlJob: 'ETL',
  exact: '정확히',
  excelFile: 'Excel 파일',
  expandAll: '모두 확장',
  export: '내보내기',
  exportSearch: '검색 내보내기',
  exportAsCsv: 'CSV로 내보내기(가져오기에 필요한 형식)',
  exportAsCSVDropdown: 'CSV로 내보내기',
  exportAsHtml: 'HTML로 내보내기',
  exportAsPdf: 'PDF로 내보내기',
  exportAsPlz: 'PLZ로 내보내기',
  exportAsXlsx: 'XLSX로 내보내기',
  exportBasicPartInfo: '기본 부품 정보 내보내기',
  exportCartToXlsxDisabled: '장바구니 XLSX로 내보내기 비활성화',
  exportCartToXlsxEnabled: '장바구니 XLSX로 내보내기 활성화',
  exportDisplayedCartColumns: '표시된 장바구니 열 내보내기',
  exportJob: '내보내기 작업',
  exportLane: '레인 내보내기',
  exportMessage: '내보내기 작업을 성공적으로 제출했습니다.',
  exporter: '엑스포터',
  exporterMessage: '콘텐츠를 내보낼 수 있습니다',
  exportParts: '부품 수출',
  external: '외부 | 외부',
  externalUrl: '외부 URL',
  faceted: '면처리됨',
  facetedTag: '필터 값은 다면이어야 합니다',
  facetedTagTooMany: '태그가 1,000개의 고유 태그 값 제한을 초과합니다',
  failedAction: '{content}이(가) {action}하지 못했습니다.',
  failedProcessContentSql: '승인되지 않은 텍스트로 인해 콘텐츠 처리에 실패했습니다. 지원되는 콘텐츠를 재업로드해 주십시오.',
  failedToCopy: '{content} 복사에 실패했습니다.',
  failedToDelete: '{content}이(가) 삭제하지 못했습니다.',
  failedToLoad: '{content}을(를) 불러오지 못했습니다.',
  failedToSave: '{content}을(를) 저장하지 못했습니다.',
  failedToSubmitItem: '{item} 제출 실패',
  failedToUpdate: '{content} 업데이트에 실패했습니다.',
  false: '오류',
  faq: 'FAQ',
  favorites: '즐겨찾기',
  favoritesLists: '즐겨찾기 목록',
  february: '2월',
  feedbackForm: '피드백 제출',
  feedbackFormMessage: '보고 싶은 기능에 대한 정보를 입력하십시오.',
  fieldDefinition: '필드 정의',
  field: '필드 | 필드',
  fieldName: '필드 이름',
  file: '파일 | 파일',
  filename: '파일명',
  filesSubmitted: '처리를 위해 제출된 파일',
  fileUpload: '파일 업로드',
  fill: '채우기',
  filter: '필터 | 필터',
  filterOrgNameOnly: '필터: 조직 이름만',
  filterOrgParentNames: '필터: 조직 & 상위 이름',
  filterable: '여과성',
  filterableTag: '필터링 가능한 태그',
  filterBy: '필터링 기준',
  filterBoundsError: '필터에는 3개 이상의 문자를 포함되어야 합니다.',
  finnishDescription: '핀란드어 설명',
  finnishName: '핀란드어 이름',
  firstName: '이름',
  firstNameEmpty: '이름은 비워둘 수 없습니다',
  fixedHotpointSize: '고정 핫포인트 크기',
  font: '글자체 | 글자체',
  footer: '꼬리말',
  forgotPassword: '암호 분실',
  forgotPasswordMessage: '암호 재설정 안내 이메일이 제공된 사용자 이름으로 발송됩니다.',
  forgotPasswordSuccess: '제공된 사용자 이름이 유효하다면, 비밀번호 재설정 지침이 포함된 이메일이 등록된 주소로 전송됩니다.',
  form: '양식 | 양식',
  format: '형식',
  formattingOptions: '서식 옵션',
  formCount: '양식 개수',
  formName: '양식 이름',
  formType: '양식 유형',
  freezeImage: '정지 이미지',
  frenchDescription: '프랑스어 설명',
  frenchName: '프랑스어 이름',
  fri: '금요일',
  from: '보낸 사람',
  fromDate: '보낸 날짜',
  frontCover: '앞면 덮개',
  frontStitch: '프론트 스티치',
  fullScreen: '전체 스크린',
  gdprFaqs: 'GDPR FAQS',
  gdprMessage: '당사 웹 사이트의 이 섹션으로 로그인하면 필수 쿠키를 사용함을 알 수 있습니다.  이러한 쿠키는 귀하가 당사 서비스를 탐색하는 데 필수적입니다. 이러한 쿠키 없이는 귀하가 요청하는 서비스를 제공할 수 없습니다. 자세한 정보는 당사 {policy}를 참고해주십시오.',
  general: '일반',
  generalSearch: '일반 검색',
  generic: '일반',
  germanDescription: '독일어 설명',
  germanName: '독일어 이름',
  gotIt: '알겠습니다',
  goTo: '이동',
  goToHome: '홈으로 이동',
  goToJobManager: '작업 관리자로 이동',
  goToSearch: '검색으로 이동',
  hashKey: '해시 키',
  headerAmpFooter: '머리글 및 바닥글',
  header: '머리말',
  help: '도움말',
  helpCenter: '지원 센터',
  highlightDescription: '이 태그로 부품 목록의 부품 강조 표시',
  history: '내역',
  home: '홈',
  horizontal: '가로',
  host: '호스트',
  hotpoint: '핫포인트 | 핫포인트',
  hotpointCalloutBehavior: '핫포인트 콜아웃 동작',
  hotpointLinksWillBeCreated: '핫포인트 링크가 {count}{content}에 생성됩니다.',
  hotpointLink: '핫포인트 링크 | 핫포인트 링크들',
  hotpointsGenerated: '{count}개의 온점이 이미지에서 발견됩니다.',
  hotpointShift: '핫포인트(Shift H)',
  hourlyDWSRequestLimit: '시간별 DWS 요청 제한',
  hourlyRESTRequestLimit: '시간별 REST 요청 제한',
  htmlUiUserSwitchEnabled: 'HTML UI 사용자 스위치 활성화됨',
  hungarianDescription: '헝가리어 설명',
  hungarianName: '헝가리어 이름',
  icon: '아이콘',
  id: 'ID',
  identifier: '식별자',
  ignore: '무시',
  ignoreAccessControls: '액세스 제어 무시',
  ignoreMatchingTranslations: '가져오기에서 로케일이 일치하는 번역 무시',
  illustration: '삽화 | 삽화',
  image: '이미지 | 이미지',
  imageDeleteMessage: '페이지 이미지를 삭제하시겠습니까?',
  imageUploadFailure: '이미지 업로드 실패',
  imageSaveFail: '이미지 저장 실패',
  imageOptimizer: '이미지 최적화 도구',
  imageSizeExceededTitle: '최대 이미지 용량 초과',
  imageSizeExceededMessage: 'Documoto에서 허용되는 최대 이미지 파일 크기는 {size}메가바이트입니다. 업로드하려는 이미지가 최대값을 초과하여 로드할 수 없습니다. 이 페이지에 추가하려면 이미지 크기를 조정하십시오.',
  imageDimensionsExceededMessage: 'Documoto 최대 이미지 용량은 65.5메가픽셀(8,191 X 8,191픽셀)입니다.  업로드하려는 이미지가 최대 용량을 초과하여 업로드할 수 없습니다. "크기 조절하기(Resize)"를 클릭하여 Documoto가 이미지 크기를 조절하고 삽입하도록 하거나 "취소하기(Cancel)"를 클릭하여 이미지를 편집하고 수동으로 삽입하십시오',
  import: '가져오기',
  importBOM: 'BOM 가져오기',
  importBOMError: '샘플 데이터를 처리하지 못했습니다. 입력 소스를 다시 확인하십시오.',
  importParts: '부품 가져오기',
  inCart: '장바구니 내',
  inCartDescription: '장바구니에 태그 표시',
  includePagePartDetails: '페이지 파트 레벨 세부 정보 포함',
  includePartDetails: '파트 레벨 세부 정보 포함',
  indexQueueJob: '인덱스 큐',
  inPartsList: '은(는) 부품 목록',
  inPartsListDescription: '부품 목록에 태그 표시',
  inputSource: '입력 소스',
  inch: '인치 | 인치',
  includeComments: '댓글 포함',
  includeCommentsAuthorAndDate: '댓글 작성자 및 날짜 포함',
  includeHeaderRow: '머리글 행 포함',
  includeImages: '이미지 포함',
  includePagesIndex: '페이지 목록 포함',
  includePartsIndex: '부품 색인 포함',
  includePartsList: '부품 목록 포함',
  includeSubChapters: '하위 챕터 포함',
  indexLane: '인덱스 레인',
  useSequentialPageNumbering: '순차 페이지 번호 매기기 사용',
  hideBookInSearchAndRecent: '검색 결과 및 최근 추가된 항목에서 책 숨기기',
  hideDuplicatePages: '중복된 페이지 숨기기',
  hidePageInSearchAndRecent: '검색 결과 및 최근 추가된 항목에서 페이지 숨기기',
  hidePartInSearchAndRecent: '검색 결과 및 최근 추가된 항목에서 부품 숨기기',
  homePageSettings: '홈 페이지 설정',
  hotpointZoomBehavior: '핫포인트 줌 동작',
  includeNewParts: '"새" 파트만 포함(Documoto에서는 제외)',
  includeWatermark: '워터마크를 포함합니까?',
  increaseHotpoint: '핫포인트 증가',
  indexed: '색인됨',
  indexingConfiguration: '인덱싱 구성',
  indexIncludeTimestamp: '인덱스는 타임스탬프를 포함합니다',
  info: '정보',
  information: '정보',
  inherit: '상속',
  inheritedParent: '상위로부터 상속됨.',
  inputSpecification: '입력 사양',
  inputType: '입력 유형',
  inputTypeListboxWarning: '저장 시 정렬 구성 표시',
  integration: '통합 | 통합',
  integrationsAddPartToErpCart: '{action} ERP 장바구니에 부품 추가',
  integrationsAddToShoppingCartPricingFromErp: '{action} ERP에서 장바구니에 가격 책정에 추가',
  integrationsDisableErp: 'ERP 비활성화',
  integrationsDoErpForQuotes: '견적을 위한 ERP',
  integrationsEnableErp: 'ERP 활성화',
  integrationsEnableFetchPartInfoFromErp: 'ERP에서 부품 정보 가져오기 사용',
  integrationsEnableScopeErp: '{scope} ERP 활성화',
  integrationsEnableSendShoppingCartToErp: '장바구니를 ERP로 전송 활성화',
  integrationsEnableSyncPoAddressWithErp: 'ERP와 PO 주소 동기화 활성화',
  integrationsErpAvailabilityMappedFrom: 'ERP 가용성 매핑:',
  integrationsErpCurrencyCodeMappedFrom: 'ERP 통화 코드 매핑:',
  integrationsErpDiscountedPriceMappedFrom: 'ERP 할인 가격 매핑:',
  integrationsErpEtaMappedFrom: 'ERP ETA 매핑:',
  integrationsErpMappings: 'ERP 매핑',
  integrationsErpRetailPriceMappedFrom: 'ERP 소매 가격 매핑:',
  integrationsErpWholesalePriceMappedFrom: 'ERP 도매 가격 매핑:',
  integrationsInvokeGetPartInfoFromClient: '클라이언트에서 GetPartInfo 호출',
  integrationsParseAddressForType: '유형에 대한 주소 구문 분석',
  integrationsShoppingCartAndPartsTitle: '장바구니 및 부품',
  integrationsShoppingCartAvailability: '{action} 장바구니 가용성',
  integrationsShoppingCartEtaFromErp: '{action} ERP 장바구니 ETA',
  internalApiKey: '내부 API 키',
  internalDescription: '내부 설명',
  internalName: '내부 이름',
  internalNameOptional: '태그 식별자',
  internalNameRequired: '이름이 필요합니다',
  invalidDecimals: '소수점 이하 두 자리만 허용됩니다',
  invalidField: '{field}이(가) 잘못되었습니다',
  invalidFileType: '지원되지 않는 파일 유형입니다. Documoto 지원 센터에서 지원되는 파일 확장자의 전체 목록을 확인하십시오.',
  isSearchable: '은(는) 검색 가능',
  insertDataField: '데이터 필드 삽입',
  isSystem: '은(는) 시스템',
  italianDescription: '이탈리아어 설명',
  italianName: '이탈리아어 이름',
  item: '#',
  itemAlt: '항목',
  itemCopied: '{item} 복사됨',
  itemDeleted: '{item} 삭제됨',
  itemInProgress: '{item} 진행 중',
  itemInformation: '{item} 정보',
  itemMustBeLteItem: '{itemA}은(는) {itemB}와(과) 같거나 그 이전이어야 합니다.',
  itemMustBeUnique: '{item}은(는) 고유해야 합니다',
  itemMustBeUniqueAndNotEmpty: '{item}은(는) 고유해야 하며 필수입니다',
  itemName: '{item} 이름',
  itemNotShown: '',
  itemSaved: '{item} 저장됨',
  itemSpecification: '항목 사양',
  showChapterTOCForHiddenPages: '숨겨진 페이지의 TOC 챕터 열기',
  january: '1월',
  japaneseDescription: '일본어 설명',
  japaneseName: '일본어 이름',
  job: '작업 | 작업',
  jobManager: '작업 관리자',
  jobManagerMessage: '작업 내보내기, 게시, 및 인쇄/다운로드',
  july: '7월',
  june: '6월',
  koreanDescription: '한국어 설명',
  koreanName: '한국 이름',
  label: '라벨 | 라벨',
  labelKey: '라벨 키',
  landscape: '가로',
  laneConfiguration: '레인 구성',
  language: '언어',
  lastModifiedDate: '마지막 수정 날짜',
  lastGenerated: '마지막 생성됨',
  lastName: '성',
  lastNameEmpty: '성은 비워둘 수 없습니다',
  lastUpdatedOn: '마지막 업데이트 날짜',
  layout: '레이아웃',
  layoutOptions: '레이아웃 옵션',
  ledger: '원장',
  left: '왼쪽',
  legal: '법률',
  letter: '문자',
  library: '라이브러리',
  libraryAdmin: '라이브러리 관리자',
  libraryBannerLogo: '라이브러리 배너 로고',
  librarySettings: '라이브러리 설정',
  librarySettingsDescription: '이 설정은 다양한 라이브러리 요소와 옵션을 제어합니다. 변경할 때는 주의해야 합니다. 자세한 내용은 Documoto 서비스 팀에 문의하십시오.',
  licenseConfiguration: '라이선스 구성',
  line: '라인',
  lineTool: '선 도구',
  link: '링크 | 링크',
  listBox: '목록 상자',
  listBoxValue: '목록 상자 값 | 목록 상자 값',
  listValue: '목록 값 | 목록 값',
  load: '로드',
  loadMore: '더 많이 불러오기',
  loading: '불러오는 중',
  locale: '로캘',
  login: '로그인',
  loginBackground: '로그인 배경',
  loginGDPRMessageBeginning: '이 웹 사이트는 쿠키를 사용합니다. 이 웹 사이트를 계속해서 사용함에 따라 귀하는 쿠키 사용에 동의하게 됩니다. 자세한 정보는 다음을 참고해주십시오 ',
  loginGDPRMessageEnd: '및 당사의',
  loginSSO: '로그인 SSO',
  logiReportName: 'Logi 보고서 이름',
  logiReportToggle: 'Logi 보고서?',
  logo: '로고 | 로고',
  logout: '로그아웃',
  lookupLoadParts: '가져올 때 Documoto에서 부품 조회/로드',
  lowerBound: '하한치',
  makePagesDivisibleBy: '페이지 분할 기준:  ',
  mainInfo: '기본 정보',
  mainLogo: '메인 로고',
  manageItem: '{item} 관리',
  management: '관리',
  march: '3월',
  margin: '마진 | 마진',
  markdown: '가격 인하',
  may: '5월',
  media: '미디어 | 미디어',
  mediaCategory: '미디어 카테고리 | 미디어 카테고리',
  mediaCategoryEditor: '미디어 카테고리 편집기',
  mediaCategoryName: '미디어 카테고리 이름',
  mediaCategoryNameMissing: '모든 미디어 카테고리에 이름이 필요합니다',
  mediaBookDeleteMessage: '이 미디어를 삭제하면 라이브러리 및 콘텐츠 관리자 모두에서 이 콘텐츠에 대한 액세스가 제거됩니다 그리고 사용자 또는 콘텐츠 관리자가 더 이상 사용할 수 없게 됩니다. 이 책에서 찾을 수 있는 부분이나 페이지 중 다른 책에서 찾을 수 없는 경우 더이상 도서관에서 볼 수 없게 됩니다.',
  mediaDeleteMessage: '이 템플릿을 삭제하면 콘텐츠 관리자에서 제거되며 더 이상 새로운 미디어에 복사할 수 없습니다.',
  mediaDeleteSuccessText: '인덱싱이 완료되면 목록에서 제거됩니다.',
  mediaDescription: '미디어 설명',
  mediaIdentifier: '미디어 식별자',
  mediaName: '미디어 이름',
  mediaPrintSettingsSaveSuccess: '미디어 인쇄 설정 저장됨',
  mediaWhereUsed: '사용된 미디어',
  menu: '메뉴',
  merge: '병합',
  mergeOrOverrideMessage: '이 항목을 덮어쓰거나 병합하시겠습니까?',
  mergeWarning: '데이터를 가져오면 기존 부품이 업데이트되고 새 항목이 추가됩니다.',
  message: '메시지',
  messageLevel: '메시지 수준',
  microsite: '마이크로사이트 | 마이크로사이트',
  micrositeAdmin: {
    cardSubtitle: '압축된 콘텐츠 파일 및 HTML 항목 정보',
    cardTitle: '사이트 콘텐츠',
    errorRequiredFile: '사이트 콘텐츠 ZIP 파일이 필요합니다',
    fieldFile: '사이트 콘텐츠 ZIP 파일',
    fieldPoint: 'HTML 진입점',
  },
  minArea: '최소 면적',
  minAreaMessage: '최소값은 필수 항목입니다. 값은 1~1500 범위를 초과할 수 없습니다.  정수 값이어야 합니다.',
  moreInfo: '더 많은 정보',
  multipleAlternatingPages: '여러 교체 페이지',
  multipleAlternatingPagesMessage: '1, 3페이지의 다이어그램, 2, 4페이지의 부품 목록.',
  mToM: 'M2M',
  multiplePages: '여러 페이지',
  multiplePagesMessage: '자체 페이지의 다이어그램 뒤에 부품 목록 페이지',
  requiredField: '필수 필드',
  messageMessage: '메시지는 필수입니다',
  metadata: '메타데이터',
  mine: '내 장바구니',
  miscellaneous: '기타',
  mon: '월요일',
  myCart: '내 장바구니 | 내 장바구니',
  navigation: '내비게이션',
  nItemsCreated: '{n}{items} 생성됨',
  nItemsDeleted: '{n}{items} 삭제됨',
  nOfMItems: '{n}/{m}{items}',
  name: '이름',
  nameDescriptionHelpText: '이름 및 설명(선택 사항)',
  new: '새',
  newAPIKey: '새 API 키',
  newERPSystem: '새 ERP 시스템',
  newItem: '새 {item}',
  newKeyGenerated: '나중의 사용을 위한 새로운 통합 키 생성, 복사 및 보관',
  newMediaCategory: '새 미디어 카테고리',
  newPassword: '새 암호',
  newsItem: '뉴스 항목 | 뉴스 항목',
  newTenant: '신규 테넌트',
  newWidgetKey: '새 위젯 키',
  newWidgetKeyGenerated: '새로운 위젯 키 생성, 향후 사용을 위해 복사 및 유지',
  noTenantSuppliers: '테넌트에게 지정된 공급업체가 없습니다.',
  noAPIKeys: '정의된 API 키가 없습니다.',
  node: '노드',
  noData: '데이터 없음',
  noFilesSelected: '선택한 파일 없음',
  noIllustrationFound: '일러스트레이션을 찾을 수 없음',
  noImageFound: '이미지를 찾을 수 없음',
  noItemAvailable: '사용할 수 있는 {item} 없음',
  noItemFound: '{item}을(를) 찾을 수 없습니다.',
  noItemSelected: '선택된 {item} 없음',
  noItemAddedToTarget: '{target}에 추가된 {item}(이)가 없습니다. ',
  noItemsFound: '항목을 찾을 수 없음',
  noMoreResults: '더 이상 결과 없음',
  noFormsMessage: '정의된 양식이 없습니다.',
  noMediaDefined: '정의된 미디어 없음',
  nonSearchableFieldsSuccessfullyUpdated: '검색할 수 없는 필드가 성공적으로 업데이트되었습니다.',
  noPartsSelected: '선택한 부품이 없음.',
  noPrice: '가격문의',
  noResults: '결과 없음',
  norwegianDescription: '노르웨이어 설명',
  norwegianName: '노르웨이어 이름',
  noSearchableTags: '검색 가능한 태그 없음',
  noSuggestions: '검색어에 대한 제안이 없습니다',
  notOrderable: '주문 불가',
  notSelected: '선택되지 않음',
  noTagsFound: '태그를 찾을 수 없음',
  noUnsavedChanges: '저장되지 않은 변경 사항 없음',
  none: '없음',
  notImplemented: '구현되지 않음',
  notification: '알림 | 알림',
  notificationsMessage: '중요한 공지 사항 및 뉴스',
  notMapped: '매핑되지 않음',
  november: '11월',
  noWidgetKeys: '정의된 위젯 키가 없음.',
  numeric: '숫자',
  numberOf: '#/{item}',
  october: '10월',
  of: '/',
  off: '꺼짐',
  offlineContent: '오프라인 콘텐츠',
  ok: '확인',
  oldPassword: '예전 비밀번호',
  on: '켜짐',
  opacity: '불투명도',
  open: '열기',
  openItem: '{item} 열기',
  optimizeIndexJob: '인덱스 최적화',
  options: '옵션',
  openTab: '새 탭에서 링크 열기',
  order: '주문 | 주문',
  orderable: '주문 가능',
  orderableDescription: '부품을 장바구니에 추가하고 구매할 수 있습니다',
  orderableLocal: '이 페이지에서 주문 가능',
  orderableLocalBlocked: '전 세계적으로 주문할 수 없는 부품',
  orderDisable: '주문 내역 비활성화',
  orderEmailRecipient: '주문 이메일 수신자 | 주문 이메일 수신자',
  orderEntity: '주문 엔티티 | 주문 엔티티',
  orderFieldUneditableRequired: '주문/견적 필드를 필수로 설정하고 사용자가 편집할 수 없도록 설정하면 주문/견적 양식을 완료할 수 없습니다. 주문/견적 양식을 작성할 수 있는지 확인하십시오.',
  orderFieldsEmptyMessage: '주문/견적 필드가 정의되지 않았습니다.',
  orderFieldsEmptyPrompt: '주문/견적 필드 추가',
  orderField: {
    nameMessage: '주문/견적 필드 이름을 변경할 경우 이 필드를 사용하는 주문/견적 양식 또는 이메일 형식을 업데이트해야 합니다.',
  },
  orderFieldCopyMessage: '새 주문/견적 필드에 고유한 이름을 입력하십시오.',
  orderForm: {
  uniqueNameInputHint: '새 양식의 고유한 이름을 입력하십시오.'
  },
  orderFormField: '주문/견적 필드 | 주문/견적 필드',
  orderFormLayout: '주문/견적 양식 레이아웃 | 주문/견적 양식 레이아웃',
  orderFormSetDefault: '기본 주문/견적 양식 세트',
  orderFormType: '주문 양식',
  orderLayout: '주문 레이아웃 | 주문 레이아웃',
  orderManagementApprover: '주문 관리 승인자',
  orderManagementDisable: '주문 관리 관리자 비활성화',
  orderManagementViewer: '주문 관리 뷰어',
  orderNumber: '주문 번호',
  orderQuoteEmail: '주문/견적 이메일 | 주문/견적 이메일',
  orderQuoteEmailFormat: '주문/견적 이메일 형식 | 주문/견적 이메일 형식',
  orderQuoteEmailFormatCopyMessage: '새로운 주문/견적 이메일 형식의 언어를 선택합니다.',
  orderQuoteFetchError: '주문/견적 양식 데이터를 가져오는 중 오류 발생',
  orderQuoteForm: '주문/견적서 | 주문/견적서',
  orderQuoteSaveConfirmation: '변경 사항을 조직 주문/견적 양식에 저장하시겠습니까?',
  orderQuoteSaveError: '주문/견적 양식 데이터 저장 중 오류 발생',
  orderQuoteFormSaved: '주문/견적 양식 저장됨',
  orderStatus: '주문/견적 상태 | 주문/견적 상태',
  orderStatusDeleteMessage: '이 상태는 주문에 사용되어 삭제할 수 없습니다',
  orderSubmittedMessage: '성공적으로 제출되었습니다',
  orderSuggestion: '주문 제안 | 주문 제안',
  orderSuggestionEnable: '주문 제안 활성화',
  orderSuggestionRemovalWarning: '부품이 더 이상 주문 제안으로 표시되지 않습니다. | 부품이 더 이상 주문 제안으로 표시되지 않습니다.',
  orderSuggestionThreshold: '주문 제안 임계값',
  ordersLogo: '주문 로고',
  ordersMessage: '주문 제출 내역',
  orientation: '오리엔테이션',
  org: '조직',
  orgEmailRecipients: '조직 이메일 수신자',
  organization: '조직 | 조직',
  organizationAdmin: '조직 관리자',
  organizationAdminBrowseFlow: '조직은 기본 플로우 찾아보기를 이용할 수 있으며, 혹은 무시하고 다른 플로우 찾아보기를 이용할 수 있습니다.',
  organizationAdminMessage: '사용자 관리 및 조직 설정 제어',
  organizationEmpty: '조직을 선택해야 합니다',
  organizationEmailRecipients: '조직 이메일 수신자',
  organizationEnforceAccessControlsForQuickAdd: '빠른 추가하기에 대한 엑세스 제어 실시',
  organizationKey: '조직 키',
  organizationLibrarySettingsDescription: '이러한 설정은 다양한 조직 수준 라이브러리 요소 및 옵션을 제어합니다. 변경할 때는 주의해야 합니다. 자세한 내용은 Documoto 서비스 팀에 문의하십시오.',
  organizationMoveToRoot: '루트로 이동하려면 여기에 드롭하십시오.',
  organizationName: '조직 이름',
  organizationRearrange: '조직 재정렬',
  organizationRearrangeDescription: '상위-하위 조직 구조는 인쇄 설정, 스타일 등 조직의 다양한 상속 가능한 속성을 정의합니다. 원하는 계층 구조를 반영하도록 조직을 끌어다 놓으십시오.',
  organizationReportSettingsSuccessMessage: '보고서 설정 저장됨',
  organizationSelect: '조직 선택',
  organizationTags: '조직 태그',
  organizationsEmpty: '아직 조직을 만들지 않은 것 같습니다.',
  orgNameOnly: '조직 이름만',
  other: '기타',
  otherSettings: '기타 설정',
  outboundCustomerIdParameter: '아웃바운드 고객 ID 매개 변수 이름',
  outboundSessionIdParameter: '아웃바운드 세션 ID 매개 변수 이름',
  outlineMode: '개요 모드(Shift O)',
  output: '출력',
  outputCatalog: '카탈로그 출력하기',
  outputDefinition: '출력 정의 | 출력 정의들',
  outputDefinitionDeleted: '출력 정의가 삭제되었습니다',
  outputDefinitionSaved: '출력 정의가 저장되었습니다',
  outputFileName: '출력 파일 이름',
  outputName: '출력 이름',
  outputDestination: '출력 대상',
  outputSpecification: '출력 사양',
  outputSubType: '출력 부분형',
  outputType: '출력 유형',
  overridden: '재정의됨',
  overriddenByOrg: '조직에 의해 재정의됨',
  override: '재정의',
  overrideBrowseFlow: '기본 플로우 무시하기',
  overrideFacetLimitValue: '패싯 제한값 재정의',
  overrideHotpointScale: '핫포인트 스케일 무시',
  overridePdfNamingStandard: 'PDF 파일명 표준 철회',
  overridePrintSettings: '인쇄 설정 무시',
  overrideFontSettings: '글자체 설정 무시',
  overrideOrderForm: '주문 양식 재정의',
  overrideQuoteForm: '견적 양식 재정의',
  overrideSvgOpacity: 'SVG 불투명도 무시',
  overrideWarning: '데이터를 가져오면 현재 BOM 목록이 바뀝니다.',
  owner: '소유자 | 소유자',
  page: '페이지 | 페이지',
  pageBulkInsertConfirmTitle: '대량 삽입 작업을 완료하시겠습니까?',
  pageBulkInsertConfirmInsertAfter: '이는 {replacement}이후에 {original}및 {whereUsedBookCount}{bookUnits}{whereUsedTemplateCount}{templateUnits}에 삽입합니다.',
  pageBulkInsertConfirmInsertBefore: '이는 {replacement}이전에 {original}및 {whereUsedBookCount}{bookUnits}{whereUsedTemplateCount}{templateUnits}에 삽입합니다.',
  pageBulkInsertConfirmInsertReplace: '이는 {replacement}에 삽입하고 {original}및 {whereUsedBookCount}{bookUnits}{whereUsedTemplateCount}{templateUnits}에서 제거합니다.',
  pageBulkInsertBooksConfirmInsertAfter: '이는 {replacement}이후에 {original}에{whereUsedBookCount} {bookUnits}삽입합니다.',
  pageBulkInsertBooksConfirmInsertBefore: '이는 {replacement} 이전에{original}에 {whereUsedBookCount} {bookUnits}삽입합니다.',
  pageBulkInsertBooksConfirmInsertReplace: '이는 {replacement}에 삽입하고 {original}에서 {whereUsedBookCount} {bookUnits}제거합니다.',
  pageBulkInsertTemplatesConfirmInsertAfter: '이는 {replacement}이후에 {original}에{whereUsedTemplateCount} {templateUnits}삽입합니다.',
  pageBulkInsertTemplatesConfirmInsertBefore: '이는 {replacement} 이전에{original}에 {whereUsedTemplateCount} {templateUnits}삽입합니다.',
  pageBulkInsertTemplatesConfirmInsertReplace: '이는 {replacement}에 삽입하고 {original}에서 {whereUsedTemplateCount} {templateUnits}제거합니다.',
  pageBulkInsertDiscard: '대량 삽입물이 처리되지 않습니다.',
  pageBulkInsertHelpText: '이 기능을 사용하면 다른 페이지 {originalDisplayName}이(가) 이미 있는 선택한 부품 책에 이 소스 페이지 {thisDisplayName}을(를) 삽입할 수 있습니다. 필요한 경우 해당 책에서 {originalDisplayName}(을)를 제거할 수도 있습니다.',
  pageBulkInsertInsertAfter: '{original} 뒤에 {replacement} 삽입',
  pageBulkInsertInsertBefore: '{original} 앞에 {replacement} 삽입',
  pageBulkInsertInsertReplace: '{replacement}를 삽입하고 {original}을(를) 제거합니다.',
  pageBulkInsertScopeAll: ' {type} 대상 페이지가 있는 전체 {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: '대상 페이지 {type}없음{originalDisplayName}',
  pageBulkInsertScopeSome: '대상 페이지 중 {type}일부{originalDisplayName}',
  pageBulkInsertStep1: '1단계: 원하는 대상 페이지 찾기',
  pageBulkInsertStep2: '2단계: 페이지 삽입 또는 교체 방법 결정',
  pageBulkInsertStep3: '3단계: 대량 삽입물을 처리할 책을 선택하십시오.',
  pageBulkInsertStep4: '4단계: 대량 삽입을 처리할 템플릿을 선택하십시오.',
  pageBulkInsertSuccessTitle: '대량 삽입 작업 제출됨',
  pageBulkInsertSuccessBody: '책 목차 변경 사항이 즉시 적용됩니다. 인덱싱이 완료되면 검색 결과가 반영됩니다.',
  pageBulkErrorTooManyBooks: '선택한 책 수를 1000권 이하로 제한',
  pageBuilder: '페이지 작성기',
  pageComposer: 'Page Composer',
  pageCreated: '페이지 생성됨',
  pageDeleteMessage: '페이지 삭제는 라이브러리 및 콘텐츠 관리에 즉시 적용됩니다. 삭제된 페이지는 몇 분 내에 색인이 다시 생성될 때까지 검색 가능한 상태로 유지됩니다.',
  pageDeleteMessage2: 'Documoto 지원의 도움 없이는 이 작업을 취소할 수 없습니다. 90일 이후에 삭제된 기록과 모든 연결은 영구적으로 삭제됩니다.',
  pageDescription: '페이지 설명',
  pageFile: '페이지 파일',
  pageFilename: '페이지 파일명',
  pageFilenameDeleted: '{filename}이(가) 삭제됩니다.',
  pageFileRequired: '페이지 파일이 필요합니다.',
  pageBomPartsDeleted: '{count} BOM 페이지 파트는 더 이상 다음을 통해 액세스할 수 없습니다{filename}.',
  pageDraftsDeleted: '이 페이지와 연결된 초안 페이지가 영구적으로 삭제됩니다.',
  pageFit: '페이지 맞춤',
  pageHeader: '페이지 머리글',
  pageLabel: '페이지 | 페이지',
  pageLayout: '페이지 레이아웃',
  pageName: '페이지 이름',
  pagePart: '페이지 부품 | 페이지 부품',
  pagePartTag: '페이지 부분 태그 | 페이지 부분 태그',
  pagePartData: '페이지 파트 데이터',
  pageUsageDataCouldNotBeLoaded: '페이지 사용량 데이터를 로드할 수 없습니다',
  pagePartLevelDetails: '페이지 파트 주문 가능/표시 가능, 페이지 파트 태그',
  pagePartNumberVisible: '페이지 부품 번호 표시',
  pagePartOrderable: '페이지 부분 주문 가능',
  pagePartOrderableDescription: '이 페이지에서 부품 주문 가능',
  pagePartVisibleDescription: '이 페이지에서 볼 수 있는 부품 번호',
  pageUpdated: '페이지 업데이트됨',
  pageViewer: '페이지 뷰어',
  pageWidth: '페이지 너비',
  pagesWhereUsed: ' 페이지 사용 위치',
  parentName: '상위 이름',
  part: '부품 | 부품',
  partCode: '부품 코드 | 부품 코드',
  partCodeAddError: '부품 코드를 추가할 수 없음',
  partCodeErrorEmpty: '저장할 수 없음: 부품 코드는 비워둘 수 없습니다.',
  partCodeErrorInUse: '이 부품 코드는 Documoto의 부품에 의해 사용되고 있으며 삭제할 수 없습니다. 도움이 필요하시면 Documoto 고객 지원 팀(support{\'@\'}documoto.com)에 문의하십시오.',
  partCodeErrorNotFound: '이 부품 코드를 찾을 수 없어 삭제할 수 없습니다. 모든 변경 사항이 저장되었는지 확인하십시오.',
  partCodesManager: '부품 코드 관리자',
  partCodesMessage: '부품 번호 대신 부품 코드를 사용할 수 있으며 BOM 항목이 더 이상 사용되지 않거나 사용할 수 없거나 참조 전용 항목인 경우 (예 : \'PNNA\', \'-\', \'REF\')를 나타내는 데 종종 사용됩니다.',
  partCreated: '부품 생성됨',
  partData: '파트 데이터',
  partUpdated: '파트 업데이트됨',
  partDebouncerEmailQueueJob: '이메일 대기열',
  partDescription: '파트 설명',
  partLabel: '부품 | 부품',
  partLevelDetails: '파트 가격 책정, 주문 가능/표시 가능, 추가 번역, 파트 태그',
  partName: '부품 이름',
  partnerApiKey: '파트너 API 키',
  partnerRESTRequestLimit: '파트너 REST 요청 제한',
  partNumber: '부품 번호',
  partNumberDescription: '최종 사용자가 검색할 내부 부품 번호',
  partNumberVisible: '부품 번호 표시',
  partNumberVisibleDescription: '최종 사용자에게 부품 번호가 표시됩니다',
  partNumberVisibleLocal: '이 페이지에 표시',
  partNumberVisibleLocalBlocked: '부품 번호가 전역적으로 표시되지 않음',
  partOrderable: '부품 주문 가능',
  partRequired: '적어도 한 개의 부품이 필요합니다',
  partTagProperty: '부품 태그 속성 | 부품 태그 속성',
  partTag: '부품 태그 | 부품 태그',
  partThumbnailDescription: '',
  partialSupport: '부분 지원',
  partsListColumn: '부품 목록 열 | 부품 목록 열',
  partsBook: '부품 책 | 부품 책',
  partsColumns: '부품 열',
  partsNotSelected: '부품 썸네일에 사용되는 기본 이미지. 사용자 지정 썸네일이 적용되지 않으면 Documoto는 표준 기본 썸네일을 표시합니다.',
  partsPage: '파트 페이지 | 파트 페이지들',
  partsPages: '부품 페이지',
  partsPageGroup: {
    excludePartCodesFromPartsLists: '부품 리스트에서 부품 코드 제외',
    highlightAlternateRows: '대체 항 강조 표시',
    rotateImagesAutomatically: '자동 이미지 회전',
    printBorderAroundImages: '이미지 주변 테두리 인쇄',
    keepImagesOnLeft: '이미지 왼쪽에 두기',
    keepBOMOnRight: 'BOM 오른쪽에 두기',
    columnsUpdateErrorMsg: '부품 열 업데이트 시도할 때오류 발생',
    columnsUpdateSuccessMsg: '부품 열 업데이트됨',
    columnsLoadErrorMsg: '부품 열 불러오기 실패',
  printHotpointsOnImages: '이미지에 핫포인트 인쇄',
  },
  partsList: '파트 목록',
  partsListData: '부품 리스트 데이터',
  partsListGridHeader: '부품 리스트 그리드 머리말',
  password: '암호',
  passwordExpiredHeader: '비밀번호 만료됨',
  passwordExpiredInfo: '귀하의 비밀번호가 만료되었습니다. 새로운 비밀번호는 다음 기준을 충족해야 합니다.',
  passwordCriteriaInfo: '새로운 비밀번호는 다음 기준을 충족해야 합니다.',
  passwordBlankMessage: '암호는 비워둘 수 없습니다',
  passwordCannotBeSame: '현재 비밀번호와 동일해서는 안 됩니다',
  passwordCasingMassage: '최소한 소문자 1개, 대문자 1개를 포함해야 합니다',
  passwordLowerCaseMessage: '암호에 하나 이상의 소문자가 포함되어야 합니다.',
  passwordMinLengthMessage: '비밀번호 길이는 최소 8자 이상이어야 합니다',
  passwordNotMatchingMessage: '암호가 같지 않습니다',
  passwordSpecialCharactersMessage: '암호에 하나 이상의 특수 문자가 포함되어야 합니다.',
  passwordUpperCaseMessage: '암호에 하나 이상의 대문자가 포함되어야 합니다',
  passwordWillExpire1Day: '비밀번호 로그인은 1일 이내에 만료됩니다',
  passwordWillExpire5Days: '비밀번호 로그인은 5일 이내에 만료됩니다',
  passwordsMatch: '비밀번호 일치',
  permission: '권한 | 권한',
  phoneNumber: '전화번호',
  phoneNumberExt: '내선번호',
  phonePrimary: '기본 전화번호',
  phoneSecondary: '보조 전화번호',
  pinFilter: '검색 결과 페이지에 필터 고정',
  pinned: '고정됨',
  plusMoreOrgs: '+ {totalOrgCount} 추가 조직',
  polishDescription: '폴란드어 설명',
  polishName: '폴란드어 이름',
  populatePdfNameWith: 'PDF 파일 이름을 다음으로 채웁니다',
  populatePdfNameSelection: 'PDF 파일 이름 선택 채우기',
  port: '포트',
  portrait: '세로',
  portugueseDescription: '포르투갈어 설명',
  portugueseName: '포르투갈어 이름',
  postComment: '댓글 달기',
  postalCode: '우편 번호',
  poweredBy: '다음에 의해 구동',
  preferences: '기본 설정',
  preferencesDisable: '사용자 기본 설정 비활성화',
  prefix: '접두사 | 접두사',
  preview: '미리보기',
  previewOfName: '{name} 미리 보기',
  pricing: '가격 책정',
  pricingDiscountEnabled: '할인 가격 표시',
  pricingDisplayInCart: '장바구니에 가격 표시',
  pricingDisplayInPartsList: '부품 목록에 가격 표시',
  pricingGlobal: '글로벌 가격 책정',
  pricingGlobalDescription: '조직 재정의가 없을 때의 정상 가격',
  pricingHidePricingButton: '가격 책정 숨기기 버튼 활성화',
  pricingHidePricingButtonDisable: '가격 책정 숨기기 버튼 비활성화',
  pricingOrganization: '조직 가격 책정',
  pricingOrganizationDeleteConfirm: '이 조직 가격 책정을 삭제하시겠습니까?',
  pricingOrganizationDescription: '조직 가격 책정은 조직 내 모든 사용자와 그 하위 조직의 글로벌 가격 책정보다 우선합니다.',
  pricingOrganizationDuplicate: '이 조직은 이미 재정의되고 있습니다',
  pricingOrganizationEmptyState: '정의된 조직 재정의가 없습니다. {add}을(를) 클릭해 시작하기.',
  pricingRetailEnabled: '소매 가격 표시',
  pricingWholesaleEnabled: '도매 가격 표시',
  primary: '기본',
  print: '인쇄하기',
  printerLane: '프린터 레인',
  printJob: '인쇄 작업',
  printLogo: '인쇄하기 로고',
  printSetting: '인쇄 설정',
  printSettingDeleteFail: '인쇄 설정 삭제 실패',
  printSettingSaveFail: '인쇄 설정 저장 실패',
  printSettingEmptyMessage: '인쇄 설정이 정의되지 않았습니다. 시작하려면 인쇄 설정을 추가하십시오.',
  printSettingSetDefaultSuccess: '기본 인쇄 설정 설정하기',
  printSettingUpdateFail: '인쇄 설정 업데이트 실패',
  printSettingCopyMessage: '새 인쇄 설정의 고유한 이름을 입력하십시오.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: '인쇄 설정 생성',
  printSettings: '인쇄 설정',
  printSettingsLogo: '인쇄 설정 로고',
  printing: '인쇄하기',
  printingDisabled: '모든 인쇄 비활성화',
  privacyPolicy: '개인정보 보호정책',
  proceed: '계속 진행',
  process: '처리',
  processContent: '콘텐츠 처리',
  processing: '처리 중',
  processToDraft: '초안 작성 프로세스',
  processedPlzToDraft: 'PLZ를 초안으로 처리했습니다',
  production: '프로덕션',
  professional: '프로페셔널',
  profile: '프로필',
  prohibitExportDownloadOfContent: '콘텐츠 내보내기/다운로드 금지',
  prohibitExportDownloadOfContentHelpText: '\'활성화\'로 설정하면 모든 사용자(권한 있음)가 이 콘텐츠를 내보내거나 다운로드할 수 없습니다.',
  property: '속성 | 속성',
  province: '지역',
  public: '공개',
  publish: '게시',
  publisherLane: '퍼블리셔 레인',
  publisherManualLane: '퍼블리셔 매뉴얼 레인',
  publishThumbnailJob: '썸네일 게시',
  publishDocuments: '문서 게시',
  publishDraft: '초안 게시',
  publisher: '게시자 | 게시자',
  publishingErrors: '발행 오류',
  publishingErrorsWithName: '게시 오류 {name}',
  publishingInProgress: '발행 처리 중이라 제출할 수 없습니다',
  publisherJob: '게시자 작업',
  publisherMessage: '콘텐츠 작성, 관리 및 인쇄 설정',
  purchaseOrder: '구매 주문',
  purchaseOrderTemplateEnabled: '주문 템플릿 활성화',
  purchaseOrderTemplateDisabled: '주문 템플릿 비활성화',
  qty: '수량',
  quantity: '수량',
  query: '질의',
  quickAdd: '빠른 추가',
  quickAddHelpText: '쇼핑 카트에 추가할 부품을 찾으려면 입력을 시작하세요.',
  quickAddInCartEnabled: '장바구니에 빠른 추가 활성화',
  quickLinks: '빠른 링크',
  quote: '견적 | 인용 부호',
  quoteFormType: '견적 양식',
  quoteTemplateDisabled: '견적 템플릿 비활성화',
  quoteTemplateEnabled: '견적 템플릿 활성화',
  range: '범위',
  rearrange: '다시 정렬',
  rebuildIndexJob: '인덱스 재생성',
  recentSearches: '최근 검색 항목',
  recentlyAdded: '최근 추가된 항목',
  recentlyEdited: '최근에 편집됨',
  recentlyViewed: '최근 본 항목',
  rectangle: '직사각형',
  redirect: '리디렉션',
  refresh: '새로 고침',
  related: '관련',
  relatedDescription: '라이브러리에 있는 미디어를 이 {type}에 연결합니다. 참고: 관련 미디어는 사용자가 \'관련\' 탭에서 볼 수 있도록 액세스 제어를 적용해야 합니다.',
  relatedInfo: '관련 정보',
  relatedView: '관련 미디어 보기',
  relationship: '관계 | 관계',
  releaseNotes: '릴리스 노트',
  rememberMe: '기억하기',
  remainingWidth: '잔여 너비',
  remove: '제거',
  removeAll: '모두 제거',
  removeItemConfirmation: '{item}을(를) 제거하시겠습니까?',
  removeItemCount: '정말 삭제하시겠습니까{count}{type}?',
  replace: '바꾸기',
  replaceExisting: '기존 대체',
  report: '보고서 | 보고서',
  reportAnIssue: '이슈 보고',
  reportAnIssueMessage: '이메일을 성공적으로 보냈습니다.',
  reportCatalogName: '보고서 카탈로그 이름',
  reportCatalogNotSet: '보고서 카탈로그가 보고서에 대해 정의되지 않았습니다.',
  reportDefinition: '보고서 정의 | 보고서 정의들',
  reportDefinitionDeleteError: '보고서 정의가 삭제되지 않았습니다',
  reportsEmptyState: '보고서가 없습니다',
  reportExportTimeWarning: '보고서 수와 선택된 형식에 따라 내보내기를 완료하는 데 몇 분이 소요될 수 있습니다.',
  reporting: '보고',
  reportingMessage: '다양한 보고서 및 측정 항목에 대한 접근',
  reportSettings: '보고서 설정',
  reportsLibrary: '보고서 라이브러리',
  requestForQuote: '견적 요청',
  requestFormType: '견적 양식',
  required: '필수',
  requiredItem: '{item}은(는) 필수입니다',
  requiredMessage: '{item}은(는) 필수입니다',
  requiredMessageAndUnique: '{item}은(는) 필수 항목이며 고유해야 합니다.',
  requireAuthentication: '인증 필요',
  requiredReading: '필수 읽기',
  requiredReadingMessage: '다음을 검토하십시오:',
  requiredUrlMessage: '올바른 형식의 URL이 필요합니다.',
  reset: '재설정',
  resetPassword: '암호 재설정',
  resetPasswordError: '암호를 재설정하는 동안 문제가 발생했습니다. 다시 시도하십시오.',
  resetPasswordTokenError: '암호 재설정 토큰이 잘못되었습니다.',
  resetZoom: '줌 재설정',
  resource: '리소스 | 리소스',
  resize: '크기 조절하기',
  resizeThumbnails: '썸네일 자동 크기 조정',
  result: '결과 | 결과',
  resultFor: '{item}에 대한 결과 | {item}에 대한 결과',
  retail: '소매',
  retailPrice: '소매 가격',
  retailTotal: '소매 총계',
  return: '돌아가기',
  returnToItem: '{item}(으)로 돌아가기',
  returnToPartList: '부품 목록으로 돌아가기',
  returnToMedia: '미디어로 돌아가기',
  returnToTemplate: '템플릿으로 돌아가기',
  returnToViewer: '뷰어로 돌아가기',
  revertChanges: '변경 내용 되돌리기',
  right: '오른쪽',
  romanianDescription: '루마니아어 설명',
  romanianName: '루마니아어 이름',
  rootLevelBrowseFlow: '루트 단계',
  row: '행',
  russianDescription: '러시아어 설명',
  russianName: '러시아어 이름',
  sampleData: '샘플 데이터',
  samplePreview: '샘플 미리보기',
  sandbox: '샌드박스',
  sat: '토요일',
  save: '저장',
  saveChanges: '변경 사항을 {item}에 저장하시겠습니까? {name}',
  saveChangesToItem: '변경 사항을 이 {item}에 저장하시겠습니까?',
  saved: '저장됨',
  saveNewAPIKey: '새로운 통합 키가 저장되지 않았습니다, 돌아가서 설정을 완료하십시오',
  saveNewWidgetKey: '새 위젯 키가 저장되지 않음. 설정 완료로 돌아가기.',
  search: '검색',
  searchableFieldsSuccessfullyUpdated: '검색 가능한 필드가 성공적으로 업데이트됨.',
  searchAllContent: '모든 콘텐츠 검색',
  searchConfiguration: '검색 구성',
  searchCriteria: '검색 조건',
  searchDocumoto: 'Documoto 검색',
  searchFeatures: '검색 기능',
  searchFieldConfigDescription: '다음 시스템 필드 검색 설정은 라이브러리 일반 검색, 검색 결과 페이지 및 카트 빠른 추가에만 적용됩니다. 기타 검색 기능(예: 관리 센터 검색 및 사용된 라이브러리)은 영향을 받지 않습니다.',
  searchInContent: ' {contentName}...에서 검색.',
  searchingInLanguage: '{lang}에서만 검색 중',
  searchingInTwoLanguages: '{lang1} 및 {lang2}에서만 검색 중',
  searchPlaceholder: '모든 콘텐츠 검색...',
  searchPrompt: '모든 콘텐츠 검색...',
  searchProperty: '속성 검색 | 속성 검색',
  searchResultsConfiguration: '검색 결과 구성',
  searchSettings: '검색 설정',
  searchSettingsDescription: '이러한 설정은 다양한 라이브러리 검색 행동, 기능 및 결과를 통제합니다.',
  searchTerm: '검색어',
  searchTips: '검색 팁:',
  searchable: '검색 가능',
  searchableTag: '검색 가능한 태그',
  section: '섹션',
  select: '선택',
  selectAField: '{field} 선택',
  selectAll: '모두 선택',
  selectAndClose: '선택 후 닫기',
  selected: '선택됨',
  selectedTargetPage: '선택한 대상 페이지',
  selectChapters: '챕터 선택',
  selectChildren: '하위 선택',
  selectFields: '{fields} 선택',
  selectItem: '선택하기 {item}',
  selectMedia: '미디어 선택',
  selectPage: '페이지 선택',
  selectPages: '페이지 선택',
  selectTargetPage: '대상 페이지 선택',
  send: '보내기',
  september: '9월',
  serbianDescription: '세르비아어 설명',
  serbianName: '세르비아어 이름',
  serviceUri: '서비스 URI',
  sessionLog: '세션 로그',
  set: '설정',
  setAsDefault: '기본값으로 설정',
  settings: '설정',
  settingsMessage: '사용자 환경 설정 및 암호 업데이트',
  sequence: '순서',
  shape: '모양',
  shapeTool: '모양 도구',
  shared: '공유됨',
  sharedCart: '공유 장바구니 | 공유 장바구니',
  sharedCartDeleteContent: '이렇게 하면 이 공유한 모든 조직의 공유 장바구니가 제거됩니다',
  sharedCartOrganizationContent: '이 공유 장바구니를 이용할 수 있는 조직을 선택하십시오.',
  sharedShoppingCartsEnabled: '장바구니 공유 활성화',
  shoppingCartExportJob: '장바구니 내보내기',
  shoppingCartLoadingLongTime: '쇼핑 카트가 로드 중입니다. 프로세스가 완료되면 카트 아이콘을 사용할 수 있게 됩니다. 문제가 지속되면 Documoto 관리자에게 지원을 요청하세요.',
  showExportFromSearch: '검색 결과에서 내보내기 허용',
  showExportFromSearchHelp: '검색에서 책 결과 목록 내보내기 허용',
  showHelp: '도움말 보기',
  showIdentifierInSearch: '검색에 미디어 식별자 표시',
  showItem: '{item} 표시',
  showLess: '간단히 표시',
  showMediaDescriptionFilter: '미디어 설명 필터 표시',
  showMediaIdentifierFilter: '미디어 식별자 필터 표시',
  showMediaNameFilter: '미디어 이름 필터 표시',
  showMore: '자세히 표시',
  showOrganizationFilter: '조직 필터 표시',
  showPartNameFilter: '부품 이름 필터 표시',
  showPartNumberFilter: '부품 번호 필터 표시',
  showRecentlyAdded: '최근 추가된 항목 표시',
  showRecentlyViewed: '최근 본 항목 표시',
  showSearchButton: '홈 페이지 검색 버튼 표시',
  showSearchButtonHelp: '라이브러리 헤더에서 \'검색으로 이동\' 버튼 표시',
  signature: '서명',
  size: '크기',
  snapshot: '스냅샷 | 스냅샷',
  solrSlaveSvrOverride: 'SOLR 슬레이브 서버 기본 URL 재정의',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: '가장 관련 있는 항목',
  sortType: '정렬 유형',
  spanishDescription: '스페인어 설명',
  spanishName: '스페인어 이름',
  square: '정사각형',
  ssoConfiguration: 'SSO 구성',
  ssoIdpMetadataUrl: 'SSO IDP 메타데이터 URL',
  ssoLoginMaxAuthenticationAgeSeconds: 'SSO 로그인 최대 인증 기간(초)',
  ssoSamlDnAttributeName: 'SSO SAML DN 속성 이름',
  ssoSamlEmailAttributeName: 'SSO SAML 이메일 속성 이름',
  ssoSamlMemberofAttributeName: 'SSO SAML 멤버 속성 이름',
  ssoSniForSsoMetadataRetrieval: 'SSO 메타데이터 검색에 SNI 사용',
  standard: '표준',
  standardUserGroup: '표준 사용자 그룹',
  startDate: '시작일',
  startDateRequired: '시작일 요청됨.',
  state: '주',
  static: '고정',
  status: '상태',
  statusCode: '상태 코드 | 상태 코드',
  stitchPages: '페이지 제목',
  storeFrontAdmin: '스토어프론트 관리자',
  storefront: '스토어프론트',
  storefrontIntegration: '스토어프론트 통합 | 스토어프론트 통합',
  storefrontIntegrationSubtitle: '이러한 설정은 다양한 스토어프론트 통합 요소 및 옵션을 제어합니다. 변경할 때는 주의해야 합니다. 자세한 내용은 Documoto 지원 팀에 문의하십시오.',
  storefrontSettings: '스토어프론트 설정',
  storefrontSettingsDescription: '이러한 설정은 다양한 스토어프론트 요소 및 옵션을 제어합니다. 변경할 때는 주의해야 합니다. 자세한 내용은 Documoto 서비스 팀에 문의하십시오.',
  storefrontSettingsOrgDescription: '이러한 설정은 다양한 조직 수준의 스토어프론트 요소 및 옵션을 제어합니다. 변경할 때는 주의해야 합니다. 자세한 내용은 Documoto 서비스 팀에 문의하십시오.',
  storeFilterValues: '필터값 저장',
  storeFilterValuesDescription: '이 설정을 활성화하면 사용자가 이 태그에 대한 검색 페이지 필터에서 설정한 값이 저장됩니다.',
  stroke: '스트로크',
  style: '스타일 | 스타일',
  styles: {
    colorHotpoint: '핫포인트 기본값',
    invalidHexcode: '16진수 코드 오류',
    inheritedFromOrg: '조직에서 상속됨 {org}',
    inheritedFromSystem: '시스템 기본값에서 상속됨',
    inheritedFromTenant: 'Tenant에서 상속됨',
    organization: '조직 스타일 | 여러 조직 스타일',
    organizationSaved: '조직 스타일 저장됨',
    sampleNavigation: '내비게이션 탭',
    samplePrimary: '내비게이션/버튼',
    sampleSelectedHotpoint: '선택된 핫포인트',
    tenant: 'Tenant 스타일 | 여러 Tenant 스타일',
    tenantSaved: 'Tenant 스타일 저장됨'
  },
  subChapter: '하위 챕터 | 하위 챕터',
  subject: '제목',
  subjectMessage: '제목은 필수입니다.',
  submit: '전송',
  submitPurchaseOrder: '구매 주문',
  submitRequestForQuote: '견적 요청',
  submitted: '제출됨',
  submitter: '제출자',
  submittedBy: '제출자',
  submitterEmail: '제출자 이메일',
  submitterFirstName: '제출자 이름',
  submittedFilesMessage: '{fileCount}개의 파일을 제출했습니다.',
  submitterLastName: '제출자 성',
  submitterOrgName: '제출자 조직 이름',
  submitterUserName: '제출자 사용자 이름',
  success: '성공',
  imageSaveSuccess: '이미지를 업데이트했습니다',
  successNotificationDuration: '카트에 추가하기 – 토스트 성공 알림 기간',
  suffix: '접미사 | 접미사',
  suggestedKeywords: '제안된 키워드',
  suggestedResults: '제안된 결과',
  suggestion: '제안 | 제안',
  sun: '일요일',
  superadmin: '슈퍼관리자',
  superAdminSettings: "슈퍼 관리자 설정",
  supersede: '대체',
  supersedeComplete: '대체 작업을 완료하시겠습니까?',
  supersedeDiscardChanges: '대체를 취소하시겠습니까?',
  supersedeMainText: '대체는 이전 부품이 있는 모든 부품 페이지에서 기존 부품을 하나 이상의 다른 부품으로 대체합니다. 이전 부품 번호가 삭제되었지만 태그를 통해 검색 가능한 상태로 유지되어 이전 번호 또는 새 번호를 사용하여 콘텐츠를 찾을 수 있습니다.',
  supersedeMessage: '부품 업데이트가 즉시 적용됩니다. 몇 분 내에 업데이트를 검색할 수 있습니다.',
  supersedeNotProcessed: '대체가 처리되지 않습니다.',
  supersedeReplacePart: '부품을 대치할 하나 이상의 부품 찾기',
  supersedeSuccessful: '대체 성공',
  supersedeWith: '다음으로 부품 대체...',
  supplier: '공급자 | 공급자',
  supplierKey: '공급자 키',
  supplierDescription: '이 부품을 공급하는 회사',
  supportedImageFormats: '지원되는 이미지 형식: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: '지원되지 않는 파일, 초안 파일 지원됨: MDZ, PLZ',
  surroundWithQuotes: '정확한 일치 항목을 찾으려면 여러 검색어를 큰따옴표로 묶으세요.',
  svgFinder: 'SVG 파인더',
  swedishDescription: '스웨덴어 설명',
  swedishName: '스웨덴어 이름',
  system: '시스템',
  systemAdmin: '시스템 관리자',
  systemFields: '시스템 필드',
  systemFieldSearchAndFiltering: '시스템 필드 검색 및 필터링',
  systemStatus: '시스템 상태',
  systemName: '시스템 이름',
  systemVersion: '시스템 버전',
  tabDelimited: '탭으로 구분',
  tableOfContents: '목차',
  tabloid: '타블로이드',
  tag: '태그 | 태그',
  tagName: '태그 이름',
  tagEditor: {
    rangeBoundLengthInvalid: '바운드는 {limit}자리를 초과할 수 없습니다.',
    tagEditorModal: '태그 편집기 모달',
    filterPrompt: '처음 100개의 값을 표시합니다. 더 보려면 필터를 사용하십시오.'
  },
  tagSearchAndFiltering: '태그 검색 및 필터링',
  tagSearchAndFilteringHelpText: '힌트: 일부 검색 및 필터 설정은 여기에서 찾을 수 있는 태그 관리자 태그 편집기에서도 구성할 수 있습니다',
  tagSubmitAffected: '모든 {type} 콘텐츠는 변경 사항과 함께 태그{tag}를 자동으로 삭제합니다.',
  tagDeleteAffectedCount: '태그를 삭제하고 {count}개 항목에서 태그 정보를 제거합니다.',
  tagDeleteAffectedNone: '현재 이 태그를 사용하는 항목이 없습니다.',
  tagManagerDeleteWarning: '{deleteCategoryName}이(가) 있는 기존 미디어는 삭제 후 이 카테고리를 잃게 됩니다.',
  tagsDisplayMore: '처음 100개의 값을 표시합니다. 더 보려면 필터를 사용하십시오.',
  tagsManager: '태그 관리자',
  tagValue: '태그 값 | 태그 값',
  tagValueMissing: '태그 값을 입력해야 합니다',
  tagValueMultiple: '세미콜론으로 여러 항목 구분됨',
  tagValueTooLong: '태그 값이 25자 제한을 초과함 | 태그 값이 25자 제한을 초과함',
  taxon: '분류군',
  taxonomy: '분류 체계',
  template: '템플릿 | 템플릿',
  templateEmailRecipients: '템플릿 이메일 수신자',
  templateLayout: {
    addRow: '행 추가',
    addItem: '항목 추가',
    deleteRow: '행 삭제',
    deleteColumn: '열 삭제',
    fullWidth: '전체 넓이',
    oneColumn: '한 개의 열',
    threeColumns: '세 개의 열',
    twoColumns: '두 개의 열'
  },
  tenant: '테넌트 | 테넌트',
  tenantAdmin: '테넌트 관리자',
  tenantAdminMessage: '테넌트의 넓은 설정을 제어합니다',
  tenantCleanerJob: '세입자 유지 관리',
  tenantConfiguration: '테넌트 구성',
  tenantEmailRecipients: '테넌트 이메일 수신자',
  tenantEmailRecipientsOverrideMessage: '조직 이메일 수진자가 지정된 경우 재정의됩니다.',
  tenantIndexQueueJob: '테넌트 인덱스 대기열',
  tenantKey: '테넌트 키',
  tenantKeyError: '테넌트 키가 고유하지 않음',
  tenantKeyMessage: '테넌트 키는 고유해야 합니다',
  tenantLabelDeleteMessage: '이 라벨을 삭제할 경우 기본 Documoto 이름으로 돌아갑니다.',
  tenantReindexModulus: '테넌트 재색인 계수',
  tenantSettings: {
    collapseTOCLabel: '일치하는 페이지에서 목차 축소',
    defaultLocaleLabel: '기본 로케일',
    defaultLocaleMessage: '테넌트 기본 로케일',
    deleteMissingTranslationsOnPublishLabel: '게시 시 누락된 번역을 삭제하시겠습니까?',
    deleteMissingTranslationsOnPublishMessage: '게시 시 부품 번역 삭제 활성화',
    defaultSupplierLabel: '기본 공급자',
    defaultSupplierMessage: '테넌트 기본 공급자',
    enableChapterIndexingMessage: '인덱스 챕터',
    enableChapterIndexingLabel: '미디어 챕터에 대해 인덱싱 설정',
    enableIndexingLabel: '발행 색인',
    enableIndexingMessage: '발행 색인 켜기.',
    helpUrlLabel: '기본 도움말 URL',
    helpUrlMessage: '대체 도움말 시스템의 URL.',
    hotPointLinkPageInBookOnlyLabel: '도서 전용 페이지 핫포인트 링크',
    hotPointLinkPageInBookOnlyMessage: '도서 전용 페이지의 핫포인트 링크',
    mediaTimestampLabel: '액세스 제어 변경 시 미디어 타임스탬프 업데이트',
    mediaTimestampMessage: '액세스 제어를 변경하면 미디어의 타임스탬프를 업데이트해야 하는 경우 활성화.',
    publisherDefaultUom: '게시자 기본 UOM',
    publishingContactLabel: '연락처 이메일 게시',
    publishingContactMessage: '콘텐츠 문제가 보고될 때 이메일을 받을 기본 이메일 주소입니다.',
    replaceHotpointLabel: '게시할 때 핫포인트 링크 항상 바꾸기',
    replaceHotpointMessage: 'Docustudio 게시에만 해당.',
    ssoLoginSystemLabel: 'SSO 로그인 시스템',
    ssoLoginSystemMessage: 'SSO를 위한 제공자 시스템 식별',
    userPasswordExpirationLabel: '사용자 비밀번호 만료일',
    userPasswordExpirationMessage: '이후 비밀번호를 재설정해야 하는 일수입니다. 만료되지 않으려면 비워 두십시오.',
    lockPartTrOnPublishLabel: '게시 시 부품 번역 잠금',
    lockPartTrOnPublishMessage: '게시 중에 부품 번역이 수정되는 것을 방지합니다',
    exportPgAsHashLabel: '페이지 파일 이름을 해시 키로 내보내기',
    exportPgAsHashMessage: '내보낸 콘텐츠에서 페이지 해시 키를 페이지 파일 이름으로 사용'
  },
  tenantSettingsTitle: '테넌트 설정',
  tenantSettingsSaved: '테넌트 설정 저장됨',
  tenantSettingsSubtitle: '이러한 설정은 테넌트 전체 속성 및 동작을 제어합니다. 변경할 때는 주의해야 합니다. 자세한 내용은 Documoto 서비스 팀에 문의하십시오.',
  tenantSuppliers: '테넌트 공급업체',
  tenantLabelsPageText: '라벨은 Documoto`의 라이브러리 및 관리 화면 전체에 있는 여러 개체의 이름을 사용자 지정하는 데 사용됩니다.',
  tenantPrintSettingsDescription: '테넌트 전체 기본 인쇄 설정을 선택합니다. 조직 또는 미디어에서 재정의되지 않는 한 모든 책 인쇄에 사용됩니다.',
  tenantType: '테넌트 유형',
  tenantWebserviceUser: '테넌트 웹서비스 사용자',
  text: '텍스트',
  textArea: '텍스트 영역',
  textField: '텍스트 필드',
  textFile: '텍스트 파일(CSV, TXT)',
  textTool: '텍스트 도구',
  threeD: {
    addFile: '3D 부품 파일 추가',
    beforeSaveModal: {
      title: 'Documoto에 이 파일 이름의 CAD 파일이 이미 존재합니다.',
      message: '존재하는 모든 위치에서 이 파일을 바꾸시겠습니까, 아니면 이 부품에만 첨부하시겠습니까? 파일을 바꾸면 다음 작업이 수행됩니다:',
      messageBulletOne: '{count}개의 부품이 이 새 CAD 파일로 업데이트됩니다.',
      messageBulletTwo: '이전 버전의 파일이 Documoto 파일 시스템에서 제거됩니다.',
      tip: '팁: 첨부 과정 중 파일 덮어쓰기를 방지하려면 첨부하려는 파일의 이름을 가져오기 전에 변경하면 됩니다.'
    },
    deleteTitle: '이 3D 보기를 삭제하시겠습니까?',
    drawMode: {
      label: '그리기 모드',
      hiddenLines: '숨겨진 선',
      shaded: '음영 처리',
      wireframe: '와이어프레임',
      wireframeShaded: '와이어프레임 음영 처리',
    },
    explode: 'Explode',
    fileSizeModal: {
      message: 'Documoto에서 허용되는 최대 파일 크기는 25메가바이트입니다. 업로드하려는 파일은 최대 용량을 초과하여 업로드할 수 없습니다. 계속하려면 ‘확인’을 클릭하세요.',
      title: '최대 파일 크기 초과',
    },
    orientationAndProjection: {
      back: '뒤쪽',
      bottom: '아래쪽',
      front: '앞쪽',
      isometric: '등각 투영',
      left: '왼쪽',
      orthographic: '정사영',
      projection: '투영',
      perspective: '투시도',
      right: '오른쪽',
      top: '위쪽'
    },
    resetCamera: '카메라 재설정',
    slice: {
      label: '슬라이스',
      planeX: '절단면 X',
      planeY: '절단면 Y',
      planeZ: '절단면 Z',
      reset: '절단면 재설정',
      section: '절단면 섹션 전환',
      visibility: '절단면 가시성 전환',
    },
    supportedFilesMessage: 'Documoto는 다양한 CAD 파일 형식을 지원합니다. CAD 파일 형식이 지원되는지 확실하지 않으면 지원 센터를 통해 인하세요.',
    toolsLabel: '3D 도구',
    topAssemblyFilename: '상단 어셈블리 파일 이름',
    topAssemblyFilenameRequired: '상단 어셈블리 파일 이름은 필수 항목입니다',
    viewerTitle: '3D 뷰어',
  },
  thu: '목요일',
  thumbnail: '썸네일',
  thumbnailDeleted: '썸네일이 성공적으로 삭제됨',
  thumbnailDeletedError: '썸네일 삭제 실패',
  thumbnailUpdated: '썸네일이 성공적으로 업데이트됨',
  thumbnailUpdatedError: '썸네일 업데이트 실패',
  thumbnailReapplyWarning: '시스템은 미디어의 내용에 따라 썸네일을 다시 적용할 수 있습니다. 몇 분 정도 걸릴 수 있습니다.',
  thumbnailResizeTooltip: '선택한 경우 썸네일 크기를 500x500픽셀 이하로 조정합니다',
  title: '표제',
  to: '까지',
  toastNotifications: '토스트 알림',
  toDate: '현재까지',
  toc: '목차',
  tocClipBoardSuccessTitle: '클립보드로 복사됨',
  tocClipBoardWarningText: '경고: 저장되지 않은 변경 사항은 클립보드에 복사할 수 없습니다.',
  tocClipBoardWarningTitle: '클립보드로 복사됨',
  tocDisplayingFirstValues: '처음 100개의 값을 표시합니다. 더 보려면 필터를 사용하십시오.',
  tocDuplicateWarningText: '중복 페이지가 챕터에서 감지되었습니다. 중복이 제거되었습니다.',
  tocEmptyMessage: '아직 목차를 만들지 않은 것 같습니다.',
  toGetStarted: '시작하려면',
  tocMissingChapterName: '챕터 이름이 없습니다',
  tocModalTableCaption: '태그 편집기 모달',
  tocNothingSelected: '선택된 항목이 없습니다',
  tocOpenEditor: '편집기 열기',
  tocSaveRequired: '챕터 세부 정보를 편집하기 전에 저장해야 함',
  tocState: '목차의 초기 상태',
  tocThumbnailDeleteFailTitle: '썸네일 삭제 실패',
  tocThumbnailDeleteSuccessTitle: '썸네일 삭제 성공',
  tocThumbnailUploadFailTitle: '썸네일 업로드 실패',
  tocThumbnailUploadSuccessTitle: '썸네일 업로드 성공',
  tocTranslationMessage: '이 챕터의 이름 및 설명(선택 사항)',
  tocUntitledPage: '제목 없는 페이지',
  tooManySelections: '목록이 {limit} 선택 제한을 초과합니다',
  togglePage: '페이지 전환',
  top: '상단',
  total: '총계',
  totalItems: '총 아이템',
  translation: '번역 | 번역',
  translationHelpTextItem: '이 {item}의 이름 및 설명(선택 사항)',
  true: '참',
  tryAgain: '다시 시도하십시오.',
  tue: '화요일',
  turkishDescription: '터키어 설명',
  turkishName: '터키어 이름',
  twoSided: '양면',
  type: '유형 | 유형',
  typeAtLeastTwoChars: '일치 항목의 목록을 보려면 최소 2자를 입력하세요.',
  typeToConfirm: '확인할 {value}을(를) 입력하십시오',
  typography: '조판',
  ukraineDescription: '우크라이나어 설명',
  ukraineName: '우크라이나어 이름',
  unableToRetrieveResults: '쿼리/검색에 대한 결과를 검색할 수 없습니다.',
  unauthorized: '권한이 없음',
  unknownError: '알 수 없는 오류, 도움이 필요하면 Documoto 지원 팀(support{\'@\'}documoto.com)에 문의하십시오.',
  unknownPart: '알 수 없는 부품',
  unknownType: '알 수 없는 유형',
  unsavedAPIKeyWarning: '돌아가지 않으면 귀하의 API 키가 사라집니다',
  unsavedChanges: '변경 사항을 저장하지 않았습니다. 계속?',
  unsavedChangesWarning: '저장하지 않은 변경 사항은 취소됩니다.',
  unsavedWidgetKeyWarning: '반환하지 않으면 위젯 키가 손실됩니다.',
  unsupportedBrowserMessage: '우리는 최신 기술로 이 애플리케이션을 만들었습니다. 따라서 애플리케이션을 더 빠르고 쉽게 사용할 수 있습니다. 유감스럽게도 귀하의 브라우저는 해당 기술을 지원하지 않습니다.',
  uom: 'UOM',
  update: '업데이트',
  updateFailed: '업데이트하지 못했음',
  updateIndex: '색인 업데이트',
  updatePassword: '암호 업데이트',
  updatePasswordMessage: '암호를 성공적으로 업데이트했습니다',
  updatePasswordPending: '암호 변경 보류 중 저장',
  updated: '업데이트됨',
  upgradeBrowser: '브라우저를 업그레이드하십시오.',
  upload: '업로드',
  uploadComplete: '업로드 완료',
  uploadInProgress: '업로드 진행 중',
  uponSavingDisabledTenant: '이 설정을 활성화하여 이 페이지를 저장하면 다음이 발생합니다:',
  uponSavingThisLicense: '이 설정을 사용하여 이 페이지를 저장하면 다음을 수행해야 합니다:',
  upperBound: '상한',
  untitledOrg: '제목 없는 조직',
  usCurrency: 'USD',
  useDefaultCartColumns: '기본 장바구니 열 사용',
  useDefaultPartsListColumns: '기본 부품 목록 열 사용',
  user: '사용자',
  userAddress: '사용자 주소 | 사용자 주소',
  userCount: '사용자 수',
  userEditable: '사용자 편집가능',
  userExpirationDate: '사용자 만료일',
  userExpirationDateInvalid: '사용자 만료일을 입력해야 합니다',
  userGroup: '사용자 그룹 | 사용자 그룹',
  userGroupEmpty: '사용자 그룹이 빈 상태일 수 없습니다',
  userGroupType: '사용자 그룹 유형',
  userGroupUniqueMessage: '이 이름은 이 테넌트의 다른 모든 사용자 그룹 이름과 다르게 고유해야 합니다.',
  userGroupUsers: '사용자 그룹 사용자',
  username: '사용자명',
  usernameEmpty: '사용자명은 공백일 수 없습니다',
  users: '사용자',
  usersWillReceiveAnUnauthorizedError: '사용자가 테넌트에 로그인하려고 할 때 ‘권한 없음’ 오류를 받게 됩니다.',
  useSniForSsoMetadataRetrieval: 'SSO 메타데이터 검색에 SNI 사용',
  value: '값',
  validate: '인증하기',
  validateTenantDirectories: {
    buttonLabel: '콘텐츠 디렉토리 검증',
    error: '디렉토리 유효성을 검사하지 못했습니다',
    success: '검증된 테넌트 디렉토리'
  },
  variantSKU: '여러 SKU',
  validationMessage: '확인 메시지 | 검증 메시지',
  valueGteError: '값은 {max}보다 작거나 이와 같아야 합니다',
  valueLteError: '값은 {min}보다 크거나 이와 같아야 합니다',
  vendorName: '벤더 이름',
  verifyPassword: '암호 확인',
  vertical: '수직',
  version: '버전',
  video: '동영상 | 동영상',
  view: '보기',
  viewAll: '모두 보기',
  viewAllPagesAndBooks: '보기: 모든 페이지 및 책',
  viewAllResults: '모든 결과 보기',
  viewBooks: '보기: 책',
  viewMore: '더 보기',
  viewPages: '보기: 페이지',
  viewModeList: '목록 보기',
  viewModeTable: '표 보기',
  visibility: '표시',
  visibilityDescription: '이 {entity}을(를) 볼 수 있는 사람',
  visible: '표시',
  waitForUpload: '업로드가 완료될 때까지 기다리십시오.',
  watermark: '워터마크',
  watermarkFont: '워터마크 폰트',
  watermarkOpacityPercentage: '워터마크 불투명도 %',
  watermarkOrientation: '워터마크 방향',
  watermarkSaved: '워터마크 저장됨',
  watermarkSaveFailed: '워터마크 저장 실패함.',
  watermarkSelectionRequired: '사용자 이름 또는 텍스트 선택 필요함.',
  watermarkText: '워터마크 텍스트',
  wed: '수요일',
  whereUsed: '사용 장소',
  whoCanViewComment: '누가 이 댓글을 볼 수 있나요?',
  wholesale: '도매',
  wholesalePrice: '도매 가격',
  wholesaleTotal: '도매 총계',
  widget: '위젯',
  widgetAuthHeader: '위젯 인증값',
  widgetKey: '위젯 키 | 위젯 키',
  widgetKeyAdmin: '위젯 키 관리',
  widgetKeyCopied: '위젯 인증값이 클립보드에 복사되었습니다',
  widgetKeyDeleted: '위젯 키 삭제됨',
  widgetKeyDeletedError: '위젯 키 삭제 오류',
  widgetKeyDeleteMsg: '위젯 키를 삭제하면 사용할 수 없게 됩니다',
  widgetKeyManager: '위젯 키 관리자',
  widgetKeyNameEmpty: '이름은 고유해야 하며 필수입니다. 이 값은 모든 위젯 활동에 대한 사용자 이름으로 보고 및 로깅 목적으로도 사용됩니다',
  widgetKeyRegenError: '위젯 키를 재생성하는 중에 오류 발생',
  widgetKeyRegenMsg: '이 위젯 키를 다시 생성하면 기존 통합이 비활성화될 수 있습니다',
  widgetKeyRegenTitle: '이 위젯 키를 다시 생성하시겠습니까?',
  widgetKeySaved: '위젯 키 저장됨',
  widgetPermissions: '위젯 사용자는 읽기 전용이며, 기능 액세스는 위젯 수준 구성으로 관리됩니다. 표준 사용자 그룹 권한은 위젯 사용자에게 적용되지 않습니다. 미디어 카테고리 접근 권한이 있습니다. 계속해서 미디어 카테고리 액세스 제어를 할당하십시오.',
  widgetRefreshErrorMsg: "요청한 콘텐츠를 보려면 페이지를 새로 고침하십시오.",
  widgetUserGroup: '위젯 사용자 그룹',
  widgetUserGroupDeleteMsg: '사용자가 있는 위젯 그룹을 삭제할 수 없습니다',
  willBeAffected: '이(가) 영향을 받게 됩니다.',
  willBeDeleted: '{content}이(가) 삭제됩니다.',
  willBeInsertedIntoItem: '{name}이(가) {item}에 삽입됨',
  willBeUpdated: '이(가) 업데이트될 예정입니다.',
  zipCode: '우편번호',
  zoomHotpointToCanvas: '핫포인트를 캔버스 크기로 확대',
  zoomIn: '확대',
  zoomOut: '축소'
}
/* eslint-enable max-len */
