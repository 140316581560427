<template>
  <div class="field has-addons stepper-button-wrap">
    <p class="control">
      <stepper-btn :disabled="disablePrevious"
                   icon="fa-arrow-left"
                   @click="emit('goToPrevious')"/>
    </p>
    <p class="control">
      <stepper-btn :disabled="disableNext"
                   icon="fa-arrow-right"
                   @click="emit('goToNext')"/>
    </p>
  </div>
</template>

<script setup>
import StepperBtn from './StepperBtn'

defineProps({
  disablePrevious: Boolean,
  disableNext: Boolean
})

const emit = defineEmits(['goToPrevious', 'goToNext'])
</script>
<style scoped>
.stepper-button-wrap {
  min-width: 6em;
}
</style>
