<template>
  <section :style="[isWidget && 'display:flex;']">
    <virtual-list v-if="isWidget"
                  :data-sources="results"
                  data-key="entityId"
                  :data-component="$options.SearchResultLayout"
                  :extra-props="{ isWidget }"
                  :style="scroll"
                  item-class="search-result-wrapper"
                  @tobottom="getMoreSearchResults">
      <template #footer>
        <widget-loading-icon  v-show="isGettingMore"
                              class="pb-2"/>
      </template>
    </virtual-list>
    <template v-else>
      <search-result v-for="result in items"
                    :key="result.id"
                    :result="result"
                    @open="$emit('open', $event)"
                    @info="$emit('info', $event)"/>
    </template>
  </section>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import SearchResult from './SearchResult'
import SearchResultLayout from './SearchResultLayout'
import VirtualList from 'vue3-virtual-scroll-list'
import WidgetLoadingIcon from '@/components/widgets/components/WidgetLoadingIcon'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { useBrowseFlows } from '@/components/widgets/composables/useBrowseFlows'

export default {
  name: 'SearchResultsList',
  components: {
    SearchResult,
    SearchResultLayout,
    VirtualList,
    WidgetLoadingIcon
  },
  SearchResultLayout,
  props: {
    items: { type: Array, required: true },
    total: { type: Number, required: true },
    offset: { type: Number, required: true },
    isGettingMore: Boolean
  },
  emits: ['widgetNavToContent', 'getMoreSearchResults'],
  setup(props, { emit }) {
    const store = useStore()
    const { isWithinBrowseContext } = useWidgetNavigator()
    const {
      isInitialSearchResultsMount,
      removeLastFromBrowseHistory,
      setIsInitialSearchResultsMount } = useBrowseFlows()

    onMounted(() => {
      const isWidget = store.getters['widgets/isWidget']
      const shouldRedirectToSingleResult = isWidget
        && isWithinBrowseContext.value
        && isInitialSearchResultsMount.value
        && (props.items.length === 1)
      if (shouldRedirectToSingleResult) {
        removeLastFromBrowseHistory()
        emit('widgetNavToContent', props.items[0])
      }
      setIsInitialSearchResultsMount(false)
    })
  },
  computed: {
    hasAllSearchResults() {
      return this.items.length === this.total
    },
    results() {
      return this.items.map(item => {
        const entityType = this.getEntityType(item)
        return {
          ...item,
          uri: `/${entityType}/${item.entityId}`,
          query: this.query,
          identifierWithSubtitle: this.getIdentifier(item)
        }
      })
    },
    isWidget() {
      return this.$store.getters['widgets/isWidget']
    },
    hideMediaIdentifier () {
      return this.$store.state.user.hideIdentifier
    },
    query () {
      const { query: { q } } = this.$route
      return { q }
    },
    scroll () {
      return `
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
      `
    },
  },
  methods: {
    getEntityType({ mediaType, entityType }) {
      return mediaType || entityType
    },
    getIdentifier(result) {
      const entityType = this.getEntityType(result)
      switch (entityType) {
        case 'part':
          return `${this.$t('partNumber')}: ${result.identifier}`
        case 'page':
          return `${this.$t('identifier')}: ${result.identifier}`
        default:
          return this.hideMediaIdentifier
            ? ''
            : `${this.$t('identifier')}: ${result.identifier}`
      }
    },
    getMoreSearchResults() {
      if (!this.hasAllSearchResults) {
        this.$emit('getMoreSearchResults')
      }
    }
  }
};
</script>
<style>
.search-result-wrapper {
  padding: 0.5rem;
  padding-top: 1rem;
  border-bottom: lightgray 1px solid;
}
.search-result-wrapper:nth-child(even) {
  background: #fafafa;
}
.search-result-wrapper:hover {
  background: whitesmoke;
}
</style>
